import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

import { environment } from 'src/environments/environment';
import { SeoModel } from '../models/seo.model';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  _devMode = !environment.production;

  setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  maybeFixUrlProtocol(url: string): string {
    if (url.startsWith('//')) {
      return `https:${url}`;
    }
    return url;
  }

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : this.maybeFixUrlProtocol(url);
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  setBlockRobots() {
    this.metaTagService.addTags([{ name: 'robots', content: 'noindex, follow' }]);
  }

  setFullBlockRobots() {
    this.metaTagService.addTags([{ name: 'robots', content: 'noindex, nofollow' }]);
  }

  setSeoModel(seo: SeoModel) {
    // if (this._devMode) console.log('setSeoModel:: ', seo);
    let robotsIndex = 'noindex';
    let robotsFollow = 'follow';

    if (environment.production) {
      robotsIndex = seo['robotsIndex'] ? 'index' : 'noindex';
      robotsFollow = seo['robotsFollow'] ? 'follow' : 'nofollow';
    }

    this.metaTagService.updateTag({ name: 'robots', content: `${robotsIndex}, ${robotsFollow}` });

    this.metaTagService.updateTag({ name: 'og:site_name', content: 'Expat Explore Travel' });

    if (seo['shareUrl'] && seo['shareUrl'].length) {
      this.metaTagService.updateTag({ name: 'og:url', content: this.maybeFixUrlProtocol(seo['shareUrl']) });
    }

    if (seo['canonicalUrl'] && seo['canonicalUrl'].length) {
      this.setCanonicalURL(this.maybeFixUrlProtocol(seo['canonicalUrl']));
    }

    if (seo['seoTitle'] && seo['seoTitle'].length) {
      this.titleService.setTitle(seo['seoTitle']);
    }
    if (seo['shareTitle'] && seo['shareTitle'].length) {
      this.metaTagService.updateTag({ name: 'og:title', content: seo['shareTitle'] });
    }

    if (seo['shareImage'] && seo['shareImage'].url?.length) {
      this.metaTagService.updateTag({ name: 'og:image', content: this.maybeFixUrlProtocol(seo['shareImage']['url']) });
    }
    if (seo['shareDescription'] && seo['shareDescription'].length) {
      this.metaTagService.updateTag({ name: 'og:description', content: seo['shareDescription'] });
    }
    if (seo['focusKeywords'] && seo['focusKeywords'].length) {
      this.metaTagService.updateTag({ name: 'keywords', content: seo['focusKeywords'] });
    }
    if (seo['shareAuthor'] && seo['shareAuthor'].length) {
      this.metaTagService.updateTag({ name: 'article:author', content: seo['shareAuthor'] });
    }
    if (seo['sharePublisher'] && seo['sharePublisher'].length) {
      this.metaTagService.updateTag({ name: 'article:publisher', content: seo['sharePublisher'] });
    }
    if (seo['shareType'] && seo['shareType'].length) {
      this.metaTagService.updateTag({ name: 'og:type', content: seo['shareType'].toLowerCase() });
    }
    if (seo['metaDescription'] && seo['metaDescription'].length) {
      this.metaTagService.updateTag({ name: 'description', content: seo['metaDescription'] });
    }

    if (seo['shareImage'] && seo['shareImage'].url.length) {
      this.metaTagService.addTags([{ name: 'og:image', content: this.maybeFixUrlProtocol(seo['shareImage'].url) }]);
    }
  }

  setHelperSeoModel(tourTitle: string, seo: SeoModel) {
    tourTitle = tourTitle && tourTitle.length ? tourTitle : 'Expat Explore';
    if (this._devMode) console.log('setHelperSeoModel:: ', tourTitle, seo);

    this.metaTagService.updateTag({ name: 'robots', content: `noindex, follow` });

    this.metaTagService.updateTag({ name: 'og:site_name', content: 'Expat Explore Travel' });

    this.titleService.setTitle('Tour Help - ' + tourTitle);
    this.metaTagService.updateTag({ name: 'og:title', content: 'Tour Help - ' + tourTitle });

    if (seo['shareImage'] && seo['shareImage'].url?.length) {
      this.metaTagService.updateTag({ name: 'og:image', content: this.maybeFixUrlProtocol(seo['shareImage']['url']) });
    }
    if (seo['shareDescription'] && seo['shareDescription'].length) {
      this.metaTagService.updateTag({ name: 'og:description', content: seo['shareDescription'] });
    }
    if (seo['focusKeywords'] && seo['focusKeywords'].length) {
      this.metaTagService.updateTag({ name: 'keywords', content: seo['focusKeywords'] });
    }
    if (seo['shareAuthor'] && seo['shareAuthor'].length) {
      this.metaTagService.updateTag({ name: 'article:author', content: seo['shareAuthor'] });
    }
    if (seo['sharePublisher'] && seo['sharePublisher'].length) {
      this.metaTagService.updateTag({ name: 'article:publisher', content: seo['sharePublisher'] });
    }
    if (seo['shareType'] && seo['shareType'].length) {
      this.metaTagService.updateTag({ name: 'og:type', content: seo['shareType'].toLowerCase() });
    }
    if (seo['metaDescription'] && seo['metaDescription'].length) {
      this.metaTagService.updateTag({ name: 'description', content: seo['metaDescription'] });
    }

    if (seo['shareImage'] && seo['shareImage'].url.length) {
      this.metaTagService.addTags([{ name: 'og:image', content: this.maybeFixUrlProtocol(seo['shareImage'].url) }]);
    }
  }

  setYoastSeo(json) {
    if (this._devMode) console.log('setYoastSeo:: ', json);
    const filterKeys = [];
    let robotsIndex = 'noindex';
    let robotsFollow = 'follow';

    if (environment.production) {
      // robotsIndex = json.robots.index;
      robotsIndex = 'index';
      robotsFollow = json.robots.follow;
    }

    this.metaTagService.updateTag({ name: 'robots', content: `${robotsIndex}, ${robotsFollow}` });

    if (json['title'] && json['title'].length) {
      this.titleService.setTitle(json['title']);
      filterKeys.push('title');
    }

    if (json['canonical'] && json['canonical'].length) {
      this.setCanonicalURL(this.maybeFixUrlProtocol(json['canonical']));
    } else {
      const parsedUrl = new URL(window.location.href);
      const baseUrl = this.maybeFixUrlProtocol(parsedUrl.href);
      this.setCanonicalURL(baseUrl);
      filterKeys.push('canonical');
    }

    if (json['description'] && json['description'].length) {
      this.metaTagService.addTags([{ name: 'description', content: json['description'] }]);
      filterKeys.push('description');
    }

    const metaTags = Object.keys(json)
      .filter(key => !filterKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = json[key];
        return obj;
      }, {});

    Object.keys(metaTags).forEach((key, index) => {
      if (typeof metaTags[key] === 'string') {
        this.metaTagService.addTags([{ name: this.setMetaName(key), content: metaTags[key] }]);
      }

      if (typeof metaTags[key] === 'object') {
        Object.keys(metaTags[key]).forEach((metaKey, metaIndex) => {
          this.metaTagService.addTags([{ name: this.setMetaName(metaKey), content: metaTags[key][metaKey] }]);
        });
      }

      if (json.og_image && json.og_image[0].url) {
        this.metaTagService.addTags([
          {
            name: 'og:image',
            content: this.formatBlogImgeUrl(json.og_image[0].url.replace(/^http:/, 'https:')),
          },
        ]);
        this.metaTagService.addTags([
          {
            name: 'image',
            content: this.formatBlogImgeUrl(json.og_image[0].url.replace(/^http:/, 'https:')),
          },
        ]);
      }
    });
  }

  setMetaName(name: string) {
    let newName = name.replace('_', ':');
    newName = name.replace('_', ':');
    newName = name.replace('_', ':');
    return newName;
  }

  formatBlogImgeUrl(imageUrl: string) {
    environment.blogLegacyUrls.forEach(legacyUrl => {
      imageUrl = imageUrl.replace(legacyUrl, environment.blogBaseUrl);
    });
    return this.maybeFixUrlProtocol(imageUrl);
  }
}
