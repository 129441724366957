import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { isPlatformBrowser } from '@angular/common';
import { SubSink } from 'subsink';
import { SeoService } from '@app/services/seo.service';
import { CheckoutService } from '@app/feature/checkout/services/checkout.service';
import { CheckoutState } from '@app/feature/checkout/store/checkout.state';
import { Observable } from 'rxjs';
import { CartData, CheckoutStateModel, OptionalExtra } from '@app/feature/checkout/dto/types';
import { GeoModel } from '@models/geo.model';
import { Option } from '@models/tour.model';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import {
  EnableMaximumForOptionalExtra,
  ReturningTraveller,
  SaveTravellerDetails,
  SetCheckoutLoading,
  SetCurrentPage,
  SetHasAlteredStepOne,
  UpdateOption,
  UpdateReservationData,
  UpdateTotalPrice,
} from '@app/feature/checkout/store/checkout.actions';
import { map, take } from 'rxjs/operators';
import { AmplitudeService } from '@app/services/amplitude.service';
import { ErrorHandlerService } from '@app/services/error-handler.service';
import { maxLengthValidator, minLengthValidator } from '@app/feature/forms/custom-form-validators';
import { CustomFormComponent } from '@app/feature/forms/custom-form/custom-form.component';
import { GeoService } from '@app/services/geo.service';
import { FormBuilder } from '@angular/forms';
import { SetUserEmail, SetUserProperties } from '@app/feature/session-tracking/store/user-details.state';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss', '../../styles/checkout-common.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Step1Component implements OnInit, OnDestroy, AfterViewInit {
  protected readonly minLengthValidator = minLengthValidator;
  protected readonly maxLengthValidator = maxLengthValidator;

  @ViewChild('customForm') customForm: CustomFormComponent;
  @ViewChild('visibleForm') visibleForm: CustomFormComponent;
  @ViewChild('returnTravellerForm') returnTravellerForm: CustomFormComponent;

  state$: Observable<CheckoutStateModel>;
  subs: SubSink = new SubSink();
  geo: GeoModel;
  loading = true;
  processing = true;
  optionalExtras$: Observable<OptionalExtra[]>;
  countryData = [];
  optionalServices: any; //set the type from open api
  totalExtrasCost = 0;
  notProcessingOrLoading = false;
  formChanged$: Observable<boolean>;
  numPax: number;
  departurePrice: number;
  depositPrice$: Observable<number>;

  constructor(
    private router: Router,
    private store: Store,
    private seoService: SeoService,
    private amplitudeService: AmplitudeService,
    private checkoutService: CheckoutService,
    private errorService: ErrorHandlerService,
    private geoService: GeoService,
    private renderer: Renderer2,
    public cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.subs.sink = this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
        }
      });
    }
  }

  /**
   * Cleanup subscriptions
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
    this.renderer.removeStyle(document.body, 'height');
    this.renderer.removeStyle(document.body, 'background-color');
  }

  /**
   * Initializes the component by setting the SEO title and blocking robots from indexing the page.
   * Subscribes to the checkout state and fetches new prices for the selected tour option.
   *
   * This method performs the following steps:
   * 1. Sets the SEO title to 'Checkout Step 1 - Expat Explore'.
   * 2. Blocks robots from indexing the page.
   * 3. Dispatches the `SetCurrentPage` action to set the current page to 'step1'.
   * 4. Selects the current state from the store.
   * 5. Subscribes to the state observable and saves the geo data to a local variable.
   * 6. Calls the `getNewPrices` method to fetch the new prices for the selected tour option.
   */
  ngOnInit() {
    this.seoService.setTitle('Checkout Step 1 - Expat Explore');
    this.seoService.setFullBlockRobots();
    this.renderer.setStyle(document.body, 'height', '100%');
    this.renderer.setStyle(document.body, 'background-color', '#FFFFFF');
    this.countryData = this.geoService.countryData;
    this.subs.sink = this.store.select(CheckoutState.notProcessingOrLoading).subscribe(notProcessingOrLoading => {
      this.notProcessingOrLoading = notProcessingOrLoading;
    });

    this.state$ = this.store.select(CheckoutState.getState);
    this.store.dispatch(new SetCurrentPage(1));

    this.formChanged$ = this.store.select(CheckoutState.hasAlteredStepOne);
    this.optionalExtras$ = this.store.select(state => state.checkout.optionalExtras);
    this.depositPrice$ = this.store.select(CheckoutState.depositPrice);

    this.calculateTotalExtrasCost();

    this.subs.sink = this.state$.subscribe(state => {
      this.numPax = state?.cartItems[0]?.numPax;
      this.departurePrice = state?.cartItems[0]?.option?.departures[0]?.price?.amount;
    });

    this.subs.sink = this.state$.pipe(take(1)).subscribe(state => {
      this.geo = state?.geo;
      this.getNewPrices(state);
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.subs.sink = this.state$.pipe(take(1)).subscribe(state => {
      if (state.travellerDetails) {
        this.customForm.formGroup.patchValue(state.travellerDetails);
        const isChecked = this.customForm?.formGroup?.get('termsConditions')?.value;
        this.visibleForm?.formGroup?.get('visibleTermsConditions')?.setValue(isChecked);
      }
    });
    this.checkoutService.trackCheckoutNav('Checkout Step Loaded');
    this.store.dispatch(new SetCheckoutLoading(false));

    this.cdr.detectChanges();

    if (this.customForm?.formGroup) {
      this.subs.sink = this.customForm.formGroup.valueChanges.subscribe(() => {
        this.formFirstEdit();
      });
    }
  }

  /**
   * Fetches the new prices for the selected tour option and updates the state with the new prices.
   *
   * @param state - The current state of the checkout process, including cart items and geo information.
   *
   * This function performs the following steps:
   * 1. Calls the `getOptionPrices` method of the `CheckoutService` to fetch the new prices for the selected tour option.
   * 2. Maps the response to include both the response and the current state.
   * 3. Subscribes to the observable returned by the `getOptionPrices` method.
   *    - On success, it calls the `handlePrices` method with the new prices and the current state.
   *    - On error, it calls the `handlePricesError` method with the error and the current state.
   *
   * @param state - The current state of the checkout process, including cart items and geo information.
   */
  getNewPrices(state: CheckoutStateModel) {
    this.subs.sink = this.checkoutService
      .getOptionPrices(state.cartItems[0]?.tourId, state.cartItems[0]?.optionId, state.geo.currency)
      .pipe(map(response => ({ response, state })))
      .subscribe({
        next: ({ response: option, state }: { response: Option; state: CheckoutStateModel }) => {
          this.handlePrices({ response: option, state });
        },
        error: (error: HttpErrorResponse) => {
          this.handlePricesError({ error, state: this.store.snapshot().checkout });
        },
      });
  }

  /**
   * Handles the response from the `getOptionPrices` method and updates the state with the new prices.
   *
   * @param response - The new prices for the selected tour option.
   * @param state - The current state of the checkout process, including cart items and geo information.
   *
   * This method performs the following steps:
   * 1. Checks if the `priceFrom` property is present in the response.
   *    - If present, it dispatches the `UpdateOption` action to update the cart item with the new prices.
   *    - If not present, it calls the `noPricingAvailable` method to handle the missing pricing information.
   * 2. Sets the `showProcessing` flag to `false` after updating the state.
   */
  handlePrices({ response: option, state }: { response: Option; state: CheckoutStateModel }) {
    if (option.priceFrom) {
      this.store.dispatch(new UpdateOption(option, state.cartItems[0]?.tourId));
      this.loading = false;
      this.processing = false;
    } else {
      this.noPricingAvailable('missing', state.cartItems[0].tourData.slug);
    }
  }

  formatPrice(price: any) {
    return this.checkoutService.formatPrice(price, this.geo.currency_sign);
  }

  /**
   * Handles errors from the `getOptionPrices` method and displays an appropriate error message.
   *
   * @param error - The error response from the `getOptionPrices` method.
   * @param state - The current state of the checkout process, including cart items and geo information.
   *
   * This method checks the error status and calls the `noPricingAvailable` method with the appropriate message.
   */
  handlePricesError({ error, state }: { error: HttpErrorResponse; state: CheckoutStateModel }) {
    this.noPricingAvailable(error.error, state.cartItems[0].tourData.slug);
  }

  /**
   * Proceeds to the next step in the checkout process by creating a cart with the current state data.
   *
   * This method performs the following steps:
   * 1. Subscribes to the current state observable.
   * 2. Extracts the necessary data from the state, including currency code and number of passengers.
   * 3. Calls the `createCart` method with the extracted data to create a cart.
   */
  onStepNext() {
    this.processing = true;

    this.customForm.formGroup.markAllAsTouched();
    this.visibleForm.formGroup.markAllAsTouched();

    if (this.customForm.formGroup.valid) {
      this.store.dispatch(new SaveTravellerDetails(this.customForm.formGroup.value));
      this.store.dispatch(new SetUserEmail(this.customForm.formGroup.value.email)).subscribe(() => {
        this.store.dispatch(
          new SetUserProperties({
            first_name: this.customForm.formGroup.value.firstName,
            last_name: this.customForm.formGroup.value.lastName,
          })
        );
      });

      if (this.returnTravellerForm.formGroup.valid) {
        const returnTravellerValues = this.returnTravellerForm.formGroup.value;
        const additionalMessage = `Return Traveller - Email: ${returnTravellerValues.email}, Booking Number: ${returnTravellerValues.bookingNumber}`;
        this.customForm.formGroup.patchValue({
          message: `${this.customForm.formGroup.value.message} ${additionalMessage}`,
        });
      }

      this.subs.sink = this.state$.pipe(take(1)).subscribe(state => {
        // Extract the optional extras from the state
        const optionals: string[] = [];
        state.optionalExtras.forEach(extra => {
          for (let i = 0; i < extra.amount; i++) {
            optionals.push(extra.shoppingCartReference);
          }
        });

        const cartData: CartData = {
          currencyCode: this.geo.currency,
          numberOfPax: state.cartItems[0]?.numPax,
          optionals: optionals,
          promoCode: '',
        };

        if (state.appliedDiscountData?.promoCode !== '' || state.promoStateData) {
          cartData.promoCode = state.promoStateData?.promoCode || state.appliedDiscountData?.promoCode;
        }

        this.createCart(state.cartItems[0].tourId, state.cartItems[0].optionId, cartData);
        this.checkoutService.amplitudeTrackEvents('Next Step click');
        this.checkoutService.trackCheckoutNav('Next Step click');
      });
    } else {
      this.processing = false;
    }
  }

  /**
   * Creates a cart with the selected tour option and user data.
   *
   * @param tourId - The ID of the selected tour.
   * @param optionId - The ID of the selected tour option.
   * @param cartData - The user data required to create the cart.
   *
   * This method performs the following steps:
   * 1. Calls the `createCart` method of the `CheckoutService` to create a cart with the selected tour option and user data.
   * 2. Subscribes to the observable returned by the `createCart` method.
   *    - On success, it calls the `handleReservationData` method with the response data.
   *    - On error, it calls the `handleReservationDataError` method with the error response.
   */
  createCart(tourId: string, optionId: string, cartData: CartData) {
    this.checkoutService.createCart(tourId, optionId, cartData).subscribe({
      next: (data: any) => {
        this.handleReservationData(data);
      },
      error: (e: HttpErrorResponse) => {
        this.handleReservationDataError(e);
        this.processing = false;
      },
    });
  }

  /**
   * Handles the reservation data by dispatching the `UpdateReservationData` action
   * and navigating to the next step in the checkout process.
   *
   * @param {any} data - The reservation data returned from the `createCart` method.
   *
   * This method performs the following steps:
   * 1. Dispatches the `UpdateReservationData` action with the reservation data.
   * 2. Sets the `processing` flag to `false`.
   * 3. Navigates to the next step in the checkout process (`/checkout/step2`).
   */
  handleReservationData(data: any) {
    this.store.dispatch(new UpdateReservationData(data));
    this.store.dispatch(new UpdateTotalPrice(data.totalPrice.amount));
    this.processing = false;
    this.router.navigate(['/checkout/step2']);
  }

  reloadWindow() {
    window.location.reload();
  }

  /**
   * Handles the change event for the returning traveller checkbox.
   *
   * This method performs the following steps:
   * 1. Extracts the checked status from the event target.
   * 2. Dispatches the `ReturningTraveller` action with the checked status.
   *
   * @param {Event} event - The change event triggered by the returning traveller checkbox.
   */
  onReturnTravellerChange(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.store.dispatch(new ReturningTraveller(isChecked));
    const eventName = isChecked ? 'Trigger Return Traveller On' : 'Trigger Return Traveller Off';
    this.checkoutService.amplitudeTrackEvents(eventName);
  }

  /**
   * Retrieves the number of times a specific optional extra has been chosen.
   *
   * This method performs the following steps:
   * 1. Subscribes to the `optionalExtras$` observable to get the list of optional extras.
   * 2. Finds the optional extra with the matching string id: `extraId`.
   * 3. Returns the amount (number) of the matched optional extra.
   *
   * @param {string} extraId - The ID of the optional extra to count.
   * @returns {number} - The number of times the optional extra has been chosen.
   */
  getNumberOfOptionalsChosen(extraId: string): number {
    let amount = 0;
    this.subs.sink = this.optionalExtras$.subscribe(optionalExtras => {
      const matchedExtra = optionalExtras.find(extra => extra.id === extraId);
      if (matchedExtra) {
        amount = matchedExtra.amount;
      }
    });
    return amount;
  }

  /**
   * Calculates the total cost of all selected optional extras.
   *
   * This method performs the following steps:
   * 1. Subscribes to the `optionalExtras$` observable to get the list of optional extras.
   * 2. Iterates over the optional extras and calculates the total cost by multiplying the amount and price of each extra.
   * 3. Updates the `totalExtrasCost` property with the calculated total cost.
   */
  calculateTotalExtrasCost(): void {
    this.subs.sink = this.optionalExtras$.subscribe(optionalExtras => {
      let total = 0;
      if (Array.isArray(optionalExtras)) {
        for (const extra of optionalExtras) {
          total += extra.amount * extra.price.amount;
        }
      }
      this.totalExtrasCost = total;
    });
  }

  formFirstEdit() {
    this.subs.sink = this.formChanged$.subscribe((hasAlteredStepOne: boolean) => {
      if (!hasAlteredStepOne) {
        this.store.dispatch(new SetHasAlteredStepOne(true));
        this.checkoutService.gaTrackEvents('begin_checkout');
      }
    });
  }

  onVisibleTermsChange(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.customForm.formGroup.get('termsConditions').setValue(isChecked);
  }

  //------------------------- Improve this approach using a global notification + global tracking store WBP-1749 + WBP-1750 -------------------------
  fullCartMessage(redirectSlug: string) {
    Swal.fire({
      icon: 'warning',
      title: 'Departure recently reserved',
      html: `<p>This departure has very recently been reserved, please try a different departure for this tour.</p>`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: '#0986c8',
      customClass: {
        container: 'throw-error-generic',
        confirmButton: 'throw-error-generic-button',
      },
      didClose: () => {
        this.router.navigate([`/tours/${redirectSlug}`]);
      },
    });
  }

  retryMessage() {
    Swal.fire({
      icon: 'warning',
      title: 'An unexpected error has occurred',
      html: `<p>We were unable to reserve seat(s). Please try again or contact our <a href="/contact-us" title="contact us" target="_blank">friendly customer service</a> </p>`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: '#0986c8',
      confirmButtonText: 'Retry',
      didClose: () => {
        this.reloadWindow();
      },
      customClass: {
        container: 'throw-error-generic',
        confirmButton: 'throw-error-generic-button',
      },
    });
  }

  noPricingAvailable(error: any, redirectSlug: string) {
    if (error.status === 400 && error.error?.message?.response?.cartFull === true) {
      //Sold out departure send back to tour
      this.checkoutService.amplitudeTrackEvents('On Step Load Error - Departure Reserved');
      this.fullCartMessage(redirectSlug);
    } else {
      //priceFrom missing from getTourOptionalServices
      this.checkoutService.amplitudeTrackEvents('On Step Load Error - priceFrom missing');
      this.retryMessage();
    }
  }

  handleReservationDataError(e: HttpErrorResponse) {
    const state = this.store.snapshot().checkout;
    this.trackCartEventsAmplitude(`Create Cart Failure`, e);
    if (e.status === 400 && e.error?.message?.response?.cartFull === true) {
      //Sold out departure send back to tour
      this.checkoutService.amplitudeTrackEvents('On Next Step Error - Departure Reserved');
      this.fullCartMessage(state.cartItems[0].tourData.slug);
    } else if (e.status === 422) {
      // Departure does not have requested amount upgrades available
      this.checkoutService.amplitudeTrackEvents('On Next Step Error - Insufficient Room Upgrades');
      const upgradeId = e.error.data.upgradeId;
      this.store.dispatch(new EnableMaximumForOptionalExtra(upgradeId, parseInt(e.error.data.available)));

      Swal.fire({
        icon: 'warning',
        title: 'Single Supplement Unavailable',
        html: `<p>Sorry, we currently don't have availability for your requested single supplement (Optional Extra). Please contact <a href="/contact-us" target="_blank">Customer Support</a> for assistance, or consider changing the date of your tour.</p>`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: '#0986c8',
        confirmButtonText: 'Change Date',
        customClass: {
          container: 'throw-error-generic',
          confirmButton: 'throw-error-generic-button',
        },
        didClose: () => {
          this.subs.sink = this.state$.pipe(take(1)).subscribe(state => {
            this.router.navigate([`/tours/${state?.cartItems[0]?.tourData?.slug}`]);
          });
        },
      });

      //When we move to multiple optionals we can uncomment the below.
      //It requires some changes from lemax to send us the id of extra that was overbooked.
      //e.error?.data?.forEach((item: { requested: number; available: number; id: string }) => {
      // this.store.dispatch(new EnableMaximumForOptionalExtra(item.id, item.available));
      //});
    } else {
      this.errorService.throwSwal('generic', 'warning', 'create cart', true);
    }
  }

  trackCartEventsAmplitude(event: string, eventData: any) {
    const state = this.store.snapshot().checkout;

    const cartData = {
      event: event,
      'checkout-step': 'step1',
      'tour-name': state.cartItems[0].tourData.name,
      'tour-code': state.cartItems[0].tourData.code,
      'event-status': eventData.status,
      'event-message': eventData.name,
      'tour-id': state.cartItems[0].tourId,
      'departure-id': state.cartItems[0].option.id,
    };
    this.amplitudeService.trackEvent('Cart Interaction', cartData);
  }

  //------------------------- Improve this approach using a global notification + global tracking store WBP-1749 + WBP-1750-------------------------
}
