import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';

import { CheckoutState } from '@app/feature/checkout/store/checkout.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    NgxsModule.forFeature([CheckoutState]),
    NgxsStoragePluginModule.forRoot({
      keys: ['checkout'],
      storage: StorageOption.SessionStorage,
    }),
  ],
})
export class CheckoutStoreModule {}
