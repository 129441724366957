import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss'],
})
export class CtaComponent {
  @Input() cta: 'solid' | 'outline';
  @Input() color: 'yellow' | 'blue' | 'green' | 'red' = 'yellow';
  @Input() location?: string;
  @Input() customClass?: string;
  @Input() disabled? = false;
  @Input() content?: string;
  @Output() buttonSelected? = new EventEmitter<void>();

  constructor(private router: Router) {}

  onClick() {
    if (this.location) {
      this.router.navigateByUrl(this.location);
    } else {
      this.buttonSelected.emit();
    }
  }
}
