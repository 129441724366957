// LoadContent action
import { TourModelLemax } from '@models/tour.model';
import { ImageModel } from '@models/image.model';

export class LoadCompactToursContent {
  static readonly type = '[Content] Load content';
}

export class LoadTourPriceData {
  static readonly type = '[Content] Load tour price data';

  constructor(public tourSlugs: string[]) {}
}

export class SetFullTourLookup {
  static readonly type = '[Content] Set full tour lookup';

  constructor(public data: Record<string, TourModelLemax>) {}
}

export class SetCompactTourMapImages {
  static readonly type = '[Content] Set compact tour images';

  constructor(public imageLookup: Record<string, ImageModel>) {}
}

export class SetContentLoaded {
  static readonly type = '[Content] Set content loaded';

  constructor(public loaded: boolean) {}
}
