import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BrazeService {
  private readonly braze: any;

  constructor(@Inject(PLATFORM_ID) private readonly platformId: NonNullable<unknown>) {
    if (isPlatformBrowser(this.platformId)) {
      this.braze = require('@braze/web-sdk');
      this.initializeBraze();
    }
  }

  initializeBraze() {
    if (this.braze && environment.braze?.key && environment.braze?.baseUrl) {
      this.braze.initialize(environment.braze.key, {
        baseUrl: environment.braze.baseUrl,
        enableLogging: !environment.production,
        allowUserSuppliedJavascript: true,
      });

      this.braze.openSession();
      this.braze.requestImmediateDataFlush();
      this.braze.automaticallyShowInAppMessages();

      const subIAM = this.braze.subscribeToInAppMessage(inAppMessage => {
        this.handleInAppMessageDismissal(subIAM, inAppMessage);
      });
    }
  }

  handleInAppMessageDismissal(subIAM, inAppMessage) {
    inAppMessage.subscribeToDismissedEvent(() => {
      this.braze.removeSubscription(subIAM);
    });
  }

  setUserId(userId: string): void {
    if (!userId) return;
    this.braze.changeUser(userId);
  }

  setUserNames(firstName: string, lastName: string): void {
    const user = this.braze.getUser();
    user.setFirstName(firstName);
    user.setLastName(lastName);
  }

  setUserEmail(email: string): void {
    if (!email) return;
    const user = this.braze.getUser();
    user.setEmail(email);
    this.braze.requestImmediateDataFlush();
  }

  logCustomEvent(eventName: string, eventProperties?: Record<string, any>): void {
    this.braze.logCustomEvent(eventName, eventProperties);
  }

  subscribeToNewsletterGroup(): void {
    this.subscribeToGroup(environment.braze.newsletterSegment);
  }

  subscribeToGroup(subscriptionGroupId: string): void {
    if (!subscriptionGroupId) return;

    const user = this.braze.getUser();
    if (user) {
      user.addToSubscriptionGroup(subscriptionGroupId);
      user.setEmailNotificationSubscriptionType('opted_in');
    }
  }
}
