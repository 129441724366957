// actions for SetPage and SetLimit
export class SetPage {
  static readonly type = '[Pagination] Set page';

  constructor(readonly page: number) {}
}

export class SetLimit {
  static readonly type = '[Pagination] Set limit';

  constructor(readonly limit: number) {}
}
