import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberise',
  standalone: true,
})
export class NumberisePipe implements PipeTransform {
  /**
   * Transforms input value to a number.
   * Accepts both string and number inputs.
   * - If the input is a number, it is returned directly.
   * - If the input is a string, attempts to parse it as a floating point number.
   * Throws an error if the input string cannot be converted to a number.
   *
   * @param value The input value to be transformed.
   * @returns The input value converted to a number.
   * @throws Error if the input string cannot be converted to a number.
   */
  transform(value: string | number): number {
    if (typeof value === 'number') {
      return value;
    } else if (typeof value === 'string') {
      const convertedNumber = parseFloat(value);
      if (!isNaN(convertedNumber)) {
        return convertedNumber;
      }
    }
    throw new Error('Input cannot be converted to a number');
  }
}
