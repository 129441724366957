import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CheckoutStoreModule } from '@app/feature/checkout/store/checkout-store.module';
import { Step1Component } from '@app/feature/checkout/ui/pages/step1/step1.component';
import { Step2Component } from '@app/feature/checkout/ui/pages/step2/step2.component';
import { PaymentsComponent } from '@app/feature/checkout/ui/pages/payments/payments.component';
import { SuccessComponent } from '@app/feature/checkout/ui/pages/success/success.component';
import { CheckoutService } from '@app/feature/checkout/services/checkout.service';
import { CheckoutRoutingModule } from '@app/feature/checkout/checkout-routing.module';
import { CheckoutHeaderComponent } from '@app/feature/checkout/ui/components/checkout-header/checkout-header.component';
import { CheckoutNavigationComponent } from '@app/feature/checkout/ui/components/checkout-navigation/checkout-navigation.component';
import { NeedAssistanceComponent } from '@app/feature/checkout/ui/components/need-assistance/need-assistance.component';
import { CustomFormModule } from '@app/feature/forms/custom-form.module';
import { CheckoutConfig } from '@app/feature/checkout/dto/checkout.config';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TourSummaryComponent } from '@app/feature/checkout/ui/components/tour-summary/tour-summary.component';
import { SharedModule } from '@app/shared/shared-modules';
import { PaymentSelectorComponent } from '@app/feature/checkout/ui/components/payment-selector/payment-selector.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PromocodeComponent } from '@app/feature/checkout/ui/components/promocode/promocode.component';
import { CheckoutFooterComponent } from '@app/feature/checkout/ui/components/checkout-footer/checkout-footer.component';
import { MakePaymentsComponent } from '@app/feature/checkout/ui/components/make-payments/make-payments.component';
import { PaymentDateSelectorComponent } from '@app/feature/checkout/ui/components/payment-date-selector/payment-date-selector.component';
import { SecurityBarComponent } from '@app/feature/checkout/ui/components/security-bar/security-bar.component';

@NgModule({
  declarations: [
    CheckoutNavigationComponent,
    Step1Component,
    Step2Component,
    PaymentsComponent,
    SuccessComponent,
    TourSummaryComponent,
    PaymentSelectorComponent,
    PromocodeComponent,
    SecurityBarComponent,
  ],
  imports: [
    CommonModule,
    CheckoutStoreModule,
    CheckoutRoutingModule,
    CustomFormModule,
    ReactiveFormsModule,
    FormsModule,
    CheckoutHeaderComponent,
    NeedAssistanceComponent,
    SharedModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCheckboxModule,
    MakePaymentsComponent,
    CheckoutFooterComponent,
    PaymentDateSelectorComponent,
  ],
  exports: [
    CheckoutHeaderComponent,
    CheckoutNavigationComponent,
    NeedAssistanceComponent,
    Step1Component,
    Step2Component,
    PaymentsComponent,
    SuccessComponent,
    TourSummaryComponent,
    PaymentSelectorComponent,
    PromocodeComponent,
    SecurityBarComponent,
  ],
  providers: [CheckoutService, CheckoutConfig],
})
export class CheckoutModule {}
