<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="alert alert-success security-bar-top" role="alert">
      <div class="row">
        <div class="thawteSecurity col-md-8 col-12 align-self-center">
          <span class="desktopSecureText d-none d-lg-block">
            <i class="lock-icon"></i> You are in a 256-bit encrypted secure checkout environment.
          </span>
          <span class="mobileSecureText d-inline-block d-lg-none">
            <i class="lock-icon"></i> 256-bit encrypted secure checkout.
          </span>
        </div>
        <div class="thawteSecurity-icons col">
          <div class="securityMini d-none d-xs-block d-md-none">
            <script
              type="text/javascript"
              src="https://seal.thawte.com/getthawteseal?host_name=expatexplore.com&amp;size=M&amp;lang=en"></script>
          </div>
          <div class="d-none d-lg-block">
            <script
              type="text/javascript"
              src="https://seal.thawte.com/getthawteseal?host_name=expatexplore.com&amp;size=S&amp;lang=en"></script>
          </div>
          <div class="mastercard">
            <img
              src="https://images.ctfassets.net/huwbudysxepr/2s5wIzyg38MOqXVSoD6vbF/2cda8339bfabefac63b4117fd2482d47/mastercard.svg"
              alt="mastercard" />
          </div>
          <div class="visa">
            <img
              src="https://images.ctfassets.net/huwbudysxepr/4Gb3iAzOpC7oCjxySvpBxl/5d4051aa2e27a1db2cf7f60e784908a4/visa.svg"
              alt="visa" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
