import { Component, Input, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplitudeService } from '@app/services/amplitude.service';
import { AccountService } from '@app/services/account.service';
import { AccountModel, Wishlist } from '@models/account.model';
import { TourModel } from '@models/tour.model';
import Swal from 'sweetalert2';
import { TourWishlistButtonTourData } from '@app/shared/components/tour-wishlist-button/tour-wishlist-button.dto';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-tour-wishlist-button',
  templateUrl: './tour-wishlist-button.component.html',
  styleUrls: ['./tour-wishlist-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TourWishlistButtonComponent implements OnInit, OnDestroy {
  @Input() tourFullData?: TourModel;
  @Input() tourData?: TourWishlistButtonTourData;
  @Input() shortText? = false;
  accountData: AccountModel;
  wishlistItem: Wishlist;
  pageLocation: string;
  userActive = false;
  updatingWishlist = false;
  wishlisted = false;
  private subs = new SubSink();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private amplitudeService: AmplitudeService
  ) {
    if (this.accountService.loginToggle) {
      this.subs.sink = this.accountService.loginToggle.subscribe(() => this.setLoginStatus());
    }
  }

  ngOnInit() {
    if (this.tourData) {
      this.wishlistItem = {
        image: this.tourData.mapImage.url,
        code: '0',
        name: this.tourData.tourName,
        days: this.tourData.numberOfDays,
        campaign: false,
        tour: this.tourData.tourCode,
        url: 'https://expatexplore.com/tours/' + this.tourData.slug,
      };
    } else if (this.tourFullData) {
      this.wishlistItem = {
        image: this.tourFullData.itinerary[0].map.url,
        code: '0',
        name: this.tourFullData.tourName,
        days: this.tourFullData.numberOfDays,
        campaign: false,
        tour: this.tourFullData.tourCode,
        url: 'https://expatexplore.com/tours/' + this.tourFullData.slug,
      };
    }

    this.setLoginStatus();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setLoginStatus() {
    this.subs.sink = this.accountService.accountData.subscribe((accountData: AccountModel) => {
      if (accountData) {
        this.userActive = true;
        this.accountData = accountData;
        if (this.accountData.accountInfo.wishlist) {
          this.wishlisted = !!this.accountData.accountInfo.wishlist.find(a => a.tour === this.wishlistItem?.tour);
        }
      } else {
        this.userActive = false;
        this.wishlisted = false;
      }
    });
  }

  addRemoveWishlistItem(tourCode: string) {
    if (!this.accountData || !this.userActive) {
      this.openLogin();
      this.trackWishlist('Login', 'Open Login Modal');
    } else {
      this.updatingWishlist = true;
      const accData = this.accountData;
      let existsIndex: number;
      if (accData.accountInfo.wishlist) {
        existsIndex = accData.accountInfo.wishlist.findIndex(wishItem => wishItem.tour === tourCode);
      }
      if (!accData.accountInfo.wishlist || accData.accountInfo.wishlist.length < 1) {
        accData.accountInfo.wishlist = [this.wishlistItem];
        this.wishlisted = true;
        this.updateWishlist(accData);
      } else if (existsIndex > -1) {
        accData.accountInfo.wishlist.splice(existsIndex, 1);
        this.wishlisted = false;
        this.trackWishlist('Wishlist', 'Removed from wishlist');
        this.updateWishlist(accData);
      } else {
        accData.accountInfo.wishlist.push(this.wishlistItem);
        this.wishlisted = true;
        this.trackWishlist('Wishlist', 'Added to wishlist');
        this.updateWishlist(accData);
      }
    }
  }

  private updateWishlist(accData) {
    this.accountService
      .updateAccountDetails(accData.emailAddress, accData.firstName, accData.lastName, accData.accountInfo)
      .then(
        () => {
          this.updatingWishlist = false;
        },
        error => {
          this.updatingWishlist = false;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:
              'Something went wrong while updating wishlist item, our apologies for the inconvenience: ' +
              error.statusText,
          });
        }
      );
  }

  openLogin() {
    this.accountService.triggerAccountModal('login');
  }

  trackWishlist(itemId: string, item: string) {
    let currentRoute = this.route.root;
    while (currentRoute.children[0] !== undefined) {
      currentRoute = currentRoute.children[0];
    }
    const url = this.router.url;
    const queryParams = currentRoute?.snapshot?.queryParams;
    const routeParams = currentRoute?.snapshot?.params;

    const buttonData = {
      'wl-btn-id': itemId,
      'wl-btn': item,
      'wl-btn-url': url,
      'wl-btn-queryParams': queryParams,
      'wl-btn-routeParams': routeParams,
    };
    if (itemId === 'Wishlist') {
      buttonData['tour-name'] = this.wishlistItem.name;
      buttonData['tour-code'] = this.wishlistItem.tour;
      buttonData['tour-campaign'] = this.wishlistItem.campaign;
    }
    this.amplitudeService.trackEvent('Wishlist Button Clicked', buttonData);
  }
}
