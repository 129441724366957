<div *ngIf="inBrowser && data" class="homepage">
  <app-header-carousel-tours
    *ngIf="data.featuredTours"
    [data]="data.featuredTours"
    [searchText]="data.searchText"
    [mobileHeader]="data.mobileHeader"></app-header-carousel-tours>
  <app-reasons-blocks *ngIf="data.reasons" [data]="data.reasons" [heading]="data.reasonsHeading"></app-reasons-blocks>
  <app-category-carousel
    *ngIf="data.categoryCarousel"
    [data]="data.categoryCarousel"
    [heading]="data.categoryCarouselHeading"
    [text]="data.categoryCarouselText"></app-category-carousel>
  <app-filters-carousel
    *ngIf="data.filters"
    [data]="data.filters"
    [heading]="data.filtersHeading"
    [text]="data.filtersText"></app-filters-carousel>
  <app-tabbed-tour-carousel
    *ngIf="data.homePageTourSlider"
    id="home-page-popular-tours"
    [carouselData]="data.homePageTourSlider"
    [allowBuilderTab]="true"></app-tabbed-tour-carousel>
  <app-travel-inspiration
    *ngIf="data.travelStories && data.blogCategoryName"
    [blogCategory]="data.blogCategoryName"
    [data]="data.travelStories"
    [heading]="data.travelInspirationHeading"
    [subText]="data.travelInspirationText"
    [travelStoriesText]="data.travelStoriesText"
    [tipsText]="data.travelTipsText"></app-travel-inspiration>
  <app-signup
    [formDirection]="'horizontal'"
    heading="Join the Expat Explore family!"
    description="We’ll deliver top travel tips, insider info and travel inspiration right to your inbox."></app-signup>
  <app-membership-carousel
    *ngIf="data.memberships"
    [data]="data.memberships"
    [desktopImage]="data.footerImage"
    [mobileImage]="data.footerImageMobile"></app-membership-carousel>
</div>
