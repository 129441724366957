<a *ngIf="!isInternalLink" [href]="url" target="_blank">
  <ng-container *ngTemplateOutlet="innerContent"></ng-container>
</a>
<a *ngIf="isInternalLink" [routerLink]="url | extractPath" [queryParams]="queryParams || {}">
  <ng-container *ngTemplateOutlet="innerContent"></ng-container>
</a>

<ng-template #innerContent>
  <ng-content></ng-content>
</ng-template>
