<button
  class="btn round-button"
  [ngClass]="{
    'round-small': size === 'small',
    'round-large': size === 'large',
    'round-normal': style === 'normal',
    'button-outline': style === 'outline',
    'button-disabled': disabled,
  }"
  [class]="'button-' + color + (customClass ? ' ' + customClass : '')"
  [disabled]="disabled"
  (click)="onClick()">
  <ng-container *ngIf="icon">
    <!-- Chevron Left -->
    <ng-container *ngIf="icon === 'chevron-left'">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="17" viewBox="0 0 11 17" fill="none">
        <path
          d="M0.270476 7.83917L7.75066 0.273578C8.11129 -0.0911927 8.69631 -0.0911927 9.05695 0.273578L9.92948 1.15611C10.2897 1.5205 10.2901 2.11067 9.93102 2.47583L4.00268 8.49981L9.93063 14.5242C10.2901 14.8893 10.2893 15.4795 9.92909 15.8439L9.05656 16.7264C8.69593 17.0912 8.11091 17.0912 7.75027 16.7264L0.270476 9.16044C-0.0901586 8.79567 -0.0901586 8.20394 0.270476 7.83917Z"
          fill="white" />
      </svg>
    </ng-container>
    <!-- Chevron Right -->
    <ng-container *ngIf="icon === 'chevron-right'">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="17" viewBox="0 0 11 17" fill="none">
        <path
          d="M10.7295 9.16083L3.24934 16.7264C2.88871 17.0912 2.30369 17.0912 1.94305 16.7264L1.07052 15.8439C0.710274 15.4795 0.709889 14.8893 1.06898 14.5242L6.99732 8.50019L1.06937 2.47583C0.709887 2.11067 0.710658 1.5205 1.07091 1.15611L1.94344 0.273579C2.30407 -0.0911915 2.88909 -0.0911915 3.24973 0.273579L10.7295 7.83956C11.0902 8.20433 11.0902 8.79606 10.7295 9.16083Z"
          fill="white" />
      </svg>
    </ng-container>
    <!-- Add | Plus -->
    <ng-container *ngIf="icon === 'add'">
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
        <path
          d="M17.6429 7.46429H11.5357V1.35714C11.5357 0.607746 10.928 0 10.1786 0H8.82143C8.07203 0 7.46429 0.607746 7.46429 1.35714V7.46429H1.35714C0.607746 7.46429 0 8.07203 0 8.82143V10.1786C0 10.928 0.607746 11.5357 1.35714 11.5357H7.46429V17.6429C7.46429 18.3923 8.07203 19 8.82143 19H10.1786C10.928 19 11.5357 18.3923 11.5357 17.6429V11.5357H17.6429C18.3923 11.5357 19 10.928 19 10.1786V8.82143C19 8.07203 18.3923 7.46429 17.6429 7.46429Z"
          fill="white" />
      </svg>
    </ng-container>
    <!-- Subtract | Minus -->
    <ng-container *ngIf="icon === 'subtract'">
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="5" viewBox="0 0 19 5" fill="none">
        <path
          d="M17.2188 0H1.78125C0.797852 0 0 0.797852 0 1.78125V2.96875C0 3.95215 0.797852 4.75 1.78125 4.75H17.2188C18.2021 4.75 19 3.95215 19 2.96875V1.78125C19 0.797852 18.2021 0 17.2188 0Z"
          fill="white" />
      </svg>
    </ng-container>
  </ng-container>
</button>
