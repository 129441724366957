import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AmplitudeService } from '@app/services/amplitude.service';
import { BrazeService } from '@app/feature/session-tracking/services/braze.service';
import { SessionTrackingService } from '@app/feature/session-tracking/services/session-tracking.service';
import { UserDetails, UserProperties } from '@app/feature/session-tracking/models/user-tracking.model';
import { tap } from 'rxjs/operators';
import { from, of } from 'rxjs';

export class SetUserDetails {
  static readonly type = '[User] Set User Details';

  constructor(public payload: UserDetails) {}
}

export class SetUserProperties {
  static readonly type = '[User] Set User Properties';

  constructor(public user_properties: UserProperties) {}
}

export class SetUserEmail {
  static readonly type = '[User] Set User Email';

  constructor(public email: string) {}
}

export class SetUserId {
  static readonly type = '[User] Set User ID';

  constructor(public email: string) {}
}

@State<UserDetails>({
  name: 'userDetails',
  defaults: {
    user_id: null,
    amplitude_id: 0,
    user_properties: {
      first_name: '',
      last_name: '',
      email: '',
    },
  },
})
@Injectable()
export class UserDetailsState {
  constructor(
    private readonly sessionTrackingService: SessionTrackingService,
    private readonly amplitudeService: AmplitudeService,
    private readonly brazeService: BrazeService
  ) {}

  @Selector()
  static getState(state: UserDetails) {
    return state;
  }

  @Selector()
  static getUserEmail(state: UserDetails) {
    return state.user_properties.email;
  }

  @Action(SetUserDetails)
  setUserDetails(ctx: StateContext<UserDetails>, action: SetUserDetails) {
    ctx.setState(action.payload);
  }

  @Action(SetUserProperties)
  setUserProperties(ctx: StateContext<UserDetails>, action: SetUserProperties) {
    const state = ctx.getState();
    ctx.patchState({
      user_properties: {
        ...state.user_properties,
        ...action.user_properties,
      },
    });
    this.brazeService.setUserNames(action.user_properties.first_name, action.user_properties.last_name);
  }

  @Action(SetUserEmail)
  setUserEmail(ctx: StateContext<UserDetails>, action: SetUserEmail) {
    return ctx.dispatch(new SetUserId(action.email)).pipe(
      tap(() => {
        const state = ctx.getState();
        ctx.patchState({
          user_properties: {
            ...state.user_properties,
            email: action.email,
          },
        });
        this.brazeService.setUserEmail(action.email);
        return of(null);
      })
    );
  }

  @Action(SetUserId)
  setUserId(ctx: StateContext<UserDetails>, action: SetUserId) {
    return from(this.sessionTrackingService.emailToUserId(action.email)).pipe(
      tap(userId => {
        ctx.patchState({
          user_id: userId,
        });

        this.sessionTrackingService.setUserIdCookie(userId);
        this.amplitudeService.setUserId(userId);
        this.brazeService.setUserId(userId);
      })
    );
  }
}
