import zod from 'zod';

export class ReviewModel {
  review_id: number;
  created_date: string;
  updated_date: number;
  source: string;
  comment: string;
  title: string;
  customer: string;
  status: string;
  total: number;
  avgRating: number;
  reviews: Reviews[];
}

export class Reviews {
  comment: string;
  created_date: string;
  customer: string;
  metrics: ReviewsMetrics;
  review_id: number;
  source: string;
  status: string;
  title: string;
  updated_date: string;
}

export class ReviewsMetrics {
  helpful_votes: number;
  not_helpful_votes: number;
  rating: number;
  helpful_score: number;
}

export const ReviewMetricsSchema = zod.object({
  helpful_votes: zod.number(),
  not_helpful_votes: zod.number(),
  rating: zod.number(),
  helpful_score: zod.number(),
});

export const ReviewSchema = zod.object({
  comment: zod.string(),
  created_date: zod.string(),
  customer: zod.string(),
  metrics: ReviewMetricsSchema,
  review_id: zod.number(),
  source: zod.string(),
  status: zod.string(),
  title: zod.string(),
  updated_date: zod.string(),
});

export const ReviewModelSchema = zod.object({
  review_id: zod.number(),
  created_date: zod.string(),
  updated_date: zod.number(),
  source: zod.string(),
  comment: zod.string(),
  title: zod.string(),
  customer: zod.string(),
  status: zod.string(),
  total: zod.number().int(),
  avgRating: zod.number(),
  reviews: zod.array(ReviewSchema),
});
