import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { Lightbox, LightboxConfig, LightboxEvent } from 'ngx-lightbox';
import { LightboxWindowRef } from 'ngx-lightbox/lightbox-event.service';
import Swal from 'sweetalert2';

import { AmplitudeService } from '@app/services/amplitude.service';
import { ToursService } from '@app/services/tours.service';
import { GeoModel } from '@models/geo.model';
import { AccountService } from '@app/services/account.service';
import { AccountModel } from '@models/account.model';
import { ReviewsService } from '@app/services/reviews.service';
import { GeoService } from '@app/services/geo.service';
import { ReviewModel } from '@models/review.model';
import { CompactTourModel } from '@models/compact-tour.model';
import { countryPluralMap } from '@utils';
import { activeBookingsTourStatuses, TourActions, TourStatus } from '@models/booking-enums';
import { roundPriceForDisplay } from '../../../../lib/utils';
import { isPlatformBrowser } from '@angular/common';
import { TourPromoData } from '@app/feature/promotion/dto/types';
import { PromotionState } from '@app/feature/promotion/store/promotion.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-tour-card',
  templateUrl: './tour-card.component.html',
  styleUrls: ['./tour-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ToursService, Lightbox, LightboxConfig, LightboxEvent, LightboxWindowRef],
})
export class TourCardComponent implements OnInit {
  @Input() data?: CompactTourModel; //@todo we need to phase our compact models out
  @Input() priceData?: any;
  @Input() bookingData?: any;
  @Input() geoData: GeoModel;
  @Input() cardWidth: string;
  @Input() cardSection? = '';
  @Input() mapView? = false;
  @Input() cardType? = 'tour';
  @ViewChild('highlightsContainer') highlightsContainer: ElementRef;
  averageRating = 0;
  totalReviews = 0;
  wishlisted = false;
  soldOut = false;
  departureNoteCampaign = false;
  campaignValues: {
    img: string;
    defaultBgColor: string;
    defaultTextColor: string;
    selectedBgColor: string;
    selectedTextColor: string;
  };
  accountData: AccountModel;
  gallery = [];
  departures = [];
  showHighlights = false;
  selectedDate = new Date();
  campaigns = this.tourService.campaign;
  isCampaignCard = false;
  lowestPriceDeparture: {
    price: any;
    fromPrice: any;
  };
  currentRoute: string;
  tourInactive = false;
  cardLoading = false;
  campaignTourData: TourPromoData;

  public tourActions = TourActions;
  public tourStatus = TourStatus;
  public roundPriceForDisplay = roundPriceForDisplay;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router,
    private route: ActivatedRoute,
    private amplitudeService: AmplitudeService,
    private reviewService: ReviewsService,
    private _lightbox: Lightbox,
    private tourService: ToursService,
    private store: Store,
    private accountService: AccountService,
    private geoService: GeoService
  ) {}

  ngOnInit(): void {
    this.cardLoading = true;
    this.currentRoute = this.route.snapshot.url.join('/');
    if (this.currentRoute === '') this.currentRoute = 'home';

    if (this.data) {
      this.campaignTourData = this.store.selectSnapshot(PromotionState.getPromoDataForSlug(this.data.slug));

      this.getReview(`${this.data.reviewCode}`);

      if (this.data.itinerary) {
        this.data.mapImage = this.data.itinerary[0].map;
      }
      if (this.data.startPoints) {
        this.data.starts = [...new Set(this.data.startPoints.map(point => point.shortName))];
      }
      if (this.data.endPoints) {
        this.data.ends = [...new Set(this.data.endPoints.map(point => point.shortName))];
      }

      this.soldOut = this.priceData.options.soldOut;
      this.departureNoteCampaign = this.priceData.options.campaignTour;
      this.campaignValues = this.priceData.options.campaignValues;
      this.lowestPriceDeparture = this.priceData.options.lowestPriceDeparture;
      if (this.priceData?.options?.flattenedDepartures) {
        this.departures = this.priceData.options.flattenedDepartures
          .filter(dep => !dep.soldOut)
          .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
      }

      // Create gallery of images including map image
      this.gallery.push(this.data.mapImage);
      this.gallery.push(...this.data.photosOfTheTour);

      // Check if we have account data to allow wishlist functionality
      this.accountService.accountData.subscribe((accountData: AccountModel) => {
        if (accountData) {
          this.accountData = accountData;
          if (this.accountData.accountInfo.wishlist) {
            this.wishlisted = !!this.accountData.accountInfo.wishlist.find(a => a.tour === this.data.tourCode); // Show hearts filled on card if tour is in wishlist
          }
        }
      });
    }

    if (this.bookingData?.details?.items) {
      this.bookingData.tour = this.bookingData.details.items[0].tour;
    }
    if (this.data === undefined) {
      this.tourInactive = true;
    }

    this.cardLoading = false;
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (view === 'month' && this.departures) {
      const valid = this.departures.some(
        d =>
          new Date(d['startDate']).getFullYear() === cellDate.getFullYear() &&
          new Date(d['startDate']).getMonth() === cellDate.getMonth() &&
          new Date(d['startDate']).getDate() === cellDate.getDate()
      );
      return valid ? 'open-date' : '';
    }
    return '';
  };

  dateFilter = (date: Date): boolean => {
    return this.departures.some(
      d =>
        new Date(d['startDate']).getFullYear() === date.getFullYear() &&
        new Date(d['startDate']).getMonth() === date.getMonth() &&
        new Date(d['startDate']).getDate() === date.getDate()
    );
  };

  openGallery(galleryArray, itemIndex) {
    //Lightbox image gallery
    const imageGallery = [];
    galleryArray.forEach(item => {
      if (item.url) {
        imageGallery.push({
          src: item.url,
          caption: this.instagramAttribution(item.title),
        });
      }
    });
    this._lightbox.open(imageGallery, itemIndex);
  }

  openHighlights(popUpClass?: string) {
    this.showHighlights = true;
    Swal.fire({
      html: this.highlightsContainer.nativeElement,
      position: 'top-end',
      showCloseButton: true,
      closeButtonHtml:
        '<img alt="Close" src="https://images.ctfassets.net/huwbudysxepr/2cKRl23kBtd4syM45wYsJn/5d24b3989692e3877ef6d83e4f3fd035/close-x.svg"/>',
      showConfirmButton: false,
      customClass: {
        closeButton: 'swal-close-custom',
        container: 'highlights-swal-outer',
        htmlContainer: 'highlights-swal ' + popUpClass,
        popup: 'highlights-swal-wrapper',
      },
      showClass: {
        popup: `
      animate__animated
      animate__fadeInRight
      animate__faster
    `,
      },
      hideClass: {
        popup: `
      animate__animated
      animate__fadeOutRight
      animate__faster
    `,
      },
      grow: 'column',
      didClose: () => {
        this.showHighlights = false;
      },
    });
  }

  calendarChange(event: Date) {
    const date = new Date(event);
    const departure = this.departures.find(
      d =>
        new Date(d['startDate']).getFullYear() === date.getFullYear() &&
        new Date(d['startDate']).getMonth() === date.getMonth() &&
        new Date(d['startDate']).getDate() === date.getDate()
    );
    const queryParams = { dep_code: departure.dateCode };
    const fragment = 'dates-rates';
    Swal.close();
    this.router.navigate(['/tours', this.data.slug], { queryParams, fragment });
  }

  onTourReviewsClick() {
    Swal.close();
    const fragment = 'reviews';
    void this.router.navigate(['/tours', this.data.slug], { fragment });
  }

  instagramAttribution(title: string) {
    const arr = title.split('-_');
    if (typeof arr[1] !== 'undefined' && arr[1] !== 'undefined' && arr[1] !== undefined) {
      return '@' + arr[1];
    } else {
      return '@expatexplore';
    }
  }

  showDateString(startDate: Date | string) {
    if (typeof startDate === 'string') {
      startDate = new Date(startDate);
    }
    return startDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  }

  formatPrice(price) {
    return this.tourService.formatPrice(price);
  }

  getBookingCurrencySign(iso3Currency: string): string {
    return this.geoService.convertISO3(iso3Currency);
  }

  pricePerDay(price, numDays) {
    return Math.ceil(price / numDays);
  }

  getReview(reviewCode: string) {
    this.reviewService.getTourReviews(reviewCode).subscribe((data: ReviewModel) => {
      this.averageRating = data.avgRating;
      this.totalReviews = data.total;
    });
  }

  showCancellationSwal(event: Event) {
    event.preventDefault();

    Swal.fire({
      title: 'Schedule a callback',
      html: `<p>Would you like to reactivate this tour?<br>Speak to one of our friendly sales agents.</p>`,
      confirmButtonColor: '#0986c8',
      confirmButtonText: 'Schedule a call back',
      customClass: {
        container: 'cancellation-swal-container',
        confirmButton: 'cancellation-swal-button',
      },
    }).then(result => {
      if (result.isConfirmed) {
        if (isPlatformBrowser(this.platformId)) {
          this.trackTourCardAmplitude('View Cancellation Page');
          window.open('https://calendly.com/travel-with-expat/sales', '_blank');
        }
      }
    });
  }

  /**
   * Log the navigation in GA and then navigate to the page
   */
  navigateAndRecord() {
    Swal.close();

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'select_item',
      ecommerce: {
        item_list_id: 'search',
        item_list_name: 'Search',
        items: [
          {
            item_id: this.data.tourCode,
            item_name: this.data.tourName,
            index: 0,
          },
        ],
      },
    });
  }

  trackTourCardAmplitude(event: string) {
    if (this.data) {
      const cardData = {
        event: event,
        'card-type': this.cardWidth,
        'card-location-page': this.currentRoute,
        'card-location-section': this.cardSection,
        'card-ShowMap': this.mapView,
        'tour-name': this.data.tourName,
        'tour-code': this.data.tourCode,
        'tour-slug': this.data.slug,
        'tour-isWishlisted': this.wishlisted,
        'tour-isSoldOut': this.soldOut,
        'tour-isCampaignCard': this.isCampaignCard,
      };
      this.amplitudeService.trackEvent('Tour Card Action', cardData);
    } else {
      const cardData = {
        event: event,
        'card-type': this.cardWidth,
        'card-location-page': this.currentRoute,
        'card-location-section': this.cardSection,
        'card-ShowMap': this.mapView,
        'tour-name': this.bookingData?.tour?.name ?? '',
      };
      this.amplitudeService.trackEvent('Tour Booking Card Action', cardData);
    }
  }

  protected readonly Number = Number;
  protected readonly countryPluralMap = countryPluralMap;
  protected readonly activeBookingsTourStatuses = activeBookingsTourStatuses;
}
