import { Inject, Pipe, PipeTransform, PLATFORM_ID } from '@angular/core';
import { Params } from '@angular/router';
import { isInternalLink } from '../../lib/utils';
import { isPlatformBrowser } from '@angular/common';

/**
 * Use this if contentful value is like: https://expatexplore.com/.....
 * Converts to relative path for angular routing to work nicely
 * Will not be effective in text blocks that utilise innerHTML front end
 */
@Pipe({ name: 'extractPath' })
export class ExtractPathPipe implements PipeTransform {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}
  transform(value: string): string {
    if (isPlatformBrowser(this.platformId) && !isInternalLink(value)) {
      return value;
    }
    try {
      const url = new URL(value);
      return url.pathname;
    } catch (error) {
      return value;
    }
  }
}

/**
 * Same as extractPath but includes any url parameters like /search?discount=show_campaign
 */
@Pipe({ name: 'extractPathAndParams' })
export class ExtractPathAndParamsPipe implements PipeTransform {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  transform(value: string): string {
    if (isPlatformBrowser(this.platformId) && !isInternalLink(value)) {
      return value;
    }
    try {
      const url = new URL(value);
      const pathname = url.pathname;
      const params = url.search;
      return params ? `${pathname}${params}` : pathname;
    } catch (error) {
      return value;
    }
  }
}

/**
 * This is for CF links specifically in header component, that may have url query parameters (ie: Upcoming departures link that goes to search with query params)
 */
@Pipe({ name: 'extractQuery' })
export class ExtractQueryPipe implements PipeTransform {
  transform(value: string): Params {
    try {
      if (value.indexOf('?') === -1) {
        //No need to use this pipe even
        return undefined;
      }
      const url = new URL(value);
      const params = {};
      const queryParams = url.search.replace('?', '').split('&');
      queryParams.forEach((param: string) => {
        const [key, value] = param.split('=');
        params[key] = value;
      });
      return params;
    } catch (error) {
      return undefined;
    }
  }
}
