import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { CheckoutService } from '@app/feature/checkout/services/checkout.service';
import { GeoService } from '@app/services/geo.service';
import { GeoModel } from '@app/models/geo.model';
import { environment } from '@environments/environment';
import { SeoService } from '@app/services/seo.service';
import { ErrorHandlerService } from '@app/services/error-handler.service';
import { ConvertedLegacyBooking, TrustPaymentsConfig } from '@app/feature/checkout/dto/types';
import { AmplitudeService } from '@app/services/amplitude.service';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, takeUntil } from 'rxjs';
import { dateConversion } from '@utils';
import { getOrders, GetOrdersQueryResponse } from '@touraxis/wbp-client';

import { Store } from '@ngxs/store';
import { SetUserEmail, SetUserProperties } from '@app/feature/session-tracking/store/user-details.state';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrl: './payments.component.scss',
})
export class PaymentsComponent implements OnInit, OnDestroy {
  showProcessing = true;
  countryData = this.geoService.countryData;
  error = this.checkoutService.error;
  geo: GeoModel;
  amountToPay = '';
  payAmount: number;
  currencySign: string;
  bookingId: string;
  currency: any;
  booking: any;
  outstandingAmount: any;
  channel: any;
  departure: any;
  tpConfig: TrustPaymentsConfig;
  bookingData: any;
  successURL: string;
  isInstalmentBooking = false;
  paymentDetailsForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.pattern(/^\D+$/),
      Validators.minLength(2),
      Validators.maxLength(100),
      Validators.required,
    ]),
    lastName: new FormControl('', [
      Validators.pattern(/^\D+$/),
      Validators.minLength(2),
      Validators.maxLength(100),
      Validators.required,
    ]),
    dialCode: new FormControl('', [Validators.required]),
    contactNumber: new FormControl('', [
      Validators.pattern('^[0-9]{6,}$'),
      Validators.maxLength(20),
      Validators.required,
    ]),
    countryCode: new FormControl('', [Validators.required]),
    streetName: new FormControl('', [Validators.minLength(2), Validators.maxLength(255), Validators.required]),
    cityTown: new FormControl('', [
      Validators.pattern(/^\D+$/),
      Validators.minLength(2),
      Validators.maxLength(50),
      Validators.required,
    ]),
    postalCode: new FormControl('', [Validators.minLength(2), Validators.maxLength(20), Validators.required]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
    ]),
    payAmount: new FormControl('', [Validators.required]),
    customAmount: new FormControl('', [Validators.pattern('^\\d{1,8}?(?:\\.\\d{1,2})?$')]),
  });
  paymentDetailsFormValid = false;
  processingTransaction = false;
  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private amplitudeService: AmplitudeService,
    private seoService: SeoService,
    private checkoutService: CheckoutService,
    private geoService: GeoService,
    private errorService: ErrorHandlerService,
    private router: Router,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private readonly store: Store
  ) {}

  ngOnInit() {
    this.setupPage();
    this.handleQueryParams();
    this.onFormChanges();
    this.renderer.setStyle(document.body, 'height', '100%');
    this.renderer.setStyle(document.body, 'background-color', '#FFFFFF');
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * This method is used to perform necessary cleanup operations to prevent memory leaks.
   * Specifically, it signals any observers subscribed to the `destroy$` observable that the component is about to be destroyed,
   * and then completes the `destroy$` observable to ensure it no longer emits any new values.
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Sets up the page by initializing SEO metadata and configuring page-specific settings.
   * This method is responsible for setting the title of the page and configuring SEO settings
   * such as blocking robots from indexing the page. It's called during the component's initialization phase.
   */
  private setupPage() {
    this.seoService.setTitle('Checkout Payments - Expat Explore');
    this.seoService.setFullBlockRobots();
  }

  /**
   * Handles the query parameters from the current route.
   * This method subscribes to the route's query parameters and processes them accordingly.
   * It checks for the presence of either `bookingRef` or `bookingId` parameters and initiates the booking process
   * based on these parameters. If neither parameter is present, it throws an error indicating invalid URL parameters.
   * In case of `bookingRef`, it proceeds to handle the booking reference through `handleBookingRef` method.
   * For `bookingId`, it converts the legacy booking ID to a new format using `convertLegacyBookingId` method.
   * Any errors encountered during the processing of query parameters are handled by the `handleError` method.
   */
  private handleQueryParams() {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.processQueryParams(params).catch(error => this.handleError(error));
    });
  }

  /**
   * Processes the query parameters from the current route.
   * This method checks for the presence of `bookingRef` or `bookingId` parameters in the route's query parameters.
   * Based on the presence of these parameters, it either initiates the booking process using the provided booking reference
   * or converts a legacy booking ID to a new format. If neither parameter is present, it throws an error indicating
   * invalid URL parameters. This method is asynchronous and returns a promise that resolves when the processing
   * of query parameters is complete.
   *
   * @param params The query parameters object from the current route.
   * @throws {Error} Throws an error if neither `bookingRef` nor `bookingId` parameters are present in the URL.
   * @returns {Promise<void>} A promise that resolves when the query parameters have been successfully processed.
   */
  private async processQueryParams(params: any): Promise<void> {
    if (!params.bookingRef && !params.bookingId) {
      throw new Error(`Invalid URL parameters - ${params}`);
    }

    this.bookingId = params.bookingRef || params.bookingId;

    if (params.bookingRef) {
      await this.handleBookingRef(params);
    } else {
      await this.convertLegacyBookingId(params.bookingId);
    }
  }

  /**
   * Handles the booking reference from the query parameters.
   * This method is responsible for processing the booking reference provided in the URL's query parameters.
   * It sets the success URL to redirect to after successful booking validation and payment.
   * The method attempts to validate the success parameters and commit the booking using the provided booking reference
   * and transaction reference. If any step fails, it removes the booking data from the session storage and
   * attempts to retrieve booking details from Lemax using the booking reference.
   *
   * @param params The query parameters object from the current route, expected to contain `bookingRef` and optionally `transactionreference`.
   * @throws {Error} Throws an error if the validation of success parameters or the booking commitment fails.
   * @returns {Promise<void>} A promise that resolves when the booking reference has been successfully handled or rejects in case of an error.
   */
  private async handleBookingRef(params: any): Promise<void> {
    this.successURL = `${this.document.location.origin}/checkout/payments?bookingRef=${params.bookingRef}`;
    try {
      await this.validateSuccessParameters(params);
      await this.commitBooking(params.bookingRef, params.transactionreference);
    } catch {
      sessionStorage.removeItem('bookingData');
      this.getBookingLemax(params.bookingRef);
    }
  }

  /**
   * Converts a legacy booking ID (bookingId) to a new format (bookingRef).
   * This method is called when a legacy booking ID is detected in the query parameters.
   * It makes a call to the checkout service to convert the legacy booking ID to the new format.
   * Upon successful conversion, it navigates the user to the checkout payments page with the new booking reference.
   * If the conversion fails, it handles the error by logging it and displaying a warning message to the user.
   *
   * @param bookingId The legacy booking ID to be converted.
   * @returns {Promise<void>} A promise that resolves when the conversion process is complete.
   */
  private async convertLegacyBookingId(bookingId: string): Promise<void> {
    this.checkoutService.convertLegacyBookingId(bookingId).subscribe({
      next: (response: ConvertedLegacyBooking) => {
        this.router.navigateByUrl(`/checkout/payments?bookingRef=${response.lemaxBookingId}`);
      },
      error: error => this.handleError(`get converted booking error - ${error.message}`, error.status),
    });
  }

  /**
   * Handles errors by logging them and displaying a warning message to the user.
   * This method is a centralized error handler for the component, which logs the error and displays
   * a user-friendly warning message using a modal dialog. It can be used throughout the component
   * to handle various types of errors consistently.
   *
   * @param message The error message to be displayed to the user.
   * @param code An optional error code to categorize the error. Defaults to 1003 if not specified.
   */
  private handleError(message: string, code = 1003) {
    this.trackCheckoutErrorLog(code, message);
    this.errorService.throwSwal('generic', 'warning', message, true);
  }

  protected onSubmittingChange($event) {
    this.store.dispatch(new SetUserEmail(this.paymentDetailsForm.value.email)).subscribe(() => {
      this.store.dispatch(
        new SetUserProperties({
          first_name: this.paymentDetailsForm.value.firstName,
          last_name: this.paymentDetailsForm.value.lastName,
        })
      );
    });

    this.processingTransaction = $event;
  }

  /**
   * Validates the success parameters from the query parameters.
   * This method checks the query parameters for specific success criteria: `errorcode` must be '0' and `settlestatus` must not be '3'.
   * It is designed to ensure that the transaction parameters meet the expected success conditions before proceeding.
   *
   * @param params The query parameters object from the current route, expected to contain `errorcode` and `settlestatus`.
   * @returns {Promise<string>} A promise that resolves with 'success' if the parameters meet the success criteria, or rejects with an error message otherwise.
   */
  validateSuccessParameters(params: any): Promise<string> {
    return new Promise((resolve, reject) => {
      if (Object.keys(params).length > 0 && params.errorcode === '0' && params.settlestatus !== '3') {
        resolve('success');
      } else {
        reject('no success params');
      }
    });
  }

  /**
   * Commits the booking with the provided booking reference and transaction reference.
   * This method is responsible for finalizing the booking process by sending the booking and payment details
   * to the backend service. It updates the session storage to remove the temporary booking data upon successful payment.
   * In case of a payment failure, it logs the error and rejects the promise to handle the error appropriately in the calling context.
   *
   * @param bookingRef The booking reference obtained from the query parameters or the booking process.
   * @param transactionreference The transaction reference obtained from the payment gateway.
   * @returns {Promise<void>} A promise that resolves when the booking has been successfully committed, or rejects with an error.
   */
  private commitBooking(bookingRef: string, transactionreference: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const bookingData = JSON.parse(sessionStorage.getItem('bookingData'));
      bookingData.externalIdentifier = transactionreference;
      this.checkoutService.makePaymentLemax(bookingRef, bookingData).subscribe({
        next: () => {
          this.checkoutService.gaTrackPayment('payment', bookingData);
          this.trackCheckoutPayment(bookingData);
          sessionStorage.removeItem('bookingData');
          Swal.fire({
            icon: 'success',
            title: 'Success',
            html:
              '<p>Please note, payment can take up to a few minutes to reflect.</p>' +
              '<p>Do not be alarmed if you see your balance remains the same, please check back later.</p>',
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: 'I understand',
            confirmButtonColor: '#0986c8',
            didClose: () => {
              this.router.navigate(['/checkout/payments'], { queryParams: { bookingRef } });
              resolve();
            },
          });
        },
        error: (error: HttpErrorResponse) => {
          this.logCommitBookingFailed(error, bookingRef);
          reject(error);
        },
      });
    });
  }

  /**
   * The backend service is expected to do communications to necessary parties for business purposes. Finance / CS
   *
   * Logs the failure of booking commitment and displays an error message to the user.
   * This method is invoked when the attempt to commit a booking fails, either due to network issues,
   * server errors, or any other reasons that prevent the booking from being successfully committed.
   * It logs the error details, including the HTTP status code and error message, and then displays
   * a user-friendly error message in a modal dialog, advising the user to contact customer service.
   *
   * @param error The HTTP error response containing the status code and error message.
   * @param bookingRef The booking reference associated with the failed booking commitment.
   */
  logCommitBookingFailed(error: HttpErrorResponse, bookingRef: string): void {
    this.trackCheckoutErrorLog(error.status, `commit booking error - ${error.message}`);
    Swal.fire({
      icon: 'warning',
      title: 'An unexpected error occurred',
      html: `<p>Please contact our <a href="/contact-us" title="contact us" target="_blank">friendly customer service</a> team with your booking reference: ${bookingRef}</p>`,
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
    });
  }

  /**
   * Retrieves booking details from Lemax by the given booking reference.
   * This method makes an HTTP GET request to the checkout service to fetch booking details associated with the provided booking reference.
   * Upon successful retrieval, it updates the booking information in the component, including currency details and outstanding amount.
   * It also attempts to pre-fill the payment details form with user information if available in local storage.
   * In case of an error during the retrieval process, it logs the error and displays a warning message to the user.
   *
   * @param bookingReference The unique booking reference for which details are to be retrieved.
   */
  getBookingLemax(bookingReference: string): void {
    this.checkoutService.getBookingLemax(bookingReference).subscribe({
      next: (response: any) => {
        this.booking = response;
        this.currency = response.totalPrice.currencyCode;
        this.currencySign = this.checkoutService.convertISO3(response.totalPrice.currencyCode);
        this.outstandingAmount = response.totalPrice.amount - response.totalPrice.paidAmount;
        this.geoService.geoData.subscribe({
          next: (data: GeoModel) => {
            this.geo = data;
            this.paymentDetailsForm.patchValue({
              countryCode: this.geo.country,
              dialCode: this.geo.country,
            });
            if (localStorage.getItem('paymentFormDetails')) {
              const storageFormDetails = JSON.parse(localStorage.getItem('paymentFormDetails'));
              this.paymentDetailsForm.patchValue({
                firstName: storageFormDetails.firstName,
                lastName: storageFormDetails.lastName,
                contactNumber: storageFormDetails.contactNumber,
                streetName: storageFormDetails.streetName,
                cityTown: storageFormDetails.cityTown,
                postalCode: storageFormDetails.postalCode,
                email: storageFormDetails.email,
              });
            }
            this.trackCheckoutNav('Checkout Step Loaded');
            this.getBookingPaymentType(bookingReference);
          },
          error: error => {
            this.trackCheckoutErrorLog(error.status, `get user location geodata error - ${error.message}`);
            this.errorService.throwSwal('generic', 'warning', 'get user location', true);
          },
        });
      },
      error: error => {
        this.trackCheckoutErrorLog(error.status, `get booking error - ${error.message}`);
        this.errorService.throwSwal('generic', 'warning', `get booking. <p>${bookingReference}</p>`, true);
      },
    });
  }

  /**
   * Retrieves the payment type for the given booking reference. (Full/deposit/instalment)
   * @param bookingReference
   */
  getBookingPaymentType(bookingReference: string) {
    /* istanbul ignore next */
    getOrders(
      {
        orderIds: bookingReference,
      },
      {
        baseURL: environment.paymentServiceBaseUrl,
      }
    )
      .then((response: GetOrdersQueryResponse) => {
        this.handleBookingPaymentTypeData(response);
      })
      .catch(error => {
        this.handleBookingPaymentTypeError(error, bookingReference);
      });
  }

  handleBookingPaymentTypeError(error: HttpErrorResponse, bookingReference: string) {
    //Log to amplitude
    Swal.fire({
      icon: 'warning',
      title: 'Unexpected error occurred',
      html: `<p>Please contact our <a href="/contact-us" title="contact us" target="_blank">friendly customer service</a> team with your booking reference: ${bookingReference}</p>`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: 'Try again',
      confirmButtonColor: '#0986c8',
      didClose: () => {
        this.router.navigate(['/checkout/payments'], { queryParams: { bookingRef: bookingReference } });
      },
    });
  }

  handleBookingPaymentTypeData(bookingTypeData: GetOrdersQueryResponse) {
    if (bookingTypeData?.orders[0]?.paymentPlans?.length) {
      this.isInstalmentBooking = true;
    }
    this.showProcessing = false;
  }

  formatPrice(price: any) {
    return this.checkoutService.formatPrice(price, this.currencySign);
  }

  amountSelected() {
    this.amountToPay = this.paymentDetailsForm.get('payAmount').value === 'ownAmount' ? 'ownAmount' : '';
  }

  /**
   * Converts a timestamp string into a formatted date string.
   * This method takes a timestamp string as input and converts it into a date string formatted as `DD-MM-YYYY`.
   * It is useful for converting ISO date strings or similar formats into a more readable date format.
   *
   * @param stamp The timestamp string to be converted.
   * @returns A string representing the formatted date in `DD-MM-YYYY` format.
   */
  dateConversion(stamp: string) {
    return dateConversion(stamp);
  }

  onFormChanges(): void {
    this.paymentDetailsForm.valueChanges.subscribe(() => {
      const payAmount = this.paymentDetailsForm.get('payAmount').value;
      const customAmount = parseFloat(this.paymentDetailsForm.get('customAmount').value);

      if (this.paymentDetailsForm.valid && (payAmount !== 'ownAmount' || this.isCustomAmountValid(customAmount))) {
        this.paymentDetailsFormValid = true;
        localStorage.setItem('paymentFormDetails', JSON.stringify(this.paymentDetailsForm.value));
        this.populateTrustPayments();
      } else {
        this.paymentDetailsFormValid = false;
      }
    });
  }

  /**
   * Checks if the custom amount entered by the user is valid.
   * A custom amount is considered valid if it is not zero and does not exceed the outstanding amount to be paid.
   * This method is used to validate user input when they choose to pay a custom amount rather than the full outstanding amount.
   *
   * @param customAmount The custom amount entered by the user.
   * @returns {boolean} True if the custom amount is valid, false otherwise.
   */
  private isCustomAmountValid(customAmount: number): boolean {
    return customAmount !== 0 && customAmount <= this.outstandingAmount;
  }

  /**
   * Determines the amount to be paid based on the user's selection.
   * If the user selects to pay a custom amount and the amount is valid (i.e., not zero and does not exceed the outstanding amount),
   * this method returns the custom amount. Otherwise, it returns the amount specified in the `payAmount` form control.
   * This method supports the flexibility of paying either a predefined or a custom amount towards the outstanding balance.
   *
   * @param customAmount The custom amount entered by the user.
   * @returns {number} The amount to be paid, determined based on the user's selection.
   */
  private setSelectedAmount(customAmount: number): number {
    if (this.amountToPay === 'ownAmount' && this.isCustomAmountValid(customAmount)) {
      return customAmount;
    }
    return parseFloat(this.paymentDetailsForm.value.payAmount);
  }

  /**
   * Asynchronously populates the Trust Payments configuration.
   * This method prepares the payment amount and booking data for the Trust Payments configuration.
   * It first checks if a custom amount is selected and valid. If the custom amount is not valid, it displays a warning.
   * Otherwise, it proceeds to set the payment amount, populate booking data, and then configure the Trust Payments settings.
   * This method supports both predefined and custom payment amounts, enhancing flexibility for payment processing.
   */
  populateTrustPayments() {
    const customAmount = parseFloat(this.paymentDetailsForm.value.customAmount);
    if (this.amountToPay === 'ownAmount' && !this.isCustomAmountValid(customAmount)) {
      this.errorService.throwSwal(
        'generic',
        'warning',
        'pay custom amount. Please ensure custom amount is not 0 (zero) and does not exceed outstanding amount.',
        true
      );
      this.trackCheckoutErrorLog(0, 'pay custom amount. User tried to enter amount 0 or more than outstanding amount.');
      return;
    }

    this.payAmount = this.setSelectedAmount(customAmount);
    const bookingData = this.populateBookingData();
    sessionStorage.setItem('bookingData', JSON.stringify(bookingData));
    this.populateTPConfig();
  }

  populateTPConfig() {
    this.tpConfig = {
      sitereference: environment.trustPaymentSiteRef,
      stprofile: 'default',
      stdefaultprofile: 'st_paymentcardonly',
      ruleidentifier: 'STR-6',
      version: '2',
      orderreference: this.bookingId,
      successfulurlredirect: this.successURL,
      mainamount: this.payAmount.toFixed(2).toString(),
      currencyiso3a: this.currency,
      billingfirstname: this.paymentDetailsForm.value.firstName,
      billinglastname: this.paymentDetailsForm.value.lastName,
      billingemail: this.paymentDetailsForm.value.email,
      billingtelephone: this.paymentDetailsForm.value.contactNumber,
      sitesecurity: '',
      sitesecuritytimestamp: '',
    } as TrustPaymentsConfig;
  }

  populateBookingData() {
    return {
      price: {
        amount: this.payAmount,
        currency: this.currency,
      },
      formOfPayment: 'Credit card',
      externalIdentifier: '',
      booking_id: this.bookingId,
      currency: this.currency,
      booking_number: this.bookingId,
      start_date: this.dateConversion(this.booking.items[0].startDate),
      end_date: this.dateConversion(this.booking.items[0].endDate),
      payment_type: 'payment',
      customer: {
        title: '',
        first_name: this.paymentDetailsForm.value.firstName,
        last_name: this.paymentDetailsForm.value.lastName,
        email: this.paymentDetailsForm.value.email,
        phone: this.paymentDetailsForm.value.contactNumber,
        countryCode: this.paymentDetailsForm.value.countryCode,
        street: this.paymentDetailsForm.value.streetName,
        postalCode: this.paymentDetailsForm.value.postalCode,
        city: this.paymentDetailsForm.value.cityTown,
        privacyPolicy: true,
        termsConditions: true,
      },
      rawResponse: {},
    };
  }

  /**
   * Determines if the form control associated with the given name should display its error state.
   * This method checks if the form control has been touched, which typically indicates that the user has interacted with the field.
   * If the control has been touched, it returns true, allowing error messages or styles to be applied.
   */
  allowErrors(name: string): boolean {
    return !!this.paymentDetailsForm.controls[name].touched;
  }

  /**
   * Calculates and returns the due date for payment based on the booking's start date.
   * The due date is determined by subtracting a set number of days (60) from the booking's start date.
   * This method is useful for determining when a payment is due for a particular booking.
   *
   * @returns A string representing the due date in ISO format.
   */
  dueDate() {
    const dueDays = 60;
    const now = new Date(this.checkoutService.subtractDays(this.booking.items[0].startDate, dueDays));
    return this.formatDate(now.toISOString());
  }

  formatDate(date: any) {
    return this.checkoutService.formatDate(date);
  }

  trackCheckoutNav(event: string) {
    const stepData = {
      events: event,
      'checkout-step': 'payments',
      'num-pax': this.booking.numPax,
      'pay-type': this.booking.payType,
      'pay-method': 'Credit Card',
      'pay-amount': this.formatPrice(this.payAmount),
      currency: this.currency,
    };
    this.amplitudeService.trackEvent('Checkout Navigation', stepData);
  }

  trackCheckoutErrorLog(code: number, message: string) {
    const stepData = {
      'checkout-step': 'payments',
      currency: this.currency,
      'error-code': code,
      'error-message': message,
      'pay-amount': this.payAmount,
      'pay-method': 'Credit Card',
      'pay-type': this.booking.payType,
    };
    this.amplitudeService.trackEvent('Checkout Error Log', stepData);
  }

  trackCheckoutPayment(bookingData: any) {
    const stepData = {
      'checkout-step': 'payments',
      currency: bookingData.price.currency,
      'pay-amount': parseFloat(bookingData.price.amount).toFixed(2),
      'booking-id': this.bookingId,
      'pay-method': 'Credit Card',
      'pay-type': bookingData.payment_type,
    };
    this.amplitudeService.trackEvent('Checkout Payment Event', stepData);
  }
}
