<div class="reasons-blocks" *ngIf="data">
  <div class="container">
    <div class="row">
      <div class="col-12" *ngIf="heading">
        <h3 class="red text-center">{{ heading }}</h3>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 item-col" *ngFor="let reason of data">
        <div class="item">
          <div class="item-inner">
            <div class="icon" *ngIf="reason.image">
              <img [ngSrc]="reason.image.url" alt="Icon" width="50" height="50" />
            </div>
            <div class="text" *ngIf="reason.text" [innerHTML]="reason.text | safeHtml"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
