import { Component, Input, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplitudeService } from '../../../services/amplitude.service';
import { MultiTextImage } from '../../../models/contentful-common.model';
import { SwiperOptions } from 'swiper/types';
import { SwiperContainer } from 'swiper/swiper-element';
import { BlogService } from '../../../services/blog.service';
import { BlogPost } from '../../../models/blog-posts.model';
import { carouselCategories } from '../../../models/home.model';

@Component({
  selector: 'app-travel-inspiration',
  templateUrl: './travel-inspiration.component.html',
  styleUrls: ['./travel-inspiration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TravelInspirationComponent {
  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;
  @ViewChild('swiperTips') swiperTips!: ElementRef<SwiperContainer>;
  @Input() data: MultiTextImage[];
  @Input() heading: string;
  @Input() subText: string;
  @Input() travelStoriesText: string;
  @Input() tipsText: string;
  @Input() blogCategory: string;
  tabs = ['Your Travel Stories', 'Our Travel Tips'];
  selectedTab = 0;
  blogsLoading = true;
  blogsFailed = false;
  fetchedBlogs = false;
  blogs: carouselCategories[] = [];
  slideIndex = 0;
  swiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    freeMode: true,
    mousewheel: {
      forceToAxis: true,
    },
    on: {
      slideChange: () => {
        if (this.swiper && this.swiper?.nativeElement?.swiper) {
          this.slideIndex = this.swiper.nativeElement.swiper.realIndex;
        }
      },
      touchStart: () => {
        if (this.swiper && this.swiper?.nativeElement?.swiper) {
          this.trackTravelInspirationAmplitude('Touch Start');
        }
      },
    },
    navigation: {
      nextEl: '.travel-inspiration-next',
      prevEl: '.travel-inspiration-prev',
    },
  };
  swiperConfigTips: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    freeMode: true,
    mousewheel: {
      forceToAxis: true,
    },
    on: {
      slideChange: () => {
        if (this.swiperTips && this.swiperTips?.nativeElement?.swiper) {
          this.slideIndex = this.swiperTips.nativeElement.swiper.realIndex;
        }
      },
      touchStart: () => {
        if (this.swiper && this.swiperTips?.nativeElement?.swiper) {
          this.trackTravelInspirationAmplitude('Touch Start');
        }
      },
    },
    navigation: {
      nextEl: '.travel-blogs-next',
      prevEl: '.travel-blogs-prev',
    },
  };

  constructor(
    private blogService: BlogService,
    private router: Router,
    private route: ActivatedRoute,
    private amplitudeService: AmplitudeService
  ) {}

  selectTab(index: number) {
    this.selectedTab = index;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    if (index === 1 && self.fetchedBlogs === false) {
      //get blogs
      this.blogService.getCategories().subscribe({
        next: categories => {
          const category = this.blogCategory.toLowerCase().trim();
          for (let i = 0; i < categories.length; i++) {
            if (categories[i].slug.toLowerCase().trim() === category) {
              const queryParams = this.blogService.getParamQueries({ categories: category });
              this.blogService.getPosts(queryParams).subscribe({
                next: (posts: BlogPost[]) => {
                  posts.forEach((post: BlogPost) => {
                    self.blogs.push({
                      image: {
                        title: post.title.rendered || '',
                        description: post.yoast_head_json.description || '',
                        url:
                          post?._embedded?.['wp:featuredmedia'][0]?.source_url ||
                          'https://images.ctfassets.net/huwbudysxepr/7CYraOZNsBc2baTmF2C07I/2e003a16a078011e9f80cb7a45d66df9/Eiffel_Tower.png',
                      },
                      url: post.slug || '',
                      heading: self.truncateString(post.title.rendered, 43) || '',
                      text: self.truncateString(post.excerpt.rendered, 120) || '',
                    });
                  });
                  this.fetchedBlogs = true;
                  this.blogsLoading = false;
                },
                error: () => {
                  this.blogsLoading = false;
                  this.blogsFailed = true;
                  console.error('failed to get blog posts home page getPosts()');
                },
              });
            }
          }
        },
        error: () => {
          this.blogsLoading = false;
          this.blogsFailed = true;
          console.error('failed to get blog categories home page getCategories()');
        },
      });
    }
    this.trackTravelInspirationAmplitude('Click Tab');
  }

  onViewBlogs() {
    this.trackTravelInspirationAmplitude('Click Tab CTA');
  }

  onViewTravelStories() {
    this.trackTravelInspirationAmplitude('Click Tab CTA');
  }

  truncateString(str: string, maxLength: number): string {
    if (str.length <= maxLength) {
      return str;
    } else {
      return str.slice(0, maxLength) + '...';
    }
  }

  onBlogFallback() {
    this.router.navigate(['/blog']);
  }

  trackTravelInspirationAmplitude(event: string) {
    const travelInspirationData = {
      event: event,
      page: this.amplitudeService.setPageUrl(this.router.url),
      section: this.heading,
      'active-tab': this.tabs[this.selectedTab],
      'carousel-index': this.slideIndex,
    };
    this.amplitudeService.trackEvent('Travel Inspiration Interaction', travelInspirationData);
  }
}
