import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqueNames',
})
export class UniqueNamesPipe implements PipeTransform {
  transform(names: string[]): string[] {
    // Use a Set to keep track of unique names
    const uniqueNames = new Set<string>();

    // Filter out duplicates and add names to the Set
    return names.filter(name => {
      if (!uniqueNames.has(name)) {
        uniqueNames.add(name);
        return true;
      }
      return false;
    });
  }
}
