<div class="filters-carousel" *ngIf="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h3 class="red" *ngIf="heading">{{ heading }}</h3>
        <div class="text" *ngIf="text" [innerHTML]="text"></div>
        <swiper-container class="filters-carousel-swiper" appSwiper init="false" #swiper [config]="swiperConfig">
          <swiper-slide class="swiper-slide" *ngFor="let item of data">
            <div class="filters-item" (mouseenter)="trackFiltersCardAmplitude('Hover Card', item)">
              <img
                [ngSrc]="item.image.url + '?fm=webp&h=130&w=315&fit=fill'"
                [alt]="item.image.description"
                width="315"
                height="130"
                class="img-fluid" />
              <a class="overlay" [attr.href]="item.url" (click)="trackFiltersCardAmplitude('Click Card', item)"></a>
              <div class="label" *ngIf="item.shortText">{{ item.shortText }}</div>
            </div>
          </swiper-slide>
        </swiper-container>
        <div class="filters-carousel-pagination">
          <div class="filters-swiper-prev">
            <app-round-buttons
              icon="chevron-left"
              color="blue"
              customClass="filter-swiper-prev"
              (buttonSelected)="trackFiltersCarouselAmplitude('Click Prev')"></app-round-buttons>
          </div>
          <div class="filters-swiper-next">
            <app-round-buttons
              icon="chevron-right"
              color="blue"
              customClass="filter-swiper-next"
              (buttonSelected)="trackFiltersCarouselAmplitude('Click Next')"></app-round-buttons>
          </div>
          <div class="section-cta d-block d-md-none">
            <a [routerLink]="'/search'" class="mobile-section-cta">View All Tours</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
