import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CheckoutService } from '@app/services/checkout.service';
import { AmplitudeService } from '@app/services/amplitude.service';
import { Option, Price } from '@models/tour.model';
import { GeoModel } from '@models/geo.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ClearPromoItems } from '@app/feature/promotion/store/promotion.actions';
import { Store } from '@ngxs/store';
import { UpdateTotalPrice } from '@app/feature/checkout/store/checkout.actions';

@Component({
  selector: 'app-promocode',
  templateUrl: './promocode.component.html',
  styleUrl: './promocode.component.scss',
})
export class PromocodeComponent implements OnChanges {
  @Input() departureId: any;
  @Input() tourId: any;
  @Input() geoData: GeoModel;
  @Input() numPax: number;
  @Input() initialPromoCode?: string;
  @Output() promoValidated = new EventEmitter<Price>();
  @Output() betterOffer = new EventEmitter<boolean>();
  promo = {
    code: '',
    processing: false,
    valid: true,
  };
  prices: Price;

  constructor(
    private readonly checkoutService: CheckoutService,
    private readonly amplitudeService: AmplitudeService,
    public store: Store
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialPromoCode']?.currentValue) {
      this.promo.code = changes['initialPromoCode'].currentValue;
      this.validatePromoCode();
    }
  }

  validatePromoCode() {
    if (this.promo.code) {
      const promoCode: string = (this.promo.code as string).trim();
      if (promoCode.length > 0) {
        this.trackPromoCode('PromoCode Processing');
        this.promo.processing = true;
        this.checkoutService
          .getTourOptionalServices(this.tourId, this.departureId, this.geoData.currency, promoCode)
          .subscribe({
            next: (response: Option) => {
              const spoCode = response.priceFrom?.spo?.code || 'N/A';
              const spoName = response.priceFrom?.spo?.name || 'N/A';
              this.trackPromoCode('PromoCode Success', spoCode, spoName);
              this.promo.valid = true;
              this.promo.processing = false;
              this.updatePrices(response, false);
            },
            error: (error: HttpErrorResponse) => {
              const errorStatus = error?.status?.toString() || 'N/A';
              const errorText = error?.statusText || 'N/A';
              this.trackPromoCode('PromoCode Failed', errorStatus, errorText);
              if (error.status === 400 && error.error?.message?.response?.betterOffer === true) {
                this.promo.code = '';
                this.betterOffer.emit(true);
                this.promo.valid = true;
                this.promo.processing = false;
              } else {
                this.promo.processing = false;
                this.promo.valid = false;
              }
            },
          });
      } else {
        this.promo.processing = false;
        this.promo.valid = false;
      }
    } else {
      this.promo.valid = false;
    }
  }

  formatPrice(price: any) {
    return this.checkoutService.formatPrice(price, this.geoData.currency_sign);
  }

  clearPromoCode() {
    this.store.dispatch(new ClearPromoItems());
    this.promo.code = '';
    this.promo.valid = true;
    this.promo.processing = true;
    this.checkoutService.getTourOptionalServices(this.tourId, this.departureId, this.geoData.currency).subscribe({
      next: (response: Option) => {
        this.promo.processing = false;
        this.updatePrices(response, true);
      },
      error: (error: HttpErrorResponse) => {
        console.error(error);
        this.promo.processing = false;
      },
    });
  }

  updatePrices(option: Option, clear: boolean) {
    this.prices = {
      amount: this.calculatePromoPricesAmount(option, clear),
      currencyCode: this.geoData.currency,
      discount: option.priceFrom.discount,
      discountPercentage: option.priceFrom.discountPercentage,
      originalAmount: option.priceFrom.originalAmount,
      promoCode: this.promo.code,
    };
    this.store.dispatch(new UpdateTotalPrice(this.prices.amount));
    this.promoValidated.emit(this.prices);
  }

  calculatePromoPricesAmount(option: Option, clear = false) {
    if (!clear) {
      return (option.priceFrom.originalAmount - option.priceFrom.discount) * this.numPax;
    } else {
      return option.priceFrom.amount * this.numPax;
    }
  }

  trackPromoCode(event: string, code?: string, message?: string) {
    const promoData = {
      event: event,
      tourId: this.tourId,
      departureId: this.departureId,
      initialPromoCode: this.initialPromoCode,
      promoCode: this.promo.code,
      response: {
        code: code,
        message: message,
      },
    };
    this.amplitudeService.trackEvent('Promo Code Validation', promoData);
  }
}
