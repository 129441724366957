<div class="promo-code-wrapper mt-lg-2">
  <div class="row mb-0">
    <div class="col-12">
      <p>Add promo code</p>
    </div>
    <div class="col-12">
      @if (!promo.processing) {
        <div class="promo-code-container d-flex">
          <input
            type="text"
            class="promo-code-field"
            id="promo-code-mobile"
            placeholder="[Enter Promo Code]"
            [ngClass]="{ 'discount-applied': prices?.discount > 0 }"
            [(ngModel)]="promo.code" />
          <button
            type="button"
            class="apply-button"
            (click)="validatePromoCode()"
            [disabled]="!promo.code"
            [class.disabled]="!promo.code">
            Apply
          </button>
        </div>
        <button type="button" class="btn clear-button" [disabled]="!promo.code" (click)="clearPromoCode()">
          Clear
        </button>
      } @else {
        <img
          class="img-fluid mt-2 promo-processing"
          src="https://images.ctfassets.net/huwbudysxepr/62mhn3rxjG1HlQ5gfO93DR/e3f25c6d4739bd188db55888a4955673/search-loader.gif"
          alt="..Loading" />
      }
    </div>
    <div class="col-12">
      <p class="red mt-2" *ngIf="!promo.valid">
        <strong class="d-lg-none d-md-block">The promo code you entered is invalid.</strong>
        <strong class="d-none d-lg-block">The promo code you entered is invalid.</strong>
        <br />
        Please ensure you have entered the code correctly and that it matches the promotion details.
      </p>
    </div>
  </div>
</div>
