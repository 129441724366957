import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha-2';

import { environment } from '../../environments/environment';

import { SwiperDirective } from '../directives/swiper.directive';

// Pipes
import { ExtractPathAndParamsPipe, ExtractPathPipe, ExtractQueryPipe } from '../pipes/extract-path.pipe';
import { SafeHtmlPipe } from '../pipes/safe.html.pipe';
import { SafePipe } from '../pipes/safe.pipe';
import { BackgroundUrlPipe } from '../pipes/background.url.pipe';
import { UniqueNamesPipe } from '../pipes/unique-names.pipe';
import { UniqueByKeyPipe } from '../pipes/unique-by-key.pipe';
import { PreventLinkPipe } from '../pipes/prevent-link.pipe';
import { ClassTextHighlightPipe } from '../pipes/class-text-highlight.pipe';
import { PhraseClassTextHighlightPipe } from '../pipes/phrase-class-text-highlight.pipe';
import { AddQueryParamsPipe } from '@app/pipes/add-query-params.pipe';

// Angular Material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

// Sections
import { TabbedTourCarouselComponent } from './sections/tabbed-tour-carousel/tabbed-tour-carousel.component';
import { FiltersCarouselComponent } from './sections/filters-carousel/filters-carousel.component';
import { TravelInspirationComponent } from './sections/travel-inspiration/travel-inspiration.component';
import { MembershipCarouselComponent } from './sections/membership-carousel/membership-carousel.component';

// Components
import { SignupComponent } from './components/signup/signup.component';
import { ReviewStarsComponent } from './components/review-stars/review-stars.component';
import { TourCardComponent } from './components/tour-card/tour-card.component';
import { LoginRegisterComponent } from './components/login-register/login-register.component';
import { TourWishlistButtonComponent } from './components/tour-wishlist-button/tour-wishlist-button.component';
import { CarouselControlsComponent } from './components/carousel-controls/carousel-controls.component';
import { HeaderCarouselToursComponent } from './sections/header-carousel-tours/header-carousel-tours.component';
import { BannerSearchComponent } from './components/banner-search/banner-search.component';
import { ReasonsBlocksComponent } from './sections/reasons-blocks/reasons-blocks.component';
import { CategoryCarouselComponent } from './sections/category-carousel/category-carousel.component';
import { PortraitCardComponent } from './components/portrait-card/portrait-card.component';
import { PersonalReviewCardComponent } from './components/personal-review-card/personal-review-card.component';
import { YearRangeSelectorComponent } from './components/year-range-selector/year-range-selector.component';
import { BookingWidgetComponent } from './components/booking-widget/booking-widget.component';
import { OfficeAddressBlockComponent } from '@app/shared/components/office-address-block/office-address-block.component';

// Elements
import { TabsComponent } from './elements/buttons/tabs/tabs.component';
import { RoundButtonsComponent } from './elements/buttons/round-buttons/round-buttons.component';
import { CtaComponent } from './elements/buttons/cta/cta.component';
import { LoaderPlaceholerComponent } from './elements/loader-placeholer/loader-placeholer.component';
import { ToggleSwitchComponent } from './elements/toggle-switch/toggle-switch.component';
import { TourComponentsModule } from '../components/tour-components/tour-components.module';
import { NumberisePipe } from '../pipes/numberise.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BuilderModule } from '@builder.io/angular';

@NgModule({
  declarations: [
    SafePipe,
    SignupComponent,
    ExtractPathPipe,
    ExtractQueryPipe,
    ExtractPathAndParamsPipe,
    SafeHtmlPipe,
    BookingWidgetComponent,
    ReviewStarsComponent,
    BackgroundUrlPipe,
    UniqueNamesPipe,
    UniqueByKeyPipe,
    TourCardComponent,
    LoginRegisterComponent,
    TourWishlistButtonComponent,
    CarouselControlsComponent,
    TabsComponent,
    RoundButtonsComponent,
    CtaComponent,
    PreventLinkPipe,
    ClassTextHighlightPipe,
    PhraseClassTextHighlightPipe,
    AddQueryParamsPipe,
    TabbedTourCarouselComponent,
    LoaderPlaceholerComponent,
    ToggleSwitchComponent,
    SwiperDirective,
    HeaderCarouselToursComponent,
    ReasonsBlocksComponent,
    CategoryCarouselComponent,
    PortraitCardComponent,
    FiltersCarouselComponent,
    TravelInspirationComponent,
    MembershipCarouselComponent,
    YearRangeSelectorComponent,
    PersonalReviewCardComponent,
    BannerSearchComponent,
    OfficeAddressBlockComponent,
  ],
  exports: [
    SwiperDirective,
    ExtractPathPipe,
    ExtractQueryPipe,
    ExtractPathAndParamsPipe,
    BookingWidgetComponent,
    SafePipe,
    SafeHtmlPipe,
    PreventLinkPipe,
    ClassTextHighlightPipe,
    PhraseClassTextHighlightPipe,
    AddQueryParamsPipe,
    SignupComponent,
    ReviewStarsComponent,
    BackgroundUrlPipe,
    UniqueNamesPipe,
    UniqueByKeyPipe,
    TourCardComponent,
    LoginRegisterComponent,
    TourWishlistButtonComponent,
    TabbedTourCarouselComponent,
    CarouselControlsComponent,
    TabsComponent,
    LoaderPlaceholerComponent,
    RoundButtonsComponent,
    CtaComponent,
    ToggleSwitchComponent,
    HeaderCarouselToursComponent,
    ReasonsBlocksComponent,
    CategoryCarouselComponent,
    PortraitCardComponent,
    FiltersCarouselComponent,
    TravelInspirationComponent,
    MembershipCarouselComponent,
    YearRangeSelectorComponent,
    PersonalReviewCardComponent,
    BannerSearchComponent,
    OfficeAddressBlockComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RecaptchaV3Module,
    RouterModule,
    NgOptimizedImage,
    MatAutocompleteModule,
    MatOptionModule,
    MatButtonModule,
    MatMenuModule,
    TourComponentsModule,
    NumberisePipe,
    MatTooltipModule,
    BuilderModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
