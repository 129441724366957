import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SearchInputComponent } from './search-input/search-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TourComponentsModule } from '@app/components/tour-components/tour-components.module';
import { FacetFilterComponent } from './facet-filter/facet-filter.component';
import { FacetOptionSelectorComponent } from './facet-option-selector/facet-option-selector.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FacetAutocompleteSelectorComponent } from './facet-autocomplete-selector/facet-autocomplete-selector.component';
import { TourSearchCardComponent } from './tour-search-card/tour-search-card.component';
import { NumberisePipe } from '@app/pipes/numberise.pipe';
import { RouterLink } from '@angular/router';
import { SharedModule } from '@app/shared/shared-modules';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SearchStoreModule } from '@search/store/search-store.module';
import { FacetNumericRangeFilterComponent } from './facet-numeric-range-filter/facet-numeric-range-filter.component';
import { SelectedFacetFilterCountComponent } from './selected-facet-filter-count/selected-facet-filter-count.component';
import { SortSelectorComponent } from './sort-selector/sort-selector.component';
import { MatSelectModule } from '@angular/material/select';
import { BuilderModule } from '@builder.io/angular';

@NgModule({
  declarations: [
    SearchInputComponent,
    FacetFilterComponent,
    FacetOptionSelectorComponent,
    FacetAutocompleteSelectorComponent,
    TourSearchCardComponent,
    FacetNumericRangeFilterComponent,
    SelectedFacetFilterCountComponent,
    SortSelectorComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    TourComponentsModule,
    MatCheckboxModule,
    NgOptimizedImage,
    NumberisePipe,
    RouterLink,
    SharedModule,
    MatTooltipModule,
    SearchStoreModule,
    MatSelectModule,
    BuilderModule,
  ],
  exports: [
    SearchInputComponent,
    FacetFilterComponent,
    FacetAutocompleteSelectorComponent,
    TourSearchCardComponent,
    FacetNumericRangeFilterComponent,
    SelectedFacetFilterCountComponent,
    SortSelectorComponent,
    FacetOptionSelectorComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiModule {}
