import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ExternalScriptsService {
  public setTagManager() {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s) as HTMLScriptElement;
      const dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);

      // Adding GA4 script
      const gtagScript = d.createElement(s) as HTMLScriptElement;
      gtagScript.async = true;
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + i;
      f.parentNode.insertBefore(gtagScript, f);

      gtagScript.onload = function () {
        w.dataLayer = w.dataLayer || [];
        w.gtag = function (...args: any[]) {
          w.dataLayer.push(args);
        };
        w.gtag('js', new Date());
        w.gtag('config', i, { allow_enhanced_conversions: true });
      };
    })(window, document, 'script', 'dataLayer', environment.gtmId);
  }
}
