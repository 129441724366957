import { environment } from '@environments/environment';

export class CheckoutConfig {
  urls = {
    checkAvailability: (tourCode: string, departureDate: string, travellers: number) =>
      `${environment.toursV2Url}/v2/tour/capacity/${tourCode}/${departureDate}/${travellers}`,
    checkPromo: (promoCode: string, tourCode: string, departureDate: string, currency: string) =>
      `${environment.bookingV2Url}/v2/booking/promo?promoCode=${promoCode}&tourCode=${tourCode}&departureDate=${departureDate}&currency=${currency}`,
    tempBookingUrl: (currency, tourCode, departureCode, travellers, payType) =>
      `${environment.bookingV2Url}/v2/booking/init/${currency}/${tourCode}/${departureCode}/${travellers}/${payType}`,
    tempBookingUrlWithPromo: (currency, tourCode, departureCode, travellers, payType, promo) =>
      `${environment.bookingV2Url}/v2/booking/init/${currency}/${tourCode}/${departureCode}/${travellers}/${payType}/${promo}`,
    getBooking: (bookingId: string) => `${environment.bookingV2Url}/v2/booking/${bookingId}`,
    getBookingExternal: (bookingId: string, tourCmsChannel: string) =>
      `${environment.bookingV2Url}/v2/booking/external/${bookingId}/${tourCmsChannel}`,
    resetOptionalExtras: (bookingId: string) => `${environment.bookingV2Url}/v2/booking/resetOptionals/${bookingId}`,
    addOptionalExtras: (bookingId: string) => `${environment.bookingV2Url}/v2/booking/addOptional/${bookingId}`,
    getDepartures: (tourCode: string) => `${environment.toursV2Url}/v2/tour/tourCode/${tourCode}`,
    checkReturnTraveller: (bookingNumber: string, email: string) =>
      `${environment.bookingV2Url}/v2/booking/previousTraveller/${bookingNumber}/${email}`,
    setNote: bookingId => `${environment.bookingV2Url}/v2/booking/setNote/${bookingId}`,
    generateTpToken: () => `${environment.checkoutLemax}/booking/tp/token`,
    makePayment: () => `${environment.checkoutV2Url}/v2/checkout/payment`,
    makePaymentLemax: (bookingId: string) => `${environment.checkoutLemax}/booking/pay/${bookingId}`,
    createCart: (tourId: string, optionId: string) =>
      `${environment.checkoutLemax}/booking/step1/${tourId}/${optionId}`,
    getBookingLemax: (bookingId: string) => `${environment.checkoutLemax}/booking/get/${bookingId}`,
    getTourOptionalServices: (tourId: string, optionId: string, currency: string, promoCode?: string) =>
      `${environment.toursV3}/tours/single/option?tourId=${tourId}&invalidate=true&currencyCode=${currency}&optionId=${optionId}${promoCode ? `&promoCode=${promoCode}` : ''}`,
    convertLegacyBookingId: (bookingId: string) => `${environment.checkoutLemax}/booking/convert/legacy/${bookingId}`,
    makeBookingLemax: (shoppingCartId: string) => `${environment.checkoutLemax}/booking/payment/${shoppingCartId}`,
    generateHMAC: () => `${environment.checkoutLemax}/booking/tp/hash`,
    cartAbandonment: (step: number) => `${environment.checkoutLemax}/booking/step-completed-email/${step}`,
  };
}
