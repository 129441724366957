import { Component } from '@angular/core';
import { NeedAssistanceComponent } from '@app/feature/checkout/ui/components/need-assistance/need-assistance.component';

@Component({
  selector: 'app-checkout-footer',
  standalone: true,
  imports: [NeedAssistanceComponent],
  templateUrl: './checkout-footer.component.html',
  styleUrl: './checkout-footer.component.scss',
})
export class CheckoutFooterComponent {}
