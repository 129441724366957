<ng-template [ngIf]="footer">
  <section class="footer" *ngIf="footer.footerBlock">
    <div class="container">
      <div class="row">
        <div *ngFor="let block of footer.footerBlock" class="col-lg-3 col-md-12 col-sm-12 col-xs-12 footer-block">
          <h3>{{ block.title }}</h3>
          <ng-template [ngIf]="block.links">
            <p *ngFor="let link of block.links">
              <app-content-link [url]="link.url" [title]="link.text">
                {{ link.text }}
              </app-content-link>
            </p>
          </ng-template>
          <ng-container *ngIf="block.title === 'Legal'">
            <p>
              <a href="javascript:void(0)" class="cky-banner-element" (click)="openCookies()" title="Cookie Consent">
                Cookies
              </a>
            </p>
            <p>
              This site is protected by reCAPTCHA and the Google
              <a href="https://policies.google.com/privacy">Privacy Policy</a> and
              <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </p>
          </ng-container>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12 footer-contact footer-block">
          <h3>{{ footer.getInTouchHeading }}</h3>
          <ul>
            <li class="footerWhatsapp" *ngIf="footer.whatsappMessengerLink">
              <a [attr.href]="footer.whatsappMessengerLink" target="_blank" title="Get in touch over WhatsApp">
                WhatsApp
              </a>
            </li>
            <li class="footerChat" *ngIf="showChat">
              <a href="javascript:void(0);" (click)="openChatBot()" title="Open Chat Bot">Chat</a>
            </li>
            <li class="email">
              <a [attr.href]="'mailto:' + footer.emailAddress" title="Get in touch via email">
                {{ footer.emailAddress }}
              </a>
            </li>
            <li class="footerFacebook">
              <a [attr.href]="footer.facebookMessengerLink" target="_blank" title="Get in touch on Facebook Messenger">
                Facebook Messenger
              </a>
            </li>
            <li class="tel">
              <a [attr.href]="'tel:' + footer.phoneNumber" title="Give us a call">{{ footer.phoneNumber }}</a>
            </li>
            <li class="times" [innerHTML]="footer.contactHours"></li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12 footer-social">
          <div class="footer-social-inner">
            <a
              href="https://www.facebook.com/ExpatExploreTravel/"
              rel="noopener"
              target="_blank"
              class="facebook"
              title="Expat Explore Facebook"></a>
            <a
              href="https://www.instagram.com/expatexplore/"
              rel="noopener"
              target="_blank"
              class="instagram"
              title="Expat Explore Instagram"></a>
            <a
              href="https://www.youtube.com/user/ExpatExploreOfficial/featured"
              rel="noopener"
              target="_blank"
              class="youtube"
              title="Expat Explore Youtube"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-security-bar">
      <div class="newfooter-security">
        <div class="container">
          <div class="row">
            <div class="col-lg-1 col-md-4 col-sm-4 col-xs-4 d-flex align-items-center align-middle"></div>
            <div class="col-lg-1 col-md-4 col-sm-4 col-xs-4 d-flex align-items-center align-middle">
              <img
                class="img-fluid"
                style="max-height: 47px"
                src="https://images.ctfassets.net/huwbudysxepr/3C2uyEWwbFJ95i15cX0tkn/54fc39af943be00c002a3a11fc749f20/AWSCertificateManager_2b.png"
                alt="AWS Certificated" />
            </div>
            <div class="col-lg-4 col-md-8 col-sm-8 col-xs-8 d-flex align-items-center align-middle encrypted">
              <p class="text-center">256 bit SSL encrypted security</p>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-xs-4 d-flex align-items-center align-middle payment-cards">
              <img
                src="https://images.ctfassets.net/zggpk8714k6l/470LKWgGgEsKycoEeGsWWg/bf8e90962701acda5f785ee00663cefd/Mastercard-logo.svg"
                alt="Mastercard"
                class="img-fluid mastercard" />
              <img
                src="https://images.ctfassets.net/zggpk8714k6l/4UkUJPmak0Y2CS2mWAQyYE/5993aede1bd122136fdf624bc158123b/visa.svg"
                alt="Visa"
                class="img-fluid visa" />
            </div>
            <div
              class="col-lg-4 col-md-8 col-sm-8 col-xs-8 d-flex align-items-center align-middle copyright-year-sitemap">
              <div class="copyright-inner d-flex" [class.isTourPage]="isTourPage">
                <div>
                  <span> © Copyright 2011 - {{ currentYear }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
