import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addQueryParams',
})
export class AddQueryParamsPipe implements PipeTransform {
  transform(imageUrl: string, queryParams: { [key: string]: any }): string {
    if (Object.keys(queryParams).length === 0) {
      return imageUrl;
    }

    const queryString = new URLSearchParams(queryParams).toString();
    return imageUrl.includes('?') ? `${imageUrl}&${queryString}` : `${imageUrl}?${queryString}`;
  }
}
