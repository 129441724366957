import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { environment } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuth0Loading$ = this.auth0.isLoading$;
  isAuthenticated$ = this.auth0.isAuthenticated$;
  user$ = this.auth0.user$;
  jwtToken: string | null = null;

  constructor(
    private auth0: Auth0Service,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.auth0.idTokenClaims$.subscribe(claims => {
        this.jwtToken = claims?.__raw || null;
      });
    }
  }

  login() {
    this.auth0.loginWithRedirect({
      appState: { target: '/account/my-account/' },
      authorizationParams: {
        redirect_uri: environment.auth0.redirect + '/account/my-account/',
      },
    });
  }

  logout() {
    this.auth0.logout({
      logoutParams: {
        returnTo: environment.auth0.redirect,
      },
    });
  }

  getJwtToken(): string | null {
    return this.jwtToken;
  }
}
