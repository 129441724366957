import { State, Action, StateContext } from '@ngxs/store';
import { TrackingStateModel, UtmParams } from './utm-params.model';

export class SetUtmParams {
  static readonly type = '[Tracking] Set UTM Params';

  constructor(public payload: UtmParams) {}
}

@State<TrackingStateModel>({
  name: 'tracking',
  defaults: {
    utmParams: {
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      utm_term: '',
      utm_content: '',
    },
  },
})
export class TrackingState {
  @Action(SetUtmParams)
  setUtmParams(ctx: StateContext<TrackingStateModel>, action: SetUtmParams) {
    ctx.patchState({ utmParams: action.payload });
  }
}
