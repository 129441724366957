<div
  class="portrait-card"
  *ngIf="data"
  [ngStyle]="{ 'background-image': background ? 'url(' + data.image.url + ')' : '' }">
  <img
    [ngSrc]="data.image.url + '?fm=webp'"
    *ngIf="!background"
    [alt]="data.image.description"
    width="315"
    height="560"
    priority="" />
  <div class="text" (mouseenter)="trackPortraitCardAmplitude('Hover Card Text')">
    <h4 *ngIf="data.heading">{{ data.heading }}</h4>
    <div *ngIf="data.text" class="portrait-card-text" [innerHTML]="data.text"></div>
    <div class="button-wrapper">
      <app-cta
        *ngIf="data.url"
        cta="solid"
        color="yellow"
        [content]="blog ? 'View Blog' : 'View Tours'"
        (buttonSelected)="onCTA(data.url)"></app-cta>
    </div>
  </div>
</div>
