import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatedCountriesComponent } from './stated-countries/stated-countries.component';
import { NumberisePipe } from '../../pipes/numberise.pipe';

@NgModule({
  declarations: [StatedCountriesComponent],
  imports: [CommonModule, NumberisePipe],
  exports: [StatedCountriesComponent],
})
export class TourComponentsModule {}
