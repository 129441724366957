import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { GeoModel } from '@models/geo.model';
import { GeoConfig } from '../configs/geo.config';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GeoService {
  private config = new GeoConfig();
  error = null;
  geoData = new BehaviorSubject<GeoModel>(null);

  countryData = [
    { Country: 'Afghanistan', Code: '93', ISO2: 'AF', ISO3: 'AFG' },
    { Country: 'Albania', Code: '355', ISO2: 'AL', ISO3: 'ALB' },
    { Country: 'Algeria', Code: '213', ISO2: 'DZ', ISO3: 'DZA' },
    { Country: 'Andorra', Code: '376', ISO2: 'AD', ISO3: 'AND' },
    { Country: 'Angola', Code: '244', ISO2: 'AO', ISO3: 'AGO' },
    { Country: 'Anguilla', Code: '1-264', ISO2: 'AI', ISO3: 'AIA' },
    { Country: 'Antarctic', Code: '672', ISO2: 'AQ', ISO3: 'ATA' },
    { Country: 'Antigua And Barbuda', Code: '1-268', ISO2: 'AG', ISO3: 'ATG' },
    { Country: 'Argentina', Code: '54', ISO2: 'AR', ISO3: 'ARG' },
    { Country: 'Armenia', Code: '374', ISO2: 'AM', ISO3: 'ARM' },
    { Country: 'Aruba', Code: '297', ISO2: 'AW', ISO3: 'ABW' },
    { Country: 'Australia', Code: '61', ISO2: 'AU', ISO3: 'AUS' },
    { Country: 'Austria', Code: '43', ISO2: 'AT', ISO3: 'AUT' },
    { Country: 'Azerbaijan', Code: '994', ISO2: 'AZ', ISO3: 'AZE' },
    { Country: 'Bahamas', Code: '1-242', ISO2: 'BS', ISO3: 'BHS' },
    { Country: 'Bahrain', Code: '973', ISO2: 'BH', ISO3: 'BHR' },
    { Country: 'Bangladesh', Code: '880', ISO2: 'BD', ISO3: 'BGD' },
    { Country: 'Barbados', Code: '1-246', ISO2: 'BB', ISO3: 'BRB' },
    { Country: 'Belarus', Code: '375', ISO2: 'BY', ISO3: 'BLR' },
    { Country: 'Belgium', Code: '32', ISO2: 'BE', ISO3: 'BEL' },
    { Country: 'Belize', Code: '501', ISO2: 'BZ', ISO3: 'BLZ' },
    { Country: 'Benin', Code: '229', ISO2: 'BJ', ISO3: 'BEN' },
    { Country: 'Bermuda', Code: '1-441', ISO2: 'BM', ISO3: 'BMU' },
    { Country: 'Bhutan', Code: '975', ISO2: 'BT', ISO3: 'BTN' },
    { Country: 'Bolivia', Code: '591', ISO2: 'BO', ISO3: 'BOL' },
    { Country: 'Bosnia And Herzegovina', Code: '387', ISO2: 'BA', ISO3: 'BIH' },
    { Country: 'Botswana', Code: '267', ISO2: 'BW', ISO3: 'BWA' },
    { Country: 'Brazil', Code: '55', ISO2: 'BR', ISO3: 'BRA' },
    { Country: 'British Virgin Islands', Code: '1-284', ISO2: 'VG', ISO3: 'IVB' },
    { Country: 'Brunei Darussalam', Code: '673', ISO2: 'BN', ISO3: 'BRN' },
    { Country: 'Bulgaria', Code: '359', ISO2: 'BG', ISO3: 'BGR' },
    { Country: 'Burundi', Code: '257', ISO2: 'BI', ISO3: 'BDI' },
    { Country: 'Cambodia', Code: '855', ISO2: 'KH', ISO3: 'KHM' },
    { Country: 'Cameroon', Code: '237', ISO2: 'CM', ISO3: 'CMR' },
    { Country: 'Canada', Code: '1', ISO2: 'CA', ISO3: 'CAN' },
    { Country: 'Cape Verde', Code: '238', ISO2: 'CV', ISO3: 'CPV' },
    { Country: 'Cayman Islands', Code: '1', ISO2: 'KY', ISO3: 'CYM' },
    { Country: 'Central African Republic', Code: '236', ISO2: 'CF', ISO3: 'CAF' },
    { Country: 'Chad', Code: '235', ISO2: 'TD', ISO3: 'TCD' },
    { Country: 'Chile', Code: '56', ISO2: 'CL', ISO3: 'CHL' },
    { Country: 'China', Code: '86', ISO2: 'CN', ISO3: 'CHN' },
    { Country: 'Colombia', Code: '57', ISO2: 'CO', ISO3: 'COL' },
    { Country: 'Comoros', Code: '269', ISO2: 'KM', ISO3: 'COM' },
    { Country: 'Congo', Code: '242', ISO2: 'CG', ISO3: 'COG' },
    { Country: 'Cook Islands', Code: '682', ISO2: 'CK', ISO3: 'COK' },
    { Country: 'Costa Rica', Code: '506', ISO2: 'CR', ISO3: 'CRI' },
    { Country: "Cote d'Ivoire", Code: '225', ISO2: 'CI', ISO3: 'CIV' },
    { Country: 'Croatia', Code: '385', ISO2: 'HR', ISO3: 'HRV' },
    { Country: 'Cuba', Code: '53', ISO2: 'CU', ISO3: 'CUB' },
    { Country: 'Curacao', Code: '599', ISO2: 'CW', ISO3: 'CUW' },
    { Country: 'Cyprus', Code: '357', ISO2: 'CY', ISO3: 'CYP' },
    { Country: 'Czech Republic', Code: '420', ISO2: 'CZ', ISO3: 'CZE' },
    { Country: "Democratic People's Republic of Korea", Code: '850', ISO2: 'KP', ISO3: 'PRK' },
    { Country: 'Denmark', Code: '45', ISO2: 'DK', ISO3: 'DNK' },
    { Country: 'Djibouti', Code: '253', ISO2: 'DJ', ISO3: 'DJI' },
    { Country: 'Dominica', Code: '1-767', ISO2: 'DM', ISO3: 'DMA' },
    { Country: 'Dominican Republic', Code: '1-809, 1-829, 1-849', ISO2: 'DO', ISO3: 'DOM' },
    { Country: 'Ecuador', Code: '593', ISO2: 'EC', ISO3: 'ECU' },
    { Country: 'Egypt', Code: '20', ISO2: 'EG', ISO3: 'EGY' },
    { Country: 'El Salvador', Code: '503', ISO2: 'SV', ISO3: 'SLV' },
    { Country: 'Eritrea', Code: '291', ISO2: 'ER', ISO3: 'ERI' },
    { Country: 'Estonia', Code: '372', ISO2: 'EE', ISO3: 'EST' },
    { Country: 'Ethiopia', Code: '251', ISO2: 'ET', ISO3: 'ETH' },
    { Country: 'Falkland Islands', Code: '500', ISO2: 'FK', ISO3: 'FLK' },
    { Country: 'Faroe Islands', Code: '298', ISO2: 'FO', ISO3: 'FRO' },
    { Country: 'Fiji', Code: '679', ISO2: 'FJ', ISO3: 'FJI' },
    { Country: 'Finland', Code: '358', ISO2: 'FI', ISO3: 'FIN' },
    { Country: 'France', Code: '33', ISO2: 'FR', ISO3: 'FRA' },
    { Country: 'French Polynesia', Code: '689', ISO2: 'PF', ISO3: 'PYF' },
    { Country: 'Gabon', Code: '241', ISO2: 'GA', ISO3: 'GAB' },
    { Country: 'Gambia', Code: '220', ISO2: 'GM', ISO3: 'GMB' },
    { Country: 'Georgia', Code: '995', ISO2: 'GE', ISO3: 'GEO' },
    { Country: 'Germany', Code: '49', ISO2: 'DE', ISO3: 'DEU' },
    { Country: 'Ghana', Code: '233', ISO2: 'GH', ISO3: 'GHA' },
    { Country: 'Gibraltar', Code: '350', ISO2: 'GI', ISO3: 'GIB' },
    { Country: 'Greece', Code: '30', ISO2: 'GR', ISO3: 'GRC' },
    { Country: 'Greenland', Code: '299', ISO2: 'GL', ISO3: 'GRL' },
    { Country: 'Grenada', Code: '1-473', ISO2: 'GD', ISO3: 'GRD' },
    { Country: 'Guadeloupe', Code: '590', ISO2: 'GP', ISO3: 'GLP' },
    { Country: 'Guam', Code: '1-671', ISO2: 'GU', ISO3: 'GUM' },
    { Country: 'Guatemala', Code: '502', ISO2: 'GT', ISO3: 'GTM' },
    { Country: 'Guinea', Code: '224', ISO2: 'GN', ISO3: 'GIN' },
    { Country: 'Guyana', Code: '592', ISO2: 'GY', ISO3: 'GUY' },
    { Country: 'Haiti', Code: '509', ISO2: 'HT', ISO3: 'HTI' },
    { Country: 'Holy See', Code: '379', ISO2: 'VA', ISO3: 'VAT' },
    { Country: 'Honduras', Code: '504', ISO2: 'HN', ISO3: 'HND' },
    { Country: 'Hong Kong', Code: '852', ISO2: 'HK', ISO3: 'HKG' },
    { Country: 'Hungary', Code: '36', ISO2: 'HU', ISO3: 'HUN' },
    { Country: 'Iceland', Code: '354', ISO2: 'IS', ISO3: 'ISL' },
    { Country: 'India', Code: '91', ISO2: 'IN', ISO3: 'IND' },
    { Country: 'Indonesia', Code: '62', ISO2: 'ID', ISO3: 'IDN' },
    { Country: 'Iran', Code: '98', ISO2: 'IR', ISO3: 'IRN' },
    { Country: 'Iraq', Code: '964', ISO2: 'IQ', ISO3: 'IRQ' },
    { Country: 'Ireland', Code: '353', ISO2: 'IE', ISO3: 'IRL' },
    { Country: 'Israel', Code: '972', ISO2: 'IL', ISO3: 'ISR' },
    { Country: 'Italy', Code: '39', ISO2: 'IT', ISO3: 'ITA' },
    { Country: 'Jamaica', Code: '1-876', ISO2: 'JM', ISO3: 'JAM' },
    { Country: 'Japan', Code: '81', ISO2: 'JP', ISO3: 'JPN' },
    { Country: 'Jordan', Code: '962', ISO2: 'JO', ISO3: 'JOR' },
    { Country: 'Kazakhstan', Code: '7', ISO2: 'KZ', ISO3: 'KAZ' },
    { Country: 'Kenya', Code: '254', ISO2: 'KE', ISO3: 'KEN' },
    { Country: 'Kiribati', Code: '686', ISO2: 'KI', ISO3: 'KIR' },
    { Country: 'Kosovo', Code: '383', ISO2: 'KV', ISO3: 'RKV' },
    { Country: 'Kuwait', Code: '965', ISO2: 'KW', ISO3: 'KWT' },
    { Country: 'Kyrgyzstan', Code: '996', ISO2: 'KG', ISO3: 'KGZ' },
    { Country: 'Laos', Code: '856', ISO2: 'LA', ISO3: 'LAO' },
    { Country: 'Latvia', Code: '371', ISO2: 'LV', ISO3: 'LVA' },
    { Country: 'Lebanon', Code: '961', ISO2: 'LB', ISO3: 'LBN' },
    { Country: 'Lesotho', Code: '266', ISO2: 'LS', ISO3: 'LSO' },
    { Country: 'Liberia', Code: '231', ISO2: 'LR', ISO3: 'LBR' },
    { Country: 'Libya', Code: '218', ISO2: 'LY', ISO3: 'LBY' },
    { Country: 'Lichtens', Code: '423', ISO2: 'LI', ISO3: 'LIE' },
    { Country: 'Lithuania', Code: '370', ISO2: 'LT', ISO3: 'LTU' },
    { Country: 'Luxembourg', Code: '352', ISO2: 'LU', ISO3: 'LUX' },
    { Country: 'Macao', Code: '853', ISO2: 'MO', ISO3: 'MAC' },
    { Country: 'Macedonia', Code: '389', ISO2: 'MK', ISO3: 'MKD' },
    { Country: 'Madagascar', Code: '261', ISO2: 'MG', ISO3: 'MDG' },
    { Country: 'Malawi', Code: '265', ISO2: 'MW', ISO3: 'MWI' },
    { Country: 'Malaysia', Code: '60', ISO2: 'MY', ISO3: 'MYS' },
    { Country: 'Maldives', Code: '960', ISO2: 'MV', ISO3: 'MDV' },
    { Country: 'Mali', Code: '223', ISO2: 'ML', ISO3: 'MLI' },
    { Country: 'Malta', Code: '356', ISO2: 'MT', ISO3: 'MLT' },
    { Country: 'Marshall Islands', Code: '692', ISO2: 'MH', ISO3: 'MHL' },
    { Country: 'Martinique', Code: '596', ISO2: 'MQ', ISO3: 'MTQ' },
    { Country: 'Mauritania', Code: '222', ISO2: 'MR', ISO3: 'MRT' },
    { Country: 'Mauritius', Code: '230', ISO2: 'MU', ISO3: 'MUS' },
    { Country: 'Mexico', Code: '52', ISO2: 'MX', ISO3: 'MEX' },
    { Country: 'Micronesia', Code: '691', ISO2: 'FM', ISO3: 'FSM' },
    { Country: 'Moldova', Code: '373', ISO2: 'MD', ISO3: 'MDA' },
    { Country: 'Monaco', Code: '377', ISO2: 'MC', ISO3: 'MCO' },
    { Country: 'Mongolia', Code: '976', ISO2: 'MN', ISO3: 'MNG' },
    { Country: 'Montenegro', Code: '382', ISO2: 'ME', ISO3: 'MNE' },
    { Country: 'Morocco', Code: '212', ISO2: 'MA', ISO3: 'MAR' },
    { Country: 'Mozambique', Code: '258', ISO2: 'MZ', ISO3: 'MOZ' },
    { Country: 'Myanmar', Code: '95', ISO2: 'MM', ISO3: 'MMR' },
    { Country: 'Namibia', Code: '264', ISO2: 'NA', ISO3: 'NAM' },
    { Country: 'Nauru', Code: '674', ISO2: 'NR', ISO3: 'NRU' },
    { Country: 'Nepal', Code: '977', ISO2: 'NP', ISO3: 'NPL' },
    { Country: 'Netherlands', Code: '31', ISO2: 'NL', ISO3: 'NLD' },
    { Country: 'Netherlands Antilles', Code: '599', ISO2: 'AN', ISO3: 'ANT' },
    { Country: 'New Caledonia', Code: '687', ISO2: 'NC', ISO3: 'NCL' },
    { Country: 'New Zealand', Code: '64', ISO2: 'NZ', ISO3: 'NZL' },
    { Country: 'Nicaragua', Code: '505', ISO2: 'NI', ISO3: 'NIC' },
    { Country: 'Niger', Code: '227', ISO2: 'NE', ISO3: 'NER' },
    { Country: 'Nigeria', Code: '234', ISO2: 'NG', ISO3: 'NGA' },
    { Country: 'Niue', Code: '683', ISO2: 'NU', ISO3: 'NIU' },
    { Country: 'Norfolk Islands', Code: '672', ISO2: 'NF', ISO3: 'NFK' },
    { Country: 'Northern Mariana Islands', Code: '1-670', ISO2: 'MP', ISO3: 'MNP' },
    { Country: 'Norway', Code: '47', ISO2: 'NO', ISO3: 'NOR' },
    { Country: 'Oman', Code: '968', ISO2: 'OM', ISO3: 'OMN' },
    { Country: 'Pakistan', Code: '92', ISO2: 'PK', ISO3: 'PAK' },
    { Country: 'Palau', Code: '680', ISO2: 'PW', ISO3: 'PLW' },
    { Country: 'Palestine', Code: '970', ISO2: 'PS', ISO3: 'PSE' },
    { Country: 'Panama', Code: '507', ISO2: 'PA', ISO3: 'PAN' },
    { Country: 'Papua New Guinea', Code: '675', ISO2: 'PG', ISO3: 'PNG' },
    { Country: 'Paraguay', Code: '595', ISO2: 'PY', ISO3: 'PRY' },
    { Country: 'Peru', Code: '51', ISO2: 'PE', ISO3: 'PER' },
    { Country: 'Philippines', Code: '63', ISO2: 'PH', ISO3: 'PHL' },
    { Country: 'Poland', Code: '48', ISO2: 'PL', ISO3: 'POL' },
    { Country: 'Portugal', Code: '351', ISO2: 'PT', ISO3: 'PRT' },
    { Country: 'Puerto Rico', Code: '1-787, 1-939', ISO2: 'PR', ISO3: 'PUR' },
    { Country: 'Qatar', Code: '974', ISO2: 'QA', ISO3: 'QAT' },
    { Country: 'Réunion', Code: '262', ISO2: 'RE', ISO3: 'REU' },
    { Country: 'Romania', Code: '40', ISO2: 'RO', ISO3: 'ROM' },
    { Country: 'Russia', Code: '7', ISO2: 'RU', ISO3: 'RUS' },
    { Country: 'Rwanda', Code: '250', ISO2: 'RW', ISO3: 'RWA' },
    { Country: 'Saint Helena', Code: '290', ISO2: 'SH', ISO3: 'SHN' },
    { Country: 'Saint Kitts and Nevis', Code: '1-869', ISO2: 'KN', ISO3: 'KNA' },
    { Country: 'Saint Lucia', Code: '1-758', ISO2: 'LC', ISO3: 'LCA' },
    { Country: 'Saint Vincent And The Grenadines', Code: '1-784', ISO2: 'VC', ISO3: 'VCT' },
    { Country: 'Samoa', Code: '685', ISO2: 'WS', ISO3: 'WSM' },
    { Country: 'San Marino', Code: '378', ISO2: 'SM', ISO3: 'SMR' },
    { Country: 'Sao Tome and Principe', Code: '239', ISO2: 'ST', ISO3: 'STP' },
    { Country: 'Saudi Arabia', Code: '966', ISO2: 'SA', ISO3: 'SAU' },
    { Country: 'Senegal', Code: '221', ISO2: 'SN', ISO3: 'SEN' },
    { Country: 'Serbia', Code: '381', ISO2: 'RS', ISO3: 'SRB' },
    { Country: 'Serbia and Montenegro', Code: '381', ISO2: 'CS', ISO3: 'SCG' },
    { Country: 'Seychelles', Code: '248', ISO2: 'SC', ISO3: 'SYC' },
    { Country: 'Sierra Leone', Code: '232', ISO2: 'SL', ISO3: 'SLE' },
    { Country: 'Singapore', Code: '65', ISO2: 'SG', ISO3: 'SIN' },
    { Country: 'Slovakia', Code: '421', ISO2: 'SK', ISO3: 'SVK' },
    { Country: 'Slovenia', Code: '386', ISO2: 'SI', ISO3: 'SLO' },
    { Country: 'Solomon Islands', Code: '677', ISO2: 'SB', ISO3: 'SLB' },
    { Country: 'Somalia', Code: '252', ISO2: 'SO', ISO3: 'SOM' },
    { Country: 'South Africa', Code: '27', ISO2: 'ZA', ISO3: 'ZAF' },
    { Country: 'South Korea', Code: '82', ISO2: 'KR', ISO3: 'KOR' },
    { Country: 'Spain', Code: '34', ISO2: 'ES', ISO3: 'ESP' },
    { Country: 'Sri Lanka', Code: '94', ISO2: 'LK', ISO3: 'LKA' },
    { Country: 'Sudan', Code: '249', ISO2: 'SD', ISO3: 'SUD' },
    { Country: 'Suriname', Code: '597', ISO2: 'SR', ISO3: 'SUR' },
    { Country: 'Swaziland', Code: '268', ISO2: 'SZ', ISO3: 'SWZ' },
    { Country: 'Sweden', Code: '46', ISO2: 'SE', ISO3: 'SWE' },
    { Country: 'Switzerland', Code: '41', ISO2: 'CH', ISO3: 'CHE' },
    { Country: 'Syrian Arab Republic (Syria)', Code: '963', ISO2: 'SY', ISO3: 'SYR' },
    { Country: 'Taiwan', Code: '886', ISO2: 'TW', ISO3: 'TWN' },
    { Country: 'Tajikistan', Code: '992', ISO2: 'TJ', ISO3: 'TJK' },
    { Country: 'Tanzania', Code: '255', ISO2: 'TZ', ISO3: 'TZA' },
    { Country: 'Thailand', Code: '66', ISO2: 'TH', ISO3: 'THA' },
    { Country: 'Timor-Leste', Code: '670', ISO2: 'TL', ISO3: 'TLS' },
    { Country: 'Togo', Code: '228', ISO2: 'TG', ISO3: 'TOG' },
    { Country: 'Tonga', Code: '676', ISO2: 'TO', ISO3: 'TGA' },
    { Country: 'Trinidad and Tobago', Code: '1-868', ISO2: 'TT', ISO3: 'TRI' },
    { Country: 'Tunisia', Code: '216', ISO2: 'TN', ISO3: 'TUN' },
    { Country: 'Turkey', Code: '90', ISO2: 'TR', ISO3: 'TUR' },
    { Country: 'Turkmenistan', Code: '993', ISO2: 'TM', ISO3: 'TKM' },
    { Country: 'Turks And Caicos Islands', Code: '1-649', ISO2: 'TC', ISO3: 'TCA' },
    { Country: 'Tuvalu', Code: '688', ISO2: 'TV', ISO3: 'TUV' },
    { Country: 'Uganda', Code: '256', ISO2: 'UG', ISO3: 'UGA' },
    { Country: 'Ukraine', Code: '380', ISO2: 'UA', ISO3: 'UKR' },
    { Country: 'United Arab Emirates', Code: '971', ISO2: 'AE', ISO3: 'ARE' },
    { Country: 'United Kingdom', Code: '44', ISO2: 'GB', ISO3: 'GBR' },
    { Country: 'United States of America', Code: '1', ISO2: 'US', ISO3: 'USA' },
    { Country: 'United States Virgin Islands', Code: '1–340', ISO2: 'VI', ISO3: 'ISV' },
    { Country: 'Uruguay', Code: '598', ISO2: 'UY', ISO3: 'URU' },
    { Country: 'Uzbekistan', Code: '998', ISO2: 'UZ', ISO3: 'UZB' },
    { Country: 'Vanuatu', Code: '678', ISO2: 'VU', ISO3: 'VAN' },
    { Country: 'Venezuela', Code: '58', ISO2: 'VE', ISO3: 'VEN' },
    { Country: 'Vietnam', Code: '84', ISO2: 'VN', ISO3: 'VIE' },
    { Country: 'Yemen', Code: '967', ISO2: 'YE', ISO3: 'YEM' },
    { Country: 'Zambia', Code: '260', ISO2: 'ZM', ISO3: 'ZAM' },
    { Country: 'Zimbabwe', Code: '263', ISO2: 'ZW', ISO3: 'ZIM' },
  ];

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    if (isPlatformServer(platformId)) {
      this.setupDefaultGeoData(7114);
    }
  }

  getGeo(urlParams) {
    //This hits twice because its subscribe will first return '' and then real params from service. Necessary for fallback creation
    if (urlParams.length > 1) {
      //There are custom params in URL, create custom object regardless
      this.setupDefaultGeoData(urlParams);
    } else {
      //No custom parameters check if existing localStorage item
      if (localStorage.getItem('geoData')) {
        const geoObject = JSON.parse(localStorage.getItem('geoData'));
        const now = new Date();
        if (geoObject.expiry < now.getTime()) {
          //The localStorage item is expired create a new one
          this.callGeoAPI();
        } else {
          //localstorage item not expired, so just use it
          this.geoData.next(JSON.parse(localStorage.getItem('geoData')));
        }
      } else {
        //there is no localstorage item to use call geo API
        this.callGeoAPI();
      }
    }
  }

  callGeoAPI() {
    this.getGeoAPI().subscribe(
      (data: GeoModel) => {
        data.expiry = this.createExpiryDate();
        this.geoData.next(data);
        localStorage.setItem('geoData', JSON.stringify(data));
      },
      error => {
        //API errored use fallback to
        this.geoData.next(this.setupDefaultGeoData(7114));
      }
    );
  }

  getGeoAPI() {
    return this.http.get<GeoModel>(this.config.urls.getGeoData()).pipe(
      retry(1),
      catchError(err => this.handleError(err))
    );
  }

  customCurrencyCheck(urlParams) {
    //Searches url parameters for currency override
    const parameters = new URLSearchParams(urlParams);
    return parameters.get('channel');
  }

  phoneMap(currency) {
    //This is only for custom currency channel switch users. No need to call api
    if (currency === 'ZAR') {
      return '+27 87 550 4924';
    } else {
      return '+44 808 168 6799';
    }
  }

  convertISO3(iso3Currency: string): string {
    switch (iso3Currency) {
      case 'GBP':
        return '£';
      case 'ZAR':
        return 'R';
      case 'CAD':
        return 'CA$';
      case 'AUD':
        return 'AU$';
      case 'NZD':
        return 'NZ$';
      case 'EUR':
        return '€';
      default: // default for USD or any other currency
        return 'US$';
    }
  }

  convertCurrencyToISO3(currencySymbol) {
    switch (currencySymbol) {
      case '£':
        return 'GBP';
      case 'R':
        return 'ZAR';
      case 'CA$':
        return 'CAD';
      case 'AU$':
        return 'AUD';
      case 'NZ$':
        return 'NZD';
      case '€':
        return 'EUR';
      default: // default for USD or any other currency
        return 'USD';
    }
  }

  channelMap(channel_id) {
    switch (parseInt(channel_id)) {
      case 33: // GBP
        return 'GBP';
      case 288: // ZAR
        return 'ZAR';
      case 8833: // CAD
        return 'CAD';
      case 3220: // AUD
        return 'AUD';
      case 4931: // EUR
        return 'EUR';
      case 12852: // NZD
        return 'NZD';
      default:
        return 'USD';
    }
  }

  setupDefaultGeoData(customChannel) {
    //If user has set channel param in url then create the object for currency map they are trying to see ie: /tours/europe-escape?channel=33
    const customGeoObject = {
      channel_id: customChannel,
      email: 'info@expatexplore.com',
      phone: this.phoneMap(this.channelMap(customChannel)),
      country: 'Override',
      country_name: 'Override',
      currency: this.channelMap(customChannel),
      currency_sign: this.convertISO3(this.channelMap(customChannel)),
      ip: 'Override',
      expiry: this.createExpiryDate(),
      cloudFrontForwardedProto: 'Override',
      cloudFrontIsDesktopViewer: 'Override',
      cloudFrontIsMobileViewer: 'Override',
      cloudFrontIsSmartTvViewer: 'Override',
      cloudFrontIsTabletViewer: 'Override',
      cloudFrontViewerCountry: 'Override',
    };
    if (isPlatformBrowser(this.platformId)) {
      //set localstorage item, always check if this item exists when using this service
      localStorage.setItem('geoData', JSON.stringify(customGeoObject));
    }
    this.geoData.next(customGeoObject);
    return customGeoObject;
  }

  createExpiryDate() {
    //4 hours
    const now = new Date();
    return now.getTime() + 15000000;
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }
}
