import { AfterContentInit, Component, Input } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-custom-input-textarea',
  templateUrl: './custom-input-textarea.component.html',
  styleUrls: ['../custom-form.scss', 'custom-input-textarea.component.scss'],
})
export class CustomInputTextareaComponent implements AfterContentInit {
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() isWhiteBackground = true;
  @Input() validators: ValidatorFn[] = [];
  control: FormControl;

  ngAfterContentInit() {
    this.control = new FormControl('');
  }
}
