import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-placeholer',
  templateUrl: './loader-placeholer.component.html',
  styleUrls: ['./loader-placeholer.component.scss'],
})
export class LoaderPlaceholerComponent {
  @Input() size: 'small' | 'normal' | 'large' = 'normal';
}
