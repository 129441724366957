import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationConfig } from '../dto/models';
import { NgxsModule } from '@ngxs/store';
import { PaginationState } from '@pagination/store/store.state';

export const PAGINATION_CONFIG_TOKEN = new InjectionToken<PaginationConfig>('PAGINATION_CONFIG');

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxsModule.forFeature([PaginationState])],
})
export class StoreModule {
  // provides the means to configure the pagination module on a per-module basis so that each use-case
  // can have different pagination settings as desired
  static forFeature(config: PaginationConfig): ModuleWithProviders<StoreModule> {
    return {
      ngModule: StoreModule,
      providers: [{ provide: PAGINATION_CONFIG_TOKEN, useValue: config }],
    };
  }
}
