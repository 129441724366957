import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map, retry } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';

import { TourModel } from '../models/tour.model';
import { ToursConfig } from '../configs/tours.config';
import { MultiTourPageModel } from '../models/multi-tour-page.model';
import { DeparturesModel, TourDatesAndCapacity } from '../models/departures.model';
import { ActiveCampaign, CampaignsModel } from '../models/campaigns.model';
import { SearchConfig } from '@app/configs/search.config';
import { CompactTourModel } from '@models/compact-tour.model';
import { AmplitudeService } from './amplitude.service';

@Injectable({
  providedIn: 'root',
})
export class ToursService {
  public pageData: TourModel | MultiTourPageModel;
  private config = new ToursConfig();
  error = new Subject<string>();
  lowestPriceDeparture: BehaviorSubject<{
    fromPrice: string;
    price: string;
  }> = new BehaviorSubject(null);
  soldOutTour = new BehaviorSubject<boolean>(false);
  campaign = new BehaviorSubject<ActiveCampaign>(null);

  tourIndex: { [slug: string]: TourModel } = {};

  constructor(
    private http: HttpClient,
    private router: Router,
    private amplitudeService: AmplitudeService
  ) {}

  getTour(slug) {
    return this.http.get(this.config.urls.getTour(slug)).pipe(
      map((responseData: TourModel | MultiTourPageModel) => {
        this.pageData = responseData;
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  async getIndexTour(slug: string): Promise<TourModel> {
    // Check if tour data is already available in the index
    if (this.tourIndex[slug]) {
      return this.tourIndex[slug];
    }

    // Fetch tour data from the API
    try {
      const tourData = await this.http.get<TourModel>(this.config.urls.getTour(slug)).toPromise();
      // Store tour data in the index
      this.tourIndex[slug] = tourData;
      return tourData;
    } catch (error) {
      console.error(`Error fetching tour data for slug ${slug}:`, error);
      throw error; // Propagate the error
    }
  }

  checkSessionItemExpiry(sessionItemName: string) {
    const currentDate = new Date();
    const sessionObject = JSON.parse(sessionStorage.getItem(sessionItemName));
    const expirationDate = sessionObject.expiresAt;
    if (currentDate < new Date(expirationDate)) {
      //item is not expired
      console.log('not expired');
      return true;
    } else {
      //Remove item since it is expired
      console.log('expired');
      sessionStorage.removeItem(sessionItemName);
      return false;
    }
  }

  /**
   * TODO remove unused method
   * This is a temporary workaround in attempt to make search faster during Black Friday.
   * This is patchwork and should be reworked during Lemax integration
   */
  getCampaign() {
    return new Promise((resolve, reject) => {
      if (sessionStorage.getItem('storedCampaign') && this.checkSessionItemExpiry('storedCampaign')) {
        const campaignData = JSON.parse(sessionStorage.getItem('storedCampaign'));
        this.campaign.next({
          active: true,
          campaign: campaignData.code,
          departures: campaignData.departures,
        });
        resolve({
          active: true,
          campaign: campaignData.code,
          departures: campaignData.departures,
        });
      } else {
        this.getCampaignDepartures().subscribe({
          next: (campaignData: CampaignsModel) => {
            if (campaignData?.[0]) {
              const expires = new Date();
              expires.setMinutes(expires.getMinutes() + 5); //5 minutes from now
              const sessionObject = campaignData[0];
              this.campaign.next(sessionObject);
              resolve({
                active: true,
                campaign: sessionObject.code,
                departures: sessionObject.departures,
              });
            } else {
              //@todo check if no campaign active
              resolve({
                active: false,
                campaign: '',
                departures: [],
              });
            }
          },
          error: error => {
            console.log('error getCampaign', error);
            reject(error);
          },
        });
      }
    });
  }

  /**
   * TODO remove unused method
   * This is a temporary workaround in attempt to make search faster during black friday.
   * This is patchwork and should be reworked during Lemax integration
   */
  getDeparturesSearch(tourCode: string, campaignDepartures?: any) {
    return new Promise(resolve => {
      if (sessionStorage.getItem(`departures${tourCode}`) && this.checkSessionStorageItem(tourCode)) {
        resolve(JSON.parse(sessionStorage.getItem(`departures${tourCode}`)).departures);
      } else {
        //tour departures dont exist yet, fetch prices from lambda
        let departures = [];
        this.getDeparturesLambda(tourCode).subscribe({
          next: depData => {
            if (campaignDepartures && campaignDepartures.length > 0) {
              const regularDeps = depData.tourDatesAndCapacity.items;
              for (let i = 0; i < campaignDepartures.length; i++) {
                if (campaignDepartures[i].tourCode === tourCode) {
                  Object.keys(regularDeps).forEach(function (key) {
                    if (regularDeps[key].dateCode === campaignDepartures[i].departureCode) {
                      regularDeps[key].discount = campaignDepartures[i].discount;
                    }
                  });
                }
              }
              departures = regularDeps;
              this.createSessionStorageItem(tourCode, departures);
            } else {
              //There are no campaign departures, assign regular departures WITHOUT discount properties
              sessionStorage.removeItem('storedCampaign');
              departures = depData.tourDatesAndCapacity.items;
              this.createSessionStorageItem(tourCode, departures);
              resolve(departures);
            }
            resolve(departures);
          },
          error: error => {
            console.log('getDeparturesError: ', error);
          },
        });
      }
    });
  }

  /**
   * TODO remove unused method
   * Returns array of departures (including campaign departures) from either sessionStorage or if they're not there make api call from getDeparturesLambda()
   * campaign departures will have a .discount property as percentage
   * */
  getDepartures(tourCode: string) {
    return new Promise(resolve => {
      if (sessionStorage.getItem(`departures${tourCode}`) && this.checkSessionStorageItem(tourCode)) {
        this.getStoredCampaign();
        resolve(JSON.parse(sessionStorage.getItem(`departures${tourCode}`)).departures);
        if (sessionStorage.getItem('campaignActive')) {
          const campaignData = JSON.parse(sessionStorage.getItem('campaignActive'));
          this.campaign.next({
            active: true,
            campaign: campaignData.code,
            departures: campaignData.departures,
          });
        }
      } else {
        //tour departures dont exist yet, fetch prices from lambda
        let departures = [];
        this.getDeparturesLambda(tourCode).subscribe({
          next: depData => {
            this.getCampaignDepartures().subscribe({
              next: campaignData => {
                if (campaignData?.[0]) {
                  sessionStorage.setItem('campaignActive', JSON.stringify(campaignData[0]));
                  //There are campaign departures, filter through regular departures add a PROPERTY for their discounts
                  //The campaign service returns an array of campaigns, we will ALWAYS ONLY use the first object in the array (campDepData[0]),
                  //whether that is BF or APL we don't care. Veera users must simply NOT activate 2 different campaigns in the same Validity time (Valid From / Valid To)
                  const campaignDeps = campaignData[0].departures;
                  const regularDeps = depData.tourDatesAndCapacity.items;
                  for (let i = 0; i < campaignDeps.length; i++) {
                    if (campaignDeps[i].tourCode === tourCode) {
                      Object.keys(regularDeps).forEach(function (key) {
                        if (regularDeps[key].dateCode === campaignDeps[i].departureCode) {
                          regularDeps[key].discount = campaignDeps[i].discount;
                        }
                      });
                    }
                  }
                  departures = regularDeps;
                  this.createSessionStorageItem(tourCode, departures);
                  this.campaign.next({
                    active: true,
                    campaign: campaignData[0].code,
                    departures: campaignData[0].departures,
                  });
                  // this.getStoredCampaign();
                  resolve(departures);
                } else {
                  //There are no campaign departures, assign regular departures WITHOUT discount properties
                  sessionStorage.removeItem('campaignActive');
                  departures = depData.tourDatesAndCapacity.items;
                  this.createSessionStorageItem(tourCode, departures);
                  resolve(departures);
                }
              },
              error: this.handleError.bind(this),
            });
          },
          error: this.handleError.bind(this),
        });
      }
    });
  }

  /**
   * Anywhere we have prices (tour pages + listing pages with cards) we also check for active campaigns,
   * in that case subscribe to this.campaign from any component in order to style your page
   */
  getStoredCampaign() {
    if (sessionStorage.getItem('campaignDepartures')) {
      const storedCampaign = JSON.parse(sessionStorage.getItem('campaignDepartures'));
      if (storedCampaign[0]) {
        // this.campaign.next({
        // 	active: true,
        // 	campaign: storedCampaign[0].code,
        // 	departures: storedCampaign[0].departures
        // });
      }
    }
  }

  /**
   * to subtract the discount value of a departures prices and return new value
   * @param price :string of float value ie: 31240.00
   * @param discount :number of solid integer ie: 10
   */
  addDiscountValue(price: string, discount: number) {
    if (discount) {
      const percentageValue = (discount / 100) * parseFloat(price);
      const newValue = parseFloat(price) - percentageValue;
      return newValue.toFixed(2);
    } else {
      return price;
    }
  }

  /**
   * returns array of departures that are open and valid for booking
   * Must use this function even if getting departures from session storage
   * - Removes departures that are in the past or have no price value for the currency in question
   * */
  departuresManipulation(departures, geoData) {
    const filteredDepartures = departures.filter(
      a => !this.isDatePassed(a.startDate) && parseFloat(a.price[geoData.currency]) > 0
    );

    //Apply any veera campaign discounts to departure prices
    filteredDepartures.forEach((departure: any) => {
      if (departure.discount) {
        departure.price[geoData.currency] = this.addDiscountValue(
          departure.price[geoData.currency],
          departure.discount
        );
      }
    });

    //Lowest price
    const lp = departures
      .map(a => {
        a.state = this.departureOpenClosedState(a);
        return a;
      })
      .sort((a, b) => {
        return parseFloat(a.price[geoData.currency]) > parseFloat(b.price[geoData.currency]) ? 1 : -1;
        // return parseFloat(this.addDiscountValue(a.price[geoData.currency],a.discount)) > parseFloat(this.addDiscountValue(b.price[geoData.currency],b.discount)) ? 1 : -1;
      });

    //final departures array is ready for front end
    return filteredDepartures;
  }

  /**
   * MUST FIRST USE departuresManipulation() to filter out inactive / sold out dates
   * returns the lowest priced departure
   * Must have geoData available for currency code [ZAR/ CAD etc..]
   * You can reference the departures .price and its .fromPrice
   * */
  getLowestPriceDeparture(filteredDepartures, geoData) {
    const lp = filteredDepartures
      .map(a => {
        a.state = this.departureOpenClosedState(a);
        return a;
      })
      .sort((a, b) => {
        return parseFloat(a.price[geoData.currency]) > parseFloat(b.price[geoData.currency]) ? 1 : -1;
      });

    return lp[0];
  }

  departureOpenClosedState(departure: TourDatesAndCapacity) {
    return (departure.state =
      this.isDatePassed(departure.startDate) || departure.status !== 1 || parseInt(departure.spacesRemaining) < 1
        ? 'closed'
        : 'open');
  }

  isDatePassed(startDate: Date) {
    const now = new Date();
    return startDate.getTime() < now.getTime();
  }

  createSessionStorageItem(tourCode: string, departures: unknown[]) {
    //Add tour departures to session storage
    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + 5); //5 minutes from now
    const sessionObject = {
      expiresAt: expires,
      departures: departures,
    };
    sessionStorage.setItem(`departures${tourCode}`, JSON.stringify(sessionObject));
  }

  /**
   * Check expiry of item, if older than 5 minutes remove it instead
   * */
  checkSessionStorageItem(tourCode: string) {
    const currentDate = new Date();
    const sessionObject = JSON.parse(sessionStorage.getItem(`departures${tourCode}`));
    const expirationDate = sessionObject.expiresAt;
    if (currentDate < new Date(expirationDate)) {
      //item is not expired
      return true;
    } else {
      //Remove item since it is expired
      sessionStorage.removeItem(`departures${tourCode}`);
      return false;
    }
  }

  getDeparturesLambda(tourCode: string) {
    return this.http.get<DeparturesModel>(this.config.urls.getDepartures(tourCode)).pipe(
      retry(1),
      catchError(err => this.handleError(err))
    );
  }

  getCampaignDepartures() {
    return this.http.get<CampaignsModel>(this.config.urls.getCampaignDepartures()).pipe(
      retry(1),
      catchError(err => this.handleError(err))
    );
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }

  formatPrice(price: string | number | undefined) {
    if (typeof price === 'undefined' || price === '0') {
      return 0;
    }
    if (typeof price === 'string') {
      price = parseFloat(price);
    }
    return price.toFixed(0).replace(/\B(?=(\d{3})+\b)/g, ',');
  }

  getCompactToursData(): Observable<CompactTourModel[]> {
    const cfg = new SearchConfig();
    return this.http.get<CompactTourModel[]>(cfg.urls.getCompactTourData);
  }

  trackTourPageInit(category: string) {
    const pageData = {
      category: category,
      page: this.amplitudeService.setPageUrl(this.router.url),
    };
    this.amplitudeService.trackEvent('Tour Page Initiated', pageData);
  }

  trackTourPageButton(location: string, tourCode: string, tourName: string, item: string, itemID: string) {
    const buttonData = {
      location: location,
      'tour-code': tourCode,
      'tour-name': tourName,
      item: item,
      'item-id': itemID,
      page: this.amplitudeService.setPageUrl(this.router.url),
    };
    this.amplitudeService.trackEvent('Tour Page Button Clicked', buttonData);
  }
}
