import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ImageModel } from '../../../models/image.model';

@Component({
  selector: 'app-personal-review-card',
  templateUrl: './personal-review-card.component.html',
  styleUrls: ['./personal-review-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PersonalReviewCardComponent {
  @Input() icon?: ImageModel;
  @Input() name?: string;
  @Input() title?: string;
  @Input() text?: string;
}
