<div class="heart-icon-container" *ngIf="tourFullData || tourData">
  <button
    class="btn btn-wishlist"
    [ngClass]="{ 'heart-only': shortText, 'heart-filled': wishlisted, 'heart-empty': !wishlisted }"
    (click)="addRemoveWishlistItem(wishlistItem.tour)">
    <ng-container *ngIf="wishlisted">
      <img
        src="https://images.ctfassets.net/zggpk8714k6l/3l9r5XgxmVYRyMKsVqwgqz/3833052697de238fd640dc9d339fc4e4/icon-added-to-wishlist.svg"
        alt="Added to Wishlist" />
      <span *ngIf="!shortText">Added to Wishlist</span>
    </ng-container>
    <ng-container *ngIf="!wishlisted">
      <img
        src="https://images.ctfassets.net/zggpk8714k6l/6BhgUO2Bs1ydhWfrPJztvc/59531a19488310a5e53b05b6ef2ac435/icon-add-to-wishlist.svg"
        alt="Add to Wishlist" />
      <span *ngIf="!shortText">Add to Wishlist</span>
    </ng-container>
  </button>
  <span class="wishlist-tooltip-item" *ngIf="shortText && wishlisted">
    <img
      src="https://images.ctfassets.net/zggpk8714k6l/19CHFcq3TQpEROA2EN1iXb/7e85524fb26b16ed5feb34bf0b2d8126/question-icon.svg"
      alt="Question Icon" />
    <span class="wishlist-tooltip-text">
      <img
        src="https://images.ctfassets.net/zggpk8714k6l/19CHFcq3TQpEROA2EN1iXb/7e85524fb26b16ed5feb34bf0b2d8126/question-icon.svg"
        alt="Question Icon" />
      <span class="tooltip-text">Click to remove</span>
    </span>
  </span>
</div>
