<div class="input-container-country-phone" [formGroup]="formGroup">
  <div class="input-container-country-phone-inner">
    <div class="select-wrap">
      <select [id]="controlName + '-country-code-phone'" name="country-code-phone" formControlName="dialCode" required>
        <option value="default" disabled selected>Select a Country*</option>
        <option *ngFor="let phoneNumber of countryData" [value]="phoneNumber.Code">
          {{ phoneNumber.Country }} (+{{ phoneNumber.Code }})
        </option>
      </select>
      <span *ngIf="formGroup.get('dialCode').invalid && formGroup.get('dialCode').touched" class="icon invalid">
        <app-cross></app-cross>
      </span>
      <span *ngIf="formGroup.get('dialCode').valid" class="icon valid">
        <app-tick></app-tick>
      </span>
    </div>

    <div class="input-phone-wrap">
      <input
        [id]="controlName + '-contact-number'"
        type="number"
        formControlName="contactNumber"
        inputmode="numeric"
        name="contact_number"
        placeholder="Your contact number"
        class="form-control float-end"
        maxlength="20"
        required />
      <span
        *ngIf="formGroup.get('contactNumber').invalid && formGroup.get('contactNumber').touched"
        class="icon invalid">
        <app-cross></app-cross>
      </span>
      <span *ngIf="formGroup.get('contactNumber').valid" class="icon valid">
        <app-tick></app-tick>
      </span>
    </div>
  </div>
  <div
    class="error-message"
    [ngClass]="{ 'white-background': isWhiteBackground, 'other-background': !isWhiteBackground }"
    *ngIf="
      (formGroup.get('dialCode').invalid && formGroup.get('contactNumber').touched) ||
      (formGroup.get('contactNumber').invalid && formGroup.get('contactNumber').touched)
    ">
    <span *ngIf="formGroup.get('dialCode').errors">[Country of residence required]</span>
    <span *ngIf="formGroup.get('contactNumber').errors?.required"> [Enter a phone number]</span>
  </div>
</div>
