import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CheckoutStateModel } from '@app/feature/checkout/dto/types';
import { CheckoutState } from '@app/feature/checkout/store/checkout.state';
import { SubSink } from 'subsink';
import { take } from 'rxjs/operators';
import { SetCurrentPage } from '@app/feature/checkout/store/checkout.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkout-navigation',
  templateUrl: './checkout-navigation.component.html',
  styleUrl: './checkout-navigation.component.scss',
})
export class CheckoutNavigationComponent implements OnInit, OnDestroy {
  state$: Observable<CheckoutStateModel>;
  subs: SubSink = new SubSink();
  activeStep: number;

  constructor(
    private store: Store,
    private router: Router
  ) {}
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.state$ = this.store.select(CheckoutState.getState);
    this.subs.sink = this.state$.pipe(take(1)).subscribe(state => {
      this.activeStep = state.currentPage;
    });
  }

  navigateToStep(step: number) {
    if (step < this.activeStep) {
      this.store.dispatch(new SetCurrentPage(step));
      this.router.navigate(['/checkout/step' + step]);
    }
  }
}
