import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ContentLinkComponent } from './content-link/content-link.component';
import { RouterLink } from '@angular/router';
import { SharedModule } from '@app/shared/shared-modules';
import { ExpatLogoComponent } from './expat-logo/expat-logo.component';
import { WhatsappComponent } from '@app/components/ui/whatsapp/whatsapp.component';

@NgModule({
  declarations: [ContentLinkComponent, ExpatLogoComponent, WhatsappComponent],
  imports: [CommonModule, RouterLink, SharedModule, NgOptimizedImage],
  exports: [ContentLinkComponent, ExpatLogoComponent, WhatsappComponent],
})
export class UiModule {}
