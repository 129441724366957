import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { ReviewsConfig } from '../configs/reviews.config';
import { ReviewModel } from '@models/review.model';

@Injectable({
  providedIn: 'root',
})
export class ReviewsService {
  private config = new ReviewsConfig();
  error = null;

  constructor(private http: HttpClient) {}

  getTourReviews(reviewCode: string, page?: number, pageSize?: number) {
    const queryParams = [];
    if (page) queryParams.push(`page=${page}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize}`);

    let queryString = '';
    if (queryParams.length > 0) queryString = `?${queryParams.join('&')}`;

    //Single tour review
    return this.http
      .get<ReviewModel>(this.config.urls.getTourReviews(reviewCode, queryString))
      .pipe(retry(1), catchError(this.handleError));
  }

  getAllReviews(page?: number, pageSize?: number): Observable<ReviewModel> {
    const queryParams = [];
    if (page) queryParams.push(`page=${page}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize}`);

    let queryString = '';
    if (queryParams.length > 0) queryString = `?${queryParams.join('&')}`;
    //All reviews for all Expat Tours
    return this.http
      .get<ReviewModel>(this.config.urls.getAllReviews(queryString))
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => errorMessage);
  }
}
