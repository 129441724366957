<h3>Checkout:</h3>
<div class="step-container d-flex align-items-center justify-content-between w-100">
  <div
    class="step d-flex align-items-center justify-content-center"
    [class.active]="activeStep >= 1"
    [class.completed]="activeStep > 1"
    (keydown)="navigateToStep(1)"
    (click)="navigateToStep(1)">
    <span>Traveller Details</span>
  </div>
  <div class="connector flex-grow-1" [class.active]="activeStep >= 2"></div>
  <div
    class="step d-flex align-items-center justify-content-center"
    [class.active]="activeStep >= 2"
    [class.completed]="activeStep > 2"
    (keydown)="navigateToStep(2)"
    (click)="navigateToStep(2)">
    <span>Payment method</span>
  </div>
</div>
