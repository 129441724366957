<button
  class="tab-button"
  [ngClass]="{
    'tab-initial': index === 0,
    'campaign-tab': index === 0 && campaignColourConfig,
    'tab-last': index + 1 === total,
    'tab-active': isActiveSlide,
    'tab-group': index + 1 === active || index - 1 === active,
    'tab-promo': isPromo,
  }"
  (click)="onTabClick()"
  [ngStyle]="{
    'background-color': index === 0 ? campaignColourConfig?.tabBgColour || '' : '',
    color: index === 0 ? campaignColourConfig?.tabTextColour || '' : '',
  }">
  {{ text }}
</button>
