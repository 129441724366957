<div class="pay-now-wrapper d-flex justify-content-center justify-content-lg-end">
  <div *ngIf="!isPaymentDetailsLoaded() || submitting; else paymentButton">
    <div class="loading">
      <img
        class="img-fluid button-loading-image"
        src="https://images.ctfassets.net/huwbudysxepr/62mhn3rxjG1HlQ5gfO93DR/e3f25c6d4739bd188db55888a4955673/search-loader.gif"
        alt="..Loading"
        width="250" />
    </div>
  </div>
  <ng-template #paymentButton>
    <button (click)="onSubmit()">Pay Now</button>
  </ng-template>
</div>
