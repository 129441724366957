<div class="checkout-wrapper" *ngIf="cartItems && bookingSuccessData">
  <app-checkout-header></app-checkout-header>
  <div class="container">
    <div class="row">
      <div class="success-header d-flex col-12">
        <h3>Your tour is booked! Get ready for the adventure of a lifetime.</h3>
        <app-need-assistance class="d-none d-lg-flex"></app-need-assistance>
      </div>
    </div>
  </div>
  <section class="container my-5">
    <div class="row">
      <!-- Left Column - Tour Summary -->
      <div class="col-12 col-lg-5">
        <div class="success-card p-4">
          <h4 class="mb-3">Tour Summary:</h4>
          <h5 class="mb-5">{{ cartItems[0].tourData.tourName }}</h5>
          <p class="color-black"><strong>Booking number:</strong> {{ bookingSuccessData.bookingCode }}</p>
          <p><strong>Start Date:</strong> {{ cartItems[0].option.attributes.periodStart }}</p>
          <p><strong>End Date:</strong> {{ cartItems[0].option.attributes.periodEnd }}</p>
          <p class="color-black"><strong>Countries:</strong> {{ cartItems[0].tourData.numberOfCountries }}</p>
          <p class="color-black">
            <strong>Experiences:</strong> {{ cartItems[0].tourData.whatsIncluded.numberOfExperiences }}
          </p>
        </div>

        <div class="mt-5">
          <h5>Next Steps:</h5>
          <p>
            Update your important passenger information (IPI)
            <a [href]="'/cs_contact/login?b=' + bookingSuccessData.bookingId" class="text-danger fw-bold">here</a>.
          </p>
          <p>
            To view essential tour information and optional excursions please visit the
            <a [href]="'/tours-help/' + cartItems[0].tourData.slug" class="text-danger fw-bold">help section</a>.
          </p>
        </div>

        <div
          class="success-instalments mt-5"
          *ngIf="
            (paymentMethod?.selectedPaymentMethod === 'instalment' ||
              paymentMethod?.selectedPaymentMethod === 'instalment') &&
            paymentMethod?.data
          ">
          <h5>Remaining monthly payments:</h5>
          <ul class="list-unstyled">
            <li *ngFor="let date of paymentMethod?.data.dates">
              {{ date | date: 'dd MMM yyyy' }} - Auto deduction:
              <strong>{{ paymentMethod?.data?.baseAmount | currency: geo.currency_sign }}.</strong>
            </li>
          </ul>
        </div>
      </div>

      <!-- Right Column - Video Placeholder -->
      <div class="col-12 col-lg-7 d-flex align-items-start justify-content-center">
        <div class="success-card d-flex align-items-center justify-content-center">
          <iframe class="embed-responsive-item" loading="lazy" [src]="youtubeVideo | safe" allowfullscreen></iframe>
        </div>
      </div>

      <div class="col-12">
        <div class="mt-5">
          <h5>Pre and post-tour accommodation</h5>
          <p>
            Secure your pre and post-tour accommodation on
            <a href="https://www.booking.com/index.html?&aid=2263248">Booking.com</a>
          </p>
        </div>

        <div class="mt-5">
          <h5>Travel insurance</h5>
          <p>
            It is vital that you secure adequate travel insurance for your tour. To learn more about travel insurance
            options, click <a href="/insurance/" class="text-danger fw-bold">here</a>.
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
<app-signup
  formDirection="horizontal"
  heading="Join the Expat Explore family!"
  description="We’ll deliver top travel tips, insider info and travel inspiration right to your inbox."
  class="d-block mb-4 mb-lg-0">
</app-signup>
<app-checkout-footer></app-checkout-footer>
