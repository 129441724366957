import { Component, ElementRef, Input, ViewChild, ViewEncapsulation, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { carouselCategories } from '../../../models/home.model';

import { SwiperOptions } from 'swiper/types';
import { AmplitudeService } from '../../../services/amplitude.service';

@Component({
  selector: 'app-category-carousel',
  templateUrl: './category-carousel.component.html',
  styleUrls: ['./category-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CategoryCarouselComponent implements OnInit {
  @Input() data: carouselCategories[];
  @Input() heading?: string;
  @Input() text?: string;
  @Input() showFilter? = true;
  @ViewChild('swiper', { static: false }) swiperContainer!: ElementRef;
  @ViewChild('swiperFilters', { static: false }) swiperFiltersContainer!: ElementRef;

  dataReady = false;
  filteredData: carouselCategories[];
  filters: string[] = [];
  catSelection = 'All';
  amplitudeTrackingValues = {
    categoryFilter: this.catSelection,
    carouselIndex: 0,
    touchMove: false,
  };
  swiperConfig: SwiperOptions = {
    spaceBetween: 30,
    slidesPerView: 'auto',
    initialSlide: 0,
    slidesPerGroup: 1,
    observer: true,
    observeParents: true,
    freeMode: true,
    mousewheel: {
      forceToAxis: true,
    },
    navigation: {
      nextEl: '.category-carousel-next',
      prevEl: '.category-carousel-prev',
    },
    on: {
      observerUpdate: () => {
        if (this.swiperContainer && this.swiperContainer?.nativeElement) {
          this.swiperContainer.nativeElement.swiper.navigation.update();
        }
      },
      slideChange: () => {
        if (this.swiperContainer && this.swiperContainer?.nativeElement?.swiper) {
          this.amplitudeTrackingValues.carouselIndex = this.swiperContainer.nativeElement.swiper.realIndex;
        }
      },
      touchStart: () => {
        if (this.swiperContainer && this.swiperContainer?.nativeElement?.swiper) {
          this.amplitudeTrackingValues.carouselIndex = this.swiperContainer.nativeElement.swiper.realIndex;
          this.trackCategoryCarouselAmplitude('Touch Start');
        }
      },
    },
    breakpoints: {
      640: {
        freeMode: false,
      },
    },
  };
  swiperFiltersConfig: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    freeMode: true,
    navigation: {
      nextEl: '.filters-carousel-prev',
      prevEl: '.filters-carousel-next',
    },
  };

  constructor(
    private router: Router,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {
    this.data.forEach((item: carouselCategories) => {
      item.category.forEach((category: string) => {
        if (this.filters.indexOf(category) === -1) {
          this.filters.push(category);
        }
      });
    });
    this.filters.sort();
    this.filteredData = this.data;
    this.dataReady = true;
  }

  onFilter(category: string) {
    this.catSelection = category;
    this.amplitudeTrackingValues.categoryFilter = category;
    this.filteredData = this.data.filter(item => item.category.includes(category));
    if (this.swiperContainer && this.swiperContainer?.nativeElement?.swiper) {
      this.amplitudeTrackingValues.carouselIndex = this.swiperContainer.nativeElement.swiper.realIndex;
    }
    this.trackCategoryCarouselAmplitude('Filter Button Click');
  }

  trackCategoryCarouselAmplitude(event: string) {
    const categoryCarouselData = {
      event: event,
      page: this.amplitudeService.setPageUrl(this.router.url),
      section: this.heading,
      'category-filter': this.amplitudeTrackingValues.categoryFilter,
      'carousel-index': this.amplitudeTrackingValues.carouselIndex,
    };
    this.amplitudeService.trackEvent('Category Carousel Interaction', categoryCarouselData);
  }
}
