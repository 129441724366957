import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-round-buttons',
  templateUrl: './round-buttons.component.html',
  styleUrls: ['./round-buttons.component.scss'],
})
export class RoundButtonsComponent {
  @Input() size: 'small' | 'normal' | 'large' = 'normal';
  @Input() icon: 'chevron-left' | 'chevron-right' | 'add' | 'subtract';
  @Input() color = 'blue';
  @Input() style: 'normal' | 'outline' = 'normal';
  @Input() customClass?: string;
  @Input() disabled? = false;
  @Output() buttonSelected? = new EventEmitter<void>();

  onClick() {
    if (!this.disabled) this.buttonSelected.emit();
  }
}
