import { Component, OnInit, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { AmplitudeService } from './services/amplitude.service';
import { SessionTrackingService } from './feature/session-tracking/services/session-tracking.service';
import { MenusService } from './services/menus.service';
import { GeoService } from './services/geo.service';
import { LoaderService } from './services/loader.service';
import { FreshDeskChatBotService } from './services/freshDeskChatBot.service';
import { environment } from 'src/environments/environment';
import { ExternalScriptsService } from './services/external-scripts.service';
import { DeviceDetectionService } from './services/device-detection.service';

interface RouteData {
  module?: string;
  showExpatMenu?: boolean;
  showAccountMenu?: boolean;
  showExpatFooter?: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'expat-angular';
  menuHeight: number;
  noticeHeight: number;
  isDesktop: boolean;
  isTourPage: boolean;
  loaderState = true;
  activeModule: RouteData = {
    module: '',
    showExpatMenu: true,
    showAccountMenu: false,
    showExpatFooter: true,
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    public auth0: AuthService,
    private amplitudeService: AmplitudeService,
    private sessionTrackingService: SessionTrackingService,
    private renderer: Renderer2,
    private loaderService: LoaderService,
    private freshDeskChatBotService: FreshDeskChatBotService,
    private menuService: MenusService,
    private geoService: GeoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private externalScripts: ExternalScriptsService,
    private deviceDetection: DeviceDetectionService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.loaderService.loaderToggle.subscribe(val => this.setLoader(val));
      this.activatedRoute.queryParams.subscribe({
        next: (params: any) => {
          if (params) {
            if (!params.bookingId) {
              this.geoService.getGeo(params['channel'] || ''); //if not checkout then we can allow custom view from url
            } else {
              this.geoService.getGeo(''); //is checkout so don't allow custom channel views
            }
          }
        },
      });
      this.checkDevice();
      this.freshDeskChatBotService.chatToggle.subscribe(() => this.openChatBot());
      this.menuService.heightEmitter.subscribe(() => {
        this.setPaddingHeight();
      });
      this.router.events
        .pipe(
          filter(event => event && event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map(route => {
            while (route.firstChild) route = route.firstChild;
            return route;
          }),
          mergeMap(route => route.data)
        )
        .subscribe((data: RouteData) => {
          this.activeModule = { ...this.activeModule, ...data };
        });
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isTourPage = event.urlAfterRedirects.indexOf('/tours/') !== -1;
          const hashIndex = event?.urlAfterRedirects?.indexOf('#');
          if (hashIndex !== -1) {
            const hash = event.urlAfterRedirects.substring(hashIndex);
            setTimeout(() => {
              this.scrollToAnchor(hash);
            }, 0);
          }
        }
      });
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.amplitudeService.startExperiments().then(() => {
        console.log('Amplitude Experiments Started');
      });
      this.sessionTrackingService.captureUTMParameters();
      this.activatedRoute.queryParams.subscribe(params => {
        if (params['event_type_name']) {
          this.sessionTrackingService.trackCalendlyCallbackEvent();
        }
      });
      this.externalScripts.setTagManager();
      if (environment.enableFreshchatWidget) {
        const scriptUrl = environment.freshchatWidgetUrl;
        const script = this.renderer.createElement('script');
        script.src = scriptUrl;
        this.renderer.appendChild(document.body, script);
        script.onload = () => {
          (<any>window).fcWidget.init({
            token: environment.freshchatWidget.token,
            host: environment.freshchatWidget.host,
          });
        };
      }
      if (environment.enableCookieYes) {
        const scriptUrl = environment.cookieYesUrl;
        const script = this.renderer.createElement('script');
        script.src = scriptUrl;
        this.renderer.appendChild(document.body, script);
      }
      setTimeout(() => {
        this.loaderState = false;
      }, 3500);
    }
  }

  scrollToAnchor(hash: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (isPlatformBrowser(this.platformId)) {
        if (hash && hash.length > 1) {
          const maxAttempts = 100;
          let attempt = 0;
          const checkElement = () => {
            attempt++;
            if (attempt > maxAttempts) {
              reject(new Error(`Exceeded maximum number of attempts (${maxAttempts})`));
              return; // Stop further attempts
            }
            const targetElement = document.getElementById(hash.substring(1));
            if (targetElement) {
              setTimeout(() => {
                let mainMenuHeight = this.menuService.getMenuHeight();
                if (this.isDesktop && this.isTourPage) {
                  mainMenuHeight += 58; //Height of blue bar
                }
                const y = targetElement.getBoundingClientRect().top + window.scrollY - mainMenuHeight;
                window.scrollTo({ top: y, behavior: 'smooth' });
                resolve();
              }, 150);
            } else {
              setTimeout(checkElement, 100);
            }
          };
          checkElement();
        } else {
          reject(new Error('Invalid hash provided'));
        }
      }
    });
  }

  checkDevice(): void {
    this.isDesktop = this.deviceDetection.isDesktop();
  }

  setPaddingHeight() {
    this.menuHeight = this.menuService.getMenuHeight();
    this.noticeHeight = this.menuService.getNoticeHeight();
  }

  setLoader(state) {
    if (state === true) {
      this.loaderState = state;
    } else if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => (this.loaderState = state), 1000);
    }
  }

  openChatBot() {
    if (<any>window && (<any>window).fcWidget) {
      if ((<any>window).fcWidget.isOpen() !== true) {
        (<any>window).fcWidget.open();
      }
    }
  }
}
