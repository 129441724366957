import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as amplitude from '@amplitude/analytics-browser';
import { Experiment } from '@amplitude/experiment-js-client';

import { Store } from '@ngxs/store';
import {
  SetAllowInstalmentPayments,
  SetShowTourSubNav,
  SetTourLayout,
} from '@app/feature/session-tracking/store/experiment.actions';
import { ExperimentClient } from '@amplitude/experiment-js-client/dist/types/src/experimentClient';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  constructor(private readonly store: Store) {}

  /**
   * Initializes and starts the experiments using Amplitude Analytics.
   * Dispatches the results of experiments to the store.
   *
   * @returns {Promise<void>} A promise that resolves when the experiments have started.
   */
  async startExperiments() {
    const webExperiments = Experiment.initializeWithAmplitudeAnalytics(environment.experiments.instalmentPayments, {
      serverZone: 'EU',
    });
    await webExperiments.fetch();
    this.tryAllowInstalmentPaymentsInit(webExperiments);
    this.tryShowTourSubNavInit(webExperiments);
    this.tryTourLayoutOrderInit(webExperiments);
  }

  tryAllowInstalmentPaymentsInit(webExperiments: ExperimentClient) {
    try {
      const allowInstalmentPayments = webExperiments.variant('allow_instalment_payments');
      const allowInstalmentPaymentsValue = this.stringToBoolean(allowInstalmentPayments.value);
      this.store.dispatch(new SetAllowInstalmentPayments(allowInstalmentPaymentsValue));
    } catch (error) {
      this.trackEvent('Web Experiment Load Error', {
        experiment: 'allow_instalment_payments',
        error: error,
      });
      console.error('Error fetching experiment:', error);
    }
  }

  tryShowTourSubNavInit(webExperiments: ExperimentClient) {
    try {
      const showTourSubNav = webExperiments.variant('mobile_show_tour_subnav');
      const showTourSubNavValue = this.stringToBoolean(showTourSubNav.value);
      this.store.dispatch(new SetShowTourSubNav(showTourSubNavValue));
    } catch (error) {
      this.trackEvent('Web Experiment Load Error', {
        experiment: 'mobile_show_tour_subnav',
        error: error,
      });
      console.error('Error fetching experiment:', error);
    }
  }

  tryTourLayoutOrderInit(webExperiments: ExperimentClient) {
    try {
      const tourPageLayout = webExperiments.variant('tour_layout_order');
      this.store.dispatch(new SetTourLayout(tourPageLayout.payload));
    } catch (error) {
      const defaultTourPageLayout = {
        sections: [
          { id: 'tour-navigation' },
          { id: 'breadcrumbs' },
          { id: 'hero-section' },
          { id: 'product-year-range' },
          { id: 'product-split-tours' },
          { id: 'reasons-to-book' },
          { id: 'summary' },
          { id: 'itinerary' },
          { id: 'included' },
          { id: 'accommodation' },
          { id: 'photos' },
          { id: 'optional-excursions' },
          { id: 'dates-rates' },
          { id: 'additional-info' },
          { id: 'reviews' },
          { id: 'why-this-tour' },
          { id: 'signup' },
        ],
      };
      console.error('Error fetching experiment:', error);
      this.trackEvent('Web Experiment Load Error', {
        experiment: 'tour_layout_order',
        error: error,
      });
      this.store.dispatch(new SetTourLayout(defaultTourPageLayout));
    }
  }

  /**
   * Sets the user ID for Amplitude Analytics.
   *
   * @param {string} id - The user ID to set.
   */
  setUserId(id: string) {
    amplitude.setUserId(id);
  }

  /**
   * Tracks an event using Amplitude Analytics.
   *
   * @param {string} eventName - The name of the event to track.
   * @param {any} [eventProperties] - Optional properties of the event.
   */
  trackEvent(eventName: string, eventProperties?: any) {
    // console.log(eventName, eventProperties);
    amplitude.track(eventName, eventProperties);
  }

  /**
   * Tracks revenue data using Amplitude's revenue tracking functionality.
   * This method ensures that all prices are recorded in GBP and validates
   * input parameters for safety before sending the event.
   *
   * It creates an Amplitude Revenue event with the provided product ID, price,
   * and quantity. If any required parameter is missing or invalid, the function
   * logs an error and does not send the event to prevent tracking invalid data.
   *
   * @param {string} productId - The unique identifier for the product.
   * @param {number} price - The price of the product (must be in GBP).
   * @param {number} quantity - The quantity of the product sold.
   * @param optionalUpgrade - If the pax chose to upgrade
   * @param promoCode - The Promo Code if one was used applied
   */
  trackRevenue(productId: string, price: number, quantity: number, optionalUpgrade: boolean, promoCode?: string) {
    const eventProperties: any = { upgraded: optionalUpgrade };

    if (promoCode) {
      eventProperties.promoCode = promoCode;
    }

    const event = new amplitude.Revenue()
      .setProductId(productId)
      .setPrice(price)
      .setQuantity(quantity)
      .setEventProperties(eventProperties);

    amplitude.revenue(event);
  }

  /**
   * Processes the given URL to return the main part without query strings.
   * If the URL is the root path '/', it returns 'home'.
   * Otherwise, it removes query strings and leading/trailing slashes from the URL.
   *
   * @param url - The URL to be processed.
   * @returns The main part of the URL without query strings and leading/trailing slashes.
   */
  setPageUrl(url: string): string {
    if (url === '/') {
      return 'home';
    }
    return url.split('?')[0].replace(/(^\/|\/$)/g, '');
  }

  /**
   * Converts a string value to a boolean.
   * Returns true if the value is 'true' or 'on', otherwise returns false.
   *
   * @param value - The string value to be converted.
   * @returns The boolean representation of the value.
   */
  private stringToBoolean(value: string): boolean {
    return value === 'true' || value === 'on';
  }
}
