import { environment } from '../../environments/environment';

export class AccountConfig {
  urls = {
    login: () => `${environment.accountUrl}/login/authenticate-user`,
    signup: () => `${environment.accountUrl}/login/create-user`,
    update: () => `${environment.accountUrl}/login/update-user`,
    fetchBookings: () => `${environment.userBookingsUrl}/`,
    fetchBookingsLemax: (customerEmail: string) => `${environment.userBookingsLemax}/booking/bookings/${customerEmail}`,
  };
}
