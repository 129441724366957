import { Component, Input, OnInit } from '@angular/core';
import { countryPluralMap } from '../../../utils';

@Component({
  selector: 'app-stated-countries',
  templateUrl: './stated-countries.component.html',
  styleUrls: ['./stated-countries.component.scss'],
})
export class StatedCountriesComponent implements OnInit {
  @Input() tourData: { numberOfCountries: string | number };
  protected readonly countryPluralMap = countryPluralMap;
  numberOfCountriesDefined = false;

  ngOnInit(): void {
    this.numberOfCountriesDefined =
      !!this.tourData?.numberOfCountries ||
      this.tourData?.numberOfCountries === 0 ||
      this.tourData?.numberOfCountries === '0';
  }
}
