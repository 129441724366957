<div class="checkout-wrapper">
  <app-checkout-header></app-checkout-header>
  <ng-container *ngIf="!loading && !hasVisitedSuccessPage && !commitBookingError">
    <ng-container>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <app-checkout-navigation></app-checkout-navigation>
          </div>
          <div class="col-6 d-none d-lg-flex">
            <app-need-assistance></app-need-assistance>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 left-col">
            <app-payment-selector
              (processingChange)="onProcessingChange($event)"
              (instalmentLoadingChange)="onInstalmentLoadingChange($event)"></app-payment-selector>
          </div>
          <div class="col-12 col-lg-6 right-col">
            <h3>Tour Summary:</h3>
            <div class="checkout-card">
              <app-tour-summary></app-tour-summary>
            </div>
            <div
              class="cta-make-payment"
              *ngIf="paymentMethodReady && tpConfig && !paymentProcessing; else noPaymentMethodReady">
              <app-make-payments
                [tpConfig]="tpConfig"
                [bookingReference]="(reservationData$ | async)?.id"></app-make-payments>
            </div>
            <ng-template #noPaymentMethodReady>
              <app-loader-placeholer></app-loader-placeholer>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="loading">
    <app-loader-placeholer></app-loader-placeholer>
  </ng-container>
</div>

<div class="checkout-wrapper pt-5">
  <ng-container *ngIf="!loading && commitBookingError && !hasVisitedSuccessPage">
    <div class="container commit-booking-error">
      <h5 style="color: #707070">Important Notice Regarding Your Booking ({{ (reservationData$ | async).id }}):</h5>

      <p>We apologise sincerely for the inconvenience.</p>

      <p>
        Unfortunately, we encountered a technical issue while processing your booking. Although your payment was
        successfully received, we regret to inform you that your booking could not be completed at this time. Please
        rest assured that your payment is secure.
      </p>

      <h5 style="color: #707070">What to expect next:</h5>
      <ul>
        <li><strong>Our Commitment:</strong> Our team is actively working to resolve this issue promptly.</li>
        <li>
          <strong>Personalised Assistance:</strong> A dedicated customer service specialist will reach out to you to
          assist you personally.
        </li>
        <li>
          <strong>Get in Touch:</strong> If you have any immediate concerns or questions, please feel free to reach out
          to us <a href="https://expatexplore.com/contact-us">here</a>.
        </li>
      </ul>

      <p>
        We understand the importance of your booking and apologise once again for any inconvenience caused. Thank you
        for your patience and understanding as we work to rectify this situation swiftly.
      </p>
    </div>
  </ng-container>
</div>

<app-checkout-footer></app-checkout-footer>
