<button
  class="btn cta-button"
  [ngClass]="{
    'cta-outline': cta === 'outline',
    'cta-solid': cta === 'solid',
    'button-disabled': disabled,
  }"
  [class]="'cta-' + color + (customClass ? ' ' + customClass : '')"
  [disabled]="disabled"
  (click)="onClick()">
  <ng-container *ngIf="content">
    <ng-container>
      {{ content }}
    </ng-container>
  </ng-container>
</button>
