import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '@app/modules/static-page/layouts/home/home.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, data: { revalidate: 600 } },
  {
    path: 'checkout',
    loadChildren: () => import('@app/feature/checkout/checkout.module').then(m => m.CheckoutModule),
    data: { module: 'CheckoutModule', showExpatMenu: false, showExpatFooter: true, revalidate: 600 },
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    data: { module: 'AccountModule', showExpatMenu: true, showExpatFooter: true, revalidate: 600 },
  },
  {
    path: 'destination',
    loadChildren: () => import('./modules/destination/destination.module').then(m => m.DestinationModule),
    data: { module: 'DestinationModule', showExpatMenu: true, showExpatFooter: true, revalidate: 600 },
  },
  {
    path: 'tours-help',
    loadChildren: () => import('./modules/tours-help/tours-help.module').then(m => m.ToursHelpModule),
    data: { module: 'ToursHelpModule', showExpatMenu: true, showExpatFooter: true, revalidate: 600 },
  },
  {
    path: 'search',
    loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
    data: { module: 'SearchModule', showExpatMenu: true, showExpatFooter: true, revalidate: 600 },
  },
  {
    path: 'tours',
    loadChildren: () => import('./modules/tours/tours.module').then(m => m.ToursModule),
    data: { module: 'ToursModule', showExpatMenu: true, showExpatFooter: true, revalidate: 600 },
  },
  {
    path: 'blog',
    loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule),
    data: { module: 'BlogModule', showExpatMenu: true, showExpatFooter: true, revalidate: 600 },
  },
  {
    path: 'cs_contact',
    loadChildren: () => import('./modules/cs-contact/cs-contact.module').then(m => m.CsContactModule),
    data: { module: 'CsContactModule', showExpatMenu: true, showExpatFooter: true, revalidate: 600 },
  },
  {
    path: 'write-a-review',
    loadChildren: () => import('./modules/write-a-review/write-a-review.module').then(m => m.WriteAReviewModule),
    data: { module: 'WriteAReviewModule', showExpatMenu: true, showExpatFooter: true, revalidate: 600 },
  },
  {
    path: 'reviews',
    loadChildren: () => import('./modules/reviews/reviews.module').then(m => m.ReviewsModule),
    data: { module: 'ReviewsModule', showExpatMenu: true, showExpatFooter: true, revalidate: 600 },
  },
  {
    path: 'black-friday',
    loadChildren: () => import('./modules/tour-finder/tour-finder.module').then(m => m.TourFinderModule),
    data: { module: 'TourFinderModule', showExpatMenu: true, showExpatFooter: true },
  },
  {
    path: '404',
    redirectTo: '/404page',
    data: { revalidate: 600 },
  },
  {
    path: '',
    loadChildren: () => import('./modules/static-page/static-page.module').then(m => m.StaticPageModule),
    data: { module: 'StaticPageModule', showExpatMenu: true, showExpatFooter: true, revalidate: 600 },
  },
  {
    path: ':static-slug',
    loadChildren: () => import('./modules/static-page/static-page.module').then(m => m.StaticPageModule),
    data: { module: 'StaticPageModule', showExpatMenu: true, showExpatFooter: true, revalidate: 600 },
  },
  {
    path: '**',
    redirectTo: '/404page',
    data: { revalidate: 600 },
  },
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  initialNavigation: 'enabledBlocking',
  onSameUrlNavigation: 'reload',
  scrollPositionRestoration: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
