//Only creates markup for stars front end
import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-review-stars',
  templateUrl: './review-stars.component.html',
  styleUrls: ['./review-stars.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewStarsComponent {
  @Input() rating: any;
  @Input() location: any;
  @Input() color = 'blue';
  @Input() size?: 'normal' | 'large' = 'normal';
  @Input() style?: 'stars' | 'circles' = 'stars';

  createRatings(rating: number, location: string, type = 'star') {
    let ratings = '';
    let halfRating = '';
    let emptyRating = '';
    if (location === 'card' && rating % 1 >= 0 && rating % 1 < 0.5) {
      //is 0 then add the empty star
      emptyRating += '<i class="rating ' + type + ' ' + this.color + ' ' + type + '-empty ' + location + '-star"></i>';
    }
    if (rating % 1 >= 0.5) {
      //More than 0.5 then add the half star
      halfRating += '<i class="rating ' + type + ' ' + this.color + ' ' + type + '-half ' + location + '-star"></i>';
    }
    //Create full ratings by rounding down to the lowest int regardless
    for (let i = 0; i < Math.floor(rating) && i < 5; i++) {
      ratings += '<i class="rating ' + type + ' ' + this.color + ' ' + type + '-full ' + location + '-star"></i>';
    }

    //Return star HTML
    if (location === 'tour') {
      if (rating < 5) ratings += halfRating;
      return ratings + '<a href="#reviews">Reviews</a>';
    } else if (location === 'card') {
      if (rating < 5) ratings += halfRating;
      return ratings;
    } else {
      if (rating < 5) ratings += halfRating;
      return ratings;
    }
  }
}
