<app-checkout-header></app-checkout-header>
<div class="payments-container">
  <div class="container checkout-container" *ngIf="!showProcessing">
    <app-security-bar></app-security-bar>
    <div class="row">
      <div class="col-lg-6 col-12 paymentsBoxWrapper breakdownBox">
        <div class="paymentsBox">
          <div>
            <h3>Outstanding costs breakdown</h3>
            <h4>
              Travellers <span class="normalFont">({{ booking.adults.length + booking.children.length }})</span>
            </h4>
            <span class="leadTraveller">
              Lead traveller
              <strong>({{ booking.customer.firstName.charAt(0).toUpperCase() }})</strong>
            </span>
            <div>
              <p class="blueText priceTotal text-end">
                <strong>Total: {{ booking.totalPrice.amount | currency: currencySign }}</strong>
              </p>
              <h3 class="outstandingTotal text-end no-m">
                Outstanding amount:
                <strong>{{
                  booking.totalPrice.amount - booking.totalPrice.paidAmount | currency: currencySign
                }}</strong>
              </h3>
            </div>
          </div>
        </div>
        <div>
          <div class="paymentsBox billingBox">
            <h3>Expat Explore Travel Limited</h3>
            <p>
              <strong>Business Address:</strong><br />
              10 Merryweather Place,<br />
              London,<br />
              SE10 8BN,<br />
              United Kingdom
            </p>
            <p>
              <strong>Telephone:</strong><br />
              <strong>+27 87 550 4924</strong>
            </p>
          </div>
        </div>
        <div>
          <div class="need-help alert alert-danger">
            <h3>Need help?</h3>
            <p>
              Call us at <strong>+27 87 550 4924</strong> or email
              <a href="mailto:info@expatexplore.com"><strong>info&#64;expatexplore.com</strong></a
              >.
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-lg-6 col-12 paymentsBoxWrapper paymentDetailsBox"
        *ngIf="booking.paymentBalance <= 0; else unpaid">
        <div class="card text-white bg-success">
          <div class="card-header">
            <strong> Account Balance Settled </strong>
          </div>
          <div class="card-body">
            <p>
              Thank you for keeping your account up to date. Your account is paid in full and you are ready to go on
              your tour!
            </p>
            <p>Please note: Your payment amount could take up to a few hours to reflect.</p>
          </div>
        </div>
      </div>
      <ng-template #unpaid>
        <div class="col-lg-6 col-12 paymentsBoxWrapper paymentDetailsBox">
          <div class="paymentsBox">
            <h3>Payment details</h3>
            <h4 class="billingAddress">
              Billing Address:<br class="d-block d-md-none" />
              (Associated with this card)
            </h4>
            <form method="post" name="payment-details-form" id="payment-details-form" [formGroup]="paymentDetailsForm">
              <div class="row">
                <div class="col-md-6 col-12">
                  <div
                    class="form-group"
                    [ngClass]="
                      paymentDetailsForm.get('countryCode').invalid && paymentDetailsForm.get('countryCode').touched
                        ? 'has-error'
                        : ''
                    ">
                    <label for="country_code"> Current country* </label>
                    <select
                      name="country_code"
                      formControlName="countryCode"
                      id="country_code"
                      data-emessage="Please select the country you're residing in"
                      required>
                      <option value="">Select a Country</option>
                      <option *ngFor="let country of countryData" [value]="country.ISO2">
                        {{ country.Country }}
                      </option>
                    </select>
                    <span
                      *ngIf="
                        paymentDetailsForm.get('countryCode').invalid && paymentDetailsForm.get('countryCode').touched
                      "
                      class="required-error">
                      This field is required!
                    </span>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div
                    class="form-group"
                    [ngClass]="
                      paymentDetailsForm.get('streetName').invalid && paymentDetailsForm.get('streetName').touched
                        ? 'has-error'
                        : ''
                    ">
                    <label for="streetName">Street name*</label>
                    <input
                      type="text"
                      formControlName="streetName"
                      name="streetName"
                      id="streetName"
                      class="form-control"
                      maxlength="260"
                      required />
                    <ng-container
                      *ngIf="
                        paymentDetailsForm.get('streetName').invalid && paymentDetailsForm.get('streetName').touched
                      ">
                      <span *ngIf="paymentDetailsForm.get('streetName').hasError('required')" class="required-error">
                        Street Name is required.
                      </span>
                      <span *ngIf="paymentDetailsForm.get('streetName').hasError('minlength')" class="required-error">
                        Street Name should be at least 2 characters long.
                      </span>
                      <span *ngIf="paymentDetailsForm.get('streetName').hasError('maxlength')" class="required-error">
                        Street Name should not exceed 255 characters.
                      </span>
                    </ng-container>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div
                    class="form-group"
                    [ngClass]="
                      paymentDetailsForm.get('postalCode').invalid && paymentDetailsForm.get('postalCode').touched
                        ? 'has-error'
                        : ''
                    ">
                    <label for="postal">Postal/Zip code*</label>
                    <input
                      type="text"
                      formControlName="postalCode"
                      name="postal"
                      id="postal"
                      class="form-control"
                      maxlength="25"
                      required />
                    <ng-container
                      *ngIf="
                        paymentDetailsForm.get('postalCode').invalid && paymentDetailsForm.get('postalCode').touched
                      ">
                      <span *ngIf="paymentDetailsForm.get('postalCode').hasError('required')" class="required-error">
                        Postal/Zip code is required.
                      </span>
                      <span *ngIf="paymentDetailsForm.get('postalCode').hasError('minlength')" class="required-error">
                        Postal/Zip code should be at least 2 characters long.
                      </span>
                      <span *ngIf="paymentDetailsForm.get('postalCode').hasError('maxlength')" class="required-error">
                        Postal/Zip code should not exceed 20 characters.
                      </span>
                    </ng-container>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div
                    class="form-group"
                    [ngClass]="
                      paymentDetailsForm.get('cityTown').invalid && paymentDetailsForm.get('cityTown').touched
                        ? 'has-error'
                        : ''
                    ">
                    <label for="city">City / Town*</label>
                    <input
                      type="text"
                      formControlName="cityTown"
                      name="city"
                      id="city"
                      class="form-control"
                      maxlength="55"
                      required />
                    <ng-container
                      *ngIf="paymentDetailsForm.get('cityTown').invalid && paymentDetailsForm.get('cityTown').touched">
                      <span *ngIf="paymentDetailsForm.get('cityTown').hasError('required')" class="required-error">
                        City / Town is required.
                      </span>
                      <span *ngIf="paymentDetailsForm.get('cityTown').hasError('pattern')" class="required-error">
                        City / Town should contain only letters, no numbers.
                      </span>
                      <span *ngIf="paymentDetailsForm.get('cityTown').hasError('minlength')" class="required-error">
                        City / Town should be at least 2 characters long.
                      </span>
                      <span *ngIf="paymentDetailsForm.get('cityTown').hasError('maxlength')" class="required-error">
                        City / Town should not exceed 50 characters.
                      </span>
                    </ng-container>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div
                    class="form-group"
                    [ngClass]="
                      paymentDetailsForm.get('firstName').invalid && paymentDetailsForm.get('firstName').touched
                        ? 'has-error'
                        : ''
                    ">
                    <label for="fname">First Name* </label>
                    <input
                      type="text"
                      formControlName="firstName"
                      id="fname"
                      name="first_name"
                      placeholder="Your first name"
                      class="form-control"
                      autocomplete="given-name"
                      maxlength="105"
                      data-emessage="Please enter your first name"
                      required />
                    <ng-container
                      *ngIf="
                        paymentDetailsForm.get('firstName').invalid && paymentDetailsForm.get('firstName').touched
                      ">
                      <span *ngIf="paymentDetailsForm.get('firstName').hasError('required')" class="required-error">
                        First Name is required.
                      </span>
                      <span *ngIf="paymentDetailsForm.get('firstName').hasError('pattern')" class="required-error">
                        First Name should contain only letters, no numbers.
                      </span>
                      <span *ngIf="paymentDetailsForm.get('firstName').hasError('minlength')" class="required-error">
                        First Name should be at least 2 characters long.
                      </span>
                      <span *ngIf="paymentDetailsForm.get('firstName').hasError('maxlength')" class="required-error">
                        First Name should not exceed 100 characters.
                      </span>
                    </ng-container>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div
                    class="form-group"
                    [ngClass]="
                      paymentDetailsForm.get('lastName').invalid && paymentDetailsForm.get('lastName').touched
                        ? 'has-error'
                        : ''
                    ">
                    <label for="lname">Last Name* </label>
                    <input
                      type="text"
                      formControlName="lastName"
                      id="lname"
                      name="last_name"
                      value=""
                      placeholder="Your last name"
                      class="form-control"
                      autocomplete="family-name"
                      maxlength="105"
                      data-emessage="Please enter your last name"
                      required />
                    <ng-container
                      *ngIf="paymentDetailsForm.get('lastName').invalid && paymentDetailsForm.get('lastName').touched">
                      <span *ngIf="paymentDetailsForm.get('lastName').hasError('required')" class="required-error">
                        Last Name is required.
                      </span>
                      <span *ngIf="paymentDetailsForm.get('lastName').hasError('pattern')" class="required-error">
                        Last Name should contain only letters, no numbers.
                      </span>
                      <span *ngIf="paymentDetailsForm.get('lastName').hasError('minlength')" class="required-error">
                        Last Name should be at least 2 characters long.
                      </span>
                      <span *ngIf="paymentDetailsForm.get('lastName').hasError('maxlength')" class="required-error">
                        Last Name should not exceed 100 characters.
                      </span>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div
                    class="form-group"
                    [ngClass]="
                      paymentDetailsForm.get('email').invalid && paymentDetailsForm.get('email').touched
                        ? 'has-error'
                        : ''
                    ">
                    <label for="detailsEmail">Email* </label>
                    <input
                      type="email"
                      formControlName="email"
                      id="detailsEmail"
                      name="email"
                      value=""
                      placeholder="Your email address"
                      class="form-control"
                      autocomplete="email"
                      data-emessage="Please enter a valid email address"
                      required />
                    <ng-container
                      *ngIf="paymentDetailsForm.get('email').invalid && paymentDetailsForm.get('email').touched">
                      <span *ngIf="paymentDetailsForm.get('email').hasError('required')" class="required-error">
                        Email is required.
                      </span>
                      <span
                        *ngIf="
                          paymentDetailsForm.get('email').hasError('email') ||
                          paymentDetailsForm.get('email').hasError('pattern')
                        "
                        class="required-error">
                        Please enter a valid email address.
                      </span>
                    </ng-container>
                  </div>

                  <div
                    class="form-group phone-group"
                    [ngClass]="
                      paymentDetailsForm.get('contactNumber').touched &&
                      (paymentDetailsForm.get('dialCode').invalid || paymentDetailsForm.get('contactNumber').invalid)
                        ? 'has-error'
                        : ''
                    ">
                    <label for="country-code-phone"> Phone Number*</label>
                    <select
                      name="country-code-phone"
                      formControlName="dialCode"
                      id="country-code-phone"
                      autocomplete="tel"
                      data-emessage="Please enter your phone number"
                      required>
                      <option value="">Select a Country*</option>
                      <option
                        *ngFor="let phoneNumber of countryData; let i = index"
                        [selected]="phoneNumber.ISO2 === geo.country"
                        [value]="phoneNumber.ISO2">
                        {{ phoneNumber.Country }} (+{{ phoneNumber.Code }})
                      </option>
                    </select>
                    <input
                      type="tel"
                      autocomplete="tel"
                      formControlName="contactNumber"
                      inputmode="numeric"
                      id="cnumber"
                      name="contact_number"
                      placeholder="Your contact number"
                      class="form-control float-end"
                      maxlength="20"
                      required />
                    <span
                      *ngIf="
                        paymentDetailsForm.get('dialCode').touched &&
                        paymentDetailsForm.get('dialCode').hasError('required')
                      "
                      class="required-error">
                      Dial Code is required.
                    </span>
                    <ng-container
                      *ngIf="
                        paymentDetailsForm.get('contactNumber').touched &&
                        paymentDetailsForm.get('contactNumber').invalid
                      ">
                      <span *ngIf="paymentDetailsForm.get('contactNumber').hasError('required')" class="required-error">
                        Contact Number is required.
                      </span>
                      <span *ngIf="paymentDetailsForm.get('contactNumber').hasError('pattern')" class="required-error">
                        Contact Number should contain numbers only and be at least 6 digits long.
                      </span>
                      <span
                        *ngIf="paymentDetailsForm.get('contactNumber').hasError('maxlength')"
                        class="required-error">
                        Contact Number should not exceed 20 digits.
                      </span>
                    </ng-container>
                  </div>

                  <div *ngIf="error.value" class="alert alert-danger" role="alert">
                    {{ error.value }}
                  </div>

                  <h4 class="detailHeading">Please select amount you would like to pay *</h4>
                  <div class="row payment-value-row">
                    <div class="col-6" *ngIf="!processingTransaction">
                      <div
                        class="form-group includingInput"
                        [ngClass]="
                          paymentDetailsForm.get('customAmount').errors && allowErrors('customAmount')
                            ? 'has-error'
                            : ''
                        ">
                        <input
                          type="number"
                          id="customAmount"
                          *ngIf="paymentDetailsForm.get('payAmount').value === 'ownAmount'"
                          formControlName="customAmount"
                          class="form-control"
                          [placeholder]="currencySign" />
                        <select
                          name="amountToPay"
                          id="amountToPayWithInput"
                          formControlName="payAmount"
                          class="form-control"
                          (change)="amountSelected()">
                          <option value="">Choose amount</option>
                          <option [value]="outstandingAmount * 0.1" *ngIf="!isInstalmentBooking">
                            10% ({{ outstandingAmount * 0.1 | currency: currencySign }})
                          </option>
                          <option [value]="outstandingAmount * 0.25" *ngIf="!isInstalmentBooking">
                            25% ({{ outstandingAmount * 0.25 | currency: currencySign }})
                          </option>
                          <option [value]="outstandingAmount * 0.5" *ngIf="!isInstalmentBooking">
                            50% ({{ outstandingAmount * 0.5 | currency: currencySign }})
                          </option>
                          <option [value]="outstandingAmount * 0.75" *ngIf="!isInstalmentBooking">
                            75% ({{ outstandingAmount * 0.75 | currency: currencySign }})
                          </option>
                          <option [value]="outstandingAmount">Full amount</option>
                          <option value="ownAmount" *ngIf="!isInstalmentBooking">Own amount</option>
                        </select>
                        <ng-container
                          *ngIf="!paymentDetailsForm.get('customAmount').valid && allowErrors('customAmount')">
                          <span *ngIf="paymentDetailsForm.get('customAmount').errors" class="required-error">
                            Numbers only, cents only up to 2 decimal points
                          </span>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="paymentDetailsFormValid">
                      <app-make-payments
                        [tpConfig]="tpConfig"
                        [bookingReference]="bookingId"
                        (submittingChange)="onSubmittingChange($event)"></app-make-payments>
                    </div>
                    <div class="col-12">
                      <div class="alert alert-success"><strong>Full payment due by: </strong>{{ dueDate() }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
