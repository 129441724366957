import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to highlight a specific phrase in a string with a CSS class.
 *
 * Usage:
 *   {{ text | phraseClassTextHighlight:phrase:cssClass }}
 *
 * Parameters:
 * - `value`: The text string in which to highlight the phrase.
 * - `phrase`: The phrase to highlight within the text.
 * - `cssClass` (optional): The CSS class to apply to the highlighted phrase. Defaults to 'highlight'.
 *
 * The pipe creates a case-insensitive regular expression to find occurrences of the given `phrase`
 * in the `value` and wraps them in a `<span>` with the specified `cssClass`. Special characters in
 * the `phrase` are escaped to ensure correct pattern matching.
 */

@Pipe({
  name: 'phraseClassTextHighlight',
})
export class PhraseClassTextHighlightPipe implements PipeTransform {
  transform(value: string, phrase: string, cssClass = 'highlight'): string {
    if (!value || !phrase) return value;

    // Escape special characters in the phrase to avoid regex issues
    const escapedPhrase = phrase.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedPhrase})(?!\\w)`, 'gi');
    const highlightedText = value.replace(regex, `<span class="${cssClass}">$1</span>`);

    return highlightedText;
  }
}
