import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SwiperOptions } from 'swiper/types';
import { SwiperContainer } from 'swiper/swiper-element';
import { FeaturedTours } from '@models/home.model';
import { ImageText } from '@models/contentful-common.model';
import { TourModelLemax } from '@models/tour.model';
import { ContentPrices } from '@app/services/content-prices.service';
import { GeoService } from '@app/services/geo.service';
import { GeoModel } from '@models/geo.model';
import { ToursService } from '@app/services/tours.service';
import { ReviewModel } from '@models/review.model';
import { ReviewsService } from '@app/services/reviews.service';
import { isPlatformBrowser } from '@angular/common';
import { ImageModel } from '@models/image.model';
import { AmplitudeService } from '@app/services/amplitude.service';
import { ActivatedRoute, Router } from '@angular/router';
import { countryPluralMap } from '@utils';

@Component({
  selector: 'app-header-carousel-tours',
  templateUrl: './header-carousel-tours.component.html',
  styleUrls: ['./header-carousel-tours.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderCarouselToursComponent implements OnInit, AfterViewInit {
  @Input() data: FeaturedTours[];
  @Input() searchText: ImageText;
  @Input() mobileHeader: ImageModel;
  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;
  index = 0;
  previousIndex = 0;
  tourList: string[] = [];
  tourData: FeaturedTours[];
  geo: GeoModel;
  isDesktop: boolean;
  amplitudeTrackingValues = {
    event: '',
    slideTitle: '',
    slideType: '',
    slideIndex: 0,
    ctaText: '',
  };
  swiperConfig: SwiperOptions;

  getSlideTitle(cardType: string) {
    switch (cardType) {
      case 'Tour Card':
        return this.tourData[this.index].tourContent.contentful.tourName;
      case 'Heading Text Card':
        return this.tourData[this.index].shortText;
      default:
        return 'Image Text Slide';
    }
  }

  getSlideCtaValue(cardType: string) {
    switch (cardType) {
      case 'Tour Card':
        return 'View Tour';
      case 'Heading Text Card':
        return this.tourData[this.index].buttonText ? this.tourData[this.index].buttonText : 'Click Here';
      default:
        return this.tourData[this.index].buttonText ? this.tourData[this.index].buttonText : 'Click Here';
    }
  }

  constructor(
    private contentPrices: ContentPrices,
    private geoService: GeoService,
    private tourService: ToursService,
    private reviewService: ReviewsService,
    private amplitudeService: AmplitudeService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.isDesktop = window.innerWidth > 768;
    }
  }

  ngOnInit() {
    this.configureSwiperSettings();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isDesktop = window.innerWidth > 994;
  }

  configureSwiperSettings() {
    let autoplay: SwiperOptions['autoplay'];
    if (this.data.some((tour: FeaturedTours) => tour.autoplay === false)) {
      autoplay = false;
    } else {
      autoplay = {
        delay: 5000,
        disableOnInteraction: true,
      };
    }

    this.swiperConfig = {
      spaceBetween: 0,
      navigation: false,
      loop: true,
      autoplay: autoplay,
      speed: 650,
      pagination: {
        el: '.header-carousel-tours-pagination',
        type: 'fraction',
      },
      on: {
        beforeLoopFix: () => {
          this.swiperConfig.on.beforeSlideChangeStart(this.swiper?.nativeElement?.swiper);
        },
        beforeSlideChangeStart() {
          if (this.swiper && this.swiper?.nativeElement?.swiper) {
            this.previousIndex = this.swiper.nativeElement.swiper.realIndex;
            this.amplitudeTrackingValues.slideType = this.tourData[this.previousIndex].cardType[0];
            this.amplitudeTrackingValues.slideTitle = this.getSlideTitle(this.tourData[this.previousIndex].cardType[0]);
            this.amplitudeTrackingValues.ctaText = this.getSlideCtaValue(this.tourData[this.previousIndex].cardType[0]);
          }
        },
        slideChange: () => {
          //Do not SEND the amplitude tracking from here, as this carousel can auto slide
          if (this.swiper && this.swiper?.nativeElement?.swiper) {
            this.index = this.swiper.nativeElement.swiper.realIndex;
          }
        },
      },
    } as SwiperOptions;
  }

  slideNext() {
    this.swiper.nativeElement.swiper.slideNext();
    this.trackTourRibbonAmplitude('Click Slide Next');
  }

  slidePrev() {
    this.swiper.nativeElement.swiper.slidePrev();
    this.trackTourRibbonAmplitude('Click Slide Previous');
  }

  /**
   * For amplitude tracking purposes
   */
  onSlideCTA() {
    this.trackTourRibbonAmplitude('Click Slide CTA');
    this.router.navigate(['/tours/' + this.tourData[this.index].tourContent.contentful.slug]);
  }

  formatPrice(price: any) {
    return this.tourService.formatPrice(price);
  }

  ctaNavigate(url: string) {
    this.trackTourRibbonAmplitude('Click Slide CTA');
    window.location.href = url;
  }

  getReviews(reviewCode: string) {
    this.reviewService.getTourReviews(reviewCode).subscribe((data: ReviewModel) => {
      const reviewObject = {
        averageRating: data.avgRating,
        totalReviews: data.total,
        reviewCode: reviewCode,
      };

      for (const tour of this.data) {
        if (tour.tourContent?.contentful?.reviewCode === reviewCode) {
          tour.tourContent.contentful.reviewObject = reviewObject;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.geoService.geoData.subscribe((data: GeoModel) => {
      if (data) {
        this.geo = data;

        this.data.forEach((tourData: FeaturedTours) => {
          if (tourData.cardType[0] === 'Tour Card') {
            this.tourList.push(tourData.url);
          }
        });

        this.contentPrices
          .getMultipleSingleTours(this.tourList, this.geo.currency)
          .then((toursList: TourModelLemax[]) => {
            toursList.forEach((tourPriceData: TourModelLemax) => {
              for (const tour of this.data) {
                if (tour.url === tourPriceData.contentful.slug) {
                  tour.tourContent = tourPriceData;
                }
              }
            });

            this.tourData = this.data;

            this.tourData.forEach((tour: any) => {
              if (tour.tourContent?.contentful?.reviewCode) {
                this.getReviews(tour.tourContent?.contentful.reviewCode);
              }
            });
          });
      }
    });
  }

  trackTourRibbonAmplitude(event: string) {
    const tabbedRibbonData = {
      event: event,
      page: this.amplitudeService.setPageUrl(this.router.url),
      section: 'Hero Section',
      'slide-title': this.amplitudeTrackingValues.slideTitle,
      'slide-type': this.amplitudeTrackingValues.slideType,
      'slide-index': this.previousIndex + 1,
      'cta-text': this.amplitudeTrackingValues.ctaText,
    };
    this.amplitudeService.trackEvent('Header Carousel Interaction', tabbedRibbonData);
  }

  protected readonly countryPluralMap = countryPluralMap;
  protected readonly Number = Number;
}
