<div class="loaderPlaceholderWrapper">
  <img
    class="img-fluid"
    [ngClass]="{
      'loader-small': size === 'small',
      'loader-normal': size === 'normal',
      'loader-large': size === 'large',
    }"
    src="https://images.ctfassets.net/huwbudysxepr/62mhn3rxjG1HlQ5gfO93DR/e3f25c6d4739bd188db55888a4955673/search-loader.gif"
    alt="..Loading" />
</div>
