export enum TourActions {
  Pay = 'Pay',
  ViewTour = 'ViewTour',
  CompleteIPI = 'CompleteIPI',
}

export enum BookingState {
  Confirmed = 'Confirmed',
  Cancelled = 'Cancelled',
}

export enum TourStatus {
  Upcoming = 'Upcoming',
  Current = 'Current',
  Historic = 'Past',
  Cancelled = 'Cancelled',
  Inactive = 'Inactive',
}

// Array containing active tour statuses
export const activeBookingsTourStatuses: TourStatus[] = [TourStatus.Upcoming, TourStatus.Current];
