import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MultiTextImage } from '../../../models/contentful-common.model';
import { SwiperContainer } from 'swiper/swiper-element';
import { SwiperOptions } from 'swiper/types';
import { ImageModel } from '../../../models/image.model';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-membership-carousel',
  templateUrl: './membership-carousel.component.html',
  styleUrls: ['./membership-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MembershipCarouselComponent {
  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;
  @Input() data: MultiTextImage[];
  @Input() desktopImage: ImageModel;
  @Input() mobileImage: ImageModel;
  isDesktop: boolean;
  swiperConfig: SwiperOptions = {
    spaceBetween: 30,
    slidesPerView: 1,
    slidesPerGroup: 1,
    watchOverflow: true,
    navigation: {
      nextEl: '.membership-swiper-next',
      prevEl: '.membership-swiper-prev',
    },
    breakpoints: {
      994: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
    },
  };

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    if (isPlatformBrowser(this.platformId)) {
      this.isDesktop = window.innerWidth > 768;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isDesktop = window.innerWidth > 994;
  }
}
