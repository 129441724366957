import { FacetValueResult, KeyValuePair, LOGICAL_OPERATOR } from '@search/dto';

export class SetSearchTerm {
  static readonly type = '[Search] Set Search Term';

  constructor(public term: string) {}
}

export class SetFilter {
  static readonly type = '[Search] Set Filter';
  public key: string;
  public value: string;

  constructor({ key, value }: KeyValuePair) {
    this.key = key;
    this.value = value;
  }
}

export class UnsetFilter {
  static readonly type = '[Search] Unset Filter';
  public key: string;
  public value: string;

  constructor({ key, value }: KeyValuePair) {
    this.key = key;
    this.value = value;
  }
}

export class InitialiseFilterKey {
  static readonly type = '[Search] Initialise Filter';

  constructor(
    public key: string,
    public operator: LOGICAL_OPERATOR
  ) {}
}
export class SetInitialFacetFilters {
  static readonly type = '[Search] Set Initial Filters';
  public facets: { key: string; value: string; operator: LOGICAL_OPERATOR }[];
  public numeric: { key: string; value: string; operator: LOGICAL_OPERATOR }[];

  constructor(
    facets: { key: string; value: string; operator: LOGICAL_OPERATOR }[],
    numeric: { key: string; value: string; operator: LOGICAL_OPERATOR }[]
  ) {
    this.facets = facets;
    this.numeric = numeric;
  }
}
export class SetFacetFilter {
  static readonly type = '[Search] Set Facet Filter';
  public key: string;
  public value: string;
  constructor({ key, value }: KeyValuePair) {
    this.key = key;
    this.value = value;
  }
}

export class SetNumericFilter {
  static readonly type = '[Search] Set Numeric Filter';
  public key: string;
  public value: string;
  constructor({ key, value }: KeyValuePair) {
    this.key = key;
    this.value = value;
  }
}

// Provides ability to set multiple values for numeric filter to have fewer events triggering search updates
export class SetNumericFilterValues {
  static readonly type = '[Search] Set Numeric Filter Values';
  public key: string;
  public values: string[];

  constructor({ key, values }: { key: string; values: string[] }) {
    this.key = key;
    this.values = values;
  }
}

export class UnsetNumericFilter {
  static readonly type = '[Search] Unset Numeric Filter';
  public key: string;
  public value: string;
  constructor({ key, value }: KeyValuePair) {
    this.key = key;
    this.value = value;
  }
}

export class InitialiseNumericFilterKey {
  static readonly type = '[Search] Initialise Numeric Filters';

  constructor(
    public key: string,
    public operator: LOGICAL_OPERATOR
  ) {}
}

export class InitialiseFacetFilterKey {
  static readonly type = '[Search] Initialise Facet Filters';

  constructor(
    public key: string,
    public operator: LOGICAL_OPERATOR = LOGICAL_OPERATOR.AND
  ) {}
}

export class UnsetFacetFilter {
  static readonly type = '[Search] Unset Facet Filter';
  public key: string;
  public value: string;

  constructor({ key, value }: KeyValuePair) {
    this.key = key;
    this.value = value;
  }
}

export class FetchSearchResults {
  static readonly type = '[Search] Search';

  constructor(public page = 0) {}
}

export class SetSearchResults {
  static readonly type = '[Search] Set Search Results';

  constructor(
    public results: any,
    public page = 0
  ) {}
}

export class ClearSearchResults {
  static readonly type = '[Search] Clear Search Results';
}

export class ClearFacetFilters {
  static readonly type = '[Search] Clear Filters';
}

export class ClearNumericFilters {
  static readonly type = '[Search] Clear Numeric Filters';
}

export class ClearAllFiltersAndSearchTerm {
  static readonly type = '[Search] Clear All Filters and Search Term';
}

export class ClearSearchTerm {
  static readonly type = '[Search] Clear Search Term';
}

export class SetFacets {
  static readonly type = '[Search] Set Facets';

  constructor(public facets: FacetValueResult) {}
}

export class SetFacetFiltersFromQueryParams {
  static readonly type = '[Search] Set Facet Filters From Query Params';
}

export class SetNumericFiltersFromQueryParams {
  static readonly type = '[Search] Set Numeric Filters From Query Params';
}

export class SetSearchTermFromQueryParams {
  static readonly type = '[Search] Set Search Term From Query Params';
}

export class SetSearchIndexSuffix {
  static readonly type = '[Search] Set Search Index Suffix';

  constructor(public suffix: string) {}
}
