import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { CommonModule } from '@angular/common';
import { from, Observable } from 'rxjs';
import { CheckoutStateModel, TourItem } from '@app/feature/checkout/dto/types';
import { SubSink } from 'subsink';
import { Store } from '@ngxs/store';
import { CheckoutState } from '@app/feature/checkout/store/checkout.state';
import { SetFirstPaymentDate, UpdatePaymentMethodData } from '@app/feature/checkout/store/checkout.actions';
import { min } from 'lodash';
import { map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-payment-date-selector',
  standalone: true,
  templateUrl: './payment-date-selector.component.html',
  styleUrl: './payment-date-selector.component.scss',
  providers: [provideNativeDateAdapter()],
  imports: [MatCalendar, CommonModule],
})
export class PaymentDateSelectorComponent implements OnInit {
  notLoadingOrProcessing$: Observable<boolean>;
  state$: Observable<CheckoutStateModel>;
  subs: SubSink = new SubSink();
  cartItems: TourItem[];
  departureDate: Date;

  selected: Date | null = null;
  minDate: Date = new Date();
  maxDate$: Observable<Date>;
  maxDateSnapshot: Date;
  processing = true;

  constructor(
    private readonly store: Store,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.state$ = this.store.select(CheckoutState.getState);
    this.maxDate$ = this.store.select(CheckoutState.maxPaymentDate);
    this.store.dispatch(new SetFirstPaymentDate());
    this.subs.sink = this.maxDate$.subscribe(maxDate => {
      const nextMonth = new Date();
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      nextMonth.setDate(1);
      this.selected = min([maxDate, nextMonth]);
      this.maxDateSnapshot = maxDate;
    });
    this.processing = false;
  }

  dateFilter = (d: Date | null): boolean => {
    if (!d) return false;
    const day = d.getDate();
    const today = new Date();
    const isToday =
      d.getDate() === today.getDate() && d.getMonth() === today.getMonth() && d.getFullYear() === today.getFullYear();
    return day >= 1 && day <= 28 && !isToday;
  };

  onDateSelected(date: Date) {
    this.processing = true;
    if (date >= this.minDate && date <= this.maxDateSnapshot && this.dateFilter(date)) {
      this.selected = date;
    } else {
      this.selected = null;
    }
    this.store
      .dispatch(new SetFirstPaymentDate(this.selected))
      .pipe(
        switchMap(() => from(this.store.dispatch(new UpdatePaymentMethodData()))),
        take(1)
      )
      .subscribe(() => {
        this.selected = date;
        this.processing = false;
      });
  }
}
