<div class="input-container">
  <input [id]="controlName + '-text-input'" [formControl]="control" type="text" />
  <label [for]="controlName + '-text-input'" [class.active]="control.value">{{ placeholder }}</label>
  <span *ngIf="control.invalid && control.touched" class="icon invalid">
    <app-cross></app-cross>
  </span>
  <span *ngIf="control.valid" class="icon valid">
    <app-tick></app-tick>
  </span>
  <div
    *ngIf="control.invalid && control.touched"
    class="error-message"
    [ngClass]="{ 'white-background': isWhiteBackground, 'other-background': !isWhiteBackground }">
    <span *ngIf="control.errors?.required || control.invalid">{{
      validationMessage ? validationMessage : '[This field is required]'
    }}</span>
    <span *ngIf="control.errors?.text">[Please enter valid text]</span>
  </div>
</div>
