import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Store, Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';

import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { GeoService } from '@app/services/geo.service';
import { CheckoutStateModel, IPaymentMethod } from '@app/feature/checkout/dto/types';
import { SubSink } from 'subsink';
import { CheckoutService } from '@app/feature/checkout/services/checkout.service';
import {
  AddItemToCart,
  AddOptionalExtra,
  EnableMaximumForOptionalExtra,
  PatchTravellerDetails,
  RemoveOptionalExtra,
  ResetCheckoutState,
  ReturningTraveller,
  SaveTravellerDetails,
  SendCartAbandonmentEmail,
  SetCheckoutLoading,
  SetCheckoutProcessing,
  SetCurrentPage,
  SetDefaultPaymentMethod,
  SetFirstPaymentDate,
  SetGeoData,
  SetPaymentMethods,
  SetSelectedPaymentMethod,
  UpdateAppliedDiscountData,
  UpdateBookingSuccessResponse,
  UpdateOption,
  UpdatePaymentMethod,
  UpdatePaymentMethodData,
  UpdatePromoStoreValues,
  UpdateReservationData,
  UpdateReviewData,
  UpdateTotalPrice,
  UpdateTransactionReference,
  UpdateVisitedSuccessPage,
  SetHasAlteredStepOne,
  UpdateDepartureDate,
  InitPaymentMethodData,
  UpdateNumPax,
  UpdateDepositPrice,
} from '@app/feature/checkout/store/checkout.actions';
import {
  postPaymentRequests,
  PostPaymentRequestsMutationRequest,
  PostPaymentRequestsMutationResponse,
} from '@touraxis/wbp-client';
import dayjs from 'dayjs';
import { environment } from '@environments/environment';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { min } from 'lodash';

import { ExperimentState } from '@app/feature/session-tracking/store/experiment.state';
import { Observable } from 'rxjs';

const defaultState: CheckoutStateModel = {
  processing: false,
  loading: true,
  currentPage: null,
  geo: null,
  optionalExtras: [],
  cartItems: [],
  reservationData: null,
  returningTraveller: false,
  travellerDetails: null,
  totalPrice: null,
  depositPrice: null,
  departureDate: null,
  promoStateData: null,
  appliedDiscountData: null,
  bookingSuccessData: null,
  paymentMethod: null,
  hasVisitedSuccessPage: null,
  hasAlteredStepOne: false,
  paymentMethodData: null,
  reviewData: null,
  cartAbandonmentEmailSentStep2: null,
  cartAbandonmentEmailSentSuccess: null,
} as CheckoutStateModel;

@State<CheckoutStateModel>({
  name: 'checkout',
  defaults: defaultState as CheckoutStateModel,
})
@Injectable()
export class CheckoutState implements NgxsOnInit, OnDestroy {
  protected currencyCode: string;
  private subs = new SubSink();
  experimentState$: Observable<boolean>;

  constructor(
    private readonly store: Store,
    private activatedRoute: ActivatedRoute,
    private geoService: GeoService,
    private checkoutService: CheckoutService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  /**
   * Here we make sure we are in a browser not SSR environment.
   * The ngxsOnInit method is called when the state is initialized.
   *
   * - Subscribe to the geoData observable.
   * @param ctx
   */
  ngxsOnInit(ctx: StateContext<CheckoutStateModel>): void {
    if (isPlatformBrowser(this.platformId)) {
      this.checkoutService.getGeoData(ctx);
    }

    this.experimentState$ = this.store.select(ExperimentState.getAllowInstalmentPayments);
  }

  /**
   * Cleanup subscriptions
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
    this.checkoutService.unsubscribe();
  }

  /**
   * To reset the state to default values. Used from tour page essentially.
   * @param ctx
   * @constructor
   */
  @Action(ResetCheckoutState)
  ResetCheckoutState(ctx: StateContext<CheckoutStateModel>) {
    ctx.setState(defaultState);
  }

  /**
   * Selects the current state from the store.
   * @param state
   */
  @Selector()
  static getState(state: CheckoutStateModel) {
    return state;
  }

  @Selector()
  static paymentMethods(state: CheckoutStateModel) {
    return state.paymentMethods;
  }

  @Selector()
  static reservationData(state: CheckoutStateModel) {
    return state.reservationData;
  }

  @Selector()
  static travellerDetails(state: CheckoutStateModel) {
    return state.travellerDetails;
  }

  @Selector()
  static geoData(state: CheckoutStateModel) {
    return state.geo;
  }

  @Selector()
  static cartItems(state: CheckoutStateModel) {
    return state.cartItems;
  }

  @Selector()
  static orderedPaymentMethods(state: CheckoutStateModel) {
    const { paymentMethods } = state;
    if (!paymentMethods) {
      return [];
    }
    const order = ['instalment', 'full', 'deposit'];
    return paymentMethods
      .filter(method => method && method.id)
      .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
  }

  @Selector()
  static optionalExtrasTotal(state: CheckoutStateModel) {
    let total = 0;
    state.optionalExtras.forEach(extra => {
      total += extra.price.amount * extra.amount;
    });
    return total;
  }

  @Selector()
  static numberOfInstalmentMonths(state: CheckoutStateModel) {
    const instalmentData = state.paymentMethods.find(method => method.id === 'instalment')?.data;
    const lastKey = Object.keys(instalmentData).pop();
    return (lastKey ? instalmentData[lastKey].maxInstalments : 0) as number;
  }

  @Selector()
  static getDepartureDate(state: CheckoutStateModel) {
    return new Date(state.cartItems[0]?.option?.attributes.periodStart);
  }

  @Selector()
  static maxPaymentDate(state: CheckoutStateModel) {
    return min([dayjs(this.getDepartureDate(state)).add(-61, 'days').toDate(), dayjs().add(60, 'days').toDate()]);
  }

  @Selector()
  static isLoading(state: CheckoutStateModel) {
    return state.loading;
  }

  @Selector()
  static isProcessing(state: CheckoutStateModel) {
    return state.processing;
  }

  @Selector()
  static isProcessingOrLoading(state: CheckoutStateModel) {
    return state.processing || state.loading;
  }

  @Selector()
  static notProcessingOrLoading(state: CheckoutStateModel) {
    return !this.isProcessingOrLoading(state);
  }

  @Selector()
  static selectedPaymentMethod(state: CheckoutStateModel) {
    return state.selectedPaymentMethod;
  }

  @Selector()
  static hasVisitedCheckoutSuccess(state: CheckoutStateModel) {
    return state.hasVisitedSuccessPage;
  }

  @Selector()
  static hasAlteredStepOne(state: CheckoutStateModel) {
    return state.hasAlteredStepOne;
  }

  @Selector()
  static depositPrice(state: CheckoutStateModel) {
    return state.depositPrice;
  }

  /**
   * Patches checkout state with geoData.
   * @param ctx
   * @param action
   * @constructor
   */
  @Action(SetGeoData)
  SetGeoData(ctx: StateContext<CheckoutStateModel>, action: SetGeoData) {
    ctx.patchState({ geo: action.geoData });
  }

  /**
   * Sets the current page in the checkout state.
   * @param ctx
   * @param currentPage
   * @constructor
   */
  @Action(SetCurrentPage)
  SetCurrentPage(ctx: StateContext<CheckoutStateModel>, { currentPage }: SetCurrentPage) {
    ctx.patchState({ currentPage: currentPage });
  }

  @Action(UpdateTransactionReference)
  UpdateTransactionReference(
    ctx: StateContext<CheckoutStateModel>,
    { transactionReference }: UpdateTransactionReference
  ) {
    ctx.patchState({
      reservationData: {
        ...ctx.getState().reservationData,
        transactionReference: transactionReference,
      },
    });
  }

  @Action(UpdateBookingSuccessResponse)
  updateBookingSuccessResponse(
    ctx: StateContext<CheckoutStateModel>,
    { bookingResponse }: UpdateBookingSuccessResponse
  ) {
    ctx.patchState({ bookingSuccessData: bookingResponse });
  }

  @Action(UpdatePaymentMethod)
  UpdatePaymentMethod(ctx: StateContext<CheckoutStateModel>, { paymentMethod }: UpdatePaymentMethod) {
    ctx.patchState({ paymentMethod: paymentMethod });
  }

  @Action(UpdateVisitedSuccessPage)
  UpdateVisitedSuccessPage(ctx: StateContext<CheckoutStateModel>, { hasVisitedSuccessPage }: UpdateVisitedSuccessPage) {
    ctx.patchState({ hasVisitedSuccessPage: hasVisitedSuccessPage });
  }

  @Action(SetHasAlteredStepOne)
  HasAlteredStepOne(ctx: StateContext<CheckoutStateModel>, { hasAlteredStepOne }: SetHasAlteredStepOne) {
    ctx.patchState({ hasAlteredStepOne: hasAlteredStepOne });
  }

  /**
   * Adds an item to our cart. Currently, we only utilize tours as cart items.
   * This method is called from the tour page.
   * @param ctx
   * @param cartItem -
   * @constructor
   */
  @Action(AddItemToCart)
  AddItemToCart(ctx: StateContext<CheckoutStateModel>, { cartItem }: AddItemToCart) {
    ctx.patchState({
      cartItems: [cartItem],
      totalPrice: cartItem.option?.departures[0]?.price?.amount * cartItem.numPax,
    });
  }

  /**
   * Adds or updates prices of our cart item
   * @param ctx
   * @param option - cart item option object with prices
   * @param tourId - cart item tour id string
   * @constructor
   */
  @Action(UpdateOption)
  UpdateCartItemOption(ctx: StateContext<CheckoutStateModel>, { option, tourId }: UpdateOption) {
    const state = ctx.getState();
    const updatedCartItems = state.cartItems.map(item => (item.tourId === tourId ? { ...item, option } : item));
    ctx.patchState({ cartItems: updatedCartItems });

    if (option && option.departures && option.departures.length > 0) {
      let optionalServices = option.departures.flatMap((dep: any) => dep.optionalServices);
      optionalServices = optionalServices.map((service: any) => {
        if (service.name === 'Private Room Upgrade') {
          return {
            ...service,
            description:
              'If you are a solo traveller and would prefer the privacy of your own room, please select this option.',
          };
        }
        return service;
      });
      ctx.patchState({ optionalServices: optionalServices });
    } else {
      console.warn('No departures or optional services available');
    }

    //calculate totalPrice and dispatch event to update .totalPrice in state
    ctx.dispatch(
      new UpdateTotalPrice(updatedCartItems[0].option.departures[0].price.amount * updatedCartItems[0].numPax)
    );
  }

  @Action(UpdateTotalPrice)
  updateTotalPrice(ctx: StateContext<CheckoutStateModel>, { amount }: UpdateTotalPrice) {
    ctx.patchState({ totalPrice: amount });
  }

  @Action(UpdateDepositPrice)
  updateDepositPrice(ctx: StateContext<CheckoutStateModel>, { amount }: UpdateDepositPrice) {
    ctx.patchState({ depositPrice: amount });
  }

  @Action(UpdateDepartureDate)
  updateDepartureDate(ctx: StateContext<CheckoutStateModel>, { departureDate }: UpdateDepartureDate) {
    ctx.patchState({ departureDate: departureDate });
  }

  @Action(UpdatePromoStoreValues)
  updatePromoStoreValues(ctx: StateContext<CheckoutStateModel>, { promoData }: UpdatePromoStoreValues) {
    ctx.patchState({ promoStateData: promoData });
  }

  @Action(UpdateAppliedDiscountData)
  UpdateAppliedDiscountData(ctx: StateContext<CheckoutStateModel>, { data }: UpdateAppliedDiscountData) {
    ctx.patchState({ appliedDiscountData: data });
  }

  /**
   * Updates the reservation data in the checkout state.
   * @param ctx
   * @param reservationData
   * @constructor
   */
  @Action(UpdateReservationData)
  UpdateReservationData(ctx: StateContext<CheckoutStateModel>, { reservationData }: UpdateReservationData) {
    ctx.patchState({ reservationData: reservationData });
  }

  /**
   * Sets the returning traveller boolean in the checkout state.
   * @param ctx
   * @param returning - boolean
   */
  @Action(ReturningTraveller)
  ReturnTraveller(ctx: StateContext<CheckoutStateModel>, { returning }: ReturningTraveller) {
    ctx.patchState({ returningTraveller: returning });
  }

  /**
   * Adds an optional extra to the checkout state.
   * matches by 'optionalExtra.id' to either push new item to array or update the amount of an existing item.
   * @param ctx
   * @param optionalExtra
   * @constructor
   */
  @Action(AddOptionalExtra)
  AddOptionalExtra(ctx: StateContext<CheckoutStateModel>, { optionalExtra }: AddOptionalExtra) {
    const state = ctx.getState();
    let found = false;

    const updatedOptionalExtras = state.optionalExtras.map(extra => {
      if (extra.id === optionalExtra.id) {
        found = true;
        return { ...extra, amount: extra.amount + 1 };
      }
      return extra;
    });

    if (!found) {
      updatedOptionalExtras.push({ ...optionalExtra, amount: 1 });
    }

    ctx.patchState({
      optionalExtras: updatedOptionalExtras,
    });
  }

  /**
   * Enables a maximum amount for an optional extra.
   * When we create cart sometimes users will have chosen more than the maximum available extras for the specified (by id) extra.
   * @param ctx
   * @param id
   * @param max
   * @constructor
   */
  @Action(EnableMaximumForOptionalExtra)
  EnableMaximumForOptionalExtra(ctx: StateContext<CheckoutStateModel>, { id, max }: EnableMaximumForOptionalExtra) {
    const state = ctx.getState();
    const updatedOptionalExtras = state.optionalExtras.map(extra => {
      if (extra.id === id) {
        return { ...extra, maximumAllowed: max };
      }
      return extra;
    });

    ctx.patchState({
      optionalExtras: updatedOptionalExtras,
    });
  }

  /**
   * Removes an optional extra from the checkout state.
   * @param ctx
   * @param optionalExtra
   * @constructor
   */
  @Action(RemoveOptionalExtra)
  RemoveOptionalExtra(ctx: StateContext<CheckoutStateModel>, { optionalExtra }: RemoveOptionalExtra) {
    const state = ctx.getState();
    const updatedOptionalExtras = state.optionalExtras
      .map(extra => {
        if (extra.id === optionalExtra.id) {
          if (extra.amount > 1) {
            return { ...extra, amount: extra.amount - 1 };
          }
          return null;
        }
        return extra;
      })
      .filter(extra => extra !== null);

    ctx.patchState({
      optionalExtras: updatedOptionalExtras,
    });
  }

  @Action(UpdateNumPax)
  UpdateNumPax(ctx: StateContext<CheckoutStateModel>, { numPax }: UpdateNumPax) {
    const state = ctx.getState();

    ctx.patchState({
      cartItems: [{ ...state.cartItems[0], numPax }],
    });

    state.optionalExtras.forEach(extra => {
      if (extra.amount > numPax) {
        ctx.dispatch(new RemoveOptionalExtra(extra));
      }
    });

    const price = state.cartItems[0]?.option?.departures[0]?.price?.amount;
    ctx.dispatch(new UpdateTotalPrice(price * numPax));
  }

  @Action(SaveTravellerDetails)
  SaveTravellerDetails(ctx: StateContext<CheckoutStateModel>, { formDetails }: SaveTravellerDetails) {
    ctx.patchState({ travellerDetails: formDetails });
  }

  @Action(PatchTravellerDetails)
  PatchTravellerDetails(ctx: StateContext<CheckoutStateModel>, { formDetails }: PatchTravellerDetails) {
    const state = ctx.getState();
    const updatedTravellerDetails = { ...state.travellerDetails, ...formDetails };
    ctx.patchState({ travellerDetails: updatedTravellerDetails });
  }

  @Action(InitPaymentMethodData)
  initPaymentMethodData(ctx: StateContext<CheckoutStateModel>) {
    const { instalmentPaymentSettings, totalPrice, departureDate } = ctx.getState();

    const requestData: PostPaymentRequestsMutationRequest = {
      firstPaymentDate: instalmentPaymentSettings?.firstPaymentDate,
      departureDate: departureDate,
      fullAmount: totalPrice,
    };

    // post to the payment requests endpoint
    /* istanbul ignore next */
    return fromPromise(
      postPaymentRequests(requestData, {
        baseURL: environment.paymentServiceBaseUrl,
      }).then((response: PostPaymentRequestsMutationResponse) => {
        ctx.patchState({ paymentMethodData: response });
        this.subs.sink = this.experimentState$.subscribe((allowInstalmentPayments: boolean) => {
          const methods = this.getAllowedPaymentMethods(response, allowInstalmentPayments);
          ctx.dispatch(new SetPaymentMethods(methods));
        });
      })
    );
  }

  @Action(UpdatePaymentMethodData)
  updatePaymentMethodData(ctx: StateContext<CheckoutStateModel>) {
    const { instalmentPaymentSettings, reservationData, cartItems } = ctx.getState();

    const requestData: PostPaymentRequestsMutationRequest = {
      firstPaymentDate: instalmentPaymentSettings?.firstPaymentDate,
      cartId: reservationData?.id,
      departureDate: cartItems[0]?.option?.attributes?.periodStart,
      fullAmount: reservationData?.totalPrice.amount,
    };

    // post to the payment requests endpoint
    /* istanbul ignore next */
    return fromPromise(
      postPaymentRequests(requestData, {
        baseURL: environment.paymentServiceBaseUrl,
      }).then((response: PostPaymentRequestsMutationResponse) => {
        ctx.patchState({ paymentMethodData: response });
        this.subs.sink = this.experimentState$.subscribe((allowInstalmentPayments: boolean) => {
          const methods = this.getAllowedPaymentMethods(response, allowInstalmentPayments);
          ctx.dispatch(new SetPaymentMethods(methods));
        });
      })
    );
  }

  /**
   * Set payment methods based on response
   * Removes payment methods from accordion that aren't allowed for this date
   * @param paymentMethods
   * @param allowInstalmentPayments
   */
  getAllowedPaymentMethods(paymentMethods: PostPaymentRequestsMutationResponse, allowInstalmentPayments: boolean) {
    // Set payment methods based on response
    const methods = [
      {
        id: 'deposit',
        name: 'Deposit',
        explanation: 'Pay a 10% deposit now and settle the balance 60 days before departure.',
        data: {},
        enabled: false,
      },
      {
        id: 'full',
        name: 'Full',
        explanation: 'Pay the full tour cost upfront.',
        data: {},
        enabled: false,
      },
      {
        id: 'instalment',
        name: 'Instalments',
        explanation: 'Pay a 10% deposit now and monthly instalments until 60 days before departure.',
        data: {},
        enabled: false,
      },
    ] as IPaymentMethod[];

    return methods.map(method => {
      const paymentMethod = paymentMethods[method.id];
      if (paymentMethod?.enabled) {
        if (method.id === 'instalment' && !allowInstalmentPayments) {
          return { ...method, enabled: false, data: {} as never };
        } else {
          return { ...method, enabled: true, data: paymentMethod.data as never };
        }
      } else {
        return { ...method, enabled: false, data: {} as never };
      }
    });
  }

  @Action(UpdateReviewData)
  UpdateReviewData(ctx: StateContext<CheckoutStateModel>, { reviewData }: UpdateReviewData) {
    ctx.patchState({ reviewData: reviewData });
  }

  @Action(SendCartAbandonmentEmail)
  sendCartAbandonmentEmail(ctx: StateContext<CheckoutStateModel>, action: SendCartAbandonmentEmail) {
    const state = ctx.getState();
    if (action.step === 2 && !state.cartAbandonmentEmailSentStep2) {
      this.checkoutService.cartAbandonment(2, action.customerData);
      this.checkoutService.amplitudeCartAbandonment(2, action.customerData);
      ctx.patchState({ cartAbandonmentEmailSentStep2: true });
    } else if (action.step === 3 && !state.cartAbandonmentEmailSentSuccess) {
      this.checkoutService.cartAbandonment(3, action.customerData);
      this.checkoutService.amplitudeCartAbandonment(3, action.customerData);
      ctx.patchState({ cartAbandonmentEmailSentSuccess: true });
    }
  }

  @Action(SetPaymentMethods)
  setPaymentMethods(ctx: StateContext<CheckoutStateModel>, { paymentMethods }: SetPaymentMethods) {
    ctx.patchState({ paymentMethods: paymentMethods });

    const instalmentPaymentConfig = paymentMethods.find(method => method.id === 'instalment' && method.enabled);
    if (instalmentPaymentConfig) {
      ctx.dispatch(new SetDefaultPaymentMethod(instalmentPaymentConfig));
      return;
    }

    const fullPaymentsConfig = paymentMethods.find(method => method.id === 'full' && method.enabled);
    if (fullPaymentsConfig) {
      ctx.dispatch(new SetDefaultPaymentMethod(fullPaymentsConfig));
      return;
    }

    const depositPaymentsConfig = paymentMethods.find(method => method.id === 'deposit' && method.enabled);
    if (depositPaymentsConfig) {
      ctx.dispatch(new SetDefaultPaymentMethod(depositPaymentsConfig));
      return;
    }
  }

  @Action(SetDefaultPaymentMethod)
  setDefaultPaymentMethod(ctx: StateContext<CheckoutStateModel>, { paymentMethod }: SetDefaultPaymentMethod) {
    ctx.patchState({ defaultPaymentMethod: paymentMethod });
    ctx.dispatch(new SetSelectedPaymentMethod(paymentMethod));
  }

  @Action(SetFirstPaymentDate)
  setPaymentDate(ctx: StateContext<CheckoutStateModel>, { date }: SetFirstPaymentDate) {
    const { instalmentPaymentSettings } = ctx.getState();
    const firstOfNextMonth = dayjs().startOf('month').add(1, 'month');
    const firstPaymentDate = date ? dayjs(date) : firstOfNextMonth;

    ctx.patchState({
      instalmentPaymentSettings: {
        ...instalmentPaymentSettings,
        firstPaymentDate: firstPaymentDate.format('YYYY-MM-DD'),
      },
    });
  }

  @Action(SetSelectedPaymentMethod)
  setSelectedPaymentMethod(ctx: StateContext<CheckoutStateModel>, { paymentMethod }: SetSelectedPaymentMethod) {
    ctx.patchState({ selectedPaymentMethod: paymentMethod });
    this.checkoutService.amplitudeTrackEvents('Checkout Payment Method Selected');
  }

  @Action(SetCheckoutProcessing)
  setProcessing(ctx: StateContext<CheckoutStateModel>, { processing }: SetCheckoutProcessing) {
    ctx.patchState({ processing });
  }

  @Action(SetCheckoutLoading)
  setLoading(ctx: StateContext<CheckoutStateModel>, { loading }: SetCheckoutLoading) {
    ctx.patchState({ loading });
  }
}
