<ng-container *ngIf="notLoadingOrProcessing$ | async">
  <div class="container p-0" *ngIf="tourData && !checkingPromoCodes">
    <div class="tour-summary" *ngFor="let item of tourData">
      <div class="tour-name">{{ item.tourData.tourName }}</div>
      <div class="tour-review">
        <app-review-stars [rating]="averageRating" location="card" />
      </div>

      <!-- Number Of Days / Number Of Countries -->
      <div class="row days-countries">
        <div class="col-6 col-md-7">
          <div class="days">
            <strong>{{ item.tourData.numberOfDays }} {{ item.tourData.numberOfDays >= 2 ? 'Days' : 'Day' }}</strong>
          </div>
        </div>
        <div class="col-6 col-md-5">
          <span class="countries">
            <button class="tooltip-button" [matTooltip]="item.tourData.displayCountries" matTooltipPosition="above">
              ?
            </button>
            <strong>
              {{ item.tourData.numberOfCountries }}
              {{ item.tourData.numberOfCountries >= 2 ? 'Countries' : 'Country' }}
            </strong>
          </span>
        </div>
      </div>

      <!-- Start Date - End Date -->
      <div class="row-spacer mb-5">
        <div class="row dates">
          <div class="col-6 col-md-7">
            <strong>Start Date</strong><br />
            <p>{{ item.option?.attributes?.periodStart | date: 'dd-MM-yyyy' }}</p>
          </div>
          <div class="col-6 col-md-5">
            <strong>End Date</strong><br />
            {{ item.option?.attributes?.periodEnd | date: 'dd-MM-yyyy' }}
          </div>
          <div class="d-none d-md-block mini-arrow">
            <img
              alt="arrow"
              src="https://images.ctfassets.net/huwbudysxepr/2XujyExIG9kIZiwtixdKKR/d6b62db6f15258a5f4e7e85c39ac0bb5/to_arrow.svg" />
          </div>
        </div>
      </div>

      <!-- Number of travellers -->
      @if (currentPage === 1) {
        <div class="row">
          <div class="col-12 col-sm-6 col-md-7 d-flex align-items-center p-mb-0">
            <p>Number of travellers:</p>
          </div>
          <div class="col-12 col-sm-6 col-md-5">
            <div class="quantity-selector">
              <button
                class="subtract float-start"
                [ngClass]="{
                  valid: numPax > 1,
                  invalid: numPax === 1,
                }"
                [disabled]="numPax === 1"
                (click)="removeTourPax()">
                <span>-</span>
              </button>

              <div class="amount-chosen">
                <strong>{{ numPax }}</strong>
              </div>

              <button
                class="add float-end"
                [ngClass]="{
                  valid: numPax < departureMaxTravellers,
                  invalid: numPax === departureMaxTravellers,
                }"
                [disabled]="numPax === departureMaxTravellers"
                (click)="AddTourPax()">
                <span>+</span>
              </button>
            </div>
          </div>
        </div>
      }

      <!-- Tour Price -->
      <div class="row tour-price">
        <div class="col-6 col-md-7">
          <p>Tour Price:</p>
        </div>
        <div class="col-6 col-md-5" *ngIf="departurePrice">
          <strong>
            {{ regularPrice * numPax | currency: geo.currency_sign }}
          </strong>
        </div>
      </div>

      <!-- Optional Extras Price -->
      <div class="row tour-price">
        <div class="col-6 col-md-7">
          <div class="tooltip-container">
            <p>Room Upgrade(s):</p>
            <button
              class="tooltip-button room-tooltip"
              matTooltip="Select this option to secure a private room - no sharing required."
              matTooltipPosition="above">
              ?
            </button>
          </div>
        </div>
        <div class="col-6 col-md-5">
          <strong>
            {{ optionalExtrasTotal | currency: geo.currency_sign }}
          </strong>
        </div>
      </div>

      <!-- Add/Remove Optional Extras -->
      @if (currentPage === 1) {
        <div class="optional-extras" *ngIf="(optionalServices$ | async)?.length > 0">
          <div class="optional" *ngFor="let extra of optionalServices$ | async; let i = index">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-7 d-flex align-items-center p-mb-0">
                <p>Number of travellers to upgrade:</p>
              </div>
              <div class="col-12 col-sm-6 col-md-5">
                <div class="quantity-selector">
                  <button
                    class="subtract float-start"
                    [ngClass]="{
                      valid: getNumberOfOptionalsChosen(extra.id) > 0,
                      invalid: getNumberOfOptionalsChosen(extra.id) === 0,
                    }"
                    (click)="removeOptionalExtra(extra)">
                    <span>-</span>
                  </button>

                  <div class="amount-chosen">
                    <strong>{{ getNumberOfOptionalsChosen(extra.id) }}</strong>
                  </div>

                  <button
                    class="add float-end"
                    [ngClass]="{ valid: !isAddButtonDisabled(extra.id), invalid: isAddButtonDisabled(extra.id) }"
                    [disabled]="isAddButtonDisabled(extra.id)"
                    (click)="addOptionalExtra(extra)">
                    <span>+</span>
                  </button>
                </div>
              </div>
              <div class="col-12" *ngIf="roomUpgradeError?.statusCode === 422">
                <p class="optional-upgrade-error-message">
                  You have requested {{ roomUpgradeError?.data?.requested }} upgrades but, <br />
                  only {{ roomUpgradeError?.data?.available }} are available.
                </p>
              </div>
            </div>
          </div>
        </div>
      }

      <!-- For special offer departures without promo codes added yet -->
      <div
        class="row tour-price"
        *ngIf="
          regularPrice !== departurePrice &&
          !(appliedDiscountData?.promoCode !== '' && appliedDiscountData?.discount > 0)
        ">
        <div class="col-6 col-md-7">
          <p>Discount Applied:</p>
        </div>
        <div class="col-6 col-md-5">
          <strong>- {{ (regularPrice - departurePrice) * numPax | currency: geo.currency_sign }}</strong>
        </div>
      </div>

      <!-- For any departures with promo codes added -->
      <div class="row tour-price" *ngIf="appliedDiscountData?.promoCode !== '' && appliedDiscountData?.discount > 0">
        <div class="col-6 col-md-7">
          <p>Discount Applied:</p>
        </div>
        <div class="col-6 col-md-5">
          <strong>- {{ appliedDiscountData.discount * numPax | currency: geo.currency_sign }}</strong>
        </div>
      </div>

      <!-- Total Price | Step 1 -->
      <div class="row tour-price" *ngIf="currentPage === 1">
        <div class="col-6 col-md-7">
          <p>
            <strong>Total:</strong>
          </p>
        </div>
        <div class="col-6 col-md-5">
          <div class="total-tour-price" *ngIf="departurePrice">
            {{ totalPrice + optionalExtrasTotal | currency: geo.currency_sign }}
          </div>
        </div>
        <div class="col-12 deposit-note">
          <small>
            Secure your spot today for as little as
            {{ depositPrice$ | async | currency: geo.currency_sign }}.
          </small>
        </div>
      </div>

      <!-- Total Price | Step 2 -->
      <div class="row tour-price" *ngIf="currentPage === 2 && (selectedPaymentMethod$ | async)?.id !== 'instalment'">
        <div class="col-6 col-md-7">
          <p *ngIf="(selectedPaymentMethod$ | async)?.id === 'deposit'">Total:</p>
          <p *ngIf="(selectedPaymentMethod$ | async)?.id === 'full'">
            <strong>Total Due Now:</strong>
          </p>
        </div>
        <div class="col-6 col-md-5">
          <strong *ngIf="(selectedPaymentMethod$ | async)?.id === 'deposit'">
            {{ totalPrice | currency: geo.currency_sign }}
          </strong>
          <div class="total-tour-price" *ngIf="(selectedPaymentMethod$ | async)?.id === 'full'">
            {{ totalPrice | currency: geo.currency_sign }}
          </div>
        </div>
      </div>

      <!-- Total outstanding | Instalment -->
      <div class="row tour-price" *ngIf="currentPage === 2 && (selectedPaymentMethod$ | async)?.id === 'instalment'">
        <div class="col-6 col-md-7">
          <p>Total outstanding amount:</p>
        </div>
        <div class="col-6 col-md-5">
          <strong>
            {{ calculateOutstandingPrice() | currency: geo.currency_sign }}
          </strong>
        </div>
      </div>

      <!-- Deposit Due Now -->
      <div
        class="row tour-price"
        *ngIf="
          currentPage === 2 &&
          ((selectedPaymentMethod$ | async)?.id === 'deposit' || (selectedPaymentMethod$ | async)?.id === 'instalment')
        ">
        <div class="col-6 col-md-7">
          <p>Deposit Due Now:</p>
        </div>
        <div class="col-6 col-md-5">
          <div class="total-tour-price">
            {{ depositPrice$ | async | currency: geo.currency_sign }}
          </div>
        </div>
      </div>

      @if (currentPage === 1) {
        <div class="minors-section mb-5">
          <button type="button" class="cta-minors" (click)="toggleMinors()">
            Are any travellers in your party under 18 years of age?
            <strong class="text-decoration-underline">Read this!</strong>
          </button>
          <div class="minors-text" *ngIf="showMinorsInfo">
            <p>
              <strong> Please note that children under 10 years of age are not allowed on Expat Explore tours. </strong>
            </p>
            <p>
              Minors (those under 18 and over the age of 10 years of age) are accepted on group tours operated by Expat
              Explore at our discretion provided they are accompanied by a parent or guardian who accepts full
              responsibility for them.<br />
              Unaccompanied minors will not be accepted. Expat Explore reserves the right to decline any booking at our
              discretion.
            </p>
          </div>
        </div>
      }

      <!-- Promo Codes -->
      @if (currentPage === 1) {
        <div class="row promo-code-container" *ngIf="!checkingPromoCodes">
          <div class="col-12">
            <app-promocode
              [departureId]="tourData[0]?.optionId"
              [tourId]="tourData[0]?.tourId"
              [geoData]="this.geo"
              [initialPromoCode]="promoStore.promoCode"
              (promoValidated)="updatePrices($event)"
              (betterOffer)="betterOfferAvailable($event)"
              [numPax]="numPax">
            </app-promocode>
          </div>
        </div>
      }
    </div>
  </div>
</ng-container>
