import { PaginationConfig } from '@pagination/dto/models';
import zod from 'zod';
import { ReviewModelSchema } from '@models/review.model';

export type SearchConfig = {
  toursIndex: string;
  name?: string;
  paginationCfg: PaginationConfig;
};

export type FacetValueCount = Record<string, number>;

export type FacetValueResult = Record<string, FacetValueCount>;

export enum LOGICAL_OPERATOR {
  OR = 'OR',
  AND = 'AND',
}

export type Filter = {
  values: string[];
  operator: LOGICAL_OPERATOR;
};

export type FacetFilter = Filter;

export type FilterMap = Record<string, FacetFilter>;
export type FacetFilterMap = FilterMap;

export type KeyValuePair = {
  key: string;
  value: string;
};

export const ImageSchema = zod.object({
  url: zod.string().url(),
  description: zod.string(),
  title: zod.string(),
});

export const CompactItinerarySchema = zod.object({
  dayDescription: zod.string(),
  daySummary: zod.string().optional(),
  places: zod.array(zod.string()),
});

export const CurrencyCodeSchema = zod.string().min(3).max(3);
export const CurrencyAmountSchema = zod.object({
  amount: zod.number(),
  currencyCode: CurrencyCodeSchema,
});

export const AlgoliaDepartureSchema = zod.object({
  startDate: zod.string().date(),
  startDateUnix: zod.number(),
  endDate: zod.string().date(),
  endDateUnix: zod.number(),
  month: zod.string(),
  soldOut: zod.boolean(),
  price: CurrencyAmountSchema,
  priceFrom: CurrencyAmountSchema,
  marketingPrice: CurrencyAmountSchema,
  depCode: zod.string().min(1),
});

export type AlgoliaDeparture = zod.infer<typeof AlgoliaDepartureSchema>;

export const AlgoliaTourSearchHitSchema = zod.object({
  ages: zod.string().optional(),
  endPoints: zod.array(zod.string()).optional(),
  hideInSearch: zod.boolean().optional(),
  highlights: zod.string().optional(),
  isNewTour: zod.boolean().optional(),
  itinerary: zod.array(CompactItinerarySchema).optional(),
  mainImage: ImageSchema.optional(),
  mapImage: ImageSchema,
  mealsIncluded: zod.number().optional(),
  numberOfCountries: zod.number().optional(),
  numberOfDays: zod.number().optional(),
  numberOfExperiences: zod.number().optional(),
  objectID: zod.string(),
  photosOfTheTour: zod.array(ImageSchema),
  slug: zod.string().optional(),
  startPoints: zod.array(zod.string()).optional(),
  summary: zod.string().optional(),
  tourCode: zod.string().optional(),
  tourCountries: zod.array(zod.string()).optional(),
  displayCountries: zod.array(zod.string()).nullable(),
  tourName: zod.string().optional(),
  transportType: zod.string().optional(),
  currencyCode: CurrencyCodeSchema,
  currencySymbol: zod.string().min(1),
  places: zod.array(zod.string()).optional(),
  departures: zod.array(AlgoliaDepartureSchema),
  reviews: ReviewModelSchema.optional(),
});

export type AlgoliaTourSearchHit = zod.infer<typeof AlgoliaTourSearchHitSchema>;

export const AlgoliaTourSearchResponseSchema = zod.object({
  hits: zod.array(AlgoliaTourSearchHitSchema),
  facets: zod.object({
    ages: zod.object({}),
    tourCountries: zod.object({}),
    currencyCode: zod.object({}).optional(),
    'departures.month': zod.object({}),
    endPoints: zod.object({}),
    'itinerary.placeList': zod.object({}),
    numberOfCountries: zod.object({}),
    transportType: zod.object({}),
    startPoints: zod.object({}),
    marketingPrice: CurrencyAmountSchema,
  }),
  nbHits: zod.number(),
  page: zod.number(),
  nbPages: zod.number(),
  hitsPerPage: zod.number(),
  processingTimeMS: zod.number(),
  query: zod.string(),
  params: zod.string(),
});

export type AlgoliaTourSearchResponse = zod.infer<typeof AlgoliaTourSearchResponseSchema>;

export interface SearchGroup {
  type: string;
  options: SearchOption[];
}

export interface SearchOption {
  type: string;
  text: string;
  url?: string;
  id?: string;
  image?: string;
  score?: number;
}
