import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';

import { SignupService } from '@app/services/signup.service';

import { Store } from '@ngxs/store';
import { SetUserEmail } from '@app/feature/session-tracking/store/user-details.state';
import { BrazeService } from '@app/feature/session-tracking/services/braze.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SignupComponent {
  @Input() heading? = 'Join the Expat Explore family!';
  @Input() description? = 'We’ll deliver top travel tips, insider info and travel inspiration right to your inbox.';
  @Input() formDirection: string;
  processing = false;
  formSubmitted = false;
  showSuccess = false;
  showResubscribed = false;
  showError = false;
  showInfo = false;
  isInputFocused = false;
  leadGenForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(100),
      Validators.pattern(/^\D+$/),
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
    ]),
  });

  constructor(
    private readonly recaptchaV3Service: ReCaptchaV3Service,
    private readonly signupService: SignupService,
    private readonly brazeService: BrazeService,
    private readonly store: Store
  ) {}

  onInputFocus() {
    this.isInputFocused = true;
  }

  onInputBlur() {
    if (!this.leadGenForm.get('firstName').value) {
      this.isInputFocused = false;
    }
  }

  signupLocation() {
    if (window.location.href === 'https://expatexplore.com/') {
      return 'home';
    } else if (window.location.href.indexOf('192.168') !== -1 || window.location.href.indexOf('localhost') !== -1) {
      return 'localhost';
    } else {
      let pathArr = window.location.pathname.split('/');
      const filteredArr = (pathArr = pathArr.filter(Boolean));
      return pathArr[pathArr.length - 1];
    }
  }

  onEmailChange() {
    this.showSuccess = false;
    this.showError = false;
    this.showInfo = false;
    this.showResubscribed = false;
  }

  onSubmit() {
    this.processing = true;
    this.showSuccess = false;
    this.showError = false;
    this.showInfo = false;
    this.showResubscribed = false;
    this.recaptchaV3Service.execute('submit').subscribe((token: string) => {
      const signupObject = {
        ...this.leadGenForm.value,
        'g-recaptcha-response': token,
        mailList: 'default',
        signUpLocation: this.signupLocation(),
      };

      this.store.dispatch(new SetUserEmail(this.leadGenForm.get('email').value));
      this.brazeService.subscribeToNewsletterGroup();

      this.signupService.sendSignup(signupObject).then(
        (response: any) => {
          this.processing = false;
          this.showSuccess = !response?.resubscribed;
          this.showResubscribed = !!response?.resubscribed;
          this.formSubmitted = true;
          if (!response?.resubscribed) this.leadGenForm.reset();
        },
        error => {
          if (error.error?.response?.response.text) {
            const errorObject = JSON.parse(error.error.response.response.text);
            if (errorObject.title === 'Member Exists') {
              this.showInfo = true;
            } else {
              this.showError = true;
            }
          } else {
            this.showError = true;
          }
          this.processing = false;
          this.formSubmitted = true;
        }
      );
    });
  }
}
