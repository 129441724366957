import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { GeoService } from 'src/app/services/geo.service';
import { GeoModel } from 'src/app/models/geo.model';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-custom-select-list',
  templateUrl: './custom-select-list.component.html',
  styleUrls: ['../custom-form.scss'],
})
export class CustomSelectListComponent implements OnInit, OnDestroy {
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() countryList?: boolean;
  @Input() validationMessage?: string;
  @Input() isWhiteBackground = true;
  @Input() options: any[] = [];
  @Input() valueKey: string;
  @Input() textKey: string;
  control: FormControl;
  isOpen = false;
  subs: SubSink = new SubSink();

  constructor(private geoService: GeoService) {}

  ngOnInit() {
    this.control = new FormControl('', Validators.required);

    //If country list is true, try to match users geo country to list and assign value
    if (this.countryList) {
      this.subs.sink = this.geoService.geoData.subscribe((data: GeoModel) => {
        if (data) {
          const matchedCountry = this.options.find(option => option[this.valueKey] === data.country);
          if (matchedCountry) {
            this.control.patchValue(matchedCountry[this.valueKey]);
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
