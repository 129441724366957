import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { ToursService } from '@app/services/tours.service';
import {
  LoadCompactToursContent,
  LoadTourPriceData,
  SetCompactTourMapImages,
  SetContentLoaded,
  SetFullTourLookup,
} from '@content/store/store.actions';
import { CompactTourModel } from '@models/compact-tour.model';
import { ContentPrices } from '@app/services/content-prices.service';
import { GeoService } from '@app/services/geo.service';
import { TourModelLemax } from '@models/tour.model';
import { filter } from 'rxjs/operators';
import { compact, sortBy } from 'lodash';
import { ImageModel } from '@models/image.model';
import { SubSink } from 'subsink';
import { interval } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

export type ContentStateModel = {
  loaded: boolean;
  tours: CompactTourModel[];
  currency: string;
  compactTourLookup: Record<string, CompactTourModel>;
  fullTourLookup: Record<string, TourModelLemax>;
};

@State<ContentStateModel>({
  name: 'content',
  defaults: {
    loaded: false,
    currency: 'USD',
    tours: [],
    compactTourLookup: {},
    fullTourLookup: {},
  },
})
@Injectable()
export class ContentState implements NgxsOnInit, OnDestroy {
  private subs = new SubSink();

  constructor(
    private toursService: ToursService,
    private contentPricesService: ContentPrices,
    private geoService: GeoService,
    private store: Store,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngxsOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // this.setupPriceRefresh();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setupPriceRefresh() {
    this.subs.add(
      // periodically update the prices of full content for every slug contained in the compactLookup
      interval(300000) // 5 minutes
        .subscribe(() => {
          this.store.dispatch(
            new LoadTourPriceData(Object.keys(this.store.selectSnapshot(ContentState.getCompactTourLookup)))
          );
        })
    );
  }

  @Selector()
  static getState(state: ContentStateModel) {
    return state;
  }

  @Selector()
  static getFullTourLookup(state: ContentStateModel) {
    return state.fullTourLookup;
  }

  @Selector()
  static getTours(state: ContentStateModel) {
    return state.tours;
  }

  static getTourBySlug(slug: string) {
    return createSelector([ContentState], (state: ContentStateModel) => {
      return state.compactTourLookup[slug] ?? undefined;
    });
  }

  @Selector()
  static getTourByTourCode(state: ContentStateModel) {
    return (tourCode: string) => state.tours.find(tour => tour.tourCode === tourCode);
  }

  /**
   * Load the tours content.
   */
  @Action(LoadCompactToursContent)
  loadCompactToursContent({ patchState, dispatch, getState }: StateContext<ContentStateModel>) {
    this.toursService.getCompactToursData().subscribe(tours => {
      const compactTourLookup = {} as Record<string, CompactTourModel>;
      tours = sortBy(tours, 'slug');
      tours.forEach(tour => {
        compactTourLookup[tour.slug] = tour;
        tour.starts = tour.startPoints;
        tour.ends = tour.endPoints;
        tour.mapImage = tour.itinerary[0]?.map;
      });
      patchState({ tours, compactTourLookup });

      const state = getState();
      dispatch(new LoadTourPriceData(state.tours.map(tour => tour.slug)));
      dispatch(new SetContentLoaded(true));
    });
  }

  @Action(SetContentLoaded)
  setContentLoaded({ patchState }: StateContext<ContentStateModel>, { loaded }: SetContentLoaded) {
    patchState({ loaded });
  }

  @Action(LoadTourPriceData)
  loadTourPriceData(ctx: StateContext<ContentStateModel>, { tourSlugs }: LoadTourPriceData) {
    this.geoService.geoData.pipe(filter(result => !!result)).subscribe(({ currency }) => {
      ctx.patchState({ currency });
      this.contentPricesService.getMultipleSingleTours(tourSlugs, currency).then(tours => {
        const fullTourLookup = {} as Record<string, TourModelLemax>;
        const imageLookup = {} as Record<string, ImageModel>;
        tours.forEach(tour => {
          if (tour.contentful.itinerary[0]?.map) {
            imageLookup[tour.contentful.slug] = tour.contentful.itinerary[0]?.map;
          }
          fullTourLookup[tour.contentful.slug] = tour;
        });
        ctx.dispatch([new SetFullTourLookup(fullTourLookup), new SetCompactTourMapImages(imageLookup)]);
      });
    });
  }

  @Action(SetFullTourLookup)
  setFullTourLookup(ctx: StateContext<ContentStateModel>, { data }: SetFullTourLookup) {
    ctx.patchState({ fullTourLookup: data });
  }

  @Action(SetCompactTourMapImages)
  setCompactTourMapImages(ctx: StateContext<ContentStateModel>, { imageLookup }: SetCompactTourMapImages) {
    const state = ctx.getState();
    const updatedCompactTourLookup = { ...state.compactTourLookup };

    // Assuming you need to update the compactTourLookup with imageLookup
    for (const slug of Object.keys(updatedCompactTourLookup)) {
      const image = imageLookup[slug];
      if (image) {
        updatedCompactTourLookup[slug] = {
          ...updatedCompactTourLookup[slug],
          mapImage: image,
        };
      } else {
        console.warn(`No map image found for slug: ${slug}`);
      }
    }

    ctx.patchState({ compactTourLookup: updatedCompactTourLookup });
  }

  static getToursBySlugs(slugs: string[]) {
    return createSelector([ContentState], (state: ContentStateModel) => {
      if (!state.fullTourLookup) {
        return [];
      }
      return compact(slugs.map(slug => state.fullTourLookup[slug]));
    });
  }

  @Selector()
  static getCompactTourLookup(state: ContentStateModel) {
    return state.compactTourLookup;
  }

  static getReviewCodeBySlug(slug: string) {
    return createSelector([ContentState], (state: ContentStateModel) => {
      return state.fullTourLookup[slug]?.contentful.reviewCode;
    });
  }

  @Selector()
  static getLoadedState(state: ContentStateModel): boolean {
    return state.loaded;
  }
}
