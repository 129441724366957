import { GeoModel } from '@models/geo.model';
import {
  BookingSuccessResponse,
  CartAbandonmentEmailData,
  IPaymentMethod,
  OptionalExtra,
  TourItem,
} from '@app/feature/checkout/dto/types';
import { Option, Price } from '@models/tour.model';
import { ReviewModel } from '@models/review.model';

/**
 * Sets geo data in the checkout state.
 */
export class SetGeoData {
  static readonly type = '[Checkout] Set Geo Data';

  constructor(public geoData: GeoModel) {}
}

/**
 * Sets users current checkout step in the checkout state.
 */
export class SetCurrentPage {
  static readonly type = '[Checkout] Set Current Page';

  constructor(public currentPage: number) {}
}

export class UpdateTransactionReference {
  static readonly type = '[Checkout] Set transaction reference after successful transaction made';

  constructor(public transactionReference: string) {}
}

export class UpdateBookingSuccessResponse {
  static readonly type = '[Checkout] Set transaction reference after successful transaction made';

  constructor(public bookingResponse: BookingSuccessResponse) {}
}

export class UpdatePaymentMethod {
  static readonly type = '[Checkout] Updates state with chosen payment method';

  constructor(public paymentMethod: any) {}
}

export class UpdateVisitedSuccessPage {
  static readonly type = '[Checkout] Updates state boolean for if user has visited success page';

  constructor(public hasVisitedSuccessPage: boolean) {}
}

export class SetHasAlteredStepOne {
  static readonly type = '[Checkout] Has altered Step 1 form';

  constructor(public hasAlteredStepOne: boolean) {}
}

/**
 * Sets cart selections in the checkout state.
 * All data from tour page
 * Departure date
 * Num pax
 * Tour content
 * Pay type
 */
export class AddItemToCart {
  static readonly type = '[Checkout] Adds Item To Cart';

  constructor(public cartItem: TourItem) {}
}

/**
 * Updates a cart item's Option (prices).
 * Option contains the new prices for the cart item.
 * tourId: string to match which cart item to update option for.
 */
export class UpdateOption {
  static readonly type = '[Checkout] Updates the prices of the selected cart item';

  constructor(
    public option: Option,
    public tourId: string
  ) {}
}

export class UpdateTotalPrice {
  static readonly type = '[Checkout] Updates totalPrice value of state';

  constructor(public amount: number) {}
}

export class UpdateDepositPrice {
  static readonly type = '[Checkout] Updates depositPrice value of state';

  constructor(public amount: number) {}
}

export class UpdateDepartureDate {
  static readonly type = '[Checkout] Updates departureDate value of state';

  constructor(public departureDate: string) {}
}

export class UpdatePromoStoreValues {
  static readonly type = '[Checkout] Updates checkout state with promo state values for special offers';

  constructor(public promoData: any) {}
}

export class UpdateAppliedDiscountData {
  static readonly type = '[Checkout] Updates the state with applied discount data';

  constructor(public data: Price) {}
}

/**
 * Updates the state.reservation data with data from create cart (seat reservations)
 */
export class UpdateReservationData {
  static readonly type = '[Checkout] Updates reservation data';

  constructor(public reservationData: any) {}
}

/**
 * Sets the boolean for if the booking user is a returning traveller. Triggered from a checkbox on step 1.
 */
export class ReturningTraveller {
  static readonly type = '[Checkout] Returning traveller boolean';

  constructor(public returning: boolean) {}
}

/**
 * Update Number of Pax on Tour
 */
export class UpdateNumPax {
  static readonly type = '[Checkout] Update number of Pax';

  constructor(public numPax: number) {}
}

/**
 * Adds an optional item to the cart.
 */
export class AddOptionalExtra {
  static readonly type = '[Checkout] Add Optional Extra';

  constructor(public optionalExtra: OptionalExtra) {}
}

/**
 * Removes an optional item from the cart.
 */
export class RemoveOptionalExtra {
  static readonly type = '[Checkout] Remove Optional Extra';

  constructor(public optionalExtra: OptionalExtra) {}
}

/**
 * Updates the quantity maximum of an optional item in the cart. Intended purpose if user tries to select more room upgrades than are available on cart creation.
 * Unfortunately we don't have this information before cart creation.
 */
export class EnableMaximumForOptionalExtra {
  static readonly type = '[Checkout] Enable Maximum Optional Extras';

  constructor(
    public id: string,
    public max: number
  ) {}
}

/**
 * Store traveller information from form into state.
 */
export class SaveTravellerDetails {
  static readonly type = '[Checkout] Store traveller details in state';

  constructor(public formDetails: any) {}
}

/**
 * When page loads, check if we have traveller details and set them in the form.
 */
export class PatchTravellerDetails {
  static readonly type = '[Checkout] Patch traveller details in state';

  constructor(public formDetails: any) {}
}

/**
 * Resets checkout state to initial state.
 */
export class ResetCheckoutState {
  static readonly type = '[Checkout] Reset Checkout State';
}

export class InitPaymentMethodData {
  static readonly type = '[Tour Page] Update Payment Method Data';
}

export class UpdatePaymentMethodData {
  static readonly type = '[Checkout] Update Payment Method Data';
}

export class UpdateReviewData {
  static readonly type = '[Checkout] Update Review Data';

  constructor(public reviewData: ReviewModel) {}
}

export class SendCartAbandonmentEmail {
  static readonly type = '[Checkout] Send Cart Abandonment Email';

  constructor(
    public step: number,
    public customerData: CartAbandonmentEmailData
  ) {}
}

export class SetPaymentMethods {
  static readonly type = '[Checkout] Set Payment Methods';

  constructor(public paymentMethods: IPaymentMethod[]) {}
}

export class SetDefaultPaymentMethod {
  static readonly type = '[Checkout] Set Default Payment Method';

  constructor(public paymentMethod: IPaymentMethod) {}
}

export class SetFirstPaymentDate {
  static readonly type = '[Checkout] Set Payment Date';

  constructor(public date?: Date | null) {}
}

export class SetSelectedPaymentMethod {
  static readonly type = '[Checkout] Set Selected Payment Method';

  constructor(public paymentMethod: IPaymentMethod) {}
}

export class SetCheckoutProcessing {
  static readonly type = '[Checkout] Set Processing';

  constructor(public processing: boolean) {}
}

export class SetCheckoutLoading {
  static readonly type = '[Checkout] Set Loading';

  constructor(public loading: boolean) {}
}
