import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { FreshDeskChatBotService } from 'src/app/services/freshDeskChatBot.service';
import { MenusService } from '../../services/menus.service';
import { MainFooterModel } from '../../models/main-footer.model';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  footer: MainFooterModel;
  currentYear = new Date().getFullYear();
  isTourPage = false;
  showChat = environment.enableFreshchatWidget;

  constructor(
    private freshDeskChatBotService: FreshDeskChatBotService,
    private menuService: MenusService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.menuService.footerMenu.subscribe((footer: MainFooterModel) => {
      this.footer = footer;
    });

    this.router.events.subscribe({
      next: event => {
        if (event instanceof NavigationEnd && this.router.url.indexOf('/tours/') !== -1) {
          this.isTourPage = true;
        }
      },
    });
  }

  openChatBot() {
    this.freshDeskChatBotService.triggerChatBot();
  }

  openCookies() {
    const button = document.querySelector('.cky-btn-revisit') as HTMLButtonElement;
    if (button) button.click();
  }
}
