import { environment } from '@environments/environment';

export class PagesConfig {
  urls = {
    getPage: (slug: string) => `${environment.contentUrl}/v3/pages/${slug}.json`,
    getHomePage: (slug: string) => `${environment.contentUrl}/v3/pages/newstatic/${slug}.json`,
    postCV: () => `${environment.signup}/email/postcv`,
    agentSubmission: () => `${environment.signup}/email/new_agent`,
    discoSubmission: () => `${environment.signup}/email/discovery_tour`,
  };
}

export const SlugConfig = {
  blackFridaySlugs: ['black-friday', 'black-friday-preview'],
  jumpCampaignSlugs: ['win-a-free-tour-travel-competition', 'win-a-free-tour-travel-competition-demo'],
  get campaignPageSlugs() {
    return [...this.blackFridaySlugs, ...this.jumpCampaignSlugs];
  },
};
