import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { PromotionState } from '@app/feature/promotion/store/promotion.state';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { PromotionService } from '@app/feature/promotion/services/promotion.service';

@NgModule({
  declarations: [],
  providers: [PromotionService],
  imports: [
    CommonModule,
    NgxsModule.forFeature([PromotionState]),
    NgxsStoragePluginModule.forRoot({
      keys: ['promoCode'],
      storage: StorageOption.LocalStorage,
    }),
  ],
})
export class StoreModule {}
