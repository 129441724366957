<ng-container *ngIf="notProcessingOrLoading">
  <div class="payment-selector">
    <h3>Select Payment Option:</h3>
    <div class="custom-accordion">
      <ng-container *ngIf="orderedPaymentMethods as paymentMethods">
        <ng-container *ngFor="let method of paymentMethods; trackBy: trackByMethod">
          <div
            *ngIf="method.enabled"
            class="accordion-item"
            [ngClass]="{ open: selectedPaymentMethod === method.id, closed: selectedPaymentMethod !== method.id }">
            <button class="accordion-header align-items-start" (click)="selectPaymentMethod(method)">
              <input
                type="radio"
                class="custom-radio"
                [checked]="selectedPaymentMethod === method.id"
                [name]="'paymentMethod'" />
              <div class="accordion-heading-wrapper">
                <div class="heading h5 mb-1">{{ method.name }}</div>
                <div class="explanation">{{ method.explanation }}</div>
              </div>
              @if (method.name === 'Instalments') {
                <span
                  class="arrow"
                  [ngClass]="{
                    'arrow-up': !showInstalmentCalendar,
                    'arrow-down': showInstalmentCalendar,
                  }">
                </span>
              }
            </button>
            <div class="accordion-body" *ngIf="selectedPaymentMethod === method.id">
              <div class="instalment" *ngIf="selectedPaymentMethod === 'instalment'">
                <div class="instalment-note-wrapper" *ngIf="!showInstalmentCalendar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.9307 6.58789L7.92875 11.293C7.85844 11.6328 7.95805 11.8262 8.23344 11.8262C8.4268 11.8262 8.71977 11.7559 8.91898 11.5801L8.83109 11.9961C8.54398 12.3418 7.91117 12.5938 7.36625 12.5938C6.66313 12.5938 6.3643 12.1719 6.55766 11.2754L7.29594 7.80664C7.36039 7.51367 7.3018 7.4082 7.00883 7.33789L6.55766 7.25586L6.63969 6.875L8.9307 6.58789ZM8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5C8.55229 3.5 9 3.94772 9 4.5C9 5.05228 8.55229 5.5 8 5.5Z"
                      fill="#5AC7DA" />
                  </svg>
                  <div class="instalment-note">
                    <p>
                      Please note: The first instalment is scheduled for the 1st of next month.
                      <a href="javascript:void(0)" (click)="showInstalmentCalendar = true">Click here</a> to choose a
                      different start date.
                    </p>
                  </div>
                </div>
                @if (showInstalmentCalendar) {
                  <div class="text-center">
                    <p>Select the start date for deductions:</p>
                    <app-payment-date-selector></app-payment-date-selector>
                  </div>
                  @if (!instalmentLoading) {
                    <div>
                      <span class="tooltip-container">
                        <button
                          class="tooltip-button"
                          matTooltip="Fixed payment period to ensure full payment 60 days before departure."
                          matTooltipPosition="above">
                          ?
                        </button>
                      </span>
                      <p>
                        <span>Period:</span>
                        <strong class="float-end"
                          >{{ numberOfInstalmentMonths$ | async }}
                          {{ (numberOfInstalmentMonths$ | async) > 1 ? 'Months' : 'Month' }}</strong
                        >
                      </p>
                    </div>
                    <ng-container *ngFor="let key of Object.keys(method.data); let last = last">
                      <ng-container *ngIf="last">
                        <div *ngFor="let date of method.data[key].dates" class="date">
                          <p>
                            <span class="date">{{ date | date: 'dd MMM yyyy' }} - Auto Deduction</span>
                            <strong class="float-end">{{
                              method.data[key].baseAmount | currency: geo.currency_sign
                            }}</strong>
                          </p>
                        </div>
                      </ng-container>
                    </ng-container>
                  } @else {
                    <div class="mt-5 mb-5">
                      <app-loader-placeholer></app-loader-placeholer>
                    </div>
                  }
                }
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!notProcessingOrLoading">
  <app-loader-placeholer></app-loader-placeholer>
</ng-container>
