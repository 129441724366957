import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { LoaderService } from '@app/services/loader.service';
import { PagesService } from '@app/services/static-page.service';
import { SeoService } from '@app/services/seo.service';

import { FadeInOut } from '@app/animations';
import { HomeModel } from '@models/home.model';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [FadeInOut],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  pageSlug = 'home';
  error = null;
  data: HomeModel;
  private errorSub: Subscription;
  inBrowser: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService,
    private seoService: SeoService,
    private pageService: PagesService,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      //Code to execute only on the client side
      this.inBrowser = true;
    }
    if (isPlatformServer(this.platformId)) {
      //Code to execute only on the server side during SSR Schema injection
      const script = this.document.createElement('script');
      script.type = 'application/ld+json';
      script.text = `{
    	"@context": "https://schema.org",
    	"@type": "Organization",
    	"name": "Expat Explore",
    	"url": "https://expatexplore.com/",
    	"logo": "https://images.ctfassets.net/zggpk8714k6l/59Kv5bV2pPNNPjWBrJ6nhD/f175d6514276859b3d42167f4093ed8d/Logo_2.svg?h=60",
    	"sameAs": [
    		"https://www.facebook.com/ExpatExploreTravel/",
    		"https://twitter.com/expatexplore?lang=en",
    		"https://www.youtube.com/user/ExpatExploreOfficial/featured",
    		"https://www.instagram.com/expatexplore/"
    	]
    	}`;
      this.document.head.appendChild(script);
    }
  }

  handleFormSubmission(formData: any) {
    console.log('Form submitted with data:', formData);
  }

  ngOnInit(): void {
    this.loaderService.setSiteLoader(true);
    this.errorSub = this.pageService.error.subscribe(errorMessage => {
      this.error = errorMessage;
    });
    this.getHome();
  }

  getHome() {
    this.pageService.getHomePage(this.pageSlug).subscribe({
      next: (page: HomeModel) => {
        this.data = page;
        this.seoService.setSeoModel(this.data.seo);
        this.loaderService.setSiteLoader(false);
        this.cdr.detectChanges();
      },
      error: error => {
        this.error = error.message;
        this.router.navigate(['404page']);
      },
    });
  }
}
