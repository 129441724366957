import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { GeoService } from 'src/app/services/geo.service';
import { GeoModel } from 'src/app/models/geo.model';
import { SubSink } from 'subsink';

function defaultOptionValidator(control: AbstractControl) {
  return control.value === 'default' ? { defaultSelected: true } : null;
}

@Component({
  selector: 'app-custom-input-country-phone',
  templateUrl: './custom-input-country-phone.component.html',
  styleUrls: ['../custom-form.scss', 'custom-input-country-phone.component.scss'],
})
export class CustomInputCountryPhoneComponent implements OnInit, OnDestroy {
  @Input() controlName: string;
  @Input() isWhiteBackground = true;
  formGroup: FormGroup;
  countryData: { Country: string; Code: string; ISO2: string; ISO3: string }[];
  geo: GeoModel;
  subs: SubSink = new SubSink();

  constructor(private geoService: GeoService) {}

  ngOnInit() {
    this.formGroup = new FormGroup({
      dialCode: new FormControl('default', [Validators.required, defaultOptionValidator]),
      contactNumber: new FormControl('', [Validators.required, Validators.maxLength(20)]),
    });

    this.subs.sink = this.geoService.geoData.subscribe((data: GeoModel) => {
      if (data) {
        this.geo = data;
        this.countryData = this.geoService.countryData;

        const matchedCountry = this.countryData.find(country => country.ISO2 === this.geo.country);
        if (matchedCountry) {
          this.formGroup.patchValue({
            dialCode: matchedCountry.Code,
          });
        } else {
          this.formGroup.patchValue({
            dialCode: 'default',
          });
        }
      }
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
