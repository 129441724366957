import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromotionConfig } from '@app/feature/promotion/services/promotion.config';
import { StoreModule } from '@app/feature/promotion/store/store.module';
import { Store } from '@ngxs/store';
import { PromotionService } from '@app/feature/promotion/services/promotion.service';
import { ClearPromoItems, SetPromoCode } from './store/promotion.actions';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { PromotionState } from '@app/feature/promotion/store/promotion.state';

@NgModule({
  imports: [CommonModule, StoreModule],
  providers: [PromotionConfig, PromotionService],
})
export class PromotionModule {
  constructor(
    private promotionService: PromotionService,
    private store: Store,
    private router: Router
  ) {
    // here we need to make sure that the route is fully loaded before we can capture the promo code
    // since capturePromoCode relies on the route snapshot
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.promotionService.capturePromoCode().then(async promoCode => {
        // always use the promo code from the query params if it exists
        if (promoCode) {
          // console.log('captured promoCode used: ', promoCode);
          this.store.dispatch(new SetPromoCode(promoCode));
        } else {
          const storedPromoCode = this.store.selectSnapshot(PromotionState.getPromoCode);
          const promoCodeMeta = await this.promotionService.getPromoCodeMetaData();
          // console.log('storedPromoCode found: ', storedPromoCode);
          if (storedPromoCode) {
            const matched = promoCodeMeta.invalidatedPromoCodes.find(code => {
              return code.promoCodeValue === storedPromoCode;
            });
            if (matched) {
              // console.log('matched promoCode cleared: ', matched);
              this.store.dispatch(new ClearPromoItems());
              this.store.dispatch(new SetPromoCode(promoCodeMeta.activeCampaign || null));
            } else {
              this.store.dispatch(new SetPromoCode(storedPromoCode));
              // console.log('set stored code: ', storedPromoCode);
            }
          } else {
            if (promoCodeMeta?.activeCampaign) {
              // console.log('set SetPromoCode active campaign: ', promoCodeMeta.activeCampaign);
              this.store.dispatch(new SetPromoCode(promoCodeMeta.activeCampaign));
            }
          }
        }
      });
    });
  }
}
