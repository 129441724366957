import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ImageText } from '../../../models/contentful-common.model';
import { AmplitudeService } from '../../../services/amplitude.service';
import { SwiperContainer } from 'swiper/swiper-element';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-filters-carousel',
  templateUrl: './filters-carousel.component.html',
  styleUrls: ['./filters-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FiltersCarouselComponent {
  @Input() data: ImageText[];
  @Input() heading: string;
  @Input() text: string;
  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;
  index = 0;
  swiperConfig: SwiperOptions = {
    spaceBetween: 30,
    slidesPerView: 'auto',
    initialSlide: 0,
    slidesPerGroup: 1,
    freeMode: true,
    mousewheel: {
      forceToAxis: true,
    },
    on: {
      slideChange: () => {
        if (this.swiper && this.swiper?.nativeElement?.swiper) {
          this.index = this.swiper?.nativeElement?.swiper.realIndex;
        }
      },
      touchStart: () => {
        if (this.swiper && this.swiper?.nativeElement?.swiper) {
          this.trackFiltersCarouselAmplitude('Touch Start');
        }
      },
    },
    navigation: {
      nextEl: '.filters-swiper-next',
      prevEl: '.filters-swiper-prev',
    },
  };

  constructor(
    private router: Router,
    private amplitudeService: AmplitudeService
  ) {}

  slideNext() {
    this.swiper.nativeElement.swiper.slideNext();
  }

  slidePrev() {
    this.swiper.nativeElement.swiper.slidePrev();
  }
  trackFiltersCarouselAmplitude(event: string) {
    const FiltersCarouselData = {
      event: event,
      page: this.amplitudeService.setPageUrl(this.router.url),
      section: this.heading,
      'carousel-index': this.index,
    };
    this.amplitudeService.trackEvent('Filters Carousel Interaction', FiltersCarouselData);
  }

  trackFiltersCardAmplitude(event: string, filter: ImageText) {
    const filterCardData = {
      event: event,
      page: this.amplitudeService.setPageUrl(this.router.url),
      section: this.heading,
      'carousel-index': this.index,
      'filter-name': filter.name,
      'filter-text': filter.shortText,
      'filter-image': filter.image.title,
    };
    this.amplitudeService.trackEvent('Filters Card Interaction', filterCardData);
  }
}
