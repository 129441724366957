import { Component, Input, ViewEncapsulation } from '@angular/core';
import { carouselCategories } from '../../../models/home.model';
import { AmplitudeService } from '../../../services/amplitude.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-portrait-card',
  templateUrl: './portrait-card.component.html',
  styleUrls: ['./portrait-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PortraitCardComponent {
  @Input() data: carouselCategories;
  @Input() background?: boolean;
  @Input() blog?: boolean;
  @Input() section?: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private amplitudeService: AmplitudeService
  ) {}

  onCTA(url: string) {
    this.trackPortraitCardAmplitude('Click Card CTA');
    if (this.blog && this.blog === true) {
      this.router.navigate(['/blog/' + url]);
    } else {
      this.router.navigate([url]);
    }
  }

  trackPortraitCardAmplitude(event: string) {
    const portraitCardData = {
      event: event,
      page: this.amplitudeService.setPageUrl(this.router.url),
      section: this.section ? this.section : '',
      'card-heading': this.data.heading,
      'card-image': this.data.image.title,
      'card-categories': this.data.category,
      'card-isBlog': this.blog ? this.blog : false,
    };
    this.amplitudeService.trackEvent('Portrait Card Interaction', portraitCardData);
  }
}
