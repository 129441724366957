import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { ContentPrices } from '@app/services/content-prices.service';
import { GeoService } from '@app/services/geo.service';
import { AmplitudeService } from '@app/services/amplitude.service';
import { GeoModel } from '@models/geo.model';
import { TourModelLemax } from '@models/tour.model';
import { TabbedTourCarouselData } from '@models/shared-components.model';
import { NavigationOptions, SwiperOptions } from 'swiper/types';
import { builder } from '@builder.io/sdk';
import { campaignCarouselTabColourConfig } from '@models/campaigns.model';
import { Subscription } from 'rxjs';
import { PromotionState } from '@app/feature/promotion/store/promotion.state';
import { PromotionData } from '@app/feature/promotion/dto/types';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-tabbed-tour-carousel',
  templateUrl: './tabbed-tour-carousel.component.html',
  styleUrls: ['./tabbed-tour-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TabbedTourCarouselComponent implements OnInit, AfterViewInit {
  @Input() carouselData: TabbedTourCarouselData[] = [];
  @Input() carouselType? = 'tour';
  @Input() customContent: TemplateRef<any>;
  @Input() sectionId?: string;
  @Input() headingCentered? = false;
  @Input() headingHighlightPhrase? = '';
  @Input() allowBuilderTab? = false;
  @Input() backgroundTransparent? = false;
  @Input() headerClass? = '';
  @Input() descriptionClass? = '';
  @Input() viewAllButtonClass? = '';

  @ViewChild('tabbedTourCarousel', { static: false }) tabbedTourCarousel!: ElementRef;
  @ViewChild('tourTabsCarousel', { static: false }) tourTabsCarousel!: ElementRef;
  @ViewChild('prevButton', { static: false }) prevButton!: ElementRef;
  @ViewChild('nextButton', { static: false }) nextButton!: ElementRef;

  tabSwiperIndex = 0;
  geo: GeoModel;
  showMapMode = false;
  tabToursLoading = false;
  tourSwiperIndex = 0;
  tourTabsCarouselConfig: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 0,
    loop: false,
    freeMode: true,
    pagination: false,
    navigation: {
      nextEl: '.tour-tabs-carousel-next',
      prevEl: '.tour-tabs-carousel-prev',
    } as NavigationOptions,
  };
  swiperToursConfig: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    loop: false,
    freeMode: true,
    on: {
      slideChange: () => {
        if (this.tabbedTourCarousel && this.tabbedTourCarousel?.nativeElement?.swiper) {
          this.tourSwiperIndex = this.tabbedTourCarousel.nativeElement.swiper.realIndex;
        }
      },
      touchStart: () => {
        if (this.tabbedTourCarousel && this.tabbedTourCarousel?.nativeElement?.swiper) {
          this.tourSwiperIndex = this.tabbedTourCarousel.nativeElement.swiper.realIndex;
          this.trackTourRibbonAmplitude('Touch Start');
        }
      },
    },
    mousewheel: {
      forceToAxis: true,
    },
    pagination: false,
    navigation: {
      nextEl: '.tabbed-tour-swiper-next',
      prevEl: '.tabbed-tour-swiper-prev',
    } as NavigationOptions,
  };
  checkedCampaign = false;
  campaignTab: campaignCarouselTabColourConfig = {
    tabBgColour: '',
    tabTextColour: '',
  };
  campaignTourList: string[];
  promoCodeData$: Subscription;
  tabsAdded = false;

  constructor(
    private router: Router,
    private amplitudeService: AmplitudeService,
    private geoService: GeoService,
    private contentPriceService: ContentPrices,
    private store: Store,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.geoService.geoData.subscribe((geoData: GeoModel) => {
        if (geoData) {
          this.geo = geoData;
          this.checkPromoData();
          this.trackTourRibbonAmplitude('Tour Ribon loaded');
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.swiperToursConfig.navigation = {
      nextEl: this.nextButton.nativeElement,
      prevEl: this.prevButton.nativeElement,
    } as NavigationOptions;
  }

  /**
   * Check if promo data is available AND contains valid tour data
   */
  checkPromoData() {
    this.promoCodeData$ = this.store
      .select(PromotionState.getPromoCodeData)
      .pipe(
        tap(data => {
          const hasValidTours = data?.tours?.some(tour => this.containsValidPromoTourData(tour));
          if (hasValidTours) {
            this.checkBuilderDynamicTabData();
          } else {
            this.getTours(this.tabSwiperIndex);
          }
        })
      )
      .subscribe();
  }

  /**
   * Check if promo data contains valid tour data
   * By default PromotionState.getPromoCodeData contains empty tour data.
   * We need to ensure that the tour data is not empty before displaying the new tab.
   *
   * Function returns true if the tour data contains even 1 valid tour data object
   * @param tour
   */
  containsValidPromoTourData(tour: any): boolean {
    const { price, id, slug } = tour;
    return price.amount !== 0 || price.currency !== '' || id !== '' || slug !== '';
  }

  /**
   * Check if there is a new tab data from BuilderIO
   */
  checkBuilderDynamicTabData() {
    builder
      .get('tabbed-tour-carousel-item')
      .promise()
      .then(data => {
        this.handleBuilderData(data);
      })
      .catch(() => {
        this.getTours(this.tabSwiperIndex);
        this.amplitudeService.trackEvent('Error fetching dynamic tab data BuilderIO', {
          model: 'tabbed-tour-carousel-item',
        });
      });
  }

  /**
   * Handle new tab data from BuilderIO
   * @param data
   */
  handleBuilderData(data: any) {
    if (this.allowBuilderTab && data.data) {
      //add new tab data to carouselData
      this.addDynamicCampaignTab(data.data);
      this.getTours(this.tabSwiperIndex);
      this.setCampaignTabConfig(data.data);
    } else {
      //normal process no new tab
      this.getTours(this.tabSwiperIndex);
    }
    if (this.campaignTab.tabBgColour && this.campaignTab.tabTextColour) {
      this.checkedCampaign = true;
    }
  }

  /**
   * Set campaign tab config, used for colour schemes
   * @param data
   */
  setCampaignTabConfig(data: any) {
    this.campaignTab = {
      tabBgColour: data.tabBgColour,
      tabTextColour: data.tabTextColour,
    };
  }

  createNewTabItem(data: any, tours: string[]): TabbedTourCarouselData {
    return {
      isFeatured: false,
      tabTitle: data.tabName,
      tabHeader: data.tabHeader,
      tabDescription: data.tabDescription,
      tours: tours,
      viewAllText: data.ctaText,
      viewAllRoute: data.ctaUrl,
    };
  }

  /**
   * Handle promotion data
   * @param data
   * @param promotionData
   */
  handlePromotionData(data: any, promotionData: PromotionData) {
    if (this.tabsAdded) return;
    this.tabsAdded = true;

    if (promotionData && promotionData.tours) {
      this.campaignTourList = promotionData.tours.map(tour => tour.slug);
      this.setCampaignTabConfig(data);
      const newTabItem = this.createNewTabItem(data, this.campaignTourList);
      this.carouselData.unshift(newTabItem);
    }
  }

  /**
   * Add new tab data to carouselData
   * @param data
   */
  addDynamicCampaignTab(data: any) {
    this.store
      .select(PromotionState.getPromoCodeData)
      // .pipe(first())
      .subscribe((promotionData: PromotionData) => {
        this.handlePromotionData(data, promotionData);
      });
  }

  /**
   * Normal process regardless of builder IO data.
   * Get tours data for the selected tab
   * @param tabIndex
   */
  getTours(tabIndex = 0) {
    this.tabToursLoading = true;
    if (!this.carouselData[tabIndex].toursData || this.carouselData[tabIndex].toursData.length === 0) {
      this.contentPriceService
        .getMultipleSingleTours(this.carouselData[tabIndex].tours, this.geo.currency)
        .then((response: TourModelLemax[]) => {
          this.carouselData[tabIndex].toursData = response;
          this.tabToursLoading = false;
        });
    } else {
      this.tabToursLoading = false;
    }
  }

  mapModeToggle() {
    this.trackTourRibbonAmplitude('Show Map Mode Toggle');
  }

  onTabSelect(index: number) {
    this.tabSwiperIndex = index;
    this.getTours(index);
    this.trackTourRibbonAmplitude('Tab click');
  }

  trackViewAllTours() {
    this.trackTourRibbonAmplitude('View All Tours clicked');
  }

  trackTourRibbonAmplitude(event: string) {
    const tabbedRibbonData = {
      event: event,
      'tab-page': this.router.url === '/' ? 'home' : this.router.url.replace(/(^\/|\/$)/g, ''),
      section: 'Tour Ribbon',
      showMapMode: this.showMapMode,
      'active-tab': this.carouselData[this.tabSwiperIndex].tabTitle,
      'tab-index': this.tabSwiperIndex + 1,
      'tab-tours': this.carouselData[this.tabSwiperIndex].tours,
      'slider-index': this.tourSwiperIndex + 1,
    };
    this.amplitudeService.trackEvent('Tour Ribbon Action', tabbedRibbonData);
  }
}
