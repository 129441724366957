import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BookingSuccessResponseData,
  CheckoutStateModel,
  PaymentMethod,
  TourItem,
} from '@app/feature/checkout/dto/types';
import { SubSink } from 'subsink';
import { GeoModel } from '@models/geo.model';
import { CheckoutState } from '@app/feature/checkout/store/checkout.state';
import { Store } from '@ngxs/store';
import { AmplitudeService } from '@app/services/amplitude.service';
import { take } from 'rxjs/operators';
import { CheckoutService } from '@app/feature/checkout/services/checkout.service';
import { SendCartAbandonmentEmail, UpdateVisitedSuccessPage } from '@app/feature/checkout/store/checkout.actions';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrl: './success.component.scss',
})
export class SuccessComponent implements OnInit, OnDestroy {
  state$: Observable<CheckoutStateModel>;
  subs: SubSink = new SubSink();
  geo: GeoModel;
  cartItems: TourItem[];
  bookingSuccessData: BookingSuccessResponseData;
  paymentMethod: PaymentMethod;
  youtubeVideo = 'https://www.youtube.com/embed/uIHd6eGpTVM?autoplay=1&rel=0';

  constructor(
    private store: Store,
    private amplitudeService: AmplitudeService,
    private checkoutService: CheckoutService
  ) {}

  ngOnInit() {
    this.state$ = this.store.select(CheckoutState.getState);
    this.subs.sink = this.state$.pipe(take(1)).subscribe(state => {
      this.geo = state?.geo;
      this.cartItems = state?.cartItems;
      this.bookingSuccessData = state?.bookingSuccessData;
      this.paymentMethod = {
        selectedPaymentMethod: state?.selectedPaymentMethod.id,
        data: state?.selectedPaymentMethod.data,
      } as PaymentMethod;

      //Used to block users from returning to previous step page.
      this.store.dispatch(new UpdateVisitedSuccessPage(true));
      this.cartAbandonment();
      this.checkoutService.trackCheckoutNav('Checkout Step Loaded', 'success');
    });
  }

  /**
   * Sends cart abandonment email for success flow.
   */
  cartAbandonment() {
    const state = this.store.snapshot().checkout;
    const customerData = {
      bookingId: state?.reservationData?.id,
    };
    this.store.dispatch(new SendCartAbandonmentEmail(3, customerData));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  formatPrice(price: number) {
    return this.checkoutService.formatPrice(price, this.geo.currency_sign);
  }
}
