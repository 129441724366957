import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ExperimentStateModel } from '@app/feature/session-tracking/models/experiment.state.model';
import { SetAllowInstalmentPayments, SetShowTourSubNav, SetTourLayout } from './experiment.actions';

@State<ExperimentStateModel>({
  name: 'experiment',
  defaults: {
    allowInstalmentPayments: false,
    showTourSubNav: false,
    tourLayout: {
      sections: [],
    },
  },
})
export class ExperimentState {
  @Action(SetAllowInstalmentPayments)
  setAllowInstalmentPayments(ctx: StateContext<ExperimentStateModel>, action: SetAllowInstalmentPayments) {
    ctx.patchState({ allowInstalmentPayments: action.payload });
  }

  @Selector()
  static getAllowInstalmentPayments(state: ExperimentStateModel) {
    return state?.allowInstalmentPayments ?? false;
  }

  @Action(SetShowTourSubNav)
  setShowTourSubNav(ctx: StateContext<ExperimentStateModel>, action: SetShowTourSubNav) {
    ctx.patchState({ showTourSubNav: action.payload });
  }

  @Selector()
  static getShowTourSubNav(state: ExperimentStateModel) {
    return state?.showTourSubNav ?? false;
  }

  @Action(SetTourLayout)
  setTourLayout(ctx: StateContext<ExperimentStateModel>, action: SetTourLayout) {
    ctx.patchState({ tourLayout: action.payload });
  }
}
