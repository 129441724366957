<div class="select-container">
  <div class="custom-select-wrapper" [ngClass]="{ valid: control.valid, invalid: control.invalid && control.touched }">
    <select
      [formControl]="control"
      [id]="controlName + '-select'"
      (focus)="isOpen = true"
      (blur)="isOpen = false"
      [class.open]="isOpen">
      <option value="" disabled selected class="custom-option">{{ placeholder }}</option>
      <option *ngFor="let option of options" [value]="option[valueKey]" class="custom-option">
        {{ option[textKey] }}
      </option>
    </select>
    <label [for]="controlName + '-select'" [class.active]="control.value && control.value !== ''">{{
      placeholder
    }}</label>
  </div>
  <span *ngIf="control.invalid && control.touched" class="icon invalid">
    <app-cross></app-cross>
  </span>
  <span *ngIf="control.valid" class="icon valid">
    <app-tick></app-tick>
  </span>
  <div
    *ngIf="control.invalid && control.touched"
    class="error-message"
    [ngClass]="{ 'white-background': isWhiteBackground, 'other-background': !isWhiteBackground }">
    <span *ngIf="control.errors?.required || control.invalid">{{
      validationMessage ? validationMessage : '[This field is required]'
    }}</span>
  </div>
</div>
