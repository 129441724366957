import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';

import { AccountInfo, AccountModel } from '../models/account.model';
import { AccountConfig } from '../configs/account.config';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private config = new AccountConfig();
  private onActionSuccess = new Subject<any>();
  accountModalToggle = new EventEmitter();
  loginToggle = new EventEmitter();
  accountData = new BehaviorSubject<AccountModel>(null);
  buttonVisible = new BehaviorSubject<boolean>(true); //used for disabling /showing buttons in account forms
  error = new BehaviorSubject<string>(null); //Error messaging
  warning = new BehaviorSubject<string>(null); //Error messaging
  success = new BehaviorSubject<string>(null); //Error messaging
  actionSuccess = this.onActionSuccess.asObservable(); //Components can subscribe to this to receive info, in this case when api call is successful (so that component can close swal modal)

  constructor(private http: HttpClient) {
    if (localStorage.getItem('expatClient')) {
      this.accountData.next(JSON.parse(localStorage.getItem('expatClient')));
    } else {
      this.accountData.next(null);
    }
  }

  /**
   * Notify observable subject that we have completed an action (login / register / reset pw) so that we can close modals we may have open
   */
  onActionComplete(completedAction: string) {
    this.onActionSuccess.next(completedAction);
  }

  /**
   * @param {string} type - email address
   */
  triggerAccountModal(type) {
    this.accountModalToggle.emit(type);
  }

  /**
   * @param {string} username - email address
   * @param {string} password - password
   */
  login(username: string, password: string) {
    //Investigate using recaptcha as brute force protection

    //hide button
    this.buttonVisible.next(false);

    //Call the existing api services
    this.http.post(this.config.urls.login(), { username, password }).subscribe({
      next: response => {
        this.buttonVisible.next(true);
        this.updateAccountData(response[0]);
        this.onActionComplete('login');
      },
      error: error => {
        //show button
        this.buttonVisible.next(true);
        this.error.next(
          `<strong>${error.status}:</strong> Could not authenticate user details, please try again or use "Forgot Password" below`
        );
      },
    });
  }

  loginWishlist(email: string) {
    //Investigate using recaptcha as brute force protection
    //hide button
    this.buttonVisible.next(false);

    //Call the existing api services
    this.http.post(this.config.urls.login(), { username: email, social: true }).subscribe({
      next: response => {
        this.buttonVisible.next(true);
        this.updateAccountData(response[0]);
        this.onActionComplete('login');
      },
      error: error => {
        //show button
        //account does not exist attempt socialRegister from socialLogin
        this.socialRegister(email, '', '');
        this.buttonVisible.next(true);
        this.error.next(
          `<strong>${error.status}:</strong> Could not authenticate user details, please try again or use "Forgot Password" below`
        );
      },
    });
  }

  /**
   * @param {string} username - email address
   * @param {string} name - first name
   * @param {string} surname - last name
   */
  socialLogin(username: string, name: string, surname: string) {
    //Investigate using recaptcha as brute force protection

    //hide button
    this.buttonVisible.next(false);

    this.http.post(this.config.urls.login(), { username, social: true }).subscribe({
      next: response => {
        //Login successful, update updateAccountData
        this.updateAccountData(response[0]);
        this.onActionComplete('socialLogin');
      },
      error: error => {
        if (error.status === 400) {
          //account does not exist attempt socialRegister from socialLogin
          this.socialRegister(username, name, surname);
        } else {
          //show button
          this.buttonVisible.next(true);
          this.error.next(
            `<strong>${error.status}:</strong>Apologies, something has gone wrong. Please try again later or <a href="/contact-us">contact our friendly staff</a> for assistance`
          );
        }
      },
    });
  }

  /**
   * @param {string} email - email address
   * @param {string} name - first name
   * @param {string} surname - last name
   */
  socialRegister(email: string, name: string, surname: string) {
    //Investigate using recaptcha as brute force protection
    //hide button
    this.buttonVisible.next(false);

    this.http
      .post(this.config.urls.signup(), {
        emailAddress: email,
        firstName: name,
        lastName: surname,
        social: true,
      })
      .subscribe({
        next: () => {
          this.loginWishlist(email);
        },
        error: error => {
          //show button
          this.buttonVisible.next(true);
          //if the account already exists there is an error in response body
          if (error.status === 200 || error.status === 400) {
            this.loginWishlist(email);
          } else {
            this.error.next(
              `<strong>${error.status}:</strong>Apologies, something has gone wrong. Please try again later or <a href="/contact-us">contact our friendly staff</a> for assistance`
            );
          }
        },
      });
  }

  /**
   * This will update localStorage item with the new account data values for use in front end templates
   * @param userAccountData {AccountModel}
   * @private
   */
  private updateAccountData(userAccountData: AccountModel) {
    //save the session storage item with updated values
    localStorage.setItem('expatClient', JSON.stringify(userAccountData));
    this.accountData.next(userAccountData);
  }

  logout() {
    localStorage.removeItem('expatClient');
    this.accountData.next(null);
    this.loginToggle.emit();
  }

  /**
   * The exclusion of the "password" parameter means the account info (name / avatar / wishlist etc.) will be updated without password change
   * @param email - {string} - email address
   * @param name - {string} - first name
   * @param surname - {string} - last name
   * @param accountInfo - {string} - Account info object
   */
  updateAccountDetails(email: string, name: string, surname: string, accountInfo: AccountInfo) {
    this.buttonVisible.next(false);
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.config.urls.update(),
          {
            emailAddress: email,
            firstName: name,
            lastName: surname,
            accountInfo: accountInfo,
            password: '',
          },
          {
            responseType: 'text',
          }
        )
        .subscribe({
          next: response => {
            const accStorageItem = JSON.parse(localStorage.getItem('expatClient'));
            accStorageItem.firstName = name;
            accStorageItem.lastName = surname;
            accStorageItem.accountInfo = accountInfo;
            this.updateAccountData(accStorageItem);
            this.buttonVisible.next(true);
            resolve(response);
          },
          error: error => {
            this.buttonVisible.next(true);
            reject(error);
          },
        });
    });
  }

  /**
   * Get all bookings associated with user email
   * @param emailOrID - {string} - email Or ID
   */
  fetchBookings(emailOrID) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.urls.fetchBookings(), {
          params: {
            email: emailOrID,
          },
        })
        .subscribe({
          next: response => {
            resolve(response);
          },
          error: error => {
            reject(error);
          },
        });
    });
  }

  /**
   * Get all bookings associated with user email
   * @param email - {string} - email Or ID
   */
  fetchBookingsLemax(email: string) {
    return this.http.get(this.config.urls.fetchBookingsLemax(email));
  }
}
