import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { SearchState } from '@search/store/search.state';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule, NgxsModule.forFeature([SearchState])],
})
export class SearchStoreModule {}
