import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import { environment } from '@environments/environment';
import { InjectionToken } from '@angular/core';
import axios from 'axios';
import { Response } from '@algolia/client-common';
import { sha256 } from '../../../../../lib/utils';

const { searchConfig } = environment;
const { searchKey, appId, proxyProtocol, proxyHost } = searchConfig.algolia;

const HashingRequestWrapper = {
  async send(request): Promise<Response> {
    try {
      const hash = await sha256((request.data ? JSON.stringify(request.data) : '') + request.url + request.method);

      const url = new URL(request.url);
      url.searchParams.set('searchKey', hash);
      request.url = url.toString();

      const response = await axios.post(request.url, request.data, {
        responseType: 'text',
      });

      return {
        isTimedOut: false,
        status: response.status,
        content: response.data,
      };
    } catch (e) {
      console.error(e);
      return {
        isTimedOut: false,
        status: 0,
        content: e,
      };
    }
  },
};

export const searchClient: SearchClient = algoliasearch(appId, searchKey, {
  hosts: [
    {
      url: proxyHost || `${appId}-dsn.algolia.net`,
      protocol: proxyProtocol || 'https',
    },
  ],
  requester: HashingRequestWrapper,
});

export const SEARCH_CLIENT_TOKEN = new InjectionToken<SearchClient>('SEARCH_CLIENT_TOKEN', {
  providedIn: 'root',
  factory: (): SearchClient => searchClient,
});
