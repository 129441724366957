<div class="auth-wrapper">
  <ng-container *ngIf="isAuthenticated$ | async; else unAuthenticated">
    <ng-container *ngIf="user$ | async as user">
      <button type="button" [matMenuTriggerFor]="menu" class="auth-avatar" (click)="handleAccountMenu()">
        <img [src]="user.picture" alt="Profile" />
      </button>
      <mat-menu #menu="matMenu">
        <a [routerLink]="['/account/my-account']" title="My Account" (click)="handleAccount()">
          <button mat-menu-item>
            <mat-icon>account_circle</mat-icon>
            <span>My Account</span>
          </button>
        </a>
        <button mat-menu-item (click)="handleLogout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </ng-container>
  </ng-container>
  <ng-template #unAuthenticated>
    <a class="auth-link" href="javascript:void(0);" (click)="handleLogin()" title="Login"> Login </a>
  </ng-template>
</div>
