import { AfterViewInit, Directive, ElementRef, Inject, Input, PLATFORM_ID } from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[appSwiper]',
})
export class SwiperDirective implements AfterViewInit {
  @Input() config?: SwiperOptions;
  @Input() init?: string;

  constructor(
    private el: ElementRef<SwiperContainer>,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      Object.assign(this.el.nativeElement, this.config);
      this.el.nativeElement.initialize();
    }
  }
}
