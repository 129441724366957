import { ImageModel } from './image.model';

export class MainMenuItemModel {
  title: string;
  mobileTitle: string;
  mainMenuItem: boolean;
  hasSubMenu: boolean;
  optionalUrl: string;
  newItem: boolean;
  desktop: boolean;
  mobile: boolean;
  mobileIcons: MainMenuItemModelMobileIconItemModel[];
  content?: MainMenuContentItemModel[];
  aboutUsBlocks?: MainMenuAboutUsBlocks[];
  travelInspirationCards?: MainMenuTravelInspirationCards[];
  colourConfigs?: {
    backgroundColour: string;
    textColour: string;
  };
}

export class MainMenuItemModelMobileIconItemModel {
  slug: string;
  standardIcon: {
    title: string;
    url: string;
  };
  activeIcon: {
    title: string;
    url: string;
  };
  nonActiveIcon: {
    title: string;
    url: string;
  };
}

export class MainMenuContentItemModel {
  slug: string;
  title: string;
  newCategory: boolean;
  linkedTours: MainMenuContentLinkedTourItemModel[];
}

export class MainMenuContentLinkedTourItemModel {
  slug: string;
  title: string;
  newTour: boolean;
  url: string;
  image: ImageModel;
}

export class MainMenuAboutUsBlocks {
  content: string;
  icon: {
    title: string;
    url: string;
  };
  isFullWidth: boolean;
  linkText: string;
  linkUrl: string;
  slug: string;
  title: string;
}

export class MainMenuTravelInspirationCards {
  image: {
    description: string;
    title: string;
    url: string;
  };
  url: string;
  newTour: boolean;
  slug: string;
  title: string;
}

export enum MenuSectionType {
  BrowseTours = 'Browse Tours',
  AboutUs = 'About Us',
  TravelInspiration = 'Travel Inspiration',
}
