import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DateTime } from 'luxon';

// Function to escape regex characters in a string
export const regexEscape = (str: string): string => str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

/**
 * Validator to ensure the input contains no digits.
 *
 * This regex pattern, /^\D+$/, matches strings that consist entirely of non-digit characters.
 * - ^ asserts the position at the start of the string.
 * - \D matches any character that is not a digit (equivalent to [^0-9]).
 * - + indicates that the previous token (non-digit characters) must appear one or more times.
 * - $ asserts the position at the end of the string.
 *
 * Example Matches:
 * - "Hello" (matches)
 * - "123" (does not match)
 * - "Hello123" (does not match)
 *
 * This ensures that the input value does not contain any numeric characters.
 */
export const noDigitsValidator: ValidatorFn = Validators.pattern(/^\D+$/);

/**
 * Validator to ensure the input is a valid email.
 *
 * This regex pattern, /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, matches strings that are valid email addresses.
 * - ^ asserts the position at the start of the string.
 * - [a-zA-Z0-9._%+-]+ matches one or more alphanumeric characters, dots, underscores, percent signs, plus signs, or hyphens.
 * - @ matches the @ symbol.
 * - [a-zA-Z0-9.-]+ matches one or more alphanumeric characters, dots, or hyphens.
 * - \. matches the dot symbol.
 * - [a-zA-Z]{2,} matches two or more alphabetic characters.
 * - $ asserts the position at the end of the string.
 *
 * Example Matches:
 * - "example@example.com" (matches)
 * - "user.name@domain.co" (matches)
 * - "invalid-email" (does not match)
 */
export const emailPatternValidator: ValidatorFn = Validators.pattern(
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
);

/**
 * Validator to allow digits, spaces, hyphens, and plus signs.
 *
 * This regex pattern, /^[\d\s+-]*$/, matches strings that contain
 * digits, spaces, hyphens, and plus signs.
 *
 * Example Matches:
 * - "123" (matches)
 * - "+123- 456" (matches)
 * - "abc" (does not match, no letters allowed)
 *
 * This ensures that the input value contains only the allowed characters.
 */
export const allowedFullContactValidator: ValidatorFn = Validators.pattern(/^[\d\s+-]*$/);

/**
 * Validator to ensure the input contains only digits.
 *
 * This regex pattern, /^\d+$/, matches strings that consist entirely of digit characters.
 * - ^ asserts the position at the start of the string.
 * - \d matches any digit character (equivalent to [0-9]).
 * - + indicates that the previous token (digit characters) must appear one or more times.
 * - $ asserts the position at the end of the string.
 *
 * Example Matches:
 * - "123" (matches)
 * - "456789" (matches)
 * - "123abc" (does not match)
 *
 * This ensures that the input value contains only numeric characters.
 */
export const digitsOnlyValidator: ValidatorFn = Validators.pattern(/^\d+$/);

/**
 * Function to create string-only validators with a dynamic max length.
 *
 * @param maxChars The maximum length of the input string.
 * @returns An array of validators.
 */
export const stringOnlyValidators = (maxChars: number): ValidatorFn[] => [
  Validators.required,
  Validators.minLength(2),
  Validators.maxLength(maxChars),
  noDigitsValidator,
];

/**
 * Function to create optional string-only validators with a dynamic max length.
 *
 * @param maxChars The maximum length of the input string.
 * @returns An array of validators.
 */
export const stringOnlyOptionalValidators = (maxChars: number): ValidatorFn[] => [
  Validators.minLength(2),
  Validators.maxLength(maxChars),
  noDigitsValidator,
];

/**
 * Validator to ensure the input is a valid email with required field validation.
 */
export const emailValidators: ValidatorFn[] = [Validators.required, Validators.email, emailPatternValidator];

/**
 * Function to create contact number validators with a dynamic max length.
 *
 * @param maxChars The maximum length of the contact number.
 * @returns An array of validators.
 */
export const fullContactNumberValidators = (maxChars: number): ValidatorFn[] => [
  Validators.required,
  Validators.minLength(2),
  Validators.maxLength(maxChars),
  allowedFullContactValidator,
];

/**
 * Function to create digits-only validators with a dynamic max length.
 *
 * @param maxChars The maximum length of the input string.
 * @returns An array of validators.
 */
export const digitsOnlyValidators = (maxChars: number): ValidatorFn[] => [
  Validators.required,
  Validators.minLength(2),
  Validators.maxLength(maxChars),
  digitsOnlyValidator,
];

/**
 * Takes a full url string returns only slug as string.
 *
 * @param {type} url - url string like https://expatexplore.com/tours/europe-escape/
 * @return {string} - The tour slug as a string
 */
export const getSlugFromUrl = (url: string): string => {
  return url.split('/').pop();
};

/**
 * Custom validator to ensure the input is a valid date.
 *
 * @returns A validation error object if the date is invalid, or null if the date is valid.
 */
export const dateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) return null;
  const date = new Date(control.value);
  const isValidDate = !isNaN(date.getTime());
  return isValidDate ? null : { invalidDate: { value: control.value } };
};

export const countryPluralMap = {
  '=1': 'country',
  other: 'countries',
};

/**
 * Calculates the number of days from the current date until the specified date.
 *
 * @param {String} date - The target date to calculate the days until. The date should be in ISO format, IE: '2022-12-31'.
 * @return {number} - The number of days from now until the specified date.
 */
export function daysUntil(date: string): number {
  const now = DateTime.now();
  const targetDate = DateTime.fromISO(date);
  const diff = targetDate.diff(now, 'days');
  return Math.ceil(diff.days);
}

/**
 * Removes spaces from a string and converts it to lowercase.
 *
 * @param str - The input string.
 * @returns The transformed string with spaces removed and converted to lowercase.
 */
export const removeSpacesLowerCase = (str: string): string => {
  if (!str) return '';
  return str.replace(/\s+/g, '-').toLowerCase();
};

/**
 * Converts a timestamp string into a formatted date string.
 * This method takes a timestamp string as input and converts it into a date string formatted as `DD-MM-YYYY`.
 * It is useful for converting ISO date strings or similar formats into a more readable date format.
 *
 * @param stamp The timestamp string to be converted.
 * @returns A string representing the formatted date in `DD-MM-YYYY` format.
 */
export const dateConversion = (stamp: string) => {
  return DateTime.fromISO(stamp).toFormat('dd-MM-yyyy');
};

/**
 * Converts a comma-separated string of values into an array of strings.
 * @param str
 */
export const commaSeparatedStringsToArray = (str: string): string[] => {
  return str.split(',').map(item => item.trim());
};

/**
 * Capitalizes the first letter of each word in a string.
 *
 * This function takes a string as input and returns a new string with the first letter of each word capitalized.
 * Words are assumed to be separated by spaces.
 *
 * @param str The input string.
 * @returns The transformed string with the first letter of each word capitalized.
 */
export const capitalizeFirstLetter = (str: string): string => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
};

/**
 * Formats a price value as a string with a currency sign and no decimal places.
 * @param price
 * @param currencySign
 */
export function formatPriceNoDecimal(price: number, currencySign: string) {
  if (typeof price === 'undefined') {
    return '';
  }
  price = parseFloat(price.toString());
  return currencySign + ' ' + price.toFixed(0).replace(/\B(?=(\d{3})+\b)/g, ',');
}
