import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrl: './whatsapp.component.scss',
})
export class WhatsappComponent {
  @Output() whatsappClick = new EventEmitter<void>();

  onClick() {
    this.whatsappClick.emit();
  }
}
