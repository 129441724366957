<form [formGroup]="searchForm" (ngSubmit)="navigateToSearchWithKeywordPreset()" class="search-form">
  <div class="styled-input-group">
    <mat-form-field class="styled-input" appearance="outline">
      <input
        id="searchGroup"
        type="text"
        class="form-control"
        (focusin)="destinationPlaceholder = 'Type to search...'"
        (focusout)="destinationPlaceholder = ''"
        [placeholder]="destinationPlaceholder || 'Where would you like to go?'"
        matInput
        formControlName="searchTerm"
        #dropdownTrigger="matAutocompleteTrigger"
        [matAutocomplete]="autoSearchGroup" />
      <button type="submit" class="btn styled-input-group-btn">
        <img
          src="https://images.ctfassets.net/zggpk8714k6l/41zpeVpr400QoQekLdzNRa/2603261b84b842d01d4cbe387ecc5bfd/Search_Icon_Black.svg"
          alt="Where would you like to go?" />
      </button>
      <mat-autocomplete
        #autoSearchGroup="matAutocomplete"
        class="search-dropdown"
        [panelWidth]="dropdownWidth"
        (optionSelected)="selectItem($event)">
        <!--    Countries   -->
        <ng-container *ngIf="noResults$ | async">
          <mat-option>Press Enter to Search</mat-option>
        </ng-container>
        <ng-container *ngIf="(countryHits$ | async)?.length">
          <mat-optgroup label="Countries" class="border-bottom">
            <mat-option *ngFor="let option of countryHits$ | async" [value]="option">
              <div class="d-flex flex-row align-items-center justify-content-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-globe-americas me-1"
                  viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484q-.121.12-.242.234c-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
                </svg>
                <div>
                  {{ option.text }}
                </div>
              </div>
            </mat-option>
          </mat-optgroup>
        </ng-container>
        <!-- Places -->
        <ng-container *ngIf="(placeHits$ | async)?.length">
          <mat-optgroup label="Places Visited" class="border-bottom">
            <mat-option *ngFor="let option of placeHits$ | async" [value]="option">
              <div class="d-flex flex-row align-items-center justify-content-start">
                <div class="me-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-geo-alt-fill"
                    viewBox="0 0 16 16">
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                  </svg>
                </div>
                <div>
                  {{ option.text }}
                </div>
              </div>
            </mat-option>
          </mat-optgroup>
        </ng-container>
        <!-- Tours -->
        <ng-container *ngIf="(tourHits$ | async)?.length">
          <mat-optgroup label="Tours">
            <mat-option *ngFor="let option of tourHits$ | async" [value]="option">
              <div class="d-flex flex-row align-items-center justify-content-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-bus-front-fill me-1"
                  viewBox="0 0 16 16">
                  <path
                    d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.5 2.5 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A44 44 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1zM3.552 3.22A43 43 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44 44 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994M8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9s3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44 44 0 0 0 8 4m-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0m8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1" />
                </svg>
                <div>
                  {{ option.text }}
                </div>
              </div>
            </mat-option>
          </mat-optgroup>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</form>
