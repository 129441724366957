import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDatepicker } from '@angular/material/datepicker';
import { ImageText } from '@models/contentful-common.model';
import { isPlatformBrowser } from '@angular/common';
import { map, switchMap } from 'rxjs/operators';
import { AmplitudeService } from '@app/services/amplitude.service';
import { Store } from '@ngxs/store';
import { merge, Observable } from 'rxjs';
import { SearchOption } from '@search/dto';
import { SearchClient, SearchIndex } from 'algoliasearch/lite';
import { SEARCH_CLIENT_TOKEN } from '@search/services/clients/algoliasearch';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { Navigate } from '@ngxs/router-plugin';
import { DateTime } from 'luxon';
import { SetFacetFilter, SetSearchTerm } from '@search/store/search.actions';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-banner-search',
  templateUrl: './banner-search.component.html',
  styleUrls: ['./banner-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BannerSearchComponent implements OnInit {
  @Input() data: ImageText;
  @Input() overlay: boolean;
  @Input() section: string;
  @ViewChildren(MatDatepicker) datePickers: QueryList<MatDatepicker<any>>;
  @ViewChild('searchDatePicker') searchDatePicker: MatDatepicker<Date>;
  searchDate: Date;
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  searchForm = this.fb.group({
    searchGroup: '',
    searchDate: '',
  });
  private searchIndex: SearchIndex;
  tourHits$: Observable<SearchOption[]>;
  countryHits$: Observable<SearchOption[]>;
  placeHits$: Observable<SearchOption[]>;
  destinationPlaceholder: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private amplitudeService: AmplitudeService,
    private store: Store,
    @Inject(SEARCH_CLIENT_TOKEN) private searchClient: SearchClient,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.searchIndex = this.searchClient.initIndex(environment.searchConfig.algolia.toursIndex);
  }

  setupSearchForFacetValues(facetName: string): Observable<SearchOption[]> {
    const initialValues$ = this.searchFacet(facetName);
    const search$ = this.searchForm
      .get('searchGroup')
      .valueChanges.pipe(switchMap(value => this.searchFacet(facetName, value)));
    return merge(initialValues$, search$);
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.tourHits$ = this.setupSearchForFacetValues('tourName');
      this.countryHits$ = this.setupSearchForFacetValues('tourCountries');
      this.placeHits$ = this.setupSearchForFacetValues('places');

      this.trackFormChanges();
    }
  }

  trackFormChanges() {
    this.searchForm.get('searchGroup').valueChanges.subscribe(() => {
      this.trackTourRibbonAmplitude('Destination Change');
    });

    this.searchForm.get('searchDate').valueChanges.subscribe(() => {
      this.trackTourRibbonAmplitude('Date Change');
    });
  }

  onViewTours() {
    this.trackTourRibbonAmplitude('Click View Tours');
    this.store.dispatch(new Navigate(['/search']));
  }

  onSearchSubmit() {
    const queryParams = {};
    const { searchGroup, searchDate } = this.searchForm.value;
    if (searchGroup) {
      queryParams['keyword'] = searchGroup.trim();
    }
    if (searchDate) {
      queryParams['departureMonth'] = 'departures.month:' + DateTime.fromJSDate(this.searchDate).toFormat('MMM yyyy');
    }
    this.trackTourRibbonAmplitude('Click Search');
    this.store.dispatch([
      new SetSearchTerm(searchGroup.trim()),
      new SetFacetFilter({ key: 'departureMonth', value: queryParams['month'] }),
      new Navigate(['/search'], queryParams),
    ]);
  }

  openSearchDatePicker() {
    this.searchDatePicker.open();
  }

  selectYearMonth(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    this.searchDate = date;
    this.searchForm.get('searchDate').setValue(this.months[month] + ' ' + year);
    this.searchDatePicker?.close();
  }

  trackTourRibbonAmplitude(event: string) {
    const bannerSearchData = {
      event: event,
      page: this.amplitudeService.setPageUrl(this.router.url),
      section: this.section,
      'search-date': this.searchForm.get('searchDate').value,
      'search-keyword': this.searchForm.get('searchGroup').value,
    };
    this.amplitudeService.trackEvent('Banner Search Interaction', bannerSearchData);
  }

  private searchFacet(facetName: string, term = ''): Observable<SearchOption[]> {
    return fromPromise(this.searchIndex.searchForFacetValues(facetName, term, { maxFacetHits: 10 })).pipe(
      map(results => {
        return results.facetHits.map(hit => {
          return { type: facetName, text: hit.value } as SearchOption;
        });
      })
    );
  }
}
