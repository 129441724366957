import { environment } from '../../environments/environment';

export class BlogConfig {
  urls = {
    getBlogPostsSearch: (search: string, query: string, page: number) =>
      `${environment.blogUrl}/posts?_embed&page=${page}${query}&search=${search}&search_columns=post_title,post_excerpt,post_content&orderby=relevance&order=desc`,
    getRelatedBlogPosts: (type: string, list: Array<any>) =>
      `${environment.blogUrl}/posts?_embed&page=1&${type}=${list}`,
    getBlogPosts: (queries: string, page: number) => `${environment.blogUrl}/posts?_embed&page=${page}${queries}`,
    getBlogPost: (slug: string) => `${environment.blogUrl}/posts?_embed&slug=${slug}`,
    getBlogSEO: (slug: string) => `${environment.blogUrl}/posts?slug=${slug}&_fields=yoast_head_json`,
    getBlogComments: (postID: number) => `${environment.blogUrl}/comments?post=${postID}`,
    createBlogComments: () => `${environment.blogUrl}/comments`,
    getBlogCategories: () => `${environment.blogUrl}/categories`,
    getBlogTags: () => `${environment.blogUrl}/tags`,
    getBlogUsers: () => `${environment.blogUrl}/users`,
  };
}
