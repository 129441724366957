import { AlgoliaConfig, IAppEnvironment } from '@environments/types';

export const environment = {
  production: false,
  qa: false,
  dev: true,
  // Injected during prebuild step
  toursV3: 'https://imv74d7p40.execute-api.eu-west-1.amazonaws.com/dev',
  checkoutLemax: 'https://h7872ygb0m.execute-api.eu-west-1.amazonaws.com/dev',
  userBookingsLemax: 'https://h7872ygb0m.execute-api.eu-west-1.amazonaws.com/dev',
  lemaxPassengerURL: 'https://gomgrqblzg.execute-api.eu-west-1.amazonaws.com/dev',
  elasticSearchURL: 'https://arvqrntyf1.execute-api.eu-west-1.amazonaws.com/dev',
  geoUrl: 'https://2b9kwc3ej6.execute-api.eu-west-1.amazonaws.com/dev',
  destinationsURL: 'https://4m5zd7o943.execute-api.eu-west-1.amazonaws.com/dev',
  signup: 'https://v7mghuf6id.execute-api.eu-west-1.amazonaws.com/dev',
  accountUrl: 'https://xb4fnoo8l5.execute-api.eu-west-1.amazonaws.com/dev',
  reviewUrl: 'https://3x5kbq6zp6.execute-api.eu-west-1.amazonaws.com/dev',
  priceBeatUrl: 'https://tet8nfmfak.execute-api.eu-west-1.amazonaws.com/dev',
  paymentServiceBaseUrl: 'https://payments.dev.expatexplore.com',

  blogUrl: 'https://wp.expatexplore.com/wp-json/wp/v2',
  blogBaseUrl: 'https://wp.expatexplore.com/',
  blogLegacyUrls: ['https://expatexplore.com/blog/', 'https://qa1.expatexplore.com/blog/'],

  //! unsecure need to rework
  attachmentsUrl: 'https://expatexplore-attachments.s3.eu-west-1.amazonaws.com',
  contentUrl: 'https://dev.expatexplore.com',

  // Legacy configurations to be removed once all references are removed
  campaignsUrl: '-',
  indemnityUrl: '-',
  userBookingsUrl: '-',
  ipiTCMS: '-',
  toursV2Url: '-',
  bookingV2Url: '-',
  checkoutV2Url: '-',

  builderIo: {
    apiKey: 'de099c493a754bdfb930859a10bf2dad',
  },
  enableHotjarTracking: false,
  hotjar: {
    id: 3561506,
    sv: 6,
  },
  enableFreshchatWidget: false,
  freshchatWidgetUrl: 'https://expatexplore.freshchat.com/js/widget.js',
  freshchatWidget: {
    token: '0537baec-0788-4bc4-bee6-97b399be5ce7',
    host: 'https://expatexplore.freshchat.com',
  },
  trustPaymentSiteRef: 'test_expatexploretravel94539',
  paymentSubmitUrl: 'https://payments.securetrading.net/process/payments/choice',
  enableCookieYes: false,
  cookieYesUrl: 'https://cdn-cookieyes.com/client_data/488795da0c14819db9acd27e/script.js',
  recaptcha: {
    siteKey: '6LfiEA4iAAAAAABY-Jl1OIQCnJM1I7ieSnJqAQvV',
  },
  amplitudeKey: 'd322b7ee22725be5db02781d837d8698',
  experiments: {
    tourHeader: 'client-J6ZdngUehPaw43IigQUJ3PlzIVHk1cGq',
    checkoutNextBtn: 'client-Wq0StA2FnW6p5CrwWwnVPGkclaFjsLxL',
    tourRibbonMapMode: 'client-r0RBW2ArjmZ3LKTL3rfCdLgXhmbXAEeJ',
    instalmentPayments: 'client-mj7V7rBf1sD68OyaITr30Jbq5er1ukn5',
  },
  braze: {
    key: '7ae2e45e-b1e9-44ab-92c8-278db7e77bd9',
    baseUrl: 'sdk.fra-02.braze.eu',
    newsletterSegment: '28773e7f-6bc8-4904-a195-3f6d16f32354',
  },
  gtmId: 'GTM-NZFWVS',
  auth0: {
    domain: 'auth0.expatexplore.com',
    clientId: 'DP7E4CDSRYSJizYBmmcsiEUTiSwwwOa1',
    redirect: typeof window !== 'undefined' ? window.location.origin : 'https://dev.expatexplore.com',
  },
  searchConfig: {
    algolia: {
      appId: '3B0FWTPCNA',
      searchKey: '41236bfcd8190004d1fadf3542dc867e',
      toursIndex: 'tours_v0.0.1_dev',
      proxyProtocol: 'https',
      proxyHost: 'search-dev.expatexplore.com',
    } as AlgoliaConfig,
  },
} as IAppEnvironment;
