import { Component, EventEmitter, Inject, Input, Output, Renderer2 } from '@angular/core';
import { DOCUMENT, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AmplitudeCheckoutStepData, TrustPaymentsConfig, TrustPaymentsHMAC } from '@app/feature/checkout/dto/types';
import { CheckoutService } from '@app/feature/checkout/services/checkout.service';
import Swal from 'sweetalert2';
import { environment } from '@environments/environment';
import { AmplitudeService } from '@app/services/amplitude.service';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-make-payments',
  standalone: true,
  imports: [NgIf],
  templateUrl: './make-payments.component.html',
  styleUrl: './make-payments.component.scss',
})
export class MakePaymentsComponent {
  @Input() tpConfig: TrustPaymentsConfig;
  @Input() bookingReference: string;
  @Input() page: string;
  @Output() submittingChange = new EventEmitter<boolean>();

  submitting = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private checkoutService: CheckoutService,
    private amplitudeService: AmplitudeService,
    private router: Router,
    private renderer: Renderer2
  ) {}

  /**
   * Checks if the payment details are fully loaded.
   *
   * This method verifies that both the TrustPayments configuration (`tpConfig`) and the booking reference
   * (`bookingReference`) are present and truthy. It's a prerequisite check before proceeding with payment
   * processing to ensure that all necessary information is available.
   *
   * @returns {boolean} True if both `tpConfig` and `bookingReference` are loaded, false otherwise.
   */
  isPaymentDetailsLoaded(): boolean {
    return !!this.tpConfig && !!this.bookingReference;
  }

  /**
   * Initiates the payment process.
   *
   * This method is triggered when the user decides to proceed with the payment. It first sets the `submitting`
   * state to true to prevent multiple submissions. Then, it calls the `generateHMAC` method from the `CheckoutService`
   * to obtain a security string and timestamp necessary for the payment process.
   *
   * Upon successful generation of HMAC, it checks for the presence of a timestamp and hash in the response,
   * assigns them to the `tpConfig` object, and creates a form with the payment details. This form is then
   * appended to the document body and submitted to the payment gateway URL.
   *
   * If the HMAC generation fails or does not return the expected timestamp and hash, it logs an error and
   * sets the `submitting` state back to false, indicating that the payment process did not initiate successfully.
   */
  onSubmit() {
    // Set submitting property and hide pay button so users don't click twice
    this.submitting = true;
    this.submittingChange.emit(this.submitting);

    //Generate HMAC to get the security string and timestamp
    this.checkoutService
      .generateHMAC(this.tpConfig.currencyiso3a, parseFloat(this.tpConfig.mainamount), this.bookingReference)
      .subscribe({
        next: (response: TrustPaymentsHMAC) => this.handleHMACResponse(response),
        error: (error: HttpErrorResponse) => this.onHMACError(error),
      });
  }

  /**
   * Handles the response from the HMAC generation request.
   *
   * This method is responsible for processing the response received from the `generateHMAC` method call.
   * Upon receiving a successful response, it updates the TrustPayments configuration (`tpConfig`) with
   * the received timestamp and hash. Then, it dynamically creates a form with the payment details and
   * appends this form to the document body before submitting it to the payment gateway URL.
   *
   * If the response does not contain the expected timestamp and hash, it logs an error message indicating
   * that the HMAC service did not return the necessary data.
   *
   * @param {TrustPaymentsHMAC} response - The response object from the HMAC generation request, expected
   * to contain a timestamp and a hash for securing the payment process.
   */
  private handleHMACResponse(response: TrustPaymentsHMAC) {
    if (response.timestamp && response.hash) {
      this.updateTPConfig(response);
      const form = this.createForm();
      this.renderer.appendChild(this.document.body, form);

      form.submit();
    } else {
      this.logError('HMAC service did not return a timestamp or hash. BookingRef: ' + this.bookingReference);
      this.genericRetryNotification(this.bookingReference);
    }
  }

  /**
   * Updates the TrustPayments configuration with the response from the HMAC generation.
   *
   * This method takes the response object from the HMAC generation request and updates the
   * TrustPayments configuration (`tpConfig`) object with the new timestamp and hash. These
   * values are essential for securing the payment process and are used in the payment submission
   * form. The `sitesecuritytimestamp` and `sitesecurity` fields of the `tpConfig` object are
   * updated with the timestamp and hash values from the response, respectively.
   *
   * @param {TrustPaymentsHMAC} response - The response object containing the timestamp and hash.
   */
  private updateTPConfig(response: TrustPaymentsHMAC) {
    this.tpConfig.sitesecuritytimestamp = response.timestamp;
    this.tpConfig.sitesecurity = response.hash;
  }

  /**
   * Handles errors that occur during the HMAC generation process.
   *
   * This method is invoked when an error occurs during the call to the `generateHMAC` method. It logs the error
   * details to the console and sets the `submitting` state back to false, indicating that the payment process
   * has been halted due to the error. This method serves as a central point for error handling within the
   * payment process, allowing for easy integration of additional error handling logic, such as user notifications
   * or error tracking, in the future.
   *
   * @param {HttpErrorResponse} error - The error response object containing details about the error that occurred.
   */
  private onHMACError(error: HttpErrorResponse) {
    this.submitting = false;
    this.submittingChange.emit(this.submitting);
    const hmacData = {
      event: 'Error generating HMAC',
      bookingRef: this.bookingReference,
      error: error.message,
      status: error.status,
      page: this.amplitudeService.setPageUrl(this.router.url),
    };
    this.amplitudeService.trackEvent('Error generating HMAC', hmacData);
    this.genericRetryNotification(this.bookingReference);
  }

  /**
   * Logs an error message to the console.
   *
   * This method is used to log error messages related to the payment process. It sets the `submitting`
   * state back to false, indicating that an error has occurred and the payment process is no longer in
   * progress. The method logs the provided message to the console, serving as a simple way to track
   * errors during development. Future implementations could extend this method to include more sophisticated
   * error handling mechanisms, such as sending error details to a monitoring service or displaying user-friendly
   * error messages in the UI.
   *
   * @param {string} message - The error message to be logged.
   */
  private logError(message: string) {
    this.submitting = false;
    this.submittingChange.emit(this.submitting);
    console.error(message);
  }

  private genericRetryNotification(bookingReference: string): void {
    Swal.fire({
      icon: 'warning',
      title: 'An unexpected error occurred',
      html: `Please try again or contact our <a href="/contact-us" title="contact us" target="_blank">friendly customer service</a> team with your booking reference: ${bookingReference}`,
    });
  }

  /**
   * Dynamically creates a form for submitting payment details.
   *
   * This method constructs a form element using Angular Renderer2 to safely interact with the DOM.
   * It sets the form's method to POST and its action to the specified payment gateway URL. For each
   * property in the `tpConfig` object, which contains the payment configuration details, a hidden input
   * field is created and appended to the form. This includes handling multiple values for specific fields
   * like `ruleidentifier`, ensuring all necessary payment information is included in the form.
   *
   * The use of Renderer2 ensures that the form is created in a way that prevents security issues, such as
   * XSS attacks, by not directly interacting with the DOM through unsafe methods.
   *
   * @returns {HTMLFormElement} The created form element, ready to be appended to the document body and submitted.
   */
  createForm(): HTMLFormElement {
    const form = this.renderer.createElement('form');
    this.renderer.setAttribute(form, 'method', 'POST');
    this.renderer.setAttribute(form, 'action', environment.paymentSubmitUrl);

    // Loop over the fields and create hidden inputs
    const fields: TrustPaymentsConfig = this.tpConfig;

    // Dynamically create form elements for each configuration property
    Object.keys(fields).forEach(key => {
      const input = this.renderer.createElement('input');
      this.renderer.setAttribute(input, 'type', 'hidden');
      this.renderer.setAttribute(input, 'name', key);
      this.renderer.setAttribute(input, 'value', fields[key]);
      this.renderer.appendChild(form, input);
    });

    return form;
  }
}
