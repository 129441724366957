import { animate, state, style, transition, trigger } from '@angular/animations';

export const FadeInOut = trigger('fadeInOut', [
  state('void', style({ opacity: 0 })),
  transition('void <=> *', [
    //can use "void <=> *" or even ":enter, :leave"
    animate(600),
  ]),
]);

//How to trigger this one first?
export const mobileMenuShadow = trigger('mobileMenuShadow', [
  state('void', style({ backgroundColor: '#00000091' })),
  transition('void => *', [
    style({ backgroundColor: '#00000000' }), //applied immediately to transition
    animate(800, style({ backgroundColor: '#00000091' })), //applied over time to transition
  ]),
  transition('* => void', [
    style({ backgroundColor: '#00000091' }),
    animate(300, style({ backgroundColor: '#00000000' })),
  ]),
]);

//Then this one second?
export const mobileMenuSlide = trigger('mobileMenuSlide', [
  state('void', style({ left: 'calc(100vw - 50px)' })),
  transition('void <=> *', [animate(300)]),
]);
