<div class="checkbox-container" [ngClass]="{ invalid: control.invalid && control.touched }">
  <input type="checkbox" [formControl]="control" [id]="controlName + '-checkbox-input'" />
  <label *ngIf="!termsConditions || label" [for]="controlName + '-checkbox-input'" [innerHTML]="safeLabel"></label>
  <label *ngIf="termsConditions" [for]="controlName + '-checkbox-input'">
    <strong>
      I agree to Expat Explore’s
      <a href="https://expatexplore.com/terms-conditions" target="_blank">T&C's</a> and
      <a href="https://expatexplore.com/privacy-policy/" target="_blank">Privacy Policy</a> to complete my payment.
    </strong>
  </label>
  <div *ngIf="control.invalid && control.touched" class="error-message">This field is required.</div>
</div>
