import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent {
  @Input() checked = false; // Initial checked status
  @Output() checkedChange = new EventEmitter<boolean>(); // Emit changes to checked status

  toggle() {
    this.checked = !this.checked; // Toggle the checked status
    this.checkedChange.emit(this.checked); // Emit the new checked status
  }
}
