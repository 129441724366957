import { Inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IPaginationInput, PaginationConfig } from '../dto/models';
import { SetLimit, SetPage } from '@pagination/store/store.actions';
import { PAGINATION_CONFIG_TOKEN } from '@pagination/store/store.module';

export interface PaginationStoreStateModel extends IPaginationInput {
  defaultLimit: number;
}

@State<PaginationStoreStateModel>({
  name: 'pagination',
  defaults: {
    defaultLimit: 10,
    limit: 10,
    page: 1,
  } as PaginationStoreStateModel,
})
@Injectable()
export class PaginationState {
  constructor(@Inject(PAGINATION_CONFIG_TOKEN) private config: PaginationConfig) {}

  @Selector()
  static getState(state: PaginationStoreStateModel) {
    return state;
  }

  @Selector()
  static getDefaultPageLimit(state: PaginationStoreStateModel) {
    return state.defaultLimit;
  }

  @Selector()
  static getPageLimit(state: PaginationStoreStateModel) {
    return state.limit;
  }

  @Selector()
  static getPage(state: PaginationStoreStateModel) {
    return state.page;
  }

  @Action(SetPage)
  setPage(ctx: StateContext<PaginationStoreStateModel>, { page }: SetPage) {
    ctx.patchState({ page });
  }

  @Action(SetLimit)
  setLimit(ctx: StateContext<PaginationStoreStateModel>, { limit }: SetLimit) {
    ctx.patchState({ limit });
  }

  ngxsOnInit(ctx: StateContext<PaginationStoreStateModel>) {
    ctx.patchState({
      defaultLimit: this.config.defaultLimit,
      limit: this.config.defaultLimit,
    });
  }
}
