import { environment } from '../../environments/environment';

export class ToursConfig {
  urls = {
    getTour: (slug: string) => `${environment.contentUrl}/v3/tours/${slug}.json`,
    getDepartures: (tourCode: string) =>
      `https://btlhux8p5f.execute-api.eu-west-1.amazonaws.com/prod/v2/tour/tourCode/${tourCode}`,
    getCampaignDepartures: () => `${environment.campaignsUrl}/campaigns`,
    postTourCardSlugs: () => `${environment.toursV3}/tours/multi`,
    getSingleTour: (slug: string, currencyCode: string, promoCode?: string) =>
      `${environment.toursV3}/tours/single?acceptLanguage=en-GB&invalidate=false&slug=${slug}&currencyCode=${currencyCode}${promoCode ? '&promoCode=' + promoCode : ''}`,
    getSingleTourById: (id: string, currencyCode: string) =>
      `${environment.toursV3}/tours/single?acceptLanguage=en-GB&invalidate=false&tourId=${id}&currencyCode=${currencyCode}`,
  };
}
