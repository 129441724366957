import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { BlogConfig } from '../configs/blog.config';
import { BlogPagination, BlogPost } from '../models/blog-posts.model';
import { BlogComment } from '../models/blog-comment.model';
import { BlogAuthor } from '../models/blog-author.model';
import { BlogCategory } from '../models/blog-category.model';
import { BlogTag } from '../models/blog-tag.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  private config = new BlogConfig();
  public paginationChanged = new EventEmitter();

  public blogPagination: BlogPagination = {
    currentPage: 1,
    totalPages: 0,
    totalPosts: 0,
  };

  public blogPost: BlogPost;
  public blogPosts: BlogPost[] = [];
  public blogStickyPosts: BlogPost[] = [];
  public blogComments: BlogComment[] = [];
  public blogAuthors: BlogAuthor[] = [];
  public blogCategories: BlogCategory[] = [];
  public blogTags: BlogTag[] = [];

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  /**
   * Get all blog posts
   * @param {params} params - search parameters
   */
  getParamQueries(params: any) {
    let queries = '';
    let item: any;
    for (const key in params) {
      if (params[key] === null) continue;
      switch (key) {
        case 'categories':
          item = this.blogCategories.find((item: any) => item.slug === params[key]);
          queries = queries + `&${key}=${item.id}`;
          break;
        case 'tags':
          item = this.blogTags.find((item: any) => item.slug === params[key]);
          queries = queries + `&${key}=${item.id}`;
          break;
        case 'author':
          item = this.blogAuthors.find((item: any) => item.slug === params[key]);
          queries = queries + `&${key}=${item.id}`;
          break;
        default:
          queries = queries + `&${key}=${params[key]}`;
      }
    }
    return queries;
  }

  /**
   * Get all blog posts filtered by params
   * @param {any} params - query search parameters
   */
  getPosts(params: any) {
    const queries = this.getParamQueries(params);

    return this.http
      .get(this.config.urls.getBlogPosts(queries, this.blogPagination.currentPage), { observe: 'response' })
      .pipe(
        map((responseData: any) => {
          const keys = responseData.headers.keys();
          this.blogPagination.totalPosts = parseInt(responseData.headers.get('x-wp-total'));
          this.blogPagination.totalPages = parseInt(responseData.headers.get('x-wp-totalpages'));
          this.paginationChanged.emit();

          this.blogPosts = responseData.body as BlogPost[];

          this.blogPosts.forEach(post => {
            if (post._links['wp:featuredmedia']) {
              const featuredMediaUrl = post._links['wp:featuredmedia'][0]['href'];
              fetch(featuredMediaUrl)
                .then(response => response.json())
                .then(featuredMedia => {
                  post.featuredMediaImgUrl = featuredMedia.source_url;
                })
                .catch(error => {
                  console.error('Error:', error);
                });
            }
          });

          return this.blogPosts;
        }),
        catchError(errorRes => {
          console.log('errorRes:: ', errorRes);
          return throwError(errorRes);
        })
      );
  }

  /**
   * Get all blog posts filtered by type and IDs
   * @param {string} type - the type of list
   * @param {Array} list - list of IDs
   */
  getPostsByType(type: string, list: Array<any>) {
    return this.http.get(this.config.urls.getRelatedBlogPosts(type, list)).pipe(
      map((responseData: any) => {
        return responseData as BlogPost[];
      }),
      catchError(errorRes => {
        console.log('errorRes:: ', errorRes);
        return throwError(errorRes);
      })
    );
  }

  /**
   * Get all blog posts filtered by search text
   * @param {string} search - text used in search
   * @param {object} params - list of IDs
   */
  searchPosts(search: string, params: any): Observable<BlogPost[]> {
    const queries = this.getParamQueries(params);

    return this.http
      .get<BlogPost[]>(this.config.urls.getBlogPostsSearch(search, queries, this.blogPagination.currentPage), {
        observe: 'response',
      })
      .pipe(
        map((responseData: any) => {
          const keys = responseData.headers.keys();
          this.blogPagination.totalPosts = parseInt(responseData.headers.get('x-wp-total'));
          this.blogPagination.totalPages = parseInt(responseData.headers.get('x-wp-totalpages'));
          this.paginationChanged.emit();

          this.blogPosts = responseData.body as BlogPost[];

          this.blogPosts.forEach(post => {
            if (post._links['wp:featuredmedia']) {
              const featuredMediaUrl = post._links['wp:featuredmedia'][0]['href'];
              fetch(featuredMediaUrl)
                .then(response => response.json())
                .then(featuredMedia => {
                  post.featuredMediaImgUrl = featuredMedia.source_url;
                })
                .catch(error => {
                  console.error('Error:', error);
                });
            }
          });

          return this.blogPosts;
        }),
        catchError(errorRes => {
          console.log('errorRes:: ', errorRes);
          return throwError(errorRes);
        })
      );
  }

  /**
   * Set pagination page
   * @param {number} newPage - number of the page
   */
  setPage(newPage: number) {
    this.blogPagination.currentPage = newPage;
  }

  /**
   * Get indivudual blog post
   * @param {string} slug - slug of the blog post
   */
  getSinglePost(slug: string) {
    return this.http.get(this.config.urls.getBlogPost(slug)).pipe(
      map((responseData: any) => {
        this.blogPost = responseData.find(post => post.slug === slug) as BlogPost;
        if (this.blogPost._links['wp:featuredmedia']) {
          const featuredMediaUrl = this.blogPost._links['wp:featuredmedia'][0]['href'];
          if (isPlatformBrowser(this.platformId)) {
            fetch(featuredMediaUrl)
              .then(response => response.json())
              .then(featuredMedia => {
                this.blogPost.featuredMediaImgUrl = featuredMedia.source_url;
              })
              .catch(error => {
                console.error('Error:', error);
              });
          }
        }

        // console.log('blogPost:: ', this.blogPost);
        return this.blogPost;
      }),
      catchError(errorRes => {
        console.log('errorRes:: ', errorRes);
        return throwError(errorRes);
      })
    );
  }

  /**getBlogSEO
   * Get indivudual blog post's SEO data
   * @param {string} slug - slug of the blog post
   */
  getSinglePostSEO(slug: string) {
    return this.http.get(this.config.urls.getBlogSEO(slug)).pipe(
      map((responseData: any) => {
        // console.log('getSinglePostSEO::', responseData);
        this.blogPost = responseData as BlogPost;
        return responseData;
      }),
      catchError(errorRes => {
        console.log('errorRes:: ', errorRes);
        return throwError(errorRes);
      })
    );
  }

  /**
   * Get all the comments of a blog post
   * @param {number} postID - ID of the blog post
   */
  getPostComments(postID: number) {
    return this.http.get(this.config.urls.getBlogComments(postID)).pipe(
      map((responseData: any) => {
        this.blogComments = responseData as BlogComment[];
        return this.blogComments;
      }),
      catchError(errorRes => {
        console.log('errorRes:: ', errorRes);
        return throwError(errorRes);
      })
    );
  }

  /**
   * Create a new comments on a blog post
   * @param {BlogComment} comment - ID of the blog post
   */
  createPostComment(comment: BlogComment) {
    return this.http.post(this.config.urls.createBlogComments(), comment);
  }

  /**
   * Get all blog categories
   */
  getCategories() {
    return this.http.get(this.config.urls.getBlogCategories()).pipe(
      map((responseData: any) => {
        this.blogCategories = responseData as BlogCategory[];
        this.blogCategories.sort((a, b) => (a.name === 'Black Friday' ? -1 : 1));
        return this.blogCategories;
      }),
      catchError(errorRes => {
        console.log('errorRes:: ', errorRes);
        return throwError(errorRes);
      })
    );
  }

  /**
   * Get all blog tags
   */
  getTags() {
    return this.http.get(this.config.urls.getBlogTags()).pipe(
      map((responseData: any) => {
        this.blogTags = responseData as BlogTag[];
        return this.blogTags;
      }),
      catchError(errorRes => {
        console.log('errorRes:: ', errorRes);
        return throwError(errorRes);
      })
    );
  }

  /**
   * Get all blog Users then filter out the Authors
   */
  getAuthors() {
    const arrAuthors = [2, 3];
    return this.http.get(this.config.urls.getBlogUsers()).pipe(
      map((responseData: any) => {
        this.blogAuthors = responseData.filter((obj: any) => {
          return responseData[arrAuthors.indexOf(obj['id'])];
        });
        return this.blogAuthors as BlogAuthor[];
      }),
      catchError(errorRes => {
        console.log('errorRes:: ', errorRes);
        return throwError(errorRes);
      })
    );
  }
}
