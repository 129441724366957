import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { AmplitudeService } from '@app/services/amplitude.service';
import { SetUtmParams } from '@app/feature/session-tracking/store/tracking.state';
import {
  TrackingStateModel,
  UtmParams,
  CalendlyDataLayerObject,
} from '@app/feature/session-tracking/store/utm-params.model';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

declare const dataLayer: any[];

@Injectable({
  providedIn: 'root',
})
export class SessionTrackingService {
  private readonly utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  utmParams$: Observable<TrackingStateModel['utmParams']>;

  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly amplitudeService: AmplitudeService
  ) {
    this.utmParams$ = this.store.select(state => state.tracking.utmParams);
  }

  captureUTMParameters() {
    const utmData = this.getUTMDataFromURL();
    this.setUTMCookies(utmData);

    const defaultUtmParams: UtmParams = {
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      utm_content: '',
      utm_term: '',
    };

    const completeUtmParams: UtmParams = { ...defaultUtmParams, ...utmData };
    this.store.dispatch(new SetUtmParams(completeUtmParams));
  }

  private getUTMDataFromURL(): Partial<UtmParams> {
    const urlParams = new URLSearchParams(window.location.search);
    const utmData: Partial<UtmParams> = {};

    this.utmParams.forEach(param => {
      const value = urlParams.get(param);
      if (value) {
        utmData[param as keyof UtmParams] = value;
      }
    });

    return utmData;
  }

  private setUTMCookies(utmData: Partial<UtmParams>) {
    Object.keys(utmData).forEach(param => {
      document.cookie = `${param}=${utmData[param as keyof UtmParams]}; path=/`;
    });
  }

  trackCalendlyCallbackEvent() {
    this.activatedRoute.queryParams
      .pipe(
        filter(params => !!params['event_type_name']),
        take(1)
      )
      .subscribe(params => {
        this.utmParams$.pipe(take(1)).subscribe(() => {
          const calendlyData = this.buildCalendlyData(params);
          dataLayer.push({ event: 'Calendly Callback', data: calendlyData } as CalendlyDataLayerObject);
          this.amplitudeService.trackEvent('Calendly Callback', calendlyData);
        });
      });
  }

  private buildCalendlyData(params: any) {
    return {
      event_type_name: this.decodeParam(params['event_type_name']),
      event_type_uuid: params['event_type_uuid'],
      event_start_time: this.formatDate(params['event_start_time']),
      event_end_time: this.formatDate(params['event_end_time']),
      guests_count: this.parseGuestsCount(params['guests']),
      assigned_to: this.decodeParam(params['assigned_to']),
      invitee_uuid: params['invitee_uuid'],
      invitee_email: params['invitee_email'],
      invitee_first_name: params['invitee_first_name'],
      invitee_last_name: params['invitee_last_name'],
      invitee_full_name: params['invitee_full_name'],
      invitee_payment_amount: params['invitee_payment_amount'],
      invitee_payment_currency: params['invitee_payment_currency'],
      text_reminder_number: params['text_reminder_number'],
      utm_source: this.getUTMParam(params, 'utm_source'),
      utm_medium: this.getUTMParam(params, 'utm_medium'),
      utm_campaign: this.getUTMParam(params, 'utm_campaign'),
      utm_content: this.getUTMParam(params, 'utm_content'),
      utm_term: this.getUTMParam(params, 'utm_term'),
    };
  }

  private decodeParam(param: string): string {
    return decodeURIComponent(param.replace(/\+/g, ' '));
  }

  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date
      .toLocaleString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'UTC',
      })
      .replace(',', '');
  }

  private parseGuestsCount(guests: string): number {
    try {
      if (!guests) {
        return 0;
      }
      return decodeURIComponent(guests).split(',').length;
    } catch {
      return 0;
    }
  }

  private getUTMParam(params: any, param: string): string | null {
    return (
      params[param] ||
      this.getCookie(param) ||
      (param === 'utm_source' ? this.getCookie('__gtm_referrer') : this.getCookie('__gtm_campaign_url'))
    );
  }

  private getCookie(name: string): string | null {
    const regex = new RegExp('(^| )' + name + '=([^;]+)');
    const match = regex.exec(document.cookie);
    return match ? decodeURIComponent(match[2]) : null;
  }

  /**
   * Converts an email address to a unique user ID.
   *
   * This function normalizes the email by trimming and converting it to lowercase,
   * then encodes it as a UTF-8 array, generates a SHA-256 hash of the encoded data,
   * and finally converts the hash to a Base64 string.
   *
   * @param email - The email address to be converted.
   * @returns A promise that resolves to the Base64 encoded SHA-256 hash of the normalized email.
   */
  async emailToUserId(email: string): Promise<string> {
    // Trim and Lowercase email
    const normalizedEmail = email.trim().toLowerCase();

    // Encode the email as a UTF-8 array
    const encoder = new TextEncoder();
    const data = encoder.encode(normalizedEmail);

    // Generate the SHA-256 hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    // Convert ArrayBuffer to Base64 string
    const byteArray = new Uint8Array(hashBuffer);
    const binaryString = Array.from(byteArray)
      .map(byte => String.fromCharCode(byte))
      .join('');
    return btoa(binaryString);
  }

  setUserIdCookie(userHashID: string) {
    document.cookie = `userHashID=${userHashID}; path=/`;
  }
}
