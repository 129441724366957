import { Component, ContentChildren, QueryList, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomInputEmailComponent } from '../custom-input-email/custom-input-email.component';
import { CustomInputTextComponent } from '../custom-input-text/custom-input-text.component';
import { CustomInputCheckboxComponent } from '../custom-input-checkbox/custom-input-checkbox.component';
import { CustomSelectTitleComponent } from '../custom-select-title/custom-select-title.component';
import { CustomInputCountryPhoneComponent } from '@app/feature/forms/custom-input-country-phone/custom-input-country-phone.component';
import { CustomInputTextareaComponent } from '../custom-input-textarea/custom-input-textarea.component';
import { CustomSelectListComponent } from '@app/feature/forms/custom-select-list/custom-select-list.component';

@Component({
  selector: 'app-custom-form',
  template: ` <form [formGroup]="formGroup">
    <ng-content></ng-content>
  </form>`,
})
export class CustomFormComponent implements AfterContentInit {
  formGroup: FormGroup;
  @ContentChildren(CustomInputEmailComponent) emailInputs: QueryList<CustomInputEmailComponent>;
  @ContentChildren(CustomInputTextComponent) textInputs: QueryList<CustomInputTextComponent>;
  @ContentChildren(CustomInputCheckboxComponent) checkboxInputs: QueryList<CustomInputCheckboxComponent>;
  @ContentChildren(CustomSelectTitleComponent) titleSelects: QueryList<CustomSelectTitleComponent>;
  @ContentChildren(CustomInputCountryPhoneComponent) countryPhoneInputs: QueryList<CustomInputCountryPhoneComponent>;
  @ContentChildren(CustomInputTextareaComponent) textareaInputs: QueryList<CustomInputTextareaComponent>;
  @ContentChildren(CustomSelectListComponent) selectList: QueryList<CustomSelectListComponent>;
  @Output() formSubmitted = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {
    this.formGroup = this.fb.group({});
  }

  ngAfterContentInit() {
    this.emailInputs.forEach(input => {
      this.formGroup.addControl(input.controlName, input.control);
    });
    this.textInputs.forEach(input => {
      this.formGroup.addControl(input.controlName, input.control);
    });
    this.checkboxInputs.forEach(checkbox => {
      this.formGroup.addControl(checkbox.controlName, checkbox.control);
    });
    this.titleSelects.forEach(select => {
      this.formGroup.addControl(select.controlName, select.control);
    });
    this.countryPhoneInputs.forEach(input => {
      this.formGroup.addControl(input.controlName, input.formGroup);
    });
    this.textareaInputs.forEach(input => {
      this.formGroup.addControl(input.controlName, input.control);
    });
    this.selectList.forEach(input => {
      this.formGroup.addControl(input.controlName, input.control);
    });
  }

  submitForm() {
    if (this.formGroup.valid) {
      this.formSubmitted.emit(this.formGroup.value);
    } else {
      Object.keys(this.formGroup.controls).forEach(key => {
        const controlErrors = this.formGroup.get(key)?.errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(errorKey => {
            const errorValue = controlErrors[errorKey];
            console.error(
              `Control: ${key}, Error: ${errorKey}, Value: ${errorValue !== undefined ? JSON.stringify(errorValue) : 'undefined'}`
            );
          });
        }
      });
    }
  }
}
