import { tourLayout } from '@app/feature/session-tracking/models/experiment.state.model';

export class SetAllowInstalmentPayments {
  static readonly type = '[Experiment] Set Allow Instalment Payments';

  constructor(public payload: boolean) {}
}

export class SetShowTourSubNav {
  static readonly type = '[Experiment] Set Show Tour Sub-Nav';

  constructor(public payload: boolean) {}
}

export class SetTourLayout {
  static readonly type = '[Experiment] Set Tour Layout';

  constructor(public payload: tourLayout) {}
}
