import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { SetFacetFilter, SetSearchTerm, UnsetFacetFilter } from '@search/store/search.actions';
import { combineLatest, merge, Observable } from 'rxjs';
import { Navigate } from '@ngxs/router-plugin';
import { isPlatformBrowser } from '@angular/common';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SearchOption } from '@search/dto';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { SearchClient, SearchIndex } from 'algoliasearch/lite';
import { SEARCH_CLIENT_TOKEN } from '@search/services/clients/algoliasearch';
import { environment } from '@environments/environment';
import { min } from 'lodash';
import { SearchState } from '@search/store/search.state';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchInputComponent implements OnDestroy, OnInit {
  searchForm = new FormGroup({
    searchTerm: new FormControl(''),
  });
  subs = new SubSink();

  private searchIndex: SearchIndex;
  tourHits$: Observable<SearchOption[]>;
  countryHits$: Observable<SearchOption[]>;
  placeHits$: Observable<SearchOption[]>;
  @ViewChild('dropdownTrigger', { read: MatAutocompleteTrigger }) dropdownTrigger: MatAutocompleteTrigger;
  protected dropdownWidth: number;
  destinationPlaceholder: string;
  noResults$: Observable<boolean>;
  private selectedItem: SearchOption;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(SEARCH_CLIENT_TOKEN) private searchClient: SearchClient,
    private store: Store
  ) {}

  @HostListener('window:resize', ['$event'])
  setDropdownWidth() {
    this.dropdownWidth = min([window.innerWidth - 20, 500]);
  }

  ngOnInit(): void {
    this.setDropdownWidth();
    this.searchIndex = this.searchClient.initIndex(environment.searchConfig.algolia.toursIndex);
    if (isPlatformBrowser(this.platformId)) {
      this.tourHits$ = this.setupSearchForFacetValues('tourName');
      this.countryHits$ = this.setupSearchForFacetValues('tourCountries');
      this.placeHits$ = this.setupSearchForFacetValues('places');
      this.noResults$ = combineLatest([this.tourHits$, this.countryHits$, this.placeHits$]).pipe(
        map(([tours, countries, places]) => {
          return tours.length === 0 && countries.length === 0 && places.length === 0;
        })
      );
    }
    this.subs.add(
      this.store.select(SearchState.getSearchTerm).subscribe(searchTerm => {
        this.searchForm.setValue({ searchTerm });
      }),
      // we need to clear previously selected filters, so we compare with previously selected item and clear if necessary without
      // clobbering existing filters
      this.searchForm
        .get('searchTerm')
        .valueChanges.pipe(debounceTime(300))
        .subscribe(value => {
          this.store.dispatch(new SetSearchTerm(value));
          if (!this.selectedItem) {
            return;
          }
          if (value !== this.selectedItem?.text) {
            // console.log('Clearing filter', this.selectedItem);
            const [key, value] = [this.selectedItem.type, this.selectedItem.id];
            this.store.dispatch([new UnsetFacetFilter({ key, value })]);
            this.selectedItem = undefined;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  selectItem(evt: MatAutocompleteSelectedEvent) {
    const opt = evt.option.value as SearchOption;
    const key = opt.type;
    const value = opt.id;
    this.selectedItem = opt;
    if (key && value) {
      this.store.dispatch(new SetFacetFilter({ key, value }));
    }
    // this.store.dispatch([new SetSearchTerm(opt.text)]);
    this.searchForm.setValue({ searchTerm: opt.text });
    this.store.dispatch(
      new Navigate(['/search'], {
        searchTerm: this.searchForm.get('searchTerm').value,
        ...{ [key]: this.store.selectSnapshot(SearchState.getFacetFilterByKey(key)).values.join(',') ?? value },
      })
    );
  }

  setupSearchForFacetValues(facetName: string): Observable<SearchOption[]> {
    const initialValues$ = this.searchFacet(facetName);
    const search$ = this.searchForm
      .get('searchTerm')
      .valueChanges.pipe(switchMap(value => this.searchFacet(facetName, value)));
    return merge(initialValues$, search$);
  }

  navigateToSearchWithKeywordPreset() {
    const searchTerm = this.searchForm.get('searchTerm').value;
    this.dropdownTrigger.closePanel();
    this.store.dispatch(new SetSearchTerm(searchTerm));
    this.store.dispatch(new Navigate(['/search'], { searchTerm: searchTerm }));
  }

  private searchFacet(facetName: string, term = ''): Observable<SearchOption[]> {
    return fromPromise(this.searchIndex.searchForFacetValues(facetName, term, { maxFacetHits: 10 })).pipe(
      map(results => {
        return results.facetHits.map(hit => {
          return { type: facetName, text: hit.value, id: facetName + ':' + hit.value } as SearchOption;
        });
      })
    );
  }
}
