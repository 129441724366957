import { AfterContentInit, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-custom-select-title',
  templateUrl: './custom-select-title.component.html',
  styleUrls: ['../custom-form.scss'],
})
export class CustomSelectTitleComponent implements AfterContentInit {
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() validationMessage?: string;
  @Input() isWhiteBackground = true;
  control: FormControl;
  isOpen = false;

  ngAfterContentInit() {
    this.control = new FormControl('', Validators.required);
  }
}
