import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Step1Component } from '@app/feature/checkout/ui/pages/step1/step1.component';
import { Step2Component } from '@app/feature/checkout/ui/pages/step2/step2.component';
import { SuccessComponent } from '@app/feature/checkout/ui/pages/success/success.component';
import { PaymentsComponent } from '@app/feature/checkout/ui/pages/payments/payments.component';

const routes: Routes = [
  {
    path: 'step1',
    component: Step1Component,
  },
  { path: 'step2', component: Step2Component },
  { path: 'success', component: SuccessComponent },
  { path: 'payments', component: PaymentsComponent },
  { path: 'payments.html', component: PaymentsComponent }, //@fixme remove me on 2025/06/30
  { path: '', redirectTo: '/checkout/error', pathMatch: 'full' },
  { path: '**', redirectTo: '/checkout/error' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CheckoutRoutingModule {}
