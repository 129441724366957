import { CUSTOM_ELEMENTS_SCHEMA, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { AuthModule } from '@auth0/auth0-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { DropdownDirective } from './directives/dropdown.directive';
import { MenuHeightCalculationsDirective } from './directives/menu-height-calculations.directive';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './modules/static-page/layouts/home/home.component';
import { ContactUsComponent } from './modules/static-page/layouts/contact-us/contact-us.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { SharedModule } from './shared/shared-modules';
import { environment } from '@environments/environment';
import { BuilderPagesComponent } from '@app/shared/components/builder-pages/builder-pages.component';

/**
 * Import Amplitude & initiate
 * Import analytics
 */
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

/**
 * Registers swiper carousel plugin
 */
import { register } from 'swiper/element/bundle';
/**
 * Allows the use of "cdkScrollable" on any container element so that if any mat-autocomplete option boxes are open it will close them on scroll
 */
import { CloseScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { UiModule } from '@app/components/ui/ui.module';
import { SearchModule } from '@search/search.module';
import { SearchConfig } from '@search/dto';
import { ContentModule } from '@content/content.module';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { provideServerRendering } from '@angular/platform-server';
/**
 * Builder IO integration
 */
import { BuilderModule } from '@builder.io/angular';
import { PromotionModule } from './feature/promotion/promotion.module';
import { SessionTrackingModule } from './feature/session-tracking/session-tracking.module';
import { CustomFormModule } from '@app/feature/forms/custom-form.module';
import { CheckoutModule } from '@app/feature/checkout/checkout.module';

register();

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

/**
 * This changes Angular default encoding behaviour to allow "+" in the URL
 * instead of replacing it with the encoding for a space...
 *
 * Basically "+" by default becomes %20. %20 = " ".
 *
 * See https://stackoverflow.com/a/51535786
 * */
export default class CustomUrlSerializer implements UrlSerializer {
  private readonly _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    // Encode "+" to "%2B"
    url = url.replace(/\+/gi, '%2B');
    // Preserve trailing slash
    if (url.endsWith('/') && !url.endsWith('//')) {
      url = url.slice(0, -1);
    }
    // Use the default serializer.
    return this._defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree): string {
    let url = this._defaultUrlSerializer.serialize(tree).replace(/\+/gi, '%2B');
    if (url.includes('?') || url.includes('#')) {
      return url;
    } else if (tree?.root?.children?.['primary']) {
      url += '/';
    }
    return url;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DropdownDirective,
    MenuHeightCalculationsDirective,
    FooterComponent,
    HomeComponent,
    ContactUsComponent,
    LoaderComponent,
  ],
  imports: [
    BuilderPagesComponent,
    CustomFormModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    BuilderModule.forRoot(environment.builderIo.apiKey),
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: environment.auth0.redirect,
      },
    }),
    UiModule,
    NgOptimizedImage,
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    // NgxsStoragePluginModule.forRoot({ keys: [ContentState] }),
    NgxsRouterPluginModule.forRoot(),
    SearchModule.forFeature({
      paginationCfg: { defaultLimit: 10 },
      name: 'toursSearch',
      toursIndex: environment.searchConfig.algolia.toursIndex,
    } as SearchConfig),
    ContentModule,
    PromotionModule,
    CheckoutModule,
    SessionTrackingModule,
  ],
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
    provideServerRendering(),
    provideHttpClient(withFetch()),
  ],
  exports: [LoaderComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(@Inject(PLATFORM_ID) private readonly platformId: object) {
    if (isPlatformBrowser(this.platformId) && environment.amplitudeKey) {
      const sessionReplayTracking = sessionReplayPlugin({
        forceSessionTracking: true,
        sampleRate: 1,
      });
      amplitude.add(sessionReplayTracking);
      amplitude.init(environment.amplitudeKey, {
        serverZone: 'EU',
        autocapture: true,
      });
    }
  }
}
