import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { TrackingState } from './store/tracking.state';
import { ExperimentState } from './store/experiment.state';
import { UserDetailsState } from './store/user-details.state';

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxsModule.forFeature([TrackingState, ExperimentState, UserDetailsState])],
})
export class SessionTrackingModule {}
