import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import {
  ClearAllFiltersAndSearchTerm,
  ClearFacetFilters,
  ClearNumericFilters,
  ClearSearchResults,
  ClearSearchTerm,
  FetchSearchResults,
  InitialiseFacetFilterKey,
  InitialiseFilterKey,
  InitialiseNumericFilterKey,
  SetFacetFilter,
  SetFacetFiltersFromQueryParams,
  SetFacets,
  SetFilter,
  SetInitialFacetFilters,
  SetNumericFilter,
  SetNumericFiltersFromQueryParams,
  SetNumericFilterValues,
  SetSearchIndexSuffix,
  SetSearchResults,
  SetSearchTerm,
  SetSearchTermFromQueryParams,
  UnsetFacetFilter,
  UnsetFilter,
  UnsetNumericFilter,
} from './search.actions';
import {
  AlgoliaTourSearchResponse,
  FacetFilter,
  FacetFilterMap,
  FacetValueCount,
  FilterMap,
  KeyValuePair,
  LOGICAL_OPERATOR,
  SearchConfig,
} from '@search/dto';
import { SEARCH_CLIENT_TOKEN } from '@search/services/clients/algoliasearch';
import { Hit, SearchOptions } from 'instantsearch.js';
import { SearchClient, SearchIndex } from 'algoliasearch/lite';
import { compact, flatMap, join, partition, pick, uniq } from 'lodash';
import { GeoService } from '@app/services/geo.service';
import { filter } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { AmplitudeService } from '@app/services/amplitude.service';
import { environment } from '@environments/environment';

export const SEARCH_CONFIG_TOKEN = new InjectionToken<SearchConfig>('SEARCH_CONFIG_TOKEN');

export type SearchStateModel = {
  currentIndex: string;
  sorting?: string;
  totalResults?: number;
  searchTerm: string;
  facetFilters?: FacetFilterMap; // actual string:value strings that will be passed to facetFilters
  numericFilters?: FacetFilterMap; // actual string:value strings that will be passed to facetFilters
  filters?: FilterMap;
  facets?: Record<string, FacetValueCount>;
  results?: Hit[];
  rawResults?: AlgoliaTourSearchResponse;
};

@State<SearchStateModel>({
  name: 'search',
  defaults: {
    currentIndex: environment.searchConfig.algolia.toursIndex,
    sorting: 'default',
    searchTerm: '',
    facetFilters: {} as FacetFilterMap,
    numericFilters: {} as FacetFilterMap,
    filters: {} as FilterMap,
    results: [],
    facets: {},
    totalResults: 0,
    rawResults: {},
  } as SearchStateModel,
})
@Injectable()
export class SearchState implements NgxsOnInit {
  protected currencyCode: string;
  protected baseIndexName: string;

  constructor(
    @Inject(SEARCH_CONFIG_TOKEN) private config: SearchConfig,
    @Inject(SEARCH_CLIENT_TOKEN) private searchClient: SearchClient,
    @Inject(PLATFORM_ID) private platformId: object,
    private activatedRoute: ActivatedRoute,
    private geoService: GeoService,
    private amplitudeService: AmplitudeService
  ) {
    this.baseIndexName = this.config.toursIndex;
  }

  // Resolves the search index based on the current index name
  searchIndex(ctx: StateContext<SearchStateModel>): SearchIndex {
    const { currentIndex } = ctx.getState();
    return this.searchClient.initIndex(currentIndex || this.baseIndexName);
  }

  ngxsOnInit(ctx: StateContext<SearchStateModel>): void {
    ctx.patchState({ currentIndex: this.baseIndexName });
    if (isPlatformBrowser(this.platformId)) {
      // ensure that we set the filter on which currency so search results are provided for the correct currency only
      this.geoService.geoData.pipe(filter(value => !!value)).subscribe(data => {
        this.currencyCode = data.currency;
      });
    }
  }

  @Selector()
  static getState(state: SearchStateModel) {
    return state;
  }

  @Selector()
  static getSearchTerm(state: SearchStateModel) {
    return state.searchTerm;
  }

  @Selector()
  static getFacetFilters(state: SearchStateModel) {
    return state.facetFilters;
  }

  static getFacetByKey(key: string) {
    return createSelector([SearchState], (state: SearchStateModel) => {
      return state.facets[key];
    });
  }

  static getFacetFilterByKey(filterKey: string) {
    return createSelector([SearchState], (state: SearchStateModel) => {
      return state.facetFilters && state.facetFilters[filterKey];
    });
  }

  @Selector()
  static getFacets(state: SearchStateModel) {
    return state.facets;
  }

  @Selector()
  static getResults(state: SearchStateModel) {
    return state.results || [];
  }

  @Action(SetSearchTerm)
  async setSearchTerm(ctx: StateContext<SearchStateModel>, { term }: SetSearchTerm) {
    ctx.patchState({ searchTerm: term });
    ctx.dispatch(new FetchSearchResults());
  }

  @Action(SetInitialFacetFilters)
  setInitialFacetFilters(ctx: StateContext<SearchStateModel>, { facets, numeric }: SetInitialFacetFilters) {
    const facetFilters = {} as FacetFilterMap;
    const numericFilters = {} as FacetFilterMap;

    facets.forEach(key => {
      const value = key.value.split(',');
      facetFilters[key.key] = { values: value, operator: key.operator };
    });

    numeric.forEach(key => {
      const value = key.value.split(',');
      numericFilters[key.key] = { values: value, operator: key.operator };
    });

    ctx.patchState({ facetFilters, numericFilters });

    ctx.dispatch(new FetchSearchResults());
  }

  @Action(InitialiseFacetFilterKey)
  initialiseFacetFilterKey(
    ctx: StateContext<SearchStateModel>,
    { key, operator }: { key: string; operator: LOGICAL_OPERATOR }
  ) {
    const state = ctx.getState();
    const existing = state.facetFilters[key];
    if (!existing) {
      const queryParams = this.activatedRoute.snapshot.queryParams;
      if (queryParams[key]) {
        const values = queryParams[key].split(',');
        operator = queryParams[key + '-operator'] ?? operator;
        ctx.patchState({
          facetFilters: { ...state.facetFilters, [key]: { values, operator } },
        });
      } else {
        const facetFilters = { ...state.facetFilters, [key]: { values: [], operator } };
        ctx.patchState({ facetFilters });
      }
    } else if (existing.operator !== operator) {
      throwError(() => {
        new Error(
          `Facet filter key ${key} already exists with operator ${existing.operator} but was re-initialized with '${operator}'
         operator. You should use a different key to work side by side or initialize with consistent operator.`
        );
      });
    }
    return of(true);
  }

  @Action(SetFacetFilter)
  setFacetFilter(ctx: StateContext<SearchStateModel>, { key, value }: { key: string; value: string }) {
    const state = ctx.getState();

    this.amplitudeService.trackEvent('Set Facet Filter', { key, value });

    const existing = state.facetFilters[key];
    if (!existing) {
      ctx.patchState({
        facetFilters: { ...state.facetFilters, [key]: { values: [value], operator: LOGICAL_OPERATOR.AND } },
      });
    } else {
      const values = uniq([...existing.values, value]);
      const facetFilters = { ...state.facetFilters, [key]: { ...existing, values } };
      ctx.patchState({ facetFilters });
    }

    ctx.dispatch(new FetchSearchResults());
  }

  @Action(UnsetFacetFilter)
  unsetFacetFilter(ctx: StateContext<SearchStateModel>, { key, value }: KeyValuePair) {
    const state = ctx.getState();
    // It seems strange to not simply unset via the key alone, but there can be many facet values that could be set
    // at the same time. So we must do so by key and value pair.
    this.amplitudeService.trackEvent('Unset Facet Filter', { key, value });
    const filter = state.facetFilters[key];
    if (!filter) {
      return;
    }
    const values = filter.values.filter((f: string) => f !== `${value}`);
    const facetFilters = { ...state.facetFilters, [key]: { ...filter, values } };
    ctx.patchState({ facetFilters });
    ctx.dispatch(new FetchSearchResults());
  }

  @Action(SetFilter)
  setFilter(ctx: StateContext<SearchStateModel>, { key, value }: KeyValuePair) {
    const state = ctx.getState();
    const existing = state.filters[key];
    if (!existing) {
      ctx.patchState({
        filters: { ...state.filters, [key]: { values: [value], operator: LOGICAL_OPERATOR.AND } },
      });
    } else {
      const values = uniq([...existing.values, value]);
      const filters = { ...state.filters, [key]: { ...existing, values } };
      ctx.patchState({ filters });
    }
    ctx.dispatch(new FetchSearchResults());
  }

  @Action(UnsetFilter)
  unsetFilter(ctx: StateContext<SearchStateModel>, { key, value }: KeyValuePair) {
    const state = ctx.getState();
    const filter = state.filters[key];
    if (!filter) {
      return;
    }
    const values = filter.values.filter((f: string) => f !== `${value}`);
    const filters = { ...state.filters, [key]: { ...filter, values } };
    ctx.patchState({ filters });
    ctx.dispatch(new FetchSearchResults());
  }

  @Action(InitialiseFilterKey)
  initialiseFilterKey(
    ctx: StateContext<SearchStateModel>,
    { key, operator }: { key: string; operator: LOGICAL_OPERATOR }
  ) {
    const state = ctx.getState();
    const existing = state.filters[key];
    if (!existing) {
      const filters = { ...state.filters, [key]: { values: [], operator } };
      ctx.patchState({ filters });
    } else if (existing.operator !== operator) {
      throw new Error(
        `Filter key ${key} already exists with operator ${existing.operator} but was re-initialized with '${operator}'
         operator. You should use a different key to work side by side or initialize with consistent operator.`
      );
    }
  }

  @Action(InitialiseNumericFilterKey)
  initialiseNumericFilterKey(
    ctx: StateContext<SearchStateModel>,
    { key, operator }: { key: string; operator: LOGICAL_OPERATOR }
  ) {
    const state = ctx.getState();
    const existing = state.numericFilters[key];
    if (!existing) {
      const queryParams = this.activatedRoute.snapshot.queryParams;
      if (queryParams[key]) {
        const values = queryParams[key].split(',');
        operator = queryParams[key + '-operator'] ?? operator;
        ctx.patchState({
          numericFilters: { ...state.numericFilters, [key]: { values, operator } },
        });
      } else {
        const numericFilters = { ...state.numericFilters, [key]: { values: [], operator } };
        ctx.patchState({ numericFilters });
      }
    } else if (existing.operator !== operator) {
      throw new Error(
        `Numeric filter key ${key} already exists with operator ${existing.operator} but was re-initialized with '${operator}'
         operator. You should use a different key to work side by side or initialize with consistent operator.`
      );
    }
  }

  @Action(SetNumericFilter)
  setNumericFilter(ctx: StateContext<SearchStateModel>, { key, value }: { key: string; value: string }) {
    const state = ctx.getState();
    this.amplitudeService.trackEvent('Set Numeric Filter', { key, value });

    const existing = state.numericFilters[key];
    if (!existing) {
      ctx.patchState({
        numericFilters: { ...state.numericFilters, [key]: { values: [value], operator: LOGICAL_OPERATOR.AND } },
      });
    } else {
      const values = uniq([...existing.values, value]);
      const numericFilters = { ...state.numericFilters, [key]: { ...existing, values } };
      ctx.patchState({ numericFilters });
    }
    ctx.dispatch(new FetchSearchResults());
  }

  @Action(SetNumericFilterValues)
  setNumericFilterValues(ctx: StateContext<SearchStateModel>, { key, values }: { key: string; values: string[] }) {
    const state = ctx.getState();
    this.amplitudeService.trackEvent('Set Numeric Filter Values', { key, values });
    const existing = state.numericFilters[key];
    if (!existing) {
      ctx.patchState({
        numericFilters: { ...state.numericFilters, [key]: { values, operator: LOGICAL_OPERATOR.AND } },
      });
    } else {
      const newValues = uniq([...existing.values, ...values]);
      const numericFilters = { ...state.numericFilters, [key]: { ...existing, values: newValues } };
      ctx.patchState({ numericFilters });
    }
    ctx.dispatch(new FetchSearchResults());
  }

  @Action(UnsetNumericFilter)
  unsetNumericFilter(ctx: StateContext<SearchStateModel>, { key, value }: KeyValuePair) {
    const state = ctx.getState();
    this.amplitudeService.trackEvent('Unset Numeric Filter', { key, value });
    // It seems strange to not simply unset via the key alone, but there can be many facet values that could be set
    // at the same time. So we must do so by key and value pair.
    const filter = state.numericFilters[key];
    if (!filter) {
      return;
    }
    const numericFilters = { ...state.numericFilters, [key]: { ...filter, values: [] } };
    ctx.patchState({ numericFilters });
    ctx.dispatch(new FetchSearchResults());
  }

  @Action(SetSearchResults)
  setSearchResults(ctx: StateContext<SearchStateModel>, { results, page }: SetSearchResults) {
    if (page === 0) {
      ctx.patchState({ results });
      return;
    } else {
      const allResults = [...ctx.getState().results, ...results];
      ctx.patchState({ results: allResults });
    }
  }

  @Action(ClearSearchResults)
  clearSearchResults(ctx: StateContext<SearchStateModel>) {
    ctx.patchState({ results: [] });
  }

  @Action(ClearAllFiltersAndSearchTerm)
  clearAllFiltersAndSearchTerm(ctx: StateContext<SearchStateModel>) {
    const facetFilters = {} as FacetFilterMap;
    const existingFacetFilters = ctx.getState().facetFilters;
    this.amplitudeService.trackEvent('Clear All Filters and Search Term');
    Object.keys(existingFacetFilters).forEach(key => {
      facetFilters[key] = { values: [], operator: existingFacetFilters[key].operator };
    });
    const numericFilters = {} as FacetFilterMap;
    const existingNumericFilters = ctx.getState().numericFilters;
    Object.keys(existingNumericFilters).forEach(key => {
      numericFilters[key] = { values: [], operator: existingNumericFilters[key].operator };
    });

    const filters = {} as FilterMap;
    const existingFilters = ctx.getState().filters;
    Object.keys(existingFilters).forEach(key => {
      filters[key] = { values: [], operator: existingFilters[key].operator };
    });

    ctx.patchState({ facetFilters, numericFilters, searchTerm: '', filters });
    ctx.dispatch(new FetchSearchResults());
  }

  @Action(ClearFacetFilters)
  clearFacetFilters(ctx: StateContext<SearchStateModel>) {
    ctx.patchState({ facetFilters: {} as FacetFilterMap });
    ctx.dispatch(new FetchSearchResults());
  }

  @Action(ClearNumericFilters)
  clearNumericFilters(ctx: StateContext<SearchStateModel>) {
    ctx.patchState({ numericFilters: {} as FacetFilterMap });
    ctx.dispatch(new FetchSearchResults());
  }

  @Action(ClearSearchTerm)
  clearSearchTerm(ctx: StateContext<SearchStateModel>) {
    ctx.patchState({ searchTerm: '' });
    ctx.dispatch(new FetchSearchResults());
  }

  @Action(FetchSearchResults)
  async fetchSearchResults(ctx: StateContext<SearchStateModel>, { page }: FetchSearchResults) {
    // console.log('Fetching search results for page: ', page);
    const state = ctx.getState();
    // compile facet filtering
    const orFacetFilters = Object.values(state.facetFilters)
      .filter((item: FacetFilter) => item.operator === LOGICAL_OPERATOR.OR)
      .map(f => f.values);
    const andFacetFilters = Object.values(state.facetFilters)
      .filter((item: FacetFilter) => item.operator === LOGICAL_OPERATOR.AND)
      .flatMap(f => f.values);

    // add currency code to the search for all queries
    andFacetFilters.push(`currencyCode:${this.currencyCode}`);

    const facetFilters = compact([...flatMap(andFacetFilters), ...orFacetFilters.map(f => compact(f))]);

    // compile numeric filtering
    const numericOrFilters = Object.values(state.numericFilters)
      .filter((item: FacetFilter) => item.operator === LOGICAL_OPERATOR.OR && item.values.length > 0)
      .map(f => f.values);
    const numericAndFilters = Object.values(state.numericFilters)
      .filter((item: FacetFilter) => item.operator === LOGICAL_OPERATOR.AND && item.values.length > 0)
      .map(f => f.values);

    const numericFilters = flatMap(compact([...flatMap(numericAndFilters), ...numericOrFilters.map(f => compact(f))]));
    let searchOptions = {
      facets: ['*'],
      facetFilters,
      numericFilters,
      page,
    };

    const [andFilters, orFilters] = partition(Object.values(state.filters), f => f.operator === LOGICAL_OPERATOR.AND);
    const filters = join(
      compact([
        join(
          flatMap(andFilters, item => item.values),
          ' AND '
        ),
        join(
          flatMap(orFilters, item => item.values),
          ' OR '
        ),
      ]),
      ' AND '
    );
    if (filters) {
      searchOptions = { ...searchOptions, filters } as SearchOptions;
    }
    const res = await this.searchIndex(ctx).search(ctx.getState().searchTerm, searchOptions);
    // patch the results based on if we have fetched the first page or not
    const rawResults =
      page === 0 ? res : { ...state.rawResults, ...res, hits: [...state.rawResults.hits, ...res.hits] };
    ctx.patchState({ totalResults: res.nbHits, rawResults });

    ctx.dispatch([new SetSearchResults(res.hits, page), new SetFacets(res.facets)]);
    return true;
  }

  @Action(SetFacets)
  setFacets(ctx: StateContext<SearchStateModel>, { facets }: SetFacets) {
    ctx.patchState({ facets });
  }

  @Selector()
  static getResultSlugs(state: SearchStateModel): string[] {
    return state.results.map(result => result['slug'] as string);
  }

  @Selector()
  static getTotalResults(state: SearchStateModel) {
    return state.totalResults;
  }

  static getNumericFilterByKey(filterKey: string) {
    return createSelector([SearchState], (state: SearchStateModel) => {
      return state.numericFilters[filterKey];
    });
  }

  @Selector()
  static getCurrentSearchTrackingData(state: SearchStateModel) {
    return {
      ...pick(state, ['searchTerm', 'facetFilters', 'numericFilters', 'sorting']),
    };
  }

  @Selector()
  static getCurrentPage(state: SearchStateModel): number {
    return state.rawResults?.page || 0;
  }

  @Action(SetFacetFiltersFromQueryParams)
  setFacetFiltersFromQueryParams(ctx: StateContext<SearchStateModel>) {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const facetFilters: FacetFilterMap = {};
    const state = ctx.getState();
    Object.keys(state.facetFilters).forEach(key => {
      if (queryParams[key]) {
        const values = queryParams[key].split(',');
        const operator = queryParams[key + '-operator'] ?? state.facetFilters[key].operator;
        facetFilters[key] = { values, operator };
      }
    });
    ctx.patchState({
      facetFilters: { ...state.facetFilters, ...facetFilters },
    });
  }

  @Action(SetNumericFiltersFromQueryParams)
  setNumericFiltersFromQueryParams(ctx: StateContext<SearchStateModel>) {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const numericFilters: FacetFilterMap = {};
    const state = ctx.getState();
    Object.keys(state.numericFilters).forEach(key => {
      if (queryParams[key]) {
        const values = queryParams[key].split(',');
        const operator = queryParams[key + '-operator'] ?? state.numericFilters[key].operator;
        numericFilters[key] = { values, operator };
      }
    });
    ctx.patchState({
      numericFilters: { ...state.numericFilters, ...numericFilters },
    });
  }

  @Action(SetSearchTermFromQueryParams)
  setSearchTermFromQueryParams(ctx: StateContext<SearchStateModel>) {
    const searchTerm = this.activatedRoute.snapshot.queryParams['searchTerm'];
    if (searchTerm) {
      ctx.patchState({ searchTerm });
    }
  }

  @Selector()
  static getAllFiltering(
    state: SearchStateModel
  ): Pick<SearchStateModel, 'facetFilters' | 'numericFilters' | 'searchTerm' | 'sorting'> {
    const { numericFilters, facetFilters, searchTerm, sorting } = state;
    return { numericFilters, facetFilters, searchTerm, sorting };
  }

  @Action(SetSearchIndexSuffix)
  setSearchIndexSuffix(ctx: StateContext<SearchStateModel>, { suffix }: { suffix: string }) {
    ctx.patchState({ sorting: suffix });
    if (suffix === 'default') {
      ctx.patchState({ currentIndex: this.baseIndexName });
      return;
    }
    ctx.patchState({ currentIndex: `${this.baseIndexName}_${suffix}` });
  }

  @Selector()
  static getNumericFilters(state: SearchStateModel) {
    return state.numericFilters;
  }

  static hasFilterKey(filterKey: string) {
    return createSelector([SearchState], (state: SearchStateModel) => {
      return !!state.facetFilters[filterKey];
    });
  }

  static getFilterByKey(filterKey: string) {
    return createSelector([SearchState], (state: SearchStateModel) => {
      return state.filters[filterKey];
    });
  }
}
