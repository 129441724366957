<ng-container *ngIf="rating && location && style === 'stars'">
  <span class="review-ratings-wrapper" matTooltip="{{ rating }} star average" matTooltipShowDelay="500">
    <span class="review-ratings-container" [innerHTML]="createRatings(rating, location)" [ngClass]="size"></span>
  </span>
</ng-container>

<ng-container *ngIf="rating && location && style === 'circles'">
  <span class="review-ratings-wrapper" matTooltip="{{ rating }} average" matTooltipShowDelay="500">
    <span class="review-ratings-container" [innerHTML]="createRatings(rating, location, 'circle')" [ngClass]="size">
    </span>
  </span>
</ng-container>
