<ng-container>
  <section [id]="sectionId ? sectionId : null" class="tour-tab-carousel" [ngClass]="{ 'no-bg': backgroundTransparent }">
    <div class="tour-tab-head" *ngIf="carouselData.length > 1">
      <div class="container-fluid d-md-none">
        <div class="row">
          <div class="col-12 tabs-carousel-col">
            <!--The clickable categories MOBILE "tourTabsCarousel"-->
            <swiper-container
              #tourTabsCarousel
              init="false"
              appSwiper
              [config]="tourTabsCarouselConfig"
              class="tour-tabs-carousel"
              *ngIf="checkedCampaign">
              <swiper-slide *ngFor="let tab of carouselData; let i = index" class="swiper-slide">
                <app-tabs
                  [text]="carouselData[i].tabTitle"
                  [index]="i"
                  [isActiveSlide]="i === tabSwiperIndex"
                  [total]="carouselData.length"
                  [isPromo]="carouselData[i].isFeatured"
                  (tabSelected)="onTabSelect(i)"
                  [campaignColourConfig]="campaignTab"></app-tabs>
              </swiper-slide>
            </swiper-container>
            <div class="tour-tabs-carousel-next">
              <img
                src="https://images.ctfassets.net/huwbudysxepr/6XmogMEmmMMVPILOCJ0z0c/b0d3da9e17cdd3eb98b466d1759a1b05/Chev-Right.svg"
                alt="Next" />
            </div>
            <div class="tour-tabs-carousel-prev">
              <img
                src="https://images.ctfassets.net/huwbudysxepr/6sDV39UTfvCReELTLVTVrg/8b5b6b12d99ab29d1643d104c234a5e2/Chev-Left.svg"
                alt="Prev" />
            </div>
          </div>
        </div>
      </div>
      <!--The clickable categories DESKTOP "tourTabsCarousel"-->
      <app-tabs
        class="d-none d-md-inline-block"
        *ngFor="let tab of carouselData; let i = index"
        [isActiveSlide]="i === tabSwiperIndex"
        [text]="carouselData[i].tabTitle"
        [index]="i"
        [total]="carouselData.length"
        [isPromo]="carouselData[i].isFeatured"
        (tabSelected)="onTabSelect(i)"
        [campaignColourConfig]="campaignTab"></app-tabs>
    </div>
    <section class="container-fluid tour-tab-wrapper" [ngClass]="{ 'center-heading': headingCentered }">
      @if (!carouselData[tabSwiperIndex]?.isCustom) {
        <div class="container-fluid tour-tab-body">
          <div class="row">
            <div class="col-12 tab-top">
              <div class="tab-info">
                <h2
                  [ngClass]="headerClass ? headerClass : 'red'"
                  [innerHTML]="
                    carouselData[tabSwiperIndex]?.tabHeader
                      | phraseClassTextHighlight: this.headingHighlightPhrase : 'heading-highlight'
                  "
                  *ngIf="carouselData[tabSwiperIndex]?.tabHeader?.length"
                  aria-hidden="true"></h2>
                <ng-container *ngIf="!carouselData[tabSwiperIndex]?.tabHeader">
                  <h2
                    [ngClass]="headerClass ? headerClass : 'red'"
                    [innerHTML]="
                      carouselData[tabSwiperIndex]?.tabTitle
                        | phraseClassTextHighlight: this.headingHighlightPhrase : 'heading-highlight'
                    ">
                    {{ carouselData[tabSwiperIndex]?.tabTitle }}
                  </h2>
                </ng-container>
                <div
                  class="tab-description"
                  [ngClass]="descriptionClass ? descriptionClass : ''"
                  [innerHTML]="carouselData[tabSwiperIndex]?.tabDescription"></div>
              </div>
              <div class="map-toggle-wrapper" *ngIf="carouselType === 'tour'">
                <strong>Map View</strong>
                <app-toggle-switch [(checked)]="showMapMode" (checkedChange)="mapModeToggle()"></app-toggle-switch>
              </div>
            </div>
          </div>
        </div>
      }
      <div class="container-fluid tour-tab-body">
        <div class="tour-swiper-wrapper" [ngClass]="{ isLoading: tabToursLoading }">
          <div class="tab-loader" *ngIf="tabToursLoading">
            <app-loader-placeholer></app-loader-placeholer>
          </div>
          <div class="tab-tours" *ngIf="!tabToursLoading">
            @if (!carouselData[tabSwiperIndex]?.isCustom) {
              <div class="tour-tab-swiper">
                <swiper-container
                  class="tour-tab-swiper-carousel"
                  init="false"
                  appSwiper
                  [config]="swiperToursConfig"
                  #tabbedTourCarousel>
                  <ng-container
                    *ngIf="
                      carouselData[tabSwiperIndex]?.toursData && carouselData[tabSwiperIndex]?.toursData.length;
                      else noTabItems
                    ">
                    <swiper-slide
                      class="swiper-slide"
                      *ngFor="let tour of carouselData[tabSwiperIndex]?.toursData; let i = index">
                      <ng-container *ngIf="carouselType === 'booking'; else defaultCard">
                        <app-tour-card
                          [bookingData]="tour.bookingData"
                          [geoData]="geo"
                          [mapView]="showMapMode"
                          [cardType]="carouselType"
                          cardWidth="vertical"
                          cardSection="tabbed-tour-ribbon"
                          *ngIf="!tour.contentful && !tour.lemax">
                        </app-tour-card>
                        <app-tour-card
                          [bookingData]="tour.bookingData"
                          [data]="tour.contentful"
                          [priceData]="tour.lemax"
                          [geoData]="geo"
                          [mapView]="showMapMode"
                          [cardType]="carouselType"
                          cardWidth="vertical"
                          cardSection="tabbed-tour-ribbon"
                          *ngIf="tour.contentful && tour.lemax">
                        </app-tour-card>
                      </ng-container>
                      <ng-template #defaultCard>
                        <app-tour-card
                          [data]="tour.contentful"
                          [priceData]="tour.lemax"
                          [geoData]="geo"
                          [mapView]="showMapMode"
                          [cardType]="carouselType"
                          cardWidth="vertical"
                          cardSection="tabbed-tour-ribbon">
                        </app-tour-card>
                      </ng-template>
                    </swiper-slide>
                  </ng-container>
                  <ng-template #noTabItems>
                    <div class="no-tab-items">
                      <h3>No {{ carouselData[tabSwiperIndex]?.tabHeader | lowercase }}</h3>
                      <p>Browse our top tours and book now for the adventure of a lifetime!</p>
                      <a
                        class="button button-yellow w-unset"
                        routerLink=""
                        fragment="home-page-popular-tours"
                        title="View popular tours">
                        View most popular tours
                      </a>
                    </div>
                  </ng-template>
                </swiper-container>
              </div>
            }
            @if (carouselData[tabSwiperIndex].isCustom) {
              <ng-container *ngTemplateOutlet="customContent; context: { tab: carouselData[tabSwiperIndex] }">
              </ng-container>
            }
            <ng-container
              *ngIf="
                carouselType === 'tour' &&
                (carouselData[tabSwiperIndex]?.viewAllText?.length ||
                  carouselData[tabSwiperIndex]?.viewAllRoute?.length)
              ">
              <div
                class="tour-tab-controls"
                *ngIf="
                  carouselData[tabSwiperIndex]?.viewAllText?.length &&
                  carouselData[tabSwiperIndex]?.viewAllRoute?.length
                ">
                <a
                  class="button section-cta w-unset d-none d-md-inline-block"
                  [ngClass]="viewAllButtonClass ? viewAllButtonClass : 'button-yellow'"
                  [href]="carouselData[tabSwiperIndex]?.viewAllRoute | extractPathAndParams"
                  (click)="trackViewAllTours()">
                  {{ carouselData[tabSwiperIndex]?.viewAllText }}
                </a>
              </div>
              <div
                class="tour-tab-controls"
                *ngIf="
                  !carouselData[tabSwiperIndex]?.viewAllText?.length &&
                  carouselData[tabSwiperIndex]?.viewAllRoute?.length
                ">
                <a
                  class="button button-yellow section-cta w-unset d-none d-md-inline-block"
                  [href]="carouselData[tabSwiperIndex]?.viewAllRoute | extractPathAndParams"
                  (click)="trackViewAllTours()">
                  View All Tours
                </a>
              </div>
              <div
                class="tour-tab-controls"
                *ngIf="
                  carouselData[tabSwiperIndex]?.viewAllText?.length &&
                  !carouselData[tabSwiperIndex]?.viewAllRoute?.length
                ">
                <a
                  class="button button-yellow section-cta w-unset d-none d-md-inline-block"
                  routerLink="search"
                  (click)="trackViewAllTours()">
                  {{ carouselData[tabSwiperIndex]?.viewAllText }}
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div
        class="container-fluid tabbed-tour-carousel-controls"
        [ngClass]="{
          'has-all-button':
            carouselType === 'tour' &&
            (carouselData[tabSwiperIndex]?.viewAllText?.length || carouselData[tabSwiperIndex]?.viewAllRoute?.length),
        }">
        <div class="row">
          <div class="col-12">
            <div
              class="tabbed-tour-carousel-pagination"
              [ngClass]="{
                'hide-controls':
                  !carouselData[tabSwiperIndex]?.toursData || !carouselData[tabSwiperIndex]?.toursData.length,
              }">
              <div class="tabbed-tour-swiper-prev" #prevButton>
                <app-round-buttons
                  icon="chevron-left"
                  color="blue"
                  customClass="filter-swiper-prev"
                  (buttonSelected)="trackTourRibbonAmplitude('Tour Slider slide previous')"></app-round-buttons>
              </div>
              <div class="tabbed-tour-swiper-next" #nextButton>
                <app-round-buttons
                  icon="chevron-right"
                  color="blue"
                  customClass="filter-swiper-next"
                  (buttonSelected)="trackTourRibbonAmplitude('Tour Slider slide next')"></app-round-buttons>
              </div>
              <div
                class="d-block d-md-none"
                *ngIf="
                  carouselType === 'tour' &&
                  (carouselData[tabSwiperIndex]?.viewAllText?.length ||
                    carouselData[tabSwiperIndex]?.viewAllRoute?.length)
                ">
                <a
                  class="button section-cta w-unset mobile-section-cta"
                  [ngClass]="viewAllButtonClass ? viewAllButtonClass : 'button-yellow'"
                  [href]="carouselData[tabSwiperIndex]?.viewAllRoute | extractPathAndParams"
                  (click)="trackViewAllTours()"
                  *ngIf="
                    carouselData[tabSwiperIndex]?.viewAllText?.length &&
                    carouselData[tabSwiperIndex]?.viewAllRoute?.length
                  ">
                  {{ carouselData[tabSwiperIndex]?.viewAllText }}
                </a>
                <a
                  class="button button-yellow section-cta w-unset mobile-section-cta"
                  [href]="carouselData[tabSwiperIndex]?.viewAllRoute | extractPathAndParams"
                  (click)="trackViewAllTours()"
                  *ngIf="
                    !carouselData[tabSwiperIndex]?.viewAllText?.length &&
                    carouselData[tabSwiperIndex]?.viewAllRoute?.length
                  ">
                  View All Tours
                </a>
                <a
                  class="button button-yellow section-cta w-unset mobile-section-cta"
                  routerLink="search"
                  (click)="trackViewAllTours()"
                  *ngIf="
                    carouselData[tabSwiperIndex]?.viewAllText?.length &&
                    !carouselData[tabSwiperIndex]?.viewAllRoute?.length
                  ">
                  {{ carouselData[tabSwiperIndex]?.viewAllText }}
                </a>
              </div>
            </div>
          </div>
          @if (!carouselData[tabSwiperIndex]?.isCustom) {
            <div class="col-12" *ngIf="carouselType === 'booking'">
              <p class="footer-note">
                If your booking doesn't reflect contact us here
                <a [routerLink]="['/contact-us']" title="Contact us"> here </a>
              </p>
            </div>
          }
        </div>
      </div>
    </section>
  </section>
</ng-container>
