import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { builderSdk } from './builder-sdk-injection-token';
import { Builder } from '@builder.io/sdk';
import { Store } from '@ngxs/store';

export interface PromoCodeInvalidation {
  promoCodeValue: string;
}

export interface PromoMetaData {
  activeCampaign: string;
  invalidatedPromoCodes: Array<PromoCodeInvalidation>;
}

@Injectable()
export class PromotionService {
  constructor(
    private route: ActivatedRoute,
    @Inject(builderSdk) private builder: Builder,
    private store: Store
  ) {}

  async capturePromoCode(): Promise<string | undefined> {
    return this.route.snapshot.queryParams['promoCode'] as string;
  }

  async getPromoCodeMetaData(): Promise<PromoMetaData> {
    const data = await this.builder.get('promo-code-meta-data').promise();
    // console.log('builder data promo-code-meta-data: ', data?.data);
    return data?.data;
  }
}
