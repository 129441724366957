import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';

import { PagesConfig } from '../configs/static-pages.config';
import { HomeModel } from '../models/home.model';
import { ContactUSModel } from '../models/contact.model';
import { StaticModel } from '../models/static.model';

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  private config = new PagesConfig();
  error = new Subject<string>();
  public pageData: HomeModel | ContactUSModel | StaticModel;

  constructor(private http: HttpClient) {}

  cvSubmit(formData) {
    return this.http.post(this.config.urls.postCV(), formData);
  }

  agentSubmit(formData) {
    return this.http.post(this.config.urls.agentSubmission(), formData);
  }

  discoSubmit(formData) {
    return this.http.post(this.config.urls.discoSubmission(), formData);
  }

  getHomePage(slug) {
    return this.http.get(this.config.urls.getHomePage(slug)).pipe(
      map((responseData: HomeModel | ContactUSModel | StaticModel) => {
        this.pageData = responseData;
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  getPage(slug) {
    return this.http.get(this.config.urls.getPage(slug)).pipe(
      map((responseData: HomeModel | ContactUSModel | StaticModel) => {
        this.pageData = responseData;
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }
}
