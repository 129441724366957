import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { CheckoutStateModel, IPaymentMethod, TourItem } from '@app/feature/checkout/dto/types';
import { SubSink } from 'subsink';
import { Store } from '@ngxs/store';
import { CheckoutState } from '@app/feature/checkout/store/checkout.state';
import { CheckoutService } from '@app/feature/checkout/services/checkout.service';
import { GeoModel } from '@models/geo.model';
import { map, take } from 'rxjs/operators';
import {
  SetCheckoutProcessing,
  SetFirstPaymentDate,
  SetSelectedPaymentMethod,
  UpdatePaymentMethodData,
} from '@app/feature/checkout/store/checkout.actions';
import { ExperimentState } from '@app/feature/session-tracking/store/experiment.state';

@Component({
  selector: 'app-payment-selector',
  templateUrl: './payment-selector.component.html',
  styleUrls: ['./payment-selector.component.scss', '../../styles/checkout-common.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentSelectorComponent implements OnInit {
  @Output() processingChange = new EventEmitter<boolean>();
  @Output() instalmentLoadingChange = new EventEmitter<boolean>();
  @Output() paymentMethodSelected = new EventEmitter<any>();

  experimentState$: Observable<any>;
  selectedPaymentMethod: string | null = null;
  state$: Observable<CheckoutStateModel>;
  subs: SubSink = new SubSink();
  cartItems: TourItem[];
  numPax: number;
  departurePrice: number;
  reservationData: any;
  geo: GeoModel;
  optionalExtrasTotal$: Observable<number>;
  paymentMethods$: Observable<IPaymentMethod[]>;
  instalmentLoading = false;
  depositValue$: Observable<number>;
  adjustedDeposit$: Observable<number>;
  orderedPaymentMethods$: Observable<IPaymentMethod[]>;
  numberOfInstalmentMonths$: Observable<number>;
  notProcessingOrLoading$: Observable<boolean>;
  processingOrLoading$: Observable<boolean>;
  selectedPaymentMethod$: Observable<IPaymentMethod>;

  notProcessingOrLoading: boolean;
  orderedPaymentMethods: IPaymentMethod[];

  showInstalmentCalendar = false;

  constructor(
    private store: Store,
    private checkoutService: CheckoutService
  ) {}

  ngOnInit() {
    this.state$ = this.store.select(CheckoutState.getState);
    this.paymentMethods$ = this.store.select(CheckoutState.paymentMethods);
    this.orderedPaymentMethods$ = this.store.select(CheckoutState.orderedPaymentMethods);
    this.optionalExtrasTotal$ = this.store.select(CheckoutState.optionalExtrasTotal);
    this.notProcessingOrLoading$ = this.store.select(CheckoutState.notProcessingOrLoading);
    this.processingOrLoading$ = this.store.select(CheckoutState.isProcessingOrLoading);
    this.experimentState$ = this.store.select(ExperimentState.getAllowInstalmentPayments);
    this.selectedPaymentMethod$ = this.store.select(CheckoutState.selectedPaymentMethod);

    this.depositValue$ = this.paymentMethods$.pipe(
      map(methods => methods.find(method => method.id === 'deposit').data['amount'])
    );

    this.adjustedDeposit$ = this.paymentMethods$.pipe(
      map(methods => {
        const instalmentData = methods.find(method => method.id === 'instalment')?.data;
        if (instalmentData) {
          const lastObject = Object.values(instalmentData).pop();
          return lastObject['adjustedDeposit'] || 0;
        }
        return null;
      })
    );

    this.subs.sink = this.notProcessingOrLoading$.subscribe(notProcessingOrLoading => {
      this.notProcessingOrLoading = notProcessingOrLoading;
    });

    this.subs.sink = this.orderedPaymentMethods$.subscribe(methods => {
      this.orderedPaymentMethods = methods;
    });

    this.numberOfInstalmentMonths$ = this.store.select(CheckoutState.numberOfInstalmentMonths);
    this.subs.sink = this.state$.pipe(take(1)).subscribe((state: CheckoutStateModel) => {
      this.cartItems = state?.cartItems;
      this.numPax = state?.cartItems[0]?.numPax;
      this.departurePrice = state?.cartItems[0]?.option?.departures[0]?.price?.amount;
      this.reservationData = state?.reservationData;
      this.geo = state?.geo;
      this.getInitPaymentTypeOptions();
    });
  }

  getInitPaymentTypeOptions() {
    this.setProcessing(true);
    this.store
      .dispatch([new SetFirstPaymentDate(), new UpdatePaymentMethodData()])
      .pipe(take(1))
      .subscribe(() => {
        const state = this.store.snapshot().checkout;
        this.selectedPaymentMethod = state.selectedPaymentMethod.id;
        this.setProcessing(false);
      });
    this.processingChange.emit(false);
  }

  /**
   * Without this we make http requests tp payment service for each change. This is bad
   * The trackBy function allows you to specify a unique identifier for each item in the list.
   * This helps Angular to track and identify items more efficiently,
   * reducing the need for re-rendering the entire list when changes occur.
   * @param index
   * @param method
   */
  trackByMethod(index: number, method: IPaymentMethod): string {
    return method.id;
  }

  selectPaymentMethod(method: IPaymentMethod) {
    this.store.dispatch(new SetSelectedPaymentMethod(method));
    this.selectedPaymentMethod = method.id;
    this.showInstalmentCalendar = false;
  }

  formatPrice(price: number) {
    if (this.geo) {
      return this.checkoutService.formatPrice(price, this.geo.currency_sign);
    }
    return price.toString();
  }

  setProcessing(processing: boolean) {
    this.store.dispatch(new SetCheckoutProcessing(processing));
  }

  protected readonly Object = Object;
}
