import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ToursConfig } from '../configs/tours.config';
import { Option, TourModelLemax } from '../models/tour.model';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngxs/store';
import { PromotionState } from '@app/feature/promotion/store/promotion.state';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContentPrices implements OnDestroy {
  private config = new ToursConfig();
  private destroy$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private store: Store
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * This function will check the store for a promo code and return it if it exists.
   * @private
   */
  private checkPromoCode(): Promise<string | null> {
    return firstValueFrom(
      this.store.select(PromotionState.getPromoCode).pipe(map((promoCode: string) => promoCode || null))
    );
  }

  /**
   * @param slug - 'great-britain'
   * @param currencyCode - "ZAR" / "GBP" etc
   *
   * This function will return a single tour object with contentful and lemax data.
   * It also checks store for promo code and appends it to the request if it exists.
   */
  async getSingleTour(slug: string, currencyCode: string) {
    const promoCode = await this.checkPromoCode();
    return this.http.get(this.config.urls.getSingleTour(slug, currencyCode, promoCode || undefined));
  }

  /**
   * Returns an array of tours, each tour has object for contentful (content) + lemax (prices).
   * @param slugs - array of slugs you want prices + contentful data for. ie ['europe-escape', 'europe-jewel']
   * @param currency - currency string unformatted ie: ZAR / USD / CAD etc etc
   */
  public async getMultipleSingleTours(slugs: string[], currency: string): Promise<TourModelLemax[]> {
    const promises = slugs.map(async slug => {
      try {
        const data = await (await this.getSingleTour(slug, currency)).toPromise();
        return Promise.resolve(data);
      } catch (e) {
        return Promise.resolve(null);
      }
    });

    const tours = await Promise.all(promises);
    return tours
      .filter(a => a && a.lemax && a.contentful)
      .map(tour => {
        tour.lemax.options = this.manipulateDepartures(tour.lemax.options, currency);
        return tour;
      });
  }

  /**
   * @param id - '85b4259b-ee3e-427c-b7bc-0e5c561bfb51'
   * @param currencyCode - "ZAR" / "GBP" etc
   */
  getSingleTourById(id: string, currencyCode: string) {
    return this.http.get(this.config.urls.getSingleTourById(id, currencyCode));
  }

  /**
   * Returns content and prices for a list of tour slugs
   * @param slugs - Array of slug strings ['great-britain', 'europe-escape']
   * @param currencyCode - "ZAR" / "GBP" etc
   */
  postTourCardSlugs(slugs: string[], currencyCode: string) {
    return this.http.post(this.config.urls.postTourCardSlugs(), {
      slugs: slugs,
      currencyCode: currencyCode,
    });
  }

  /**
   * for pulling values from lemax departure notes, used in custom note campaigns
   * @param note - string of departure note
   */
  private customNoteValues(note: string) {
    const regex = /\[([^]+?)]/g;
    const matches = note.match(regex);
    const resultArray = matches.map(match => match.slice(1, -1));
    return {
      img: resultArray[0],
      defaultBgColor: resultArray[1],
      defaultTextColor: resultArray[2],
      selectedBgColor: resultArray[3],
      selectedTextColor: resultArray[4],
    };
  }

  /**
   * Give a Lemax Options array, it will return an object of flattened departures and some useful properties for display purposes:
   * {
   *     departures: departures[], - for use on tour page + tour cards
   *     years: [], - for use on tour page dates / rates
   *     soldOut: true, - for use on tour page + tour cards
   *     lowestPriceDeparture: { - for use on tour page + tour cards
   *     price: '',
   *     fromPrice: ''
   *    }
   * }
   * @param options
   * @param currencyCode - "ZAR", "CAD", "USD" etc
   */
  manipulateDepartures(options: Option[], currencyCode: string) {
    const data = {
      flattenedDepartures: [],
      years: [],
      soldOut: true,
      departureNoteCampaign: false,
      campaignTour: false,
      campaignValues: {},
      lowestPriceDeparture: {
        price: '',
        fromPrice: '',
      },
    };

    //Some tours have no Options, meaning no prices, mark them as sold out
    if (!options) {
      data.soldOut = true;
      return data;
    }

    options.forEach((option: Option) => {
      if (option.departures) {
        option.departures.forEach((departure: any) => {
          let marketingPrice = '';
          if (departure.attributes && departure.attributes.marketing && departure.attributes.marketing[currencyCode]) {
            marketingPrice = departure.attributes.marketing[currencyCode];
          }

          //This is a hack to bypass a fix deployed by lemax. Please leave it in.
          delete departure.id;

          const dep = {
            ...option,
            ...departure,
            marketingPrice: marketingPrice,
            startDate: option.attributes.periodStart || '',
            endDate: option.attributes.periodEnd || '',
            depCode: option.attributes.code || '',
            campaignNoteDep: false,
            description: departure.attributes?.departureStatusDescription || '',
            hasDepartures: true,
            soldOut: option.soldOut,
          };

          /**
           * Special functionality, if the Lemax departure note contains a square bracket it is a "campaign departure / tour".
           * The brackets end up giving us an image and some colour values we can use front end (populated in lemax dep note)
           */
          if (dep.description.indexOf('[') !== -1) {
            data.campaignTour = true;
            dep.campaignNoteDep = true;
            data.campaignValues = this.customNoteValues(dep.description);
          }

          if (
            !option.soldOut &&
            ((data.lowestPriceDeparture.price === '' && data.lowestPriceDeparture.fromPrice === '') ||
              parseInt(String(option.priceFrom.amount)) < parseInt(data.lowestPriceDeparture.fromPrice))
          ) {
            data.soldOut = false;
            data.lowestPriceDeparture.price = dep.marketingPrice;
            data.lowestPriceDeparture.fromPrice = option.priceFrom.amount.toString();
          }

          delete dep['departures'];
          delete dep['date'];

          if (dep.startDate) {
            const year = new Date(dep.startDate).getFullYear();
            if (year) {
              data.years.indexOf(year) === -1 ? data.years.push(year) : '';
            }
          }
          data.flattenedDepartures.push(dep);
        });
      } else {
        data.flattenedDepartures.push({
          ...option,
          startDate: option.attributes.periodStart || '',
          endDate: option.attributes.periodEnd || '',
          hasDepartures: false,
          soldOut: true,
        });
      }
    });
    return data;
  }

  /**
   * MUST use manipulateDepartures() on your array BEFORE using this sort function.
   *
   * Tour card data has a structure like this:
   * {
   *  lemax: {},
   *  contentful: {}
   * }
   *
   * This sorting function will return the array tour objects based on your given sort value.
   * @param arr - array of tour card data which you must use manipulateDepartures() first
   * @param action - the type of sorting you want on the array.
   *
   * Note: "Sold out" tours show at the bottom of the list for each of these sort types
   *
   * n_az = name a to z
   * n_za = name z to a
   * p_lh = price low to high
   * p_hl = price high to low
   * d_lh = duration low to high
   * d_hl = duration high to low
   */
  sortTourCards(arr: any[], action: string) {
    // Sort the data
    switch (action) {
      case 'n_az': {
        return arr.sort((a, b) =>
          a.lemax.options.soldOut
            ? b.lemax.options.soldOut
              ? 0
              : 1
            : b.lemax.options.soldOut
              ? -1
              : a.contentful.tourName.toLowerCase() > b.contentful.tourName.toLowerCase()
                ? 1
                : -1
        );
      }
      case 'n_za': {
        return arr.sort((a, b) =>
          a.lemax.options.soldOut
            ? b.lemax.options.soldOut
              ? 0
              : 1
            : b.lemax.options.soldOut
              ? -1
              : b.contentful.tourName.toLowerCase() > a.contentful.tourName.toLowerCase()
                ? 1
                : -1
        );
      }
      case 'p_lh': {
        return arr.sort((a, b) =>
          a.lemax.options.soldOut
            ? b.lemax.options.soldOut
              ? a.lemax.options.lowestPriceDeparture.fromPrice - b.lemax.options.lowestPriceDeparture.fromPrice
              : 1
            : b.lemax.options.soldOut
              ? -1
              : a.lemax.options.lowestPriceDeparture.fromPrice - b.lemax.options.lowestPriceDeparture.fromPrice
        );
      }
      case 'p_hl': {
        return arr.sort((a, b) =>
          a.lemax.options.soldOut
            ? b.lemax.options.soldOut
              ? b.lemax.options.lowestPriceDeparture.fromPrice - a.lemax.options.lowestPriceDeparture.fromPrice
              : 1
            : b.lemax.options.soldOut
              ? -1
              : b.lemax.options.lowestPriceDeparture.fromPrice - a.lemax.options.lowestPriceDeparture.fromPrice
        );
      }
      case 'd_lh': {
        return arr.sort((a, b) =>
          a.lemax.options.soldOut
            ? b.lemax.options.soldOut
              ? 0
              : 1
            : b.lemax.options.soldOut
              ? -1
              : parseInt(a.contentful.numberOfDays, 10) - parseInt(b.contentful.numberOfDays, 10)
        );
      }
      case 'd_hl': {
        return arr.sort((a, b) =>
          a.lemax.options.soldOut
            ? b.lemax.options.soldOut
              ? 0
              : 1
            : b.lemax.options.soldOut
              ? -1
              : parseInt(b.contentful.numberOfDays, 10) - parseInt(a.contentful.numberOfDays, 10)
        );
      }
      default: {
        return arr;
      }
    }
  }
}
