<div class="personal-review-card">
  <div class="content">
    <img
      *ngIf="icon"
      [ngSrc]="icon.url + '?w=55&h=55&fit=fill&fm=webp'"
      [alt]="icon.description"
      class="icon"
      width="55"
      height="55" />
    <div class="text">
      <div *ngIf="name">{{ name }}</div>
      <div *ngIf="title">{{ title }}</div>
    </div>
  </div>
  <div class="review-text" *ngIf="text" [innerHTML]="text"></div>
</div>
