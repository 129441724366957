/**
 * This component is used to render pages from Builder.io.
 * It handles the scenario when a page is missing by redirecting to our own 404page.
 * It handles amplitude tracking for bad urls and successful page views
 */
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { BuilderModule } from '@builder.io/angular';
import { DOCUMENT, NgIf } from '@angular/common';
import { SeoService } from '@app/services/seo.service';
import { Router } from '@angular/router';
import { AmplitudeService } from '@app/services/amplitude.service';
import { LoaderService } from '@app/services/loader.service';
import { GeoModel } from '@models/geo.model';
import { TourModelLemax } from '@models/tour.model';
import { MultiTourPageModel } from '@models/multi-tour-page.model';
import { GeoService } from '@app/services/geo.service';
import { ContentPrices } from '@app/services/content-prices.service';

@Component({
  selector: 'app-builder-pages',
  standalone: true,
  imports: [BuilderModule, NgIf],
  templateUrl: './builder-pages.component.html',
  styleUrl: './builder-pages.component.scss',
})
export class BuilderPagesComponent {
  seo: any;
  readonly currentSlug: string;
  featuredTourSlug: string;
  noBuilderPageForUrl = false;

  tourData: TourModelLemax | MultiTourPageModel;
  lowestPriceDeparture = {
    fromPrice: '',
    price: '',
  };
  geo: GeoModel;

  constructor(
    @Inject(DOCUMENT) private dom: Document,
    @Inject(PLATFORM_ID) private platformId: object,
    private seoService: SeoService,
    private router: Router,
    private amplitudeService: AmplitudeService,
    private loaderService: LoaderService,
    private readonly geoService: GeoService,
    private readonly contentPrices: ContentPrices
  ) {
    this.currentSlug = this.router.url;
  }

  /**
   * Custom load function that handles the builderIO event data.
   * If the event data is missing, it handles the missing page scenario.
   * If the event data is present, it assigns SEO data.
   *
   * @param {any} event - The event object containing data.
   */
  onBuilderContentLoad(event: any) {
    if (!event) {
      this.noBuilderPageForUrl = true;
      this.trackMissingPage();
      this.handleMissingPage();
    } else if (event.data) {
      this.amplitudeService.trackEvent('Builder IO page visited', {
        pageUrl: this.currentSlug,
      });

      if (event.data?.featuredTour) {
        this.featuredTourSlug = event.data.featuredTour;
        this.geoService.geoData.subscribe({
          next: (data: GeoModel) => {
            if (data) {
              this.geo = data;
              this.getTour().then(() => {
                this.loaderService.setSiteLoader(false);
              });
            }
          },
          error: () => {
            this.loaderService.setSiteLoader(false);
            console.warn('Could not get geo data');
          },
        });
      } else {
        this.loaderService.setSiteLoader(false);
      }

      this.noBuilderPageForUrl = false;
      this.assignSeoData(event.data);
    }
  }

  /**
   * Handles the error scenario when loading builder.io content.
   * Logs the error and navigates to the 404 page.
   */
  onBuilderContentLoadError() {
    this.trackMissingPage();
    this.handleMissingPage();
  }

  /**
   * This is used to log missing pages from Builder IO.
   */
  trackMissingPage() {
    this.amplitudeService.trackEvent('Builder IO page missing', {
      pageUrl: this.currentSlug,
    });
  }

  /**
   * Handles the scenario when a page is missing by navigating to the 404 page.
   */
  handleMissingPage() {
    this.router.navigate(['/404page']);
  }

  /**
   * Assigns SEO data from the provided data object.
   *
   * @param {any} data - The data object containing SEO information.
   */
  assignSeoData(data: any) {
    //cleanup unnecessary data sent from builder IO
    delete data.html;
    delete data.heading;

    //Page exists in builder IO, assign SEO data
    this.seo = data;
    this.seo.shareImage = {
      url: data.shareImage,
      title: '',
      description: '',
    };

    this.seoService.setSeoModel(this.seo);
  }

  async getTour() {
    try {
      const observable = await this.contentPrices.getSingleTour(this.featuredTourSlug, this.geo.currency);
      observable.subscribe({
        next: (page: any) => {
          this.seoService.setSeoModel(page.contentful.seo);
          if (page.contentful.contentType === 'tour') {
            if (page.lemax) {
              this.tourData = page as TourModelLemax;
              this.tourData.geo = this.geo;
              this.lowestPriceDeparture = this.setLowestPrices(this.tourData.lemax.options, this.tourData.geo);
            }
          }
          this.tourData = page;
        },
        error: error => {
          console.warn('Could not get tour data');
          this.trackMissingTour(error);
        },
      });
    } catch (error) {
      console.warn('Try could not get tour data');
      this.trackMissingTour(error);
    }
  }

  /**
   * This is used to log missing tours from Builder IO.
   */
  trackMissingTour(error: any) {
    this.amplitudeService.trackEvent('Builder IO tour missing', {
      pageUrl: this.currentSlug,
      featuredTourSlug: this.featuredTourSlug,
      errorText: error?.statusText || 'Unknown error',
      errorCode: error?.status || 'Unknown status',
    });
  }

  setLowestPrices(options, currency) {
    const manipulatedDepartures = this.contentPrices.manipulateDepartures(options, currency.currency);
    const lowestPriceDeparture = manipulatedDepartures.lowestPriceDeparture;
    lowestPriceDeparture.fromPrice = `${currency.currency_sign}${Math.round(Number(lowestPriceDeparture.fromPrice))}`;
    lowestPriceDeparture.price = `${currency.currency_sign}${Math.round(Number(lowestPriceDeparture.price))}`;
    return lowestPriceDeparture;
  }
}
