import { Component } from '@angular/core';
import { UiModule } from '@app/components/ui/ui.module';

@Component({
  selector: 'app-checkout-header',
  standalone: true,
  imports: [UiModule],
  templateUrl: './checkout-header.component.html',
  styleUrl: './checkout-header.component.scss',
})
export class CheckoutHeaderComponent {}
