<div class="input-container">
  <input
    [id]="controlName + '-email-input'"
    [formControl]="control"
    type="email"
    (input)="control.setValue($event.target.value.toLowerCase(), { emitEvent: false })" />
  <label [for]="controlName + '-email-input'" [class.active]="control.value">{{ placeholder }}</label>
  <span *ngIf="control.invalid && control.touched" class="icon invalid">
    <app-cross></app-cross>
  </span>
  <span *ngIf="control.valid" class="icon valid">
    <app-tick></app-tick>
  </span>
  <div
    *ngIf="control.invalid && control.touched"
    class="error-message"
    [ngClass]="{ 'white-background': isWhiteBackground, 'other-background': !isWhiteBackground }">
    <span *ngIf="control.errors?.email || control.invalid">{{
      validationMessage ? validationMessage : '[This field is required]'
    }}</span>
  </div>
</div>
