import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

import { AuthService } from '@app/services/auth.service';
import { AmplitudeService } from '@app/services/amplitude.service';
import { AccountService } from '@app/services/account.service';
import { AccountModel } from '@models/account.model';
import { Observable } from 'rxjs';

import { Store } from '@ngxs/store';
import { SetUserEmail, SetUserProperties } from '@app/feature/session-tracking/store/user-details.state';
import { capitalizeFirstLetter } from '@utils';

/**
 * Pre go live i made the decision to hide social logins, the app keys were testing with arent the same as the keys we NEED to use on launch and they dont work with the packages
 * that ive been testing, should social login be a requirement post live experiment with this package below and make sure the fb app key is the same as the account Angus shared access to with dev
 * "@abacritt/angularx-social-login": "^1.3.2",
 */

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginRegisterComponent implements OnInit {
  buttonVisible = this.accountService.buttonVisible;
  error = this.accountService.error;
  warning = this.accountService.warning;
  success = this.accountService.success;
  params: any;
  accountData: AccountModel;

  isAuthenticated$: Observable<boolean>;
  user$: Observable<any>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private amplitudeService: AmplitudeService,
    private readonly accountService: AccountService,
    private readonly store: Store
  ) {
    //Make this validation for reset form require 2 fields to be the same
    // this.resetForm.addValidators(this.createCompareValidator(this.resetForm.get('password'),this.resetForm.get('confirmPassword')));
    this.accountService.accountModalToggle.subscribe(val => this.triggerModalAction(val));
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isAuthenticated$ = this.authService.isAuthenticated$;
      this.user$ = this.authService.user$;

      this.authService.isAuthenticated$.subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.user$.subscribe(user => {
            this.store.dispatch(new SetUserEmail(user.email)).subscribe(() => {
              const userProperties = {
                first_name: user.given_name || capitalizeFirstLetter(user.nickname),
                last_name: user.family_name || '',
              };
              this.store.dispatch(new SetUserProperties(userProperties));
            });

            this.accountService.loginWishlist(user.email);
          });
        } else {
          this.handleLogout();
        }
      });
    }
    this.accountService.accountData.subscribe((accItem: AccountModel) => {
      this.accountData = accItem;
    });

    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  handleLogin(): void {
    const eventData = {
      event: 'Login with Redirect Triggered',
      page: this.amplitudeService.setPageUrl(this.router.url),
    };
    this.amplitudeService.trackEvent('Auth Event Tracking', eventData);
    this.authService.login();
  }

  handleAccountMenu(): void {
    const eventData = {
      event: 'Account Menu Triggered',
      page: this.amplitudeService.setPageUrl(this.router.url),
    };
    this.amplitudeService.trackEvent('Auth Event Tracking', eventData);
  }

  handleAccount(): void {
    const eventData = {
      event: 'Go to Account Triggered',
      page: this.amplitudeService.setPageUrl(this.router.url),
    };
    this.amplitudeService.trackEvent('Auth Event Tracking', eventData);
  }

  handleLogout() {
    if (localStorage.getItem('expatClient')) {
      const eventData = {
        event: 'Logout Triggered',
        page: this.amplitudeService.setPageUrl(this.router.url),
      };
      this.amplitudeService.trackEvent('Auth Event Tracking', eventData);
      localStorage.removeItem('expatClient');
      this.accountData = null;
      this.authService.logout();
      this.accountService.logout();
    }
  }

  triggerModalAction(val: string) {
    if (val === 'login') {
      this.authService.login();
    }
  }
}
