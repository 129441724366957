import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { SignupConfig } from '../configs/signup.config';

@Injectable({ providedIn: 'root' })
export class SignupService {
  private config = new SignupConfig();

  constructor(private http: HttpClient) {}

  sendSignup(data: any) {
    return new Promise((resolve, reject) => {
      this.http.post(this.config.urls.signup(), data).subscribe({
        //, {observe: 'response'} if you want to use network status like 200 / 201 etc
        next: response => {
          resolve(response);
        },
        error: error => {
          reject(error);
        },
      });
    });
  }

  /**
   * @param slug 'ppr'|'contact_us'|'postcv'
   * @param body form object
   */
  formSubmission(slug: 'ppr' | 'contact_us' | 'postcv', body: any) {
    return new Promise((resolve, reject) => {
      this.http.post(this.config.urls.formSubmission(slug), body).subscribe({
        next: () => {
          resolve(null);
        },
        error: error => {
          reject(error);
        },
      });
    });
  }

  /**
   * @param body form object
   */
  sendToCS(body: any) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.priceBeatUrl, body).subscribe({
        next: () => {
          resolve(null);
        },
        error: error => {
          reject(error);
        },
      });
    });
  }
}
