<a
  href="https://wa.me/447731943978"
  title="WhatsApp Us"
  class="whatsapp d-flex align-items-center"
  target="_blank"
  rel="noopener"
  (click)="onClick()">
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
    <path
      d="M18.7236 3.74121C16.6777 1.69043 13.9531 0.5625 11.0576 0.5625C5.08105 0.5625 0.217773 5.42578 0.217773 11.4023C0.217773 13.3115 0.71582 15.1768 1.66309 16.8223L0.125 22.4375L5.87207 20.9287C7.4541 21.793 9.23633 22.2471 11.0527 22.2471H11.0576C17.0293 22.2471 22 17.3838 22 11.4072C22 8.51172 20.7695 5.79199 18.7236 3.74121ZM11.0576 20.4209C9.43652 20.4209 7.84961 19.9863 6.46777 19.166L6.14062 18.9707L2.73242 19.8643L3.64062 16.5391L3.42578 16.1973C2.52246 14.7617 2.04883 13.1064 2.04883 11.4023C2.04883 6.43652 6.0918 2.39355 11.0625 2.39355C13.4697 2.39355 15.7305 3.33105 17.4297 5.03516C19.1289 6.73926 20.1738 9 20.1689 11.4072C20.1689 16.3779 16.0234 20.4209 11.0576 20.4209ZM15.999 13.6729C15.7305 13.5361 14.3975 12.8818 14.1484 12.7939C13.8994 12.7012 13.7188 12.6572 13.5381 12.9307C13.3574 13.2041 12.8398 13.8096 12.6787 13.9951C12.5225 14.1758 12.3613 14.2002 12.0928 14.0635C10.501 13.2676 9.45605 12.6426 8.40625 10.8408C8.12793 10.3623 8.68457 10.3965 9.20215 9.36133C9.29004 9.18066 9.24609 9.02441 9.17773 8.8877C9.10938 8.75098 8.56738 7.41797 8.34277 6.87598C8.12305 6.34863 7.89844 6.42188 7.73242 6.41211C7.57617 6.40234 7.39551 6.40234 7.21484 6.40234C7.03418 6.40234 6.74121 6.4707 6.49219 6.73926C6.24316 7.0127 5.54492 7.66699 5.54492 9C5.54492 10.333 6.5166 11.6221 6.64844 11.8027C6.78516 11.9834 8.55762 14.7178 11.2773 15.8945C12.9961 16.6367 13.6699 16.7002 14.5293 16.5732C15.0518 16.4951 16.1309 15.9189 16.3555 15.2842C16.5801 14.6494 16.5801 14.1074 16.5117 13.9951C16.4482 13.873 16.2676 13.8047 15.999 13.6729Z"
      fill="#25D366" />
  </svg>
  <span class="ms-2">WhatsApp Us</span>
</a>
