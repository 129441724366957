import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomFormComponent } from './custom-form/custom-form.component';
import { CustomInputEmailComponent } from './custom-input-email/custom-input-email.component';
import { CustomInputTextComponent } from '@app/feature/forms/custom-input-text/custom-input-text.component';
import { CrossComponent } from '@app/feature/forms/ui/cross/cross.component';
import { TickComponent } from '@app/feature/forms/ui/tick/tick.component';
import { CustomSelectTitleComponent } from '@app/feature/forms/custom-select-title/custom-select-title.component';
import { CustomInputTextareaComponent } from '@app/feature/forms/custom-input-textarea/custom-input-textarea.component';
import { CustomInputCheckboxComponent } from '@app/feature/forms/custom-input-checkbox/custom-input-checkbox.component';
import { CustomInputCountryPhoneComponent } from '@app/feature/forms/custom-input-country-phone/custom-input-country-phone.component';
import { CustomSelectListComponent } from '@app/feature/forms/custom-select-list/custom-select-list.component';

@NgModule({
  declarations: [
    CustomFormComponent,
    CustomInputEmailComponent,
    CustomInputTextareaComponent,
    CustomInputTextComponent,
    CustomInputCheckboxComponent,
    CustomSelectTitleComponent,
    CustomInputCountryPhoneComponent,
    CustomInputCheckboxComponent,
    CustomSelectListComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, TickComponent, CrossComponent],
  exports: [
    CustomFormComponent,
    CrossComponent,
    TickComponent,
    CustomInputEmailComponent,
    CustomInputTextComponent,
    CustomSelectTitleComponent,
    CustomInputTextareaComponent,
    CustomInputCheckboxComponent,
    CustomInputCheckboxComponent,
    CustomInputCountryPhoneComponent,
    CustomSelectListComponent,
  ],
})
export class CustomFormModule {}
