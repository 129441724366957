import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { extractUrlSearchParams, hasQueryParams, isInternalLink } from '../../../../lib/utils';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-content-link',
  templateUrl: './content-link.component.html',
  styleUrls: ['./content-link.component.scss'],
})
export class ContentLinkComponent implements AfterViewInit, OnInit {
  @Input() url: string;
  @Output() linkAction = new EventEmitter<string>();

  isInternalLink: boolean;
  queryParams: Record<string, unknown>;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit(): void {
    this.isInternalLink = isPlatformBrowser(this.platformId) && isInternalLink(this.url);
    if (hasQueryParams(this.url)) {
      this.queryParams = Object.fromEntries(extractUrlSearchParams(this.url));
    } else {
      this.queryParams = {};
    }
  }

  ngAfterViewInit() {
    const anchor = this.el.nativeElement.querySelector('a');
    if (anchor) {
      // Copy all attributes from the host element to the anchor element
      Array.from(this.el.nativeElement.attributes).forEach(({ name, value }: { name: string; value: unknown }) => {
        if (name !== 'url') {
          this.renderer.setAttribute(anchor, name, value as string);
        }
      });
    }

    this.renderer.listen(anchor, 'click', event => {
      this.linkAction.emit(event);
    });
  }
}
