<div class="calendar-card" *ngIf="!processing">
  <mat-calendar
    *ngIf="!processing"
    [selected]="selected"
    [minDate]="minDate"
    [maxDate]="maxDate$ | async"
    [dateFilter]="dateFilter"
    (selectedChange)="onDateSelected($event)">
  </mat-calendar>
</div>
<ng-container *ngIf="processing">
  <div class="loaderPlaceholderWrapper">
    <img
      class="img-fluid small"
      src="https://images.ctfassets.net/huwbudysxepr/62mhn3rxjG1HlQ5gfO93DR/e3f25c6d4739bd188db55888a4955673/search-loader.gif"
      alt="..Loading" />
  </div>
</ng-container>
<small> Please note: The 29th, 30th, and 31st are unavailable for selection. </small>
