import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { campaignCarouselTabColourConfig } from '@models/campaigns.model';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input() index = 0;
  @Input() text = '';
  @Input() isActiveSlide = false;
  @Input() active? = 0;
  @Input() total? = 0;
  @Input() isPromo? = false;
  @Input() campaignColourConfig?: campaignCarouselTabColourConfig;
  tabBgColour = '';
  tabTextColour = '';

  @Output() tabSelected: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit(): void {
    if (this.campaignColourConfig) {
      if (this.campaignColourConfig.tabBgColour && this.campaignColourConfig.tabBgColour !== '') {
        this.tabBgColour = this.campaignColourConfig.tabBgColour;
        this.tabTextColour = this.campaignColourConfig.tabTextColour;
      }
    }
  }

  onTabClick(): void {
    this.tabSelected.emit(this.index);
  }
}
