import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

/**
 * Validator to ensure the input is a valid email.
 *
 * This regex pattern, /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, matches strings that are valid email addresses.
 * - ^ asserts the position at the start of the string.
 * - [a-zA-Z0-9._%+-]+ matches one or more alphanumeric characters, dots, underscores, percent signs, plus signs, or hyphens.
 * - @ matches the @ symbol.
 * - [a-zA-Z0-9.-]+ matches one or more alphanumeric characters, dots, or hyphens.
 * - \. matches the dot symbol.
 * - [a-zA-Z]{2,} matches two or more alphabetic characters.
 * - $ asserts the position at the end of the string.
 *
 * Example Matches:
 * - "example@example.com" (matches)
 * - "user.name@domain.co" (matches)
 * - "invalid-email" (does not match)
 */
export const emailPatternValidator: ValidatorFn = Validators.pattern(
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
);

/**
 * Validator to ensure the input is a valid email with required field validation.
 */
export function emailValidators(): ValidatorFn[] {
  return [Validators.required, Validators.email];
}

/**
 * basic text field validation can be anything first name / last name / city / town
 * min length is 1 because there is a town in Norway with 1 letter name
 */
export function textValidators() {
  return [Validators.required, Validators.minLength(1)];
}

export function requiredValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value ? null : { required: true };
  };
}

/**
 * Validator to ensure the input is of minimum length.
 * @param minLength - The minimum length required for the input.
 */
export function minLengthValidator(minLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value && control.value.length >= minLength ? null : { minLength: { requiredLength: minLength } };
  };
}

/**
 * Validator to ensure the input does not exceed the maximum length.
 * @param maxLength - The maximum length allowed for the input.
 */
export function maxLengthValidator(maxLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value && control.value.length <= maxLength ? null : { maxLength: { requiredLength: maxLength } };
  };
}
