import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Subject, throwError } from 'rxjs';

import { MenusConfig } from '../configs/menus.config';
import { MainMenuItemModel } from '@models/main-menu.model';
import { MainFooterModel } from '@models/main-footer.model';
import { isPlatformBrowser } from '@angular/common';
import { builder } from '@builder.io/sdk';
import { environment } from '@environments/environment';

builder.init(environment.builderIo.apiKey);

@Injectable({ providedIn: 'root' })
export class MenusService {
  private config = new MenusConfig();
  mainMenu = new Subject<{ menuParts: MainMenuItemModel[] }>();
  footerMenu = new Subject<any>();
  heightEmitter = new EventEmitter();
  menuHeight = 125;
  noticeHeight = 0;
  navFixed = false;
  showTourMenu = false;
  isTourPage = new BehaviorSubject<boolean>(null);
  builderMenuPart: MainMenuItemModel;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    // Contain extra API calls to the browser where possible. Intended to boost page rendering speed.
    if (isPlatformBrowser(this.platformId)) {
      this.getBuilderNavItem();
    }
  }

  /**
   * Get the builder.io nav item data
   * If it's not there, or it fails, just carry on with getting regular menu data
   */
  getBuilderNavItem() {
    builder
      .get('nav-item')
      .promise()
      .then(data => {
        //Found builder nav item data
        if (data?.data) {
          this.handleBuilderNavItem(data).then(() => {
            this.getMenuData(true);
          });
        } else {
          this.getMenuData(false);
        }
      })
      .catch(() => {
        // console.log('getBuilderNavItem Errored');
      });
  }

  /**
   * manipulate builder values to match our MainMenuItemModel
   * @param data - the data from the builder.io nav item
   */
  handleBuilderNavItem(data: any) {
    return new Promise((resolve, reject) => {
      if (data?.data) {
        this.builderMenuPart = {
          title: data.data.text,
          mobileTitle: data.data.text,
          mainMenuItem: true,
          hasSubMenu: false,
          newItem: false,
          desktop: true,
          mobile: true,
          colourConfigs: {
            backgroundColour: data.data.backgroundColour,
            textColour: data.data.textColour,
          },
          mobileIcons: [
            {
              slug: 'builderIcons',
              standardIcon: {
                title: data.data.text,
                url: data.data.mobileStandardIcon,
              },
              activeIcon: {
                title: data.data.text,
                url: data.data.mobileActiveIcon,
              },
              nonActiveIcon: {
                title: data.data.text,
                url: data.data.mobileInactiveIcon,
              },
            },
          ],
          optionalUrl: data.data.url,
        };
        resolve(true);
      } else {
        reject(false);
      }
    });
  }

  /**
   * Gets the main menu and footer menu data
   * @param hasBuilderNavItem - boolean to determine if we need to add the builder nav item data to the menu data
   */
  getMenuData(hasBuilderNavItem = false) {
    this.http
      .get<{ mainMenu: { menuParts: MainMenuItemModel[] }; footerMenu: MainFooterModel }>(this.config.urls.getMenus())
      .subscribe({
        next: response => {
          //If we have a builder nav item, add it to the menu data
          if (hasBuilderNavItem && this.builderMenuPart) {
            response.mainMenu.menuParts.push(this.builderMenuPart);
          }
          this.mainMenu.next(response.mainMenu);
          this.footerMenu.next(response.footerMenu);
        },
        error: (error: HttpErrorResponse) => {
          return throwError(() => error);
        },
      });
  }

  setMenuHeight(val: number) {
    this.menuHeight = val;
    this.heightEmitter.emit();
  }

  // isTourMenuVisible() {
  //   return this.showTourMenu;
  // }

  getMenuHeight() {
    return this.menuHeight;
  }

  setNoticeHeight(val) {
    this.noticeHeight += val;
  }

  getNoticeHeight() {
    return this.noticeHeight;
  }
}
