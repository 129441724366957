<div class="checkout-wrapper">
  <app-checkout-header></app-checkout-header>
  <div class="container" *ngIf="notProcessingOrLoading">
    <div class="row">
      <div class="col-12 col-md-6">
        <app-checkout-navigation></app-checkout-navigation>
      </div>
      <div class="col-6 d-none d-lg-flex">
        <app-need-assistance></app-need-assistance>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-5 left-col">
        <h3>Traveller Details:</h3>
        <div class="customer-form">
          <strong class="d-block mb-4">Personal Details</strong>
          <app-custom-form #customForm>
            <app-custom-select-title
              controlName="title"
              placeholder="[Title]"
              validationMessage="This field is required!"></app-custom-select-title>

            <app-custom-input-text
              controlName="firstName"
              placeholder="[First Name]"
              validationMessage="This field is required, max 50 characters"
              [validators]="[minLengthValidator(2), maxLengthValidator(50)]"></app-custom-input-text>

            <app-custom-input-text
              controlName="lastName"
              placeholder="[Last Name]"
              validationMessage="This field is required, max 50 characters"
              [validators]="[minLengthValidator(2), maxLengthValidator(100)]"></app-custom-input-text>

            <strong class="d-block mb-4">Contact Details</strong>

            <app-custom-input-email
              controlName="email"
              placeholder="[Email]"
              validationMessage="Please enter a valid email address!"></app-custom-input-email>

            <app-custom-select-list
              controlName="country"
              placeholder="[Country of Residence]"
              [options]="countryData"
              countryList="true"
              valueKey="ISO2"
              textKey="Country"
              validationMessage="This field is required!"></app-custom-select-list>

            <app-custom-input-country-phone controlName="countryPhone"></app-custom-input-country-phone>

            <app-custom-input-text
              controlName="streetName"
              placeholder="[Street Name]"
              validationMessage="This field is required, max 255 characters"
              [validators]="[minLengthValidator(2), maxLengthValidator(255)]"></app-custom-input-text>

            <app-custom-input-text
              controlName="zipCode"
              placeholder="[Zip Code]"
              validationMessage="This field is required, max 20 characters"
              [validators]="[minLengthValidator(2), maxLengthValidator(20)]"></app-custom-input-text>

            <app-custom-input-text
              controlName="cityTown"
              placeholder="[City / Town]"
              validationMessage="This field is required, max 20 characters"
              [validators]="[minLengthValidator(2), maxLengthValidator(20)]"></app-custom-input-text>

            <app-custom-input-textarea
              controlName="message"
              placeholder="Special note or request..."></app-custom-input-textarea>

            <app-custom-input-checkbox
              controlName="termsConditions"
              termsConditions="true"
              [required]="true"></app-custom-input-checkbox>
          </app-custom-form>
        </div>
        <div class="return-traveller">
          <p>Returning customer? Enter your details for a 10% discount!</p>
          <app-custom-form #returnTravellerForm>
            <app-custom-input-email
              controlName="email"
              placeholder="[Email]"
              validationMessage="Please enter a valid email address!"></app-custom-input-email>

            <app-custom-input-text controlName="bookingNumber" placeholder="[Booking number]"></app-custom-input-text>
          </app-custom-form>
        </div>
      </div>
      <div class="col-12 col-lg-7 right-col">
        <h3>Tour Summary:</h3>
        <div class="checkout-card">
          <app-tour-summary></app-tour-summary>

          <app-custom-form #visibleForm>
            <app-custom-input-checkbox
              controlName="visibleTermsConditions"
              termsConditions="true"
              [required]="true"
              (change)="onVisibleTermsChange($event)"></app-custom-input-checkbox>
          </app-custom-form>

          <div class="next-step-container d-flex justify-content-center justify-content-lg-end">
            <button
              class="next-step"
              (click)="onStepNext()"
              *ngIf="!processing"
              [class.disabled]="customForm?.formGroup?.invalid">
              Next
            </button>
            <ng-container *ngIf="!notProcessingOrLoading || processing">
              <app-loader-placeholer></app-loader-placeholer>
            </ng-container>
          </div>
        </div>

        <div class="deposit-note-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.9307 6.58789L7.92875 11.293C7.85844 11.6328 7.95805 11.8262 8.23344 11.8262C8.4268 11.8262 8.71977 11.7559 8.91898 11.5801L8.83109 11.9961C8.54398 12.3418 7.91117 12.5938 7.36625 12.5938C6.66313 12.5938 6.3643 12.1719 6.55766 11.2754L7.29594 7.80664C7.36039 7.51367 7.3018 7.4082 7.00883 7.33789L6.55766 7.25586L6.63969 6.875L8.9307 6.58789ZM8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5C8.55229 3.5 9 3.94772 9 4.5C9 5.05228 8.55229 5.5 8 5.5Z"
              fill="#5AC7DA" />
          </svg>
          <div class="deposit-note">
            <p>
              Secure your spot for as little as
              <strong>{{ depositPrice$ | async | currency: geo.currency_sign }}!</strong> The rest just needs to be paid
              at least 60 days before departure.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-checkout-footer></app-checkout-footer>
