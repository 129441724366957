import { AfterContentInit, Component, Input } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import { textValidators } from '@app/feature/forms/custom-form-validators';

@Component({
  selector: 'app-custom-input-text',
  templateUrl: './custom-input-text.component.html',
  styleUrls: ['../custom-form.scss'],
})
export class CustomInputTextComponent implements AfterContentInit {
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() validationMessage?: string;
  @Input() validators: ValidatorFn[] = [];
  @Input() isWhiteBackground = true;
  control: FormControl;

  ngAfterContentInit() {
    const appliedValidators = this.validators.length ? this.validators : textValidators();
    this.control = new FormControl('', appliedValidators);
  }
}
