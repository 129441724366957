<div class="header-carousel-outer">
  <div class="header-carousel-wrapper">
    <div class="mobile-header" *ngIf="!isDesktop">
      <picture>
        <source media="(min-width: 900px)" [srcset]="mobileHeader.url + '?h=300&w=900&fit=fill'" />
        <source media="(min-width: 850px)" [srcset]="mobileHeader.url + '?h=300&w=850&fit=fill'" />
        <source media="(min-width: 750px)" [srcset]="mobileHeader.url + '?h=300&w=750&fit=fill'" />
        <source media="(min-width: 650px)" [srcset]="mobileHeader.url + '?h=300&w=650&fit=fill'" />
        <source media="(min-width: 550px)" [srcset]="mobileHeader.url + '?h=300&w=550&fit=fill'" />
        <source media="(min-width: 450px)" [srcset]="mobileHeader.url + '?h=300&w=450&fit=fill'" />
        <img [ngSrc]="mobileHeader.url + '?h=300&w=350&fit=fill'" [alt]="mobileHeader.description" fill="" />
      </picture>
    </div>

    <ng-container *ngIf="isDesktop">
      <swiper-container class="header-carousel-swiper" appSwiper init="false" #swiper [config]="swiperConfig">
        <swiper-slide class="swiper-slide" *ngFor="let item of data; let i = index">
          <picture>
            <source
              media="(min-width: 993px) and (max-width: 1200px)"
              [srcset]="item.slideImage.url + '?h=768&w=1024&fit=fill&fm=webp&q=40'" />
            <source
              media="(min-width: 1201px) and (max-width: 1660px)"
              [srcset]="item.slideImage.url + '?h=1080&w=1920&fit=fill&fm=webp&q=40'" />
            <source
              media="(min-width: 1661px)"
              [srcset]="item.slideImage.url + '?h=1200&w=2560&fit=fill&fm=webp&q=40'" />
            <img
              ngSrc="{{ item.slideImage.url + '?h=768&w=1903&fit=fill&fm=webp&q=40' }}"
              [alt]="item.slideImage.description"
              class="img-swiper"
              fill />
          </picture>
        </swiper-slide>
      </swiper-container>
    </ng-container>

    <div class="overlay-content d-none d-lg-flex">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <app-banner-search
              [data]="searchText"
              [overlay]="true"
              *ngIf="isDesktop"
              section="Hero Section Carousel"></app-banner-search>
          </div>
          <div class="col-md-6">
            <div class="banner-tours float-end">
              <div class="banner-tours-inner">
                <div class="banner-carousel-outer">
                  <div class="tours-block" *ngIf="tourData">
                    <div class="container-fluid" *ngIf="tourData[index].cardType[0] === 'Tour Card'">
                      <div class="row">
                        <div class="col-8">
                          <div class="tour-type">
                            {{ tourData[index].tourContent.contentful.transportText }}
                          </div>
                          <div class="tour-name">
                            <h3 class="red">
                              {{ tourData[index].tourContent.contentful.tourName }}
                            </h3>
                          </div>
                        </div>
                        <div class="col-4">
                          <div
                            class="prices"
                            *ngIf="
                              this.geo.currency_sign &&
                              tourData[index]?.tourContent?.lemax?.options?.lowestPriceDeparture?.price
                            ">
                            <span
                              class="was"
                              *ngIf="
                                tourData[index].tourContent.lemax.options.lowestPriceDeparture?.price &&
                                !(
                                  tourData[index].tourContent.lemax.options.lowestPriceDeparture.price <=
                                  tourData[index].tourContent.lemax.options.lowestPriceDeparture.fromPrice
                                )
                              ">
                              <sup>Up To</sup>
                              <span class="price">
                                {{ this.geo.currency_sign
                                }}{{
                                  formatPrice(tourData[index].tourContent.lemax.options.lowestPriceDeparture.price)
                                }}
                              </span>
                            </span>
                            <span class="from">
                              <sup><strong>Now</strong></sup>
                              <span class="price">
                                {{ this.geo.currency_sign
                                }}{{
                                  formatPrice(tourData[index].tourContent.lemax.options.lowestPriceDeparture.fromPrice)
                                }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3 mt-2">
                        <div class="col-4 banner-stars" *ngIf="tourData[index].tourContent?.contentful?.reviewObject">
                          <app-review-stars
                            [rating]="tourData[index].tourContent?.contentful?.reviewObject.averageRating"
                            [location]="'tours'"
                            color="grey"></app-review-stars>
                        </div>
                        <div class="col-4 banner-num-days text-center">
                          <span
                            >{{ tourData[index].tourContent.contentful.numberOfDays }}
                            {{ tourData[index].tourContent.contentful.numberOfDays > 1 ? 'Days' : 'Day' }}</span
                          >
                        </div>
                        <div class="col-4 banner-num-country text-end">
                          <span>
                            <app-stated-countries [tourData]="tourData[index].tourContent.contentful" />
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="col-12 banner-tour-review"
                          *ngIf="data[index].text"
                          [innerHTML]="data[index].text | safeHtml"></div>
                      </div>
                      <div class="tour-button">
                        <app-cta
                          cta="solid"
                          color="green"
                          content="View Tour"
                          (click)="onSlideCTA('Tour Card')"></app-cta>
                      </div>
                    </div>
                    <div
                      class="container-fluid featured-heading-text"
                      *ngIf="tourData[index].cardType[0] === 'Heading Text Card'">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h4 class="red" *ngIf="tourData[index].shortText">
                            {{ tourData[index].shortText }}
                          </h4>
                          <div
                            class="text"
                            *ngIf="tourData[index].text"
                            [innerHTML]="tourData[index].text | safeHtml"></div>
                          <app-cta
                            cta="solid"
                            color="yellow"
                            [content]="tourData[index].buttonText ? tourData[index].buttonText : 'Click Here'"
                            (buttonSelected)="ctaNavigate(tourData[index].url)"
                            *ngIf="tourData[index].url"></app-cta>
                        </div>
                      </div>
                    </div>
                    <div
                      class="container-fluid featured-image-text"
                      *ngIf="tourData[index].cardType[0] === 'Image Text Card'">
                      <div class="row">
                        <div class="col-12 text-center">
                          <img
                            [ngSrc]="tourData[index].image.url + '?fm=webp&w=450&h=90&fit=fill'"
                            class="img-fluid"
                            [alt]="tourData[index].image.description"
                            height="90"
                            width="450"
                            *ngIf="tourData[index].image" />
                          <div
                            class="text"
                            *ngIf="tourData[index].text"
                            [innerHTML]="tourData[index].text | safeHtml"></div>
                          <app-cta
                            cta="solid"
                            color="yellow"
                            [content]="tourData[index].buttonText ? tourData[index].buttonText : 'Click Here'"
                            (buttonSelected)="ctaNavigate(tourData[index].url)"
                            *ngIf="tourData[index].url"></app-cta>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="header-pagination">
                    <app-round-buttons
                      icon="chevron-left"
                      color="blue"
                      customClass="tour-swiper-prev"
                      (buttonSelected)="slidePrev()"></app-round-buttons>
                    <span class="header-carousel-tours-pagination"></span>
                    <app-round-buttons
                      icon="chevron-right"
                      color="blue"
                      customClass="tour-swiper-next"
                      (buttonSelected)="slideNext()"></app-round-buttons>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <builder-component model="home-banner-cta" [reloadOnRoute]="false"></builder-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-block d-lg-none">
  <div class="container-fluid container-banner-search-mobile">
    <div class="row">
      <div class="col-12">
        <app-banner-search
          [data]="searchText"
          [overlay]="false"
          *ngIf="!isDesktop"
          section="Hero Section Carousel"></app-banner-search>
      </div>
    </div>
  </div>
</div>
