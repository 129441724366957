//Adds class of hovering to element while user is hovering on it.
//Intended for use in styling main menu drop out effect on desktop requiring shared menu heights for dropout offset
import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

import { HeaderComponent } from '../components/header/header.component';
import { MenusService } from '../services/menus.service';

@Directive({
  selector: '[appMenuHeightCalculations]',
})
export class MenuHeightCalculationsDirective {
  menuHeight: number;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private headerComponent: HeaderComponent,
    private menuService: MenusService
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.elementRef.nativeElement.classList.add('hovering');
    this.menuHeight = this.headerComponent.getMenuHeight();
    this.elementRef.nativeElement.querySelector('.dropout').style.top = this.menuHeight + 'px';
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.elementRef.nativeElement.classList.remove('hovering');
    this.elementRef.nativeElement.querySelector('.dropout').style.removeProperty('top');
  }

  @HostListener('click', ['$event.target.parentElement']) onClick(e) {
    if (!(e instanceof HTMLAnchorElement)) return;

    this.elementRef.nativeElement.classList.remove('hovering');
    this.elementRef.nativeElement.querySelector('.dropout').style.removeProperty('top');
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    //For tour pages to reset menu height if people overscroll upwards
    if (window.scrollY <= 0) {
      this.menuHeight = this.headerComponent.getMenuHeight();
    }
  }
}
