import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectionService {
  private desktopWidth = 994;

  /**
   * Do a platformBrowser check where you use this service or "window is not defined" will happen because of SSR
   * Not including it here because else condition managed elsewhere
   */
  isDesktop(): boolean {
    return window.innerWidth >= this.desktopWidth;
  }
}
