import { AfterContentInit, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { emailValidators } from '@app/feature/forms/custom-form-validators';

@Component({
  selector: 'app-custom-input-email',
  templateUrl: './custom-input-email.component.html',
  styleUrls: ['../custom-form.scss'],
})
export class CustomInputEmailComponent implements AfterContentInit {
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() validationMessage: string;
  @Input() isWhiteBackground = true;
  control: FormControl;

  ngAfterContentInit() {
    this.control = new FormControl('', emailValidators());
  }
}
