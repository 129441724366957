<div class="container category-carousel-upper" *ngIf="dataReady">
  <div class="row">
    <div class="col-12">
      <h3 *ngIf="heading">{{ heading }}</h3>
      <div class="category-carousel-text text-center" *ngIf="text" [innerHTML]="text"></div>
      <div class="filters d-flex" *ngIf="showFilter">
        <div>Filters:</div>
        <div class="d-none d-md-flex">
          <button
            [ngClass]="{ filter: true, active: catSelection === filter }"
            *ngFor="let filter of filters"
            (click)="onFilter(filter)">
            {{ filter }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid filters-carousel-container d-md-none">
  <div class="row">
    <div class="col-12 filters-carousel-col">
      <div class="filters-carousel-wrapper">
        <swiper-container
          class="filters-carousel-swiper"
          appSwiper
          init="false"
          #swiperFilters
          [config]="swiperFiltersConfig">
          <swiper-slide class="swiper-slide" *ngFor="let filter of filters">
            <button [ngClass]="{ filter: true, active: catSelection === filter }" (click)="onFilter(filter)">
              {{ filter }}
            </button>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid category-carousel">
  <div class="row">
    <div class="col-12" *ngFor="let filter of filters">
      <ng-container *ngIf="catSelection === filter">
        <swiper-container class="category-carousel-swiper" init="false" appSwiper #swiper [config]="swiperConfig">
          <swiper-slide class="swiper-slide" *ngFor="let card of filteredData">
            <app-portrait-card [data]="card" [section]="heading"></app-portrait-card>
          </swiper-slide>
        </swiper-container>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="category-carousel-pagination">
        <div class="category-carousel-prev">
          <app-round-buttons
            icon="chevron-left"
            color="blue"
            (buttonSelected)="trackCategoryCarouselAmplitude('Click Prev')"></app-round-buttons>
        </div>
        <div class="category-carousel-next">
          <app-round-buttons
            icon="chevron-right"
            color="blue"
            (buttonSelected)="trackCategoryCarouselAmplitude('Click Next')"></app-round-buttons>
        </div>
        <div class="section-cta d-block d-md-none">
          <a [routerLink]="'/search'" class="mobile-section-cta">View All Tours</a>
        </div>
      </div>
    </div>
  </div>
</div>
