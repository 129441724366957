import { AfterContentInit, Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-custom-input-checkbox',
  templateUrl: './custom-input-checkbox.component.html',
  styleUrls: ['../custom-form.scss', 'custom-input-checkbox.component.scss'],
})
export class CustomInputCheckboxComponent implements AfterContentInit {
  @Input() controlName: string;
  @Input() label?: string;
  @Input() termsConditions?: boolean;
  @Input() required = false;
  control: FormControl;
  safeLabel: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngAfterContentInit() {
    this.control = new FormControl(false, this.required ? Validators.requiredTrue : null);
    this.safeLabel = this.sanitizer.bypassSecurityTrustHtml(this.label);
  }
}
