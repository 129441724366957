import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { ThrowSwalCheckout } from '../models/error-handler.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
      console.log(error);
    }
    // return an observable with a user-facing error message
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  /**
   *
   * @param title - "generic" || "Some custom text"
   * @param icon - 'warning' || 'success' || 'info' || 'question' - Design directive "Please don't use error, always use warning it just looks friendlier"
   * @param reason - Appends your text / html to a short sentance. Keep it short generic what went wrong. IE: "create cart" it will become "There was an unexpected error when trying to create cart"
   * @param includeCSLink - Includes a generic footer with link to CS
   */
  public throwSwal(title: string, icon: SweetAlertIcon, reason: string, includeCSLink?: boolean) {
    const contactCsFooter = `<p>We apologise for any inconvenience caused. If you require assistance, please contact our <a href="/contact-us">friendly customer service team</a>.`;

    title.toLowerCase() === 'generic' ? (title = 'Something has gone wrong') : null;

    const notification: ThrowSwalCheckout = {
      icon: icon,
      title: title,
      html: `<p>There was an unexpected error when trying to ${reason}</p>`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: '#0986c8',
      customClass: {
        container: 'throw-error-generic',
        confirmButton: 'throw-error-generic-button',
      },
    };

    includeCSLink ? (notification.footer = contactCsFooter) : null;

    void Swal.fire(notification);
  }
}
