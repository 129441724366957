import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '@search/ui/ui.module';
import { SearchConfig } from '@search/dto';
import { SearchStoreModule } from '@search/store/search-store.module';
import { PAGINATION_CONFIG_TOKEN } from '@pagination/store/store.module';
import { SearchInputComponent } from '@search/ui/search-input/search-input.component';
import { SEARCH_CONFIG_TOKEN } from '@search/store/search.state';

@NgModule({
  exports: [SearchInputComponent],
  imports: [CommonModule, SearchStoreModule, UiModule],
})
export class SearchModule {
  static forFeature(config: SearchConfig) {
    return {
      ngModule: SearchModule,
      providers: [
        {
          provide: SEARCH_CONFIG_TOKEN,
          useValue: config,
        },
        {
          provide: PAGINATION_CONFIG_TOKEN,
          useValue: config.paginationCfg,
        },
      ],
    };
  }
}
