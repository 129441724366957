import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CheckoutStateModel, IPaymentMethod, OptionalExtra, TourItem } from '@app/feature/checkout/dto/types';
import { async, combineLatest, Observable } from 'rxjs';
import { ReviewModel } from '@models/review.model';
import { ReviewsService } from '@app/services/reviews.service';
import { SubSink } from 'subsink';
import { CheckoutService } from '@app/feature/checkout/services/checkout.service';
import { CheckoutState } from '@app/feature/checkout/store/checkout.state';
import { Store } from '@ngxs/store';
import { GeoModel } from '@models/geo.model';
import { PromotionData } from '@app/feature/promotion/dto/types';
import { PromotionState } from '@app/feature/promotion/store/promotion.state';
import { Price, RoomUpgradeErrorResponse } from '@models/tour.model';
import Swal from 'sweetalert2';
import {
  AddOptionalExtra,
  RemoveOptionalExtra,
  UpdateAppliedDiscountData,
  UpdateDepositPrice,
  UpdateNumPax,
  UpdatePromoStoreValues,
  UpdateReviewData,
  UpdateTotalPrice,
} from '@app/feature/checkout/store/checkout.actions';

@Component({
  selector: 'app-tour-summary',
  templateUrl: './tour-summary.component.html',
  styleUrl: './tour-summary.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class TourSummaryComponent implements OnInit, OnDestroy {
  state$: Observable<CheckoutStateModel>;
  subs: SubSink = new SubSink();
  averageRating: number;
  totalReviews: number;
  tourData: TourItem[];
  checkingPromoCodes = true;
  currentPage: number;
  departurePrice: number;
  regularPrice: number;
  numPax: number;
  geo: GeoModel;
  optionalExtrasTotal = 0;
  totalPrice: number;
  promoStore: {
    promoCode: string;
    promoCodeData: PromotionData;
  } = {
    promoCode: '',
    promoCodeData: null,
  };
  appliedDiscountData: Price;
  notLoadingOrProcessing$: Observable<boolean>;
  showMinorsInfo = false;
  optionalServices$: Observable<OptionalExtra[]>;
  roomUpgradeError: RoomUpgradeErrorResponse;
  optionalExtras$: Observable<OptionalExtra[]>;
  departureMaxTravellers = 9;
  selectedPaymentMethod$: Observable<IPaymentMethod>;
  depositPrice$: Observable<number>;

  constructor(
    private reviewService: ReviewsService,
    private checkoutService: CheckoutService,
    private store: Store
  ) {}

  ngOnInit() {
    this.state$ = this.store.select(CheckoutState.getState);
    this.notLoadingOrProcessing$ = this.store.select(CheckoutState.notProcessingOrLoading);
    this.optionalExtras$ = this.store.select(state => state.checkout.optionalExtras);
    this.optionalServices$ = this.store.select(state => state.checkout.optionalServices);
    this.selectedPaymentMethod$ = this.store.select(CheckoutState.selectedPaymentMethod);
    this.depositPrice$ = this.store.select(CheckoutState.depositPrice);

    this.store.select(CheckoutState.optionalExtrasTotal).subscribe(total => {
      this.optionalExtrasTotal = total;
    });
    this.subs.sink = this.state$.subscribe((state: CheckoutStateModel) => {
      this.tourData = state?.cartItems;
      this.currentPage = state?.currentPage;
      this.numPax = state?.cartItems[0]?.numPax;
      this.departurePrice = state?.cartItems[0]?.option?.departures[0]?.price?.amount;
      this.regularPrice = state?.cartItems[0]?.option?.departures[0]?.regularPrice?.amount;
      this.totalPrice = state?.totalPrice;
      this.geo = state?.geo;
      const availableSpots = state?.cartItems[0]?.option?.departures[0]?.availability?.spots?.available;
      this.departureMaxTravellers = availableSpots && availableSpots <= 9 ? availableSpots : 9;
      state?.appliedDiscountData?.promoCode !== undefined
        ? this.applyPromoCode(state?.appliedDiscountData)
        : (this.checkingPromoCodes = false);
      if (!state.reviewData) {
        this.getReviews(this.tourData[0]?.tourData?.reviewCode);
      } else {
        this.averageRating = state.reviewData.avgRating;
        this.totalReviews = state.reviewData.total;
      }
    });

    //For special offers or auto applied promo codes from builder IO
    this.getPromoState();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  applyPromoCode(discountData: Price) {
    this.appliedDiscountData = discountData;
    this.promoStore.promoCode = discountData.promoCode;
    this.checkingPromoCodes = false;
  }

  getReviews(reviewCode: string) {
    this.subs.sink = this.reviewService.getTourReviews(reviewCode).subscribe((data: ReviewModel) => {
      this.store.dispatch(new UpdateReviewData(data));
    });
  }

  calculateOptionalExtrasTotal(optionalExtras: OptionalExtra[]) {
    this.optionalExtrasTotal = this.checkoutService.calculateOptionalExtrasTotal(optionalExtras);
  }

  formatPrice(price: number) {
    return this.checkoutService.formatPrice(price, this.geo.currency_sign);
  }

  /**
   * Compare current option id (departure.id) with promo code data list of option id's
   * If promo code data list contains current option id, set promo code in properties to be used by promo code component
   */
  handlePromoData(promoCode: string, promoCodeData: PromotionData) {
    if (promoCodeData && promoCodeData.options) {
      const promoData = promoCodeData.options.find(option => option.id === this.tourData[0]?.optionId);
      if (promoData) {
        this.store.dispatch(new UpdatePromoStoreValues(promoData));
      }
    }
  }

  /**
   * For default promo codes like special offers or builder IO auto applied promo codes
   * Get store promo code value and data list of tour id's / option id's
   */
  getPromoState() {
    this.subs.sink = combineLatest([
      this.store.select(PromotionState.getPromoCode),
      this.store.select(PromotionState.getPromoCodeData),
    ]).subscribe({
      next: ([promoCode, promoCodeData]: [string, PromotionData]) => {
        this.handlePromoData(promoCode, promoCodeData);
      },
    });
  }

  updatePrices(data: Price) {
    this.appliedDiscountData = data;
    this.store.dispatch(new UpdateTotalPrice(data.amount));
    this.store.dispatch(new UpdateAppliedDiscountData(data));
  }

  betterOfferAvailable() {
    Swal.fire({
      icon: 'success',
      title: 'This departure has a better offer',
      html: `<p>The better discount has already been applied to your booking!</p>`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: '#0986c8',
      confirmButtonText: 'Ok',
      customClass: {
        container: 'throw-error-generic',
        confirmButton: 'throw-error-generic-button',
      },
    });
  }

  /**
   * Toggles the visibility of the minors information section. (Read more)
   */
  toggleMinors() {
    this.showMinorsInfo = !this.showMinorsInfo;
  }

  removeTourPax() {
    this.numPax = this.numPax - 1;
    this.store.dispatch(new UpdateNumPax(this.numPax));
    this.calculateDepositPrice();
    this.checkoutService.amplitudeTrackEvents(`Update Pax Number - Remove Pax`);
  }

  AddTourPax() {
    this.numPax = this.numPax + 1;
    this.store.dispatch(new UpdateNumPax(this.numPax));
    this.calculateDepositPrice();
    this.checkoutService.amplitudeTrackEvents(`Update Pax Number - Add Pax`);
  }

  /**
   * Removes an optional extra from the checkout state.
   *
   * This method performs the following steps:
   * 1. Dispatches the `RemoveOptionalExtra` action with the provided extra.
   *
   * @param {OptionalExtra} extra - The optional extra to be removed.
   */
  removeOptionalExtra(extra: OptionalExtra) {
    this.store.dispatch(new RemoveOptionalExtra(extra));
    this.calculateDepositPrice();
    this.checkoutService.amplitudeTrackEvents(`Trigger Extras - Remove ${extra.name}`);
  }

  /**
   * Adds an optional extra to the checkout state.
   *
   * This method performs the following steps:
   * 1. Dispatches the `AddOptionalExtra` action with the provided extra.
   *
   * @param {OptionalExtra} extra - The optional extra to be added.
   */
  addOptionalExtra(extra: OptionalExtra) {
    this.store.dispatch(new AddOptionalExtra(extra));
    this.calculateDepositPrice();
    this.checkoutService.amplitudeTrackEvents(`Trigger Extras - Add ${extra.name}`);
  }

  /**
   * Retrieves the number of times a specific optional extra has been chosen.
   *
   * This method performs the following steps:
   * 1. Subscribes to the `optionalExtras$` observable to get the list of optional extras.
   * 2. Finds the optional extra with the matching string id: `extraId`.
   * 3. Returns the amount (number) of the matched optional extra.
   *
   * @param {string} extraId - The ID of the optional extra to count.
   * @returns {number} - The number of times the optional extra has been chosen.
   */
  getNumberOfOptionalsChosen(extraId: string): number {
    let amount = 0;
    this.subs.sink = this.optionalExtras$.subscribe(optionalExtras => {
      const matchedExtra = optionalExtras.find(extra => extra.id === extraId);
      if (matchedExtra) {
        amount = matchedExtra.amount;
      }
    });
    return amount;
  }

  /**
   * Determines if the "Add" button should be disabled for a given optional extra.
   *
   * This method performs the following steps:
   * 1. Retrieves the current state from the store.
   * 2. Gets the number of passengers (numPax) from the cart items in the state.
   * 3. Retrieves the number of times the optional extra has been chosen.
   * 4. Finds the optional extra in the state and gets the maximum allowed amount.
   * 5. Returns true if the selected amount is greater than or equal to the number of passengers or the maximum allowed amount, otherwise false.
   *
   * @param {string} extraId - The ID of the optional extra to check.
   * @returns {boolean} - True if the "Add" button should be disabled, otherwise false.
   */
  isAddButtonDisabled(extraId: string): boolean {
    const state = this.store.snapshot().checkout;
    const numPax = state.cartItems[0]?.numPax || 1;
    const selectedAmount = this.getNumberOfOptionalsChosen(extraId);
    const optionalExtra = state.optionalExtras.find(extra => extra.id === extraId);
    const maximumAllowed = optionalExtra?.maximumAllowed || numPax;
    return selectedAmount >= numPax || selectedAmount >= maximumAllowed;
  }

  calculateDepositPrice() {
    const tenPercentPrice = this.departurePrice * this.numPax * 0.1 + this.optionalExtrasTotal * 0.1;
    const minDeposit = this.checkoutService.minDepositViaCurrency(this.geo.currency) * this.numPax;
    this.store.dispatch(new UpdateDepositPrice(Math.max(tenPercentPrice, minDeposit)));
  }

  calculateOutstandingPrice() {
    let outstandingPrice: number;
    this.subs.sink = this.depositPrice$.subscribe(depositPrice => {
      outstandingPrice = this.totalPrice + this.optionalExtrasTotal - depositPrice;
    });
    return outstandingPrice;
  }
}
