<div class="travel-inspiration">
  <h3 class="center red">{{ heading }}</h3>
  <div class="tab-sub-text" [innerHTML]="subText"></div>
  <div class="tab-container">
    <div class="tab-header">
      <app-tabs
        class="d-none d-md-inline-block"
        *ngFor="let tab of tabs; let i = index"
        [isActiveSlide]="i === selectedTab"
        [text]="tab"
        [index]="i"
        [total]="tabs.length"
        (tabSelected)="selectTab(i)">
      </app-tabs>
    </div>
    <div class="tab-content-container" *ngIf="data">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="tab-content" *ngIf="selectedTab === 0">
              <div class="tab-sub-text" [innerHTML]="travelStoriesText"></div>
              <swiper-container
                class="travel-inspiration-carousel-swiper"
                appSwiper
                init="false"
                #swiper
                [config]="swiperConfig">
                <swiper-slide class="swiper-slide" *ngFor="let card of data">
                  <app-personal-review-card
                    [icon]="card.image"
                    [name]="card.shortTextOne"
                    [title]="card.shortTextTwo"
                    [text]="card.longTextOne"></app-personal-review-card>
                </swiper-slide>
              </swiper-container>
              <div class="travel-inspiration-pagination">
                <div class="travel-inspiration-prev">
                  <app-round-buttons
                    icon="chevron-left"
                    color="blue"
                    (buttonSelected)="trackTravelInspirationAmplitude('Click Travel Stories Prev')"></app-round-buttons>
                </div>
                <div class="travel-inspiration-next">
                  <app-round-buttons
                    icon="chevron-right"
                    color="blue"
                    (buttonSelected)="trackTravelInspirationAmplitude('Click Travel Stories Next')"></app-round-buttons>
                </div>
                <a
                  routerLink="/blog/category/travel-story/"
                  class="button button-yellow section-cta d-none d-md-inline-block w-unset"
                  (click)="onViewTravelStories()"
                  title="Travel Stories">
                  See Travel Stories
                </a>
                <div class="section-cta d-block d-md-none">
                  <a
                    routerLink="/blog/category/travel-story/"
                    class="mobile-section-cta"
                    (click)="onViewTravelStories()"
                    title="Travel Stories">
                    See Travel Stories
                  </a>
                </div>
              </div>
            </div>
            <div class="tab-content" *ngIf="selectedTab === 1">
              <div class="tab-sub-text" [innerHTML]="tipsText"></div>
              <img
                *ngIf="blogsLoading"
                class="img-fluid blogs-loading"
                src="https://images.ctfassets.net/huwbudysxepr/62mhn3rxjG1HlQ5gfO93DR/e3f25c6d4739bd188db55888a4955673/search-loader.gif"
                alt="..Loading" />
              <app-cta
                *ngIf="blogsFailed"
                cta="solid"
                color="yellow"
                customClass="blog-fallback"
                content="View Our Blogs"
                (buttonSelected)="onBlogFallback()"></app-cta>
              <ng-container *ngIf="fetchedBlogs">
                <swiper-container
                  class="travel-inspiration-tips-carousel-swiper"
                  appSwiper
                  init="false"
                  #swiperTips
                  [config]="swiperConfigTips">
                  <swiper-slide class="swiper-slide" *ngFor="let blog of blogs">
                    <app-portrait-card
                      [data]="blog"
                      [background]="true"
                      [blog]="true"
                      [section]="heading"></app-portrait-card>
                  </swiper-slide>
                </swiper-container>
                <div class="travel-inspiration-pagination">
                  <div class="travel-blogs-prev">
                    <app-round-buttons
                      icon="chevron-left"
                      color="blue"
                      (buttonSelected)="trackTravelInspirationAmplitude('Click Blog Prev')"></app-round-buttons>
                  </div>
                  <div class="travel-blogs-next">
                    <app-round-buttons
                      icon="chevron-right"
                      color="blue"
                      (buttonSelected)="trackTravelInspirationAmplitude('Click Blog Next')"></app-round-buttons>
                  </div>
                  <a
                    routerLink="/blog"
                    class="button button-yellow section-cta d-none d-md-inline-block w-unset"
                    (click)="onViewBlogs()"
                    title="View Blog">
                    See our blogs
                  </a>
                  <div class="section-cta d-block d-md-none">
                    <a routerLink="/blog" class="mobile-section-cta" (click)="onViewBlogs()" title="View Blog">
                      See our blogs
                    </a>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
