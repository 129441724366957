<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
  <mask id="mask0_2139_2829" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="15">
    <path d="M15 0H0V15H15V0Z" fill="white" />
  </mask>
  <g mask="url(#mask0_2139_2829)">
    <path
      d="M14.6076 11.7646L10.343 7.50001L14.6076 3.23383C14.8589 2.9825 15 2.64165 15 2.28625C15 1.93085 14.8589 1.59 14.6076 1.33867L13.6598 0.392447C13.4085 0.141165 13.0676 0 12.7122 0C12.3568 0 12.016 0.141165 11.7646 0.392447L7.50001 4.65706L3.23461 0.392447C2.98328 0.141165 2.64243 0 2.28703 0C1.93163 0 1.59078 0.141165 1.33945 0.392447L0.392447 1.34023C0.141165 1.59156 0 1.93241 0 2.28781C0 2.64321 0.141165 2.98406 0.392447 3.23539L4.65706 7.50001L0.392447 11.7646C0.141165 12.016 0 12.3568 0 12.7122C0 13.0676 0.141165 13.4085 0.392447 13.6598L1.34023 14.6076C1.59156 14.8589 1.93241 15 2.28781 15C2.64321 15 2.98406 14.8589 3.23539 14.6076L7.50001 10.343L11.7646 14.6076C12.016 14.8589 12.3568 15 12.7122 15C13.0676 15 13.4085 14.8589 13.6598 14.6076L14.6076 13.6598C14.8589 13.4085 15 13.0676 15 12.7122C15 12.3568 14.8589 12.016 14.6076 11.7646Z"
      fill="#D32027" />
  </g>
</svg>
