import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';

import { CheckoutConfig } from '../configs/checkout.config';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  private config = new CheckoutConfig();
  buttonVisible = new BehaviorSubject<boolean>(true); //Used for disabling /showing buttons in checkout forms
  error = new BehaviorSubject<string>(null); //Error messaging
  warning = new BehaviorSubject<string>(null); //Warning messaging
  success = new BehaviorSubject<string>(null); //Success messaging
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  constructor(private http: HttpClient) {}

  /**
   * Step 1 checkout create cart when user goes to step 2.
   * @param tourId {string} - Lemax tourId
   * @param optionId {string} - Lemax optionId (aka departure id)
   * @param data {object} - {
   *     "currencyCode": string,
   *     "numberOfPax": number,
   *     "optionals": string[] //array of strings, id's of optional extras (services in Lemax)
   * }
   */
  createCart(tourId: string, optionId: string, data: any) {
    return this.http.post(this.config.urls.createCart(tourId, optionId), data);
  }

  /**
   * Generates a HMAC (Hash-based Message Authentication Code) for securing payment transactions.
   * This method sends a POST request to the server to generate a HMAC based on the provided parameters.
   *
   * @param currency The currency code (e.g., "USD", "EUR") for the transaction.
   * @param amount The total amount of the transaction.
   * @param reference A unique reference for the booking associated with this transaction.
   * @returns An Observable containing the response from the server, typically the generated HMAC string.
   */
  generateHMAC(currency: string, amount: number, reference: string) {
    return this.http.post(this.config.urls.generateHMAC(), {
      currency: currency,
      amount: amount,
      reference: reference,
    });
  }

  /**
   * For use in checkout step 1 currently: "Lemax services"
   * Multi purpose function: Either don't send promo code and get just optional extras back.
   * Or send promo code and get promo code validation back.
   * @param tourId - Lemax tour id string
   * @param optionId - Lemax option id string
   * @param currency - Currency string: ZAR / USD / CAD etc
   * @param promoCode - OPTIONAL promoCode, if given will return promoCode validation
   */
  getTourOptionalServices(tourId: string, optionId: string, currency: string, promoCode?: string) {
    return this.http.get(this.config.urls.getTourOptionalServices(tourId, optionId, currency, promoCode));
  }

  /**
   * Converts a legacy TCMS bookingId into a lemax id. To be used in Payments page.
   * @param bookingId - TourCMS Booking ID that you want converted into it's Lemax booking ID equivalent
   */
  convertLegacyBookingId(bookingId: string) {
    return this.http.get(this.config.urls.convertLegacyBookingId(bookingId));
  }

  /**
   * Generated by getCreateTempBookingUrl() and inserted into URL parameters of step 1 after the redirect and subsequently saved into session storage
   * @param bookingId {string} - bookingId
   */
  getBooking(bookingId: string) {
    return new Promise((resolve, reject) => {
      const UniqueName = 'getBooking' + bookingId;
      if (sessionStorage.getItem(UniqueName)) {
        return resolve(JSON.parse(sessionStorage.getItem(UniqueName)));
      } else {
        this.http.get(this.config.urls.getBooking(bookingId)).subscribe({
          next: response => {
            sessionStorage.setItem(UniqueName, JSON.stringify(response));
            resolve(response);
          },
          error: error => {
            reject(error);
          },
        });
      }
    });
  }

  /**
   * on payments page in url structure is expected like this: /checkout/payments?bookingRef=e836c81a-e9a0-4efd-9413-a0004da7cf00&currency=ZAR
   * @param bookingId (bookingRef)- e836c81a-e9a0-4efd-9413-a0004da7cf00
   */
  getBookingLemax(bookingId: string) {
    return this.http.get(this.config.urls.getBookingLemax(bookingId));
  }

  /**
   * Generated by getCreateTempBookingUrl() and inserted into URL parameters of step 1 after the redirect and subsequently saved into session storage
   * @param bookingId {string} - bookingId
   * @param tcmsChannel {string} - Tour cms channel, ie : 288 / 7115 etc
   */
  getBookingExternal(bookingId: string, tcmsChannel: string) {
    return new Promise((resolve, reject) => {
      const UniqueName = 'getBookingExternal' + bookingId;
      if (sessionStorage.getItem(UniqueName)) {
        return resolve(JSON.parse(sessionStorage.getItem(UniqueName)));
      } else {
        this.http.get(this.config.urls.getBookingExternal(bookingId, tcmsChannel)).subscribe({
          next: response => {
            sessionStorage.setItem(UniqueName, JSON.stringify(response));
            resolve(response);
          },
          error: error => {
            reject(error);
          },
        });
      }
    });
  }

  /**
   * @param date {any} returns like: September 26, 2024
   */
  formatDate(date: any) {
    date = date.split('T')[0].split('-');
    return date[2] + ' ' + this.months[date[1] - 1] + ' ' + date[0];
  }

  /**
   * returns like: R 31,240.00
   * @param price {string}
   * @param currencySign {string} - R | $ etc
   */
  formatPrice(price, currencySign: any) {
    if (typeof price === 'undefined') {
      return '';
    }
    price = parseFloat(price);
    return currencySign + ' ' + price.toFixed(2).replace(/\B(?=(\d{3})+\b)/g, ',');
  }

  /**
   * Each currency has a minimum deposit amount
   * @param iso3Currency {string} - 'GBP'
   */
  minDepositViaCurrency(iso3Currency: any) {
    switch (iso3Currency) {
      case 'GBP':
        return 50;
      case 'ZAR':
        return 1000;
      case 'CAD':
        return 110;
      case 'AUD':
        return 110;
      case 'NZD':
        return 110;
      case 'EUR':
        return 75;
      default: // default for USD or any other currency
        return 110;
    }
  }

  /**
   * Subtracts days from iso date string
   * @param isoDate {string} - 2024-09-22T00:00:00.000Z
   * @param days {number} - 2024-09-22T00:00:00.000Z
   */
  subtractDays(isoDate: string, days: number) {
    const myDate = new Date(isoDate);
    myDate.setDate(myDate.getDate() - days);
    return myDate;
  }

  /**
   * Returns currency code. Defaults to USD (7115) if unknown.
   * @param channel_id {number} - 33 / 288 / 8833 / 3220 / 4931 / 12852
   */
  channelMap(channel_id: any) {
    switch (parseInt(channel_id)) {
      case 33: // GBP
        return 'GBP';
      case 288: // ZAR
        return 'ZAR';
      case 8833: // CAD
        return 'CAD';
      case 3220: // AUD
        return 'AUD';
      case 4931: // EUR
        return 'EUR';
      case 12852: // NZD
        return 'NZD';
      default:
        return 'USD';
    }
  }

  /**
   * Convert Currency code to currency symbol
   * @param iso3Currency {string} - ZAR / GBP / CAD / AUD / NZD / EUR
   * Returns like £ / R / CA$ / AU$ / NZ$ / € / US$
   */
  convertISO3(iso3Currency: string) {
    switch (iso3Currency) {
      case 'GBP':
        return '£';
      case 'ZAR':
        return 'R';
      case 'CAD':
        return 'CA$';
      case 'AUD':
        return 'AU$';
      case 'NZD':
        return 'NZ$';
      case 'EUR':
        return '€';
      default: // default for USD or any other currency
        return 'US$';
    }
  }

  makeBookingLemax(shoppingCartId: string, bookingData: any) {
    return this.http.post(this.config.urls.makeBookingLemax(shoppingCartId), bookingData);
  }

  makePaymentLemax(bookingId: string, bookingData: any) {
    return this.http.post(this.config.urls.makePaymentLemax(bookingId), bookingData);
  }

  /**
   * @param customerData {object} with properties of below
   * @param step {number} 2 | 3 - step 2 is for when user goes from step 2 to step 3 & we have their form field details.
   * Step 3 is when a user successfully booked and sees success page
   * ---------------------------------------------------------------------
   * 	"bookingId": required
   * 	"tourCode": required for step 2 only
   * 	"startDate": required for step 2 only
   * 	"endDate": required for step 2 only
   * 	"title": required for step 2 only
   * 	"firstName": required for step 2 only
   * 	"lastName": required for step 2 only
   * 	"email": required for step 2 only
   * 	"phoneNumber": required for step 2 only
   * 	"country": required for step 2 only
   * 	--------------------------------------------------------------------
   */
  cartAbandonment(step: number, customerData: any) {
    this.http.post(this.config.urls.cartAbandonment(step), customerData).subscribe({});
  }
}
