<div class="loaderWrapper" *ngIf="loaderState">
  <img
    class="img-fluid account-loading-image"
    src="https://images.ctfassets.net/huwbudysxepr/62mhn3rxjG1HlQ5gfO93DR/e3f25c6d4739bd188db55888a4955673/search-loader.gif"
    alt="..Loading" />
</div>
<section class="app-section" [ngClass]="{ 'no-loader': !loaderState }" cdkScrollable>
  <div class="app-header" *ngIf="activeModule.showExpatMenu">
    <app-header></app-header>
  </div>
  <div class="app-body">
    <router-outlet></router-outlet>
  </div>
</section>
<app-footer *ngIf="activeModule.showExpatFooter"></app-footer>
