import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentStoreModule } from '@content/store/store.module';
import { Store } from '@ngxs/store';

@NgModule({
  declarations: [],
  imports: [CommonModule, ContentStoreModule],
})
export class ContentModule {
  constructor(private store: Store) {
    // we want to proactively load this content once on module initialization
    // if (!this.store.selectSnapshot(ContentState.getLoadedState)) {
    //   this.store.dispatch(new LoadCompactToursContent());
    // } else {
    //   console.log('Content already loaded');
    // }
  }
}
