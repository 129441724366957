<div class="container-fluid signup-section">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <h3 *ngIf="heading && heading.length">{{ heading }}</h3>
      <p [innerHTML]="description" *ngIf="description && description.length"></p>
      <form id="lead-gen-form" method="post" name="leadGenForm" [formGroup]="leadGenForm" (ngSubmit)="onSubmit()">
        <div
          class="inner justify-content-center flex-wrap"
          [ngClass]="{
            'vertical-form': formDirection === 'vertical',
            'horizontal-form': formDirection === 'horizontal',
          }">
          <div
            class="form-group"
            [class.error]="leadGenForm.get('firstName').invalid && leadGenForm.get('firstName').touched"
            [class.valid]="leadGenForm.get('firstName').valid && leadGenForm.get('firstName').touched"
            [class.touched]="leadGenForm.get('firstName').touched">
            <input
              name="firstName"
              id="firstName"
              type="text"
              placeholder="First Name"
              formControlName="firstName"
              (focus)="onInputFocus()"
              (blur)="onInputBlur()" />
            <label for="firstName">First Name</label>
            <div class="error-description">Please enter your first name</div>
          </div>
          <div
            class="form-group"
            [class.error]="leadGenForm.get('email').invalid && leadGenForm.get('email').touched"
            [class.valid]="leadGenForm.get('email').valid && leadGenForm.get('email').touched"
            [class.touched]="leadGenForm.get('email').touched">
            <input
              name="email"
              id="email"
              type="email"
              autocomplete="on"
              placeholder="pat@example.com"
              formControlName="email"
              (input)="onEmailChange()"
              (focus)="onInputFocus()"
              (blur)="onInputBlur()" />
            <label for="firstName">Email</label>
            <div class="error-description">Please enter valid email</div>
          </div>
          <div class="button-group">
            <button class="yellow-button-gradient" type="submit" [disabled]="!leadGenForm.valid" *ngIf="!processing">
              Join Now
            </button>
            <img
              class="img-fluid signup-loading-image"
              src="https://images.ctfassets.net/huwbudysxepr/62mhn3rxjG1HlQ5gfO93DR/e3f25c6d4739bd188db55888a4955673/search-loader.gif"
              alt="..Loading"
              *ngIf="processing" />
          </div>
        </div>
        <ng-container *ngIf="formSubmitted && !processing">
          <div class="alert alert-success" role="alert" *ngIf="showSuccess">
            Welcome to the <strong>Expat Explore family</strong>!
          </div>
          <div class="alert alert-success" role="alert" *ngIf="showResubscribed">
            Check your inbox to confirm that you would like to resubscribe this email
            <small>( {{ leadGenForm.get('email').getRawValue() }} )</small>
          </div>
          <div class="alert alert-danger" role="alert" *ngIf="showError">
            Something has gone wrong, please get in touch if problem persists.
          </div>
          <div class="alert alert-info" role="alert" *ngIf="showInfo">
            This email <small>( {{ leadGenForm.get('email').getRawValue() }} )</small> has already been signed up.
          </div>
        </ng-container>
        <p class="lead-gen-form-privacy-policy">
          By submitting this form you agree to our
          <a [routerLink]="['/privacy-policy']" title="Privacy Policy">Privacy Policy</a>.
          <br />
          <img
            class="grecaptcha-logo"
            src="https://images.ctfassets.net/zggpk8714k6l/pQXqaXjduMfmcKMZTdUMq/d5552c37daccd7da2a9b814328b40333/RecaptchaLogo.svg"
            alt="Protected by Google reCAPTCHA" />
        </p>
      </form>
    </div>
  </div>
</div>
