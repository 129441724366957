import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import {
  LoadGeoData,
  LoadPromoCodeData,
  LoadPromoCodeStyles,
  SetPromoCode,
  SetGeoData,
  ClearPromoItems,
  SetPromoData,
} from '@app/feature/promotion/store/promotion.actions';
import { Inject, Injectable } from '@angular/core';
import { DeparturesModel } from '@models/departures.model';
import { catchError, retry, tap } from 'rxjs/operators';
import { PromotionConfig } from '@app/feature/promotion/services/promotion.config';
import { HttpClient } from '@angular/common/http';
import { first, of, take } from 'rxjs';
import { PromotionData, PromotionStateModel } from '@app/feature/promotion/dto/types';
import { builderSdk } from '@app/feature/promotion/services/builder-sdk-injection-token';
import { Builder } from '@builder.io/sdk';
import { GeoService } from '@app/services/geo.service';
import { GeoModel } from '@models/geo.model';

const defaultPromotionState = {
  promoCode: null,
  data: {
    tours: [
      {
        price: { amount: 0, currency: '' },
        id: '',
        slug: '',
      },
    ],
    options: [],
  },
  styles: {
    name: '',
    key: '',
    defaultBgColor: '',
    defaultTextColor: '',
    selectedBgColor: '',
    selectedTextColor: '',
    img: '',
  },
  geo: null,
};

@State<PromotionStateModel>({
  name: 'promoCode',
  defaults: defaultPromotionState,
})
@Injectable()
export class PromotionState {
  constructor(
    private promoConfig: PromotionConfig,
    private http: HttpClient,
    private geoService: GeoService,
    @Inject(builderSdk) private builder: Builder
  ) {}

  @Selector()
  static getPromoCode(state: PromotionStateModel) {
    return state?.promoCode;
  }

  @Selector()
  static getPromoCodeData(state: PromotionStateModel) {
    if (state?.data) {
      return state?.data;
    } else {
      return defaultPromotionState.data;
    }
  }

  static getPromoDataForSlug(slug: string) {
    return createSelector([PromotionState.getPromoCodeData], (state: PromotionData) => {
      return state?.tours?.find(tour => tour.slug === slug);
    });
  }

  @Selector()
  static getPromoDepartureCodes(state: PromotionStateModel) {
    return state.data?.options?.map(option => option.depCode) || [];
  }

  // Creates a quick access lookup for promo departure codes which makes this O(n) instead of O(n^2) for downstream use
  @Selector()
  static getPromoDepartureLookup(state: PromotionStateModel) {
    const result = new Map<string, boolean>();
    state?.data.options?.forEach(option => {
      result.set(`${option.depCode}`, true);
    });
    return result;
  }

  @Selector()
  static getPromoStyles(state: PromotionStateModel) {
    return state?.styles;
  }

  @Selector()
  static getGeo(state: PromotionStateModel) {
    return state.geo;
  }

  @Action(ClearPromoItems)
  clearPromoItems(ctx: StateContext<PromotionStateModel>) {
    ctx.patchState(defaultPromotionState);
  }

  @Action(SetPromoCode)
  setPromoCode(ctx: StateContext<PromotionStateModel>, action: SetPromoCode) {
    ctx.patchState({ promoCode: action.promoCode });
    ctx.dispatch(new LoadGeoData()).pipe(take(1)).subscribe();
    return true;
  }

  @Action(LoadPromoCodeStyles)
  async loadPromoStyles(ctx: StateContext<PromotionStateModel>) {
    const res = await this.builder.get('dates-rates-campaign-colours').promise();
    ctx.patchState({ styles: res?.data });
    return res;
  }

  @Action(LoadPromoCodeData)
  loadPromoCodeData(ctx: StateContext<PromotionStateModel>, action: LoadPromoCodeData) {
    const state = ctx.getState();
    const currency = state.geo?.currency;

    this.getPromoDepartureData(action.promoCode, currency)
      .pipe(
        first(),
        tap((data: PromotionData) => {
          if (data?.tours?.length) {
            ctx.dispatch(new SetPromoData(data));
          } else {
            ctx.dispatch(new ClearPromoItems());
            this.handleError(data);
          }
        }),
        catchError(err => {
          ctx.dispatch(new ClearPromoItems());
          return this.handleError(err);
        })
      )
      .subscribe();
  }

  getPromoDepartureData(promoCode: string, currency: string) {
    return this.http.get<DeparturesModel>(this.promoConfig.urls.getPromoDepartureData(promoCode, currency)).pipe(
      retry(1),
      catchError(err => this.handleError(err))
    );
  }

  @Action(SetPromoData)
  setPromoData(ctx: StateContext<PromotionStateModel>, action: SetPromoData) {
    ctx.patchState({ data: action.promoData });
  }

  @Action(LoadGeoData)
  loadGeoData(ctx: StateContext<PromotionStateModel>) {
    this.geoService.geoData.subscribe((data: GeoModel) => {
      if (data) {
        ctx.dispatch(new SetGeoData(data));
      }
    });
  }

  @Action(SetGeoData)
  setGeoData(ctx: StateContext<PromotionStateModel>, action: SetGeoData) {
    ctx.patchState({ geo: action.geoData });
    ctx.dispatch([new LoadPromoCodeStyles(), new LoadPromoCodeData(ctx.getState().promoCode)]);
  }

  handleError(err: any) {
    console.error(err);
    return of(err);
  }
}
