import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  loaderToggle = new EventEmitter();
  opacStatus: boolean;

  setSiteLoader(state, solid = false) {
    this.opacStatus = solid;
    this.loaderToggle.emit(state);
  }
}
