import { Injectable } from '@angular/core';

import { GeoService } from '@app/services/geo.service';

import { SubSink } from 'subsink';
import { StateContext, Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';
import { GeoModel } from '@models/geo.model';
import { SetGeoData } from '@app/feature/checkout/store/checkout.actions';
import { CartAbandonmentEmailData, CheckoutStateModel, OptionalExtra } from '@app/feature/checkout/dto/types';
import { HttpClient } from '@angular/common/http';
import { CheckoutConfig } from '@app/feature/checkout/dto/checkout.config';
import { BehaviorSubject } from 'rxjs';
import { AmplitudeService } from '@app/services/amplitude.service';
import { CheckoutState } from '@app/feature/checkout/store/checkout.state';
import { environment } from '@environments/environment.prod';

@Injectable()
export class CheckoutService {
  private subs = new SubSink();
  error = new BehaviorSubject<string>(null);
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  constructor(
    private geoService: GeoService,
    private http: HttpClient,
    private config: CheckoutConfig,
    private readonly store: Store,
    private readonly amplitudeService: AmplitudeService
  ) {}

  /**
   * Subscribes to the geoData observable and dispatches the SetGeoData action.
   * @param ctx
   * @private
   */
  getGeoData(ctx: StateContext<CheckoutStateModel>) {
    this.subs.sink = this.geoService.geoData.pipe(filter(value => !!value)).subscribe((data: GeoModel) => {
      if (data) {
        ctx.dispatch(new SetGeoData(data));
      }
    });
  }

  /**
   *To unsubscribe from all subscriptions when the state is destroyed. Called from checkout.state.ts:
   */
  unsubscribe() {
    this.subs.unsubscribe();
  }

  /**
   * Each currency has a minimum deposit amount
   * @param iso3Currency {string} - 'GBP'
   */
  minDepositViaCurrency(iso3Currency: any) {
    switch (iso3Currency) {
      case 'GBP':
        return 50;
      case 'ZAR':
        return 1000;
      case 'CAD':
        return 110;
      case 'AUD':
        return 110;
      case 'NZD':
        return 110;
      case 'EUR':
        return 75;
      default: // default for USD or any other currency
        return 110;
    }
  }

  /**
   * Optional promo code if not added you get optional extras back.
   * Optional promo code if added we get promo code validation back.
   * @param tourId - Lemax tour id string
   * @param optionId - Lemax option id string
   * @param currency - Currency string: ZAR / USD / CAD etc
   * @param promoCode - OPTIONAL promoCode, if given will return promoCode validation
   */
  getOptionPrices(tourId: string, optionId: string, currency: string, promoCode?: string) {
    return this.http.get(this.config.urls.getTourOptionalServices(tourId, optionId, currency, promoCode));
  }

  /**
   * Step 1 checkout create cart when user goes to step 2.
   * @param tourId {string} - Lemax tourId
   * @param optionId {string} - Lemax optionId (aka departure id)
   * @param data {object} - {
   *     "currencyCode": string,
   *     "numberOfPax": number,
   *     "optionals": string[] //id's of optional extras ("services" in Lemax)
   * }
   */
  createCart(tourId: string, optionId: string, data: any) {
    return this.http.post(this.config.urls.createCart(tourId, optionId), data);
  }

  /**
   * returns 31240 as: R 31,240.00
   * @param price {number}
   * @param currencySign {string} - R | $ etc
   */
  formatPrice(price: number, currencySign: string) {
    if (typeof price === 'undefined') {
      return '';
    }
    price = parseFloat(price.toString());
    return currencySign + ' ' + price.toFixed(2).replace(/\B(?=(\d{3})+\b)/g, ',');
  }

  /**
   * Calculate total price of optional extras
   * @param optionalExtras {OptionalExtra[]}
   */
  calculateOptionalExtrasTotal(optionalExtras: OptionalExtra[]) {
    let total = 0;
    optionalExtras.forEach(extra => {
      total += extra.price.amount * extra.amount;
    });
    return total;
  }

  /**
   * Calculate total price based on payment type. DOES NOT CALCULATE INSTALMENT TOTALS.
   * @param paymentType - 'deposit' | 'full'
   * @param departurePrice - price of the departure
   * @param numPax - number of passengers
   * @param optionalExtrasTotal - total price of optional extras
   */
  calculateTotalPrice(paymentType: string, departurePrice: number, numPax: number, optionalExtrasTotal: number) {
    return paymentType === 'deposit'
      ? (departurePrice * numPax + optionalExtrasTotal) * 0.1
      : departurePrice * numPax + optionalExtrasTotal;
  }

  /**
   * Generates a HMAC (Hash-based Message Authentication Code) for securing payment transactions.
   * This method sends a POST request to the server to generate a HMAC based on the provided parameters.
   *
   * @param currency The currency code (e.g., "USD", "EUR") for the transaction.
   * @param amount The total amount of the transaction.
   * @param reference A unique reference for the booking associated with this transaction.
   * @returns An Observable containing the response from the server, typically the generated HMAC string.
   */
  generateHMAC(currency: string, amount: number, reference: string) {
    return this.http.post(this.config.urls.generateHMAC(), {
      currency: currency,
      amount: amount,
      reference: reference,
    });
  }

  makeBookingLemax(shoppingCartId: string, bookingData: any) {
    return this.http.post(this.config.urls.makeBookingLemax(shoppingCartId), bookingData);
  }

  /**
   * Converts a legacy TCMS bookingId into a lemax id. To be used in Payments page.
   * @param bookingId - TourCMS Booking ID that you want converted into it's Lemax booking ID equivalent
   */
  convertLegacyBookingId(bookingId: string) {
    return this.http.get(this.config.urls.convertLegacyBookingId(bookingId));
  }

  makePaymentLemax(bookingId: string, bookingData: any) {
    return this.http.post(this.config.urls.makePaymentLemax(bookingId), bookingData);
  }

  /**
   * on payments page in url structure is expected like this: /checkout/payments?bookingRef=e836c81a-e9a0-4efd-9413-a0004da7cf00&currency=ZAR
   * @param bookingId (bookingRef)- e836c81a-e9a0-4efd-9413-a0004da7cf00
   */
  getBookingLemax(bookingId: string) {
    return this.http.get(this.config.urls.getBookingLemax(bookingId));
  }

  /**
   * Convert Currency code to currency symbol
   * @param iso3Currency {string} - ZAR / GBP / CAD / AUD / NZD / EUR
   * Returns like £ / R / CA$ / AU$ / NZ$ / € / US$
   */
  convertISO3(iso3Currency: string) {
    switch (iso3Currency) {
      case 'GBP':
        return '£';
      case 'ZAR':
        return 'R';
      case 'CAD':
        return 'CA$';
      case 'AUD':
        return 'AU$';
      case 'NZD':
        return 'NZ$';
      case 'EUR':
        return '€';
      default:
        return 'US$';
    }
  }

  /**
   * Subtracts days from iso date string
   * @param isoDate {string} - 2024-09-22T00:00:00.000Z
   * @param days {number} - 2024-09-22T00:00:00.000Z
   */
  subtractDays(isoDate: string, days: number) {
    const myDate = new Date(isoDate);
    myDate.setDate(myDate.getDate() - days);
    return myDate;
  }

  /**
   * @param date {any} returns like: September 26, 2024
   */
  formatDate(date: any) {
    date = date.split('T')[0].split('-');
    return date[2] + ' ' + this.months[date[1] - 1] + ' ' + date[0];
  }

  trackCheckoutNav(event: string, step?: string) {
    const state = this.store.selectSnapshot(CheckoutState.getState);
    const stepData = {
      event: event,
      'checkout-step': step ? `step-${step}` : `step-${state?.currentPage}`,
      'num-pax': state?.cartItems[0]?.numPax,
      'pay-type': state?.selectedPaymentMethod?.id,
      'pay-method': 'Credit Card',
      currency: state?.geo?.currency,
      'tour-name': state?.cartItems[0]?.tourData?.tourName,
      'tour-code': state?.cartItems[0]?.tourData?.tourCode,
    };
    this.amplitudeService.trackEvent('Checkout Navigation', stepData);
  }

  trackCheckoutErrorLog(code: number, message: string) {
    const state = this.store.selectSnapshot(CheckoutState.getState);

    const stepData = {
      'checkout-step': `step-${state?.currentPage}`,
      currency: state?.geo?.currency,
      'error-code': code,
      'error-message': message,
      'pay-amount': state?.selectedPaymentMethod?.data['amount'],
      'pay-method': 'Credit Card',
      'pay-type': state?.selectedPaymentMethod?.id,
      'tour-name': state?.cartItems[0]?.tourData?.tourName,
      'tour-code': state?.cartItems[0]?.tourData?.tourCode,
    };
    this.amplitudeService.trackEvent('Checkout Error Log', stepData);
  }

  amplitudeTrackEvents(event: string) {
    const state = this.store.selectSnapshot(CheckoutState.getState);
    const trackingData = {
      event: event,
      'checkout-step': `step-${state?.currentPage}`,
      'num-pax': state?.cartItems[0]?.numPax,
      'pay-type': state?.selectedPaymentMethod?.id,
      currency: state?.geo?.currency,
      'tour-name': state?.cartItems[0]?.tourData?.tourName,
      'tour-code': state?.cartItems[0]?.tourData?.tourCode,
      'return-traveller': state?.returningTraveller,
      'promo-code': state?.appliedDiscountData?.promoCode ?? null,
      upgrades: state?.optionalExtras?.map(service => service.name),
    };
    this.amplitudeService.trackEvent('Checkout Step Interaction', trackingData);
  }

  gaTrackEvents(event: string) {
    const state = this.store.selectSnapshot(CheckoutState.getState);
    const gaData = {
      event: event,
      ecommerce: {
        currency: state?.geo?.currency,
        value: state?.totalPrice.toFixed(2),
        coupon: state?.appliedDiscountData?.promoCode ?? null,
        items: [
          {
            item_id: state?.cartItems[0]?.option.departures[0].code,
            item_name: state?.cartItems[0]?.tourData.tourName,
            item_category: state?.cartItems[0]?.tourData.multiTourPageCustomBreadcrumb,
            coupon: state?.appliedDiscountData?.promoCode ?? null,
            discount: state?.appliedDiscountData?.discount ?? null,
            price: (state?.totalPrice / state?.cartItems[0].numPax).toFixed(2),
            quantity: state?.cartItems[0]?.numPax,
          },
        ],
      },
    };

    dataLayer.push({ ecommerce: null });
    dataLayer.push(gaData);
  }

  gaTrackPurchases(event: string, amount?: number) {
    const state = this.store.selectSnapshot(CheckoutState.getState);
    const gaData = {
      event: event,
      ecommerce: {
        transaction_id: state?.bookingSuccessData?.bookingId,
        payment_type: state?.selectedPaymentMethod?.id,
        currency: state?.geo?.currency,
        value: amount ?? state?.totalPrice?.toFixed(2),
        coupon: state?.appliedDiscountData?.promoCode ?? null,
        items: [
          {
            item_id: state?.cartItems[0]?.option?.departures[0].code,
            item_name: state?.cartItems[0]?.tourData?.tourName,
            item_category: state?.cartItems[0]?.tourData?.multiTourPageCustomBreadcrumb,
            coupon: state?.appliedDiscountData?.promoCode ?? null,
            discount: state?.appliedDiscountData?.discount ?? null,
            price: (state?.totalPrice / state?.cartItems[0]?.numPax).toFixed(2),
            quantity: state?.cartItems[0].numPax,
          },
        ],
        user_data: {
          email: state?.travellerDetails?.email,
          phone_number: `+${state?.travellerDetails?.countryPhone?.dialCode} ${state?.travellerDetails?.countryPhone?.contactNumber}`,
          address: {
            first_name: state?.travellerDetails?.firstName,
            last_name: state?.travellerDetails?.lastName,
            street: state?.travellerDetails?.streetName,
            city: state?.travellerDetails?.cityTown,
            postal_code: state?.travellerDetails?.zipCode,
            country: state?.travellerDetails?.country,
          },
        },
      },
    };

    dataLayer.push({ ecommerce: null });
    dataLayer.push(gaData);
  }

  gaTrackPayment(event: string, paymentData: any) {
    const gaData = {
      event: event,
      ecommerce: {
        transaction_id: paymentData.booking_id,
        payment_type: paymentData.formOfPayment,
        currency: paymentData.price.currency,
        value: parseFloat(paymentData.price.amount).toFixed(2),
      },
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push(gaData);
  }

  /**
   * @param customerData {object} with properties of below
   * @param step {number} 2 | 3 - step 2 is for when user goes from step 2 to step 3 & we have their form field details.
   * Step 3 is when a user successfully booked and sees success page
   * ---------------------------------------------------------------------
   *  "bookingId": required
   *  "tourCode": required for step 2 only
   *  "startDate": required for step 2 only
   *  "endDate": required for step 2 only
   *  "title": required for step 2 only
   *  "firstName": required for step 2 only
   *  "lastName": required for step 2 only
   *  "email": required for step 2 only
   *  "phoneNumber": required for step 2 only
   *  "country": required for step 2 only
   *  --------------------------------------------------------------------
   */
  cartAbandonment(step: number, customerData: CartAbandonmentEmailData) {
    this.http.post(this.config.urls.cartAbandonment(step), customerData).subscribe({});
  }

  amplitudeCartAbandonment(step: number, customerData: CartAbandonmentEmailData) {
    this.amplitudeService.trackEvent('Checkout Cart Abandonment', {
      step: step,
      data: customerData,
    });
  }
}
