import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ImageText } from '../../../models/contentful-common.model';

@Component({
  selector: 'app-reasons-blocks',
  templateUrl: './reasons-blocks.component.html',
  styleUrls: ['./reasons-blocks.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReasonsBlocksComponent {
  @Input() data: ImageText[];
  @Input() heading: string;
}
