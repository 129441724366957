<div class="footer-border-container" *ngIf="data">
  <div class="membership-carousel">
    <h3>Memberships</h3>
    <div class="container-fluid">
      <div class="row">
        <swiper-container class="membership-carousel-swiper" appSwiper init="false" #swiper [config]="swiperConfig">
          <swiper-slide class="swiper-slide" *ngFor="let member of data">
            <div class="col-12 col-md-6 membership-col text-center">
              <img class="img-fluid" [src]="member.image.url + '?fm=webp'" [alt]="member.image.description" />
              <p *ngIf="member.shortTextOne">
                <strong [innerHTML]="member.shortTextOne"></strong>
              </p>
              <div *ngIf="member.longTextOne" [innerHTML]="member.longTextOne"></div>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
      <div class="membership-carousel-pagination" [ngClass]="{ hide: isDesktop && data.length <= 2 }">
        <div class="membership-swiper-prev">
          <app-round-buttons icon="chevron-left" color="blue"></app-round-buttons>
        </div>
        <div class="membership-swiper-next">
          <app-round-buttons icon="chevron-right" color="blue"></app-round-buttons>
        </div>
      </div>
    </div>
    <div class="bottom-tear">
      <img
        [src]="desktopImage.url + '?h=500&w=1920&fit=fill&fm=webp'"
        class="img-fluid tear-image"
        *ngIf="desktopImage && isDesktop"
        [alt]="desktopImage.description" />
      <div class="mobile-footer" *ngIf="!isDesktop">
        <picture>
          <source media="(min-width: 850px)" [srcset]="mobileImage.url + '?h=300&w=1200&fit=fill&fm=webp'" />
          <source media="(min-width: 750px)" [srcset]="mobileImage.url + '?h=300&w=750&fit=fill&fm=webp'" />
          <source media="(min-width: 650px)" [srcset]="mobileImage.url + '?h=300&w=650&fit=fill&fm=webp'" />
          <source media="(min-width: 550px)" [srcset]="mobileImage.url + '?h=300&w=550&fit=fill&fm=webp'" />
          <img
            [src]="mobileImage.url + '?h=300&w=450&fit=fill&fm=webp'"
            class="img-fluid tear-image"
            [alt]="mobileImage.description" />
        </picture>
      </div>
      <img
        src="https://images.ctfassets.net/huwbudysxepr/55Y2VGupU10wXoBUnt9RmY/bd2d292abbc02a751059dec86c854636/Herotear_White.svg"
        class="tear-overlay"
        alt="Tear Overlay" />
    </div>
  </div>
</div>
