import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { SubSink } from 'subsink';
import { combineLatest, Observable } from 'rxjs';
import {
  AmplitudeCheckoutStepData,
  BookingSuccessResponse,
  CheckoutStateModel,
  CommitBookingData,
  IPaymentMethod,
  ReservationData,
  TourItem,
  TravellerDetails,
  TrustPaymentResponse,
  TrustPaymentsConfig,
} from '@app/feature/checkout/dto/types';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SeoService } from '@app/services/seo.service';
import { AmplitudeService } from '@app/services/amplitude.service';
import { CheckoutService } from '@app/feature/checkout/services/checkout.service';
import { ErrorHandlerService } from '@app/services/error-handler.service';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import {
  SendCartAbandonmentEmail,
  SetCheckoutLoading,
  SetCheckoutProcessing,
  SetCurrentPage,
  UpdateBookingSuccessResponse,
  UpdatePaymentMethod,
  UpdateVisitedSuccessPage,
} from '@app/feature/checkout/store/checkout.actions';
import { CheckoutState } from '@app/feature/checkout/store/checkout.state';
import { GeoModel } from '@models/geo.model';
import { environment } from '@environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { PaymentTerms } from '@touraxis/wbp-client';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss', '../../styles/checkout-common.scss'],
})
export class Step2Component implements OnInit, OnDestroy {
  subs: SubSink = new SubSink();
  state$: Observable<CheckoutStateModel>;
  tpConfig: TrustPaymentsConfig;
  processing = false;
  instalmentProcessing = false;
  bookingData: CommitBookingData;
  geo: GeoModel;
  cartItems: TourItem[];
  currentPath: string;
  transactionreference: string;
  commitBookingError = false;
  paymentMethodReady = false;
  paymentProcessing = true;
  reservationData$: Observable<ReservationData>;
  travellerDetails$: Observable<TravellerDetails>;
  geo$: Observable<GeoModel>;
  cartItems$: Observable<TourItem[]>;
  hasVisitedSuccessPage$: Observable<boolean>;
  notProcessingOrLoading$: Observable<boolean>;
  loading = true;

  hasVisitedSuccessPage: boolean;
  notProcessingOrLoading: boolean;

  constructor(
    private router: Router,
    private store: Store,
    private seoService: SeoService,
    private amplitudeService: AmplitudeService,
    private checkoutService: CheckoutService,
    private errorService: ErrorHandlerService,
    private renderer: Renderer2,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.subs.sink = this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
        }
      });
    }
  }

  ngOnInit() {
    this.renderer.setStyle(document.body, 'height', '100%');
    this.renderer.setStyle(document.body, 'background-color', '#FFFFFF');
    this.currentPath = this.document.location['origin'] + this.location.prepareExternalUrl(this.router.url);

    this.store.dispatch(new SetCurrentPage(2));
    this.state$ = this.store.select(CheckoutState.getState);
    this.notProcessingOrLoading$ = this.store.select(CheckoutState.notProcessingOrLoading);
    this.hasVisitedSuccessPage$ = this.store.select(CheckoutState.hasVisitedCheckoutSuccess);
    this.reservationData$ = this.store.select(CheckoutState.reservationData);
    this.travellerDetails$ = this.store.select(CheckoutState.travellerDetails);
    this.geo$ = this.store.select(CheckoutState.geoData);
    this.cartItems$ = this.store.select(CheckoutState.cartItems);

    this.subs.add(
      this.notProcessingOrLoading$.subscribe(notProcessingOrLoading => {
        this.notProcessingOrLoading = notProcessingOrLoading;
      }),
      this.hasVisitedSuccessPage$.subscribe(hasVisitedSuccessPage => {
        this.hasVisitedSuccessPage = hasVisitedSuccessPage;
      }),
      this.store.select(CheckoutState.hasVisitedCheckoutSuccess).subscribe(hasVisitedSuccess => {
        if (hasVisitedSuccess) {
          this.showUserHasVisitedSuccessPageError();
        }
      })
    );

    this.checkParams(this.currentPath).then((response: string) => {
      this.handleUrlParameters(response);
    });

    this.checkoutService.trackCheckoutNav('Checkout Step Loaded');
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.renderer.removeStyle(document.body, 'height');
    this.renderer.removeStyle(document.body, 'background-color');
  }

  handleUrlParameters(response: string) {
    const state = this.store.snapshot().checkout;
    if (response === 'success') {
      //Commit to lemax & track events
      this.populateLemaxBooking(state);
      this.commitBooking(state, this.bookingData);
    } else {
      this.store.dispatch(new UpdateVisitedSuccessPage(false));

      //To update TP config whenever a payment method is selected essentially
      this.subs.sink = combineLatest([
        this.store.select(CheckoutState.selectedPaymentMethod),
        this.reservationData$,
        this.travellerDetails$,
        this.geo$,
      ]).subscribe(([paymentMethod, reservationData, travellerDetails, geo]) => {
        this.populateTrustPayments(this.currentPath, paymentMethod, reservationData, travellerDetails, geo);
        this.store.dispatch(new SetCheckoutLoading(false));
        this.store.dispatch(new SetCheckoutProcessing(false));
        this.loading = false;
      });
      this.cartAbandonment(state);
    }
  }

  /**
   * Users have landed on step 2, there are no payment parameters in the url
   * meaning step 2 information is available, and we can send it along cart abandonment email notification service
   */
  cartAbandonment(state: CheckoutStateModel) {
    const customerData = {
      bookingId: state?.reservationData?.id,
      tourCode: state?.cartItems[0]?.tourData?.tourCode,
      startDate: state?.cartItems[0]?.option?.attributes?.periodStart,
      endDate: state?.cartItems[0]?.option?.attributes?.periodEnd,
      title: state?.travellerDetails?.title,
      firstName: state?.travellerDetails?.firstName,
      lastName: state?.travellerDetails?.lastName,
      email: state?.travellerDetails?.email,
      phoneNumber:
        '(+' +
        state?.travellerDetails?.countryPhone.dialCode +
        ') ' +
        state?.travellerDetails?.countryPhone.contactNumber,
      country: state?.travellerDetails?.country,
    };
    this.store.dispatch(new SendCartAbandonmentEmail(2, customerData));
  }

  /**
   * Populates the booking data required for Lemax booking.
   *
   * This method prepares the booking data to be sent to Lemax for booking confirmation.
   * It extracts and formats necessary information from the component's state, including
   * customer details, payment information, and booking specifics. The booking data is
   * structured to match Lemax's expected format, ensuring compatibility and successful
   * data transmission.
   *
   * The method sets the `bookingData` property of the component with the formatted booking
   * information, ready to be used in the booking process. This includes the booking note,
   * external identifier, form of payment, booking ID, payable amount, currency, start and
   * end dates of the tour, payment type, and detailed customer information.
   */
  populateLemaxBooking(state: CheckoutStateModel) {
    this.bookingData = {
      payment_type: state.selectedPaymentMethod.id,
      amount: this.getPaymentAmount(state.selectedPaymentMethod),
      externalIdentifier: this.transactionreference,
      formOfPayment: 'Credit card',
      booking_id: state.reservationData.id,
      currency: state.geo.currency,
      start_date: state.cartItems[0].option.attributes.periodStart,
      end_date: state.cartItems[0].option.attributes.periodEnd,
      bookingNote: state.travellerDetails.message,
      customerEmail: state.travellerDetails.email,
      customer: {
        title: state.travellerDetails.title,
        first_name: state.travellerDetails.firstName,
        last_name: state.travellerDetails.lastName,
        email: state.travellerDetails.email,
        phone: state.travellerDetails.countryPhone.contactNumber.toString(),
        countryCode: state.geo.country,
        street: state.travellerDetails.streetName,
        postalCode: state.travellerDetails.zipCode,
        city: state.travellerDetails.cityTown,
      },
    } as CommitBookingData;

    if (state?.selectedPaymentMethod.id === 'instalment') {
      const selectedPaymentMethodData = state?.selectedPaymentMethod?.data ?? {};
      const lastObject = Object.values(selectedPaymentMethodData).pop() ?? {};
      this.bookingData.firstPaymentDate = lastObject?.['dates'][0];
      this.bookingData.baseAmount = lastObject['baseAmount'] as number;
      this.bookingData.lastPaymentDeadline = lastObject['lastPaymentDeadline'] as string;
      this.bookingData.maxInstalments = lastObject['maxInstalments'] as number;
      this.bookingData.unit = 'MONTH';
      this.bookingData.frequency = 1;
    }
  }

  /**
   * Populates the Trust Payments configuration for the checkout process.
   *
   * This method prepares the necessary configuration for integrating with Trust Payments.
   * It sets up various parameters required for the payment process, including site reference,
   * profile, rule identifier, version, order reference, and success URL redirect among others.
   * These parameters are crucial for initiating and completing the payment process securely.
   *
   * The `path` parameter is used to specify the URL to which the user should be redirected upon
   * successful payment. This is particularly important for ensuring a smooth user experience
   * by guiding the user to the appropriate next step in the application flow after payment.
   *
   * @param {string} path - The URL to redirect to after a successful payment from component.
   * @param {IPaymentMethod} paymentMethod - The selected payment method from state.
   * @param {ReservationData} reservationData - The reservation data from state.
   * @param {TravellerDetails} travellerDetails - The traveller's details from state.
   * @param {GeoModel} geo - The geo data from state.
   * */
  populateTrustPayments(
    path: string,
    paymentMethod: IPaymentMethod,
    reservationData: ReservationData,
    travellerDetails: TravellerDetails,
    geo: GeoModel
  ) {
    this.tpConfig = {
      sitereference: environment.trustPaymentSiteRef,
      stprofile: 'default',
      stdefaultprofile: 'st_paymentcardonly',
      ruleidentifier: 'STR-6',
      version: '2',
      orderreference: reservationData.id,
      successfulurlredirect: path,
      mainamount: this.getPaymentAmount(paymentMethod)?.toFixed(2),
      currencyiso3a: geo.currency,
      billingstreet: travellerDetails.streetName,
      billingtown: travellerDetails.cityTown,
      billingcountryiso2a: geo.country,
      billingfirstname: travellerDetails.firstName,
      billinglastname: travellerDetails.lastName,
      billingemail: travellerDetails.email,
      billingtelephone: `+${travellerDetails.countryPhone.dialCode}${travellerDetails.countryPhone.contactNumber.toString()}`,
      sitesecurity: '',
      sitesecuritytimestamp: '',
    } as TrustPaymentsConfig;

    if (paymentMethod?.id === 'instalment') {
      const data = paymentMethod.data;
      const lastKey = Object.keys(data)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .pop();
      const lastObject = data[lastKey];
      if (lastObject?.['dates'] && lastObject?.['dates'].length > 0) {
        this.tpConfig.subscriptionbegindate = dayjs(lastObject?.['dates'][0]).format('YYYY-MM-DD');
        this.tpConfig.subscriptionfinalnumber = lastObject?.['maxInstalments'];
        this.tpConfig.subscriptiontype = 'RECURRING';
        this.tpConfig.subscriptionunit = 'MONTH';
        this.tpConfig.subscriptionnumber = 1;
        this.tpConfig.subscriptionfrequency = 1;
        this.tpConfig.credentialsonfile = 1;
      }
    }
    this.paymentMethodReady = true;
  }

  getPaymentAmount(paymentMethod: IPaymentMethod): number {
    if (paymentMethod?.id === 'deposit') {
      const depositData = paymentMethod.data as PaymentTerms['deposit'];
      return depositData?.['amount'] || 0;
    } else if (paymentMethod?.id === 'full') {
      const fullData = paymentMethod.data as PaymentTerms['full'];
      return fullData?.['fullAmount'] ?? 0;
    } else {
      const instalmentData = paymentMethod as IPaymentMethod;
      if (instalmentData?.data) {
        const lastKey = Object.keys(instalmentData.data)
          .sort((a, b) => parseInt(a) - parseInt(b))
          .reverse()[0];
        const lastObject = instalmentData.data[lastKey];
        return lastObject?.adjustedDeposit || 0;
      }
      return 0;
    }
  }

  /**
   * Checks if the payment parameters are present in the URL.
   * If the parameters indicate a successful payment, it commits the booking to Lemax.
   * Otherwise, it displays the payment button to the user, allowing them to attempt payment.
   *
   * This function is designed to be called during the component's initialization phase,
   * specifically in the ngOnInit lifecycle hook, to determine the next steps based on URL parameters.
   *
   * The URL parameters are expected to include 'errorcode' and 'settlestatus' among others,
   * which are used to determine the success of the payment process.
   *
   * - If 'errorcode' is '0' (indicating no error) and 'settlestatus' is not '3' (indicating payment is settled),
   *   the function proceeds to commit the booking by calling `commitBooking` with the payment ID and booking data.
   * - If the required parameters are not present or do not indicate a successful payment,
   *   the function resolves the promise with `null` and sets `showProcessing` to false to show the payment button.
   */
  checkParams(path: string) {
    return new Promise(resolve => {
      try {
        const url = new URL(path);
        const params: TrustPaymentResponse = {};
        for (const [key, value] of url.searchParams) {
          params[key] = value;
        }
        // Check if the successful parameters are in the URL
        if (Object.keys(params).length > 0 && params.errorcode === '0' && params.settlestatus !== '3') {
          this.transactionreference = params.transactionreference;
          resolve('success');
        } else {
          resolve(null);
        }
      } catch (error) {
        resolve(null);
      }
    });
  }

  commitBooking(state: CheckoutStateModel, bookingData: CommitBookingData) {
    this.checkoutService.makeBookingLemax(state.reservationData.id, bookingData).subscribe({
      next: (response: BookingSuccessResponse) => {
        this.store.dispatch(new SetCheckoutLoading(true));
        this.store.dispatch(new SetCheckoutProcessing(true));
        this.store.dispatch(new UpdateBookingSuccessResponse(response));
        const state = this.store.snapshot().checkout;
        this.trackBookingEvent(state);
        this.trackCheckoutPayment(state);
        this.trackBookingRevenue(state);
        this.checkoutService.trackCheckoutNav('Pay Now click');

        this.checkoutService.gaTrackEvents('add_payment_info');
        this.checkoutService.gaTrackPurchases('payment');
        this.checkoutService.gaTrackPurchases('purchase', this.getPaymentAmount(state.selectedPaymentMethod));

        this.router.navigateByUrl('/checkout/success');
      },
      error: (error: HttpErrorResponse) => {
        this.commitBookingError = true;
        this.loading = false;
        this.store.dispatch(new SetCheckoutLoading(false));
        this.store.dispatch(new SetCheckoutProcessing(false));
        this.logCommitBookingFailed(error);
      },
    });
  }

  showUserHasVisitedSuccessPageError() {
    Swal.fire({
      icon: 'warning',
      title: 'You have successfully reserved your seats.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: '#0986c8',
      customClass: {
        container: 'throw-error-generic',
        confirmButton: 'throw-error-generic-button',
      },
      didClose: () => {
        this.router.navigate([`/checkout/success`]);
      },
    });
  }

  onProcessingChange(processing: boolean) {
    this.processing = processing;
    this.updatePaymentProcessing();
  }

  onInstalmentLoadingChange(instalmentLoading: boolean) {
    this.instalmentProcessing = instalmentLoading;
    this.updatePaymentProcessing();
  }

  updatePaymentProcessing() {
    this.paymentProcessing = this.processing || this.instalmentProcessing;
  }

  logCommitBookingFailed(error: HttpErrorResponse): void {
    this.checkoutService.trackCheckoutErrorLog(error.status, error.message);
  }

  /**
   * Triggers a Google Analytics event to track a successful purchase.
   * This method should be called after a purchase has been successfully completed.
   * It constructs and sends an event to Google Analytics with details about the purchase,
   * including transaction ID, currency, total value, and items purchased.
   *
   * Note: This method is currently not implemented and serves as a placeholder for future analytics integration.
   * Implementers should replace the placeholder logic with actual Google Analytics event tracking code.
   */

  trackBookingEvent(state: CheckoutStateModel) {
    const purchaseEventData = {
      'booking-id': state.bookingSuccessData.bookingId,
      'booking-code': state.bookingSuccessData.bookingCode,
      currency: state.geo.currency,
      value: parseFloat(state.totalPrice.toString()).toFixed(2),
      'payment-form': 'Credit Card',
      'payment-type': state.selectedPaymentMethod.id,
      'tour-name': state.cartItems[0].tourData.tourName,
      'tour-code': state.cartItems[0].tourData.tourCode,
      'promo-code': state.appliedDiscountData?.promoCode ?? null,
      quantity: state.cartItems[0].numPax,
    };
    this.amplitudeService.trackEvent('Booking Success Event', purchaseEventData);
  }

  trackCheckoutPayment(state: CheckoutStateModel) {
    const stepData = {
      'checkout-step': `step-${state.currentPage}`,
      currency: state.geo.currency,
      'pay-amount': this.getPaymentAmount(state.selectedPaymentMethod)?.toFixed(2),
      'pay-method': 'Credit Card',
      'pay-type': state.selectedPaymentMethod.id,
      'tour-name': state.cartItems[0].tourData.tourName,
      'tour-code': state.cartItems[0].tourData.tourCode,
      'booking-id': state.bookingSuccessData.bookingId,
      'booking-code': state.bookingSuccessData.bookingCode,
      'departure-code': state.cartItems[0].option.departures[0].code,
      'num-pax': state.cartItems[0].numPax,
      'promo-code': state.appliedDiscountData?.promoCode ?? null,
    };
    this.amplitudeService.trackEvent('Checkout Payment Event', stepData);
  }

  trackBookingRevenue(state: CheckoutStateModel) {
    const { optionalExtras, cartItems, appliedDiscountData } = state;
    const { numPax, option, tourData } = cartItems[0];
    const { conversionTracking } = option;
    const { fromPrice } = conversionTracking;

    const privateRoomUpgrade = optionalExtras?.find(extra => extra.name === 'Private Room Upgrade');
    const upgradedCount = privateRoomUpgrade?.amount || 0;
    const regularCount = numPax - upgradedCount;

    const regularPrice = fromPrice?.amount || 0;
    const upgradedPrice =
      upgradedCount > 0 ? regularPrice + (privateRoomUpgrade?.conversionTracking?.price?.amount || 0) : 0;

    const promoCode = appliedDiscountData?.promoCode;
    const isValidPromoCode = promoCode && promoCode !== '[none]';

    if (upgradedCount > 0) {
      this.amplitudeService.trackRevenue(
        tourData.tourCode,
        upgradedPrice,
        upgradedCount,
        true,
        isValidPromoCode ? promoCode : undefined
      );
    }

    if (regularCount > 0) {
      this.amplitudeService.trackRevenue(
        tourData.tourCode,
        regularPrice,
        regularCount,
        false,
        isValidPromoCode ? promoCode : undefined
      );
    }
  }
}
