import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';

import { AuthService } from '@app/services/auth.service';
import { MenusService } from '@app/services/menus.service';
import { GeoService } from 'src/app/services/geo.service';

import { MainMenuItemModel, MenuSectionType } from '@models/main-menu.model';
import { SuggestionsResponseModel } from '@models/suggestions.model';
import { mobileMenuShadow, mobileMenuSlide } from '@app/animations';
import { GeoModel } from 'src/app/models/geo.model';

import { isExternalLink } from '../../../lib/utils';
import { AmplitudeService } from '@app/services/amplitude.service';
import { SearchGroup } from '@search/dto';
import { builder } from '@builder.io/sdk';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [mobileMenuSlide, mobileMenuShadow],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('menuContainer') menuContainer: ElementRef;
  @ViewChild('navNotice') navNotice: ElementRef;

  @HostListener('window:scroll', []) onWindowScroll() {
    this.navFixed = (document.documentElement.scrollTop || document.body.scrollTop || 0) > this.scrollOffset;
    this.menuService.navFixed = this.navFixed;
    this.menuService.setMenuHeight(this.getMenuHeight());
  }

  isAuth0Loading$: Observable<boolean>;
  isAuthenticated$: Observable<boolean>;

  geo: GeoModel;
  menuParts: MainMenuItemModel[];
  activeItemIndex: number;
  activeSubSectionIndex = 0;
  noticeAcknowledged = true;
  mobileMenuOpen = false;
  mobileSubOpen = false;
  activeSubMobileIndex = 0;
  activeSubInnerIndex = 0;
  navFixed = false;
  isTourPage = false;
  currentLocation: string;
  private scrollOffset = 70;
  searchGroups: SearchGroup[];
  searchSuggestions: SuggestionsResponseModel;
  gridRows = 3;
  screenWidth: number = window.innerWidth;
  subNavItemTakeOverConfig = {
    subNavTab: '',
    backgroundColour: '',
    textColour: '',
    subItemUrl: '',
    subItemTitle: '',
    imageUrl: '',
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private geoService: GeoService,
    public auth0: AuthService,
    private menuService: MenusService,
    public router: Router,
    public elRef: ElementRef,
    public amplitudeService: AmplitudeService
  ) {
    //current location to avoid using window.location because angular universal breaks as it has no header
    this.router.events.subscribe(() => {
      this.currentLocation = this.router.url.toString();
    });

    if (isPlatformBrowser(this.platformId)) {
      this.isAuth0Loading$ = this.auth0.isAuth0Loading$;
      this.isAuthenticated$ = this.auth0.isAuthenticated$;
      this.getBuilderSubNavConfig();
    }
  }

  /**
   * Get the sub nav config from Builder.io
   * Intended for promotions tab where in campaigns like black friday make the background black and append a black friday sub nav item.
   * This does string matching on sub nav tab name ie: "Promotions" and checks if the value from builderIO matches that tab name.
   */
  getBuilderSubNavConfig() {
    builder
      .get('sub-nav-item')
      .promise()
      .then(data => {
        if (data?.data) {
          const { subNavTab, backgroundColour, textColour, subItemUrl, subItemTitle, imageUrl } = data.data;
          Object.assign(this.subNavItemTakeOverConfig, {
            subNavTab,
            backgroundColour,
            textColour,
            subItemUrl,
            subItemTitle,
            imageUrl,
          });
        }
      })
      .catch(() => {
        console.log('error fetching sub nav item from builder');
      });
  }

  ngOnInit(): void {
    this.geoService.geoData.subscribe((data: GeoModel) => {
      if (data) {
        this.geo = data;
      }
    });
    this.menuService.mainMenu.subscribe((mainMenu: { menuParts: MainMenuItemModel[] }) => {
      this.menuParts = mainMenu.menuParts;
    });
    if (sessionStorage.getItem('noticeAcknowledged')) {
      this.noticeAcknowledged = true;
    }
    this.menuService.isTourPage.subscribe({
      next: value => {
        this.isTourPage = value === true;
      },
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const height = this.menuContainer?.nativeElement?.offsetHeight || 0;
      this.menuService.setMenuHeight(height);

      const swiperWrapper = this.elRef.nativeElement.querySelector('.menu-display-panel');
      if (swiperWrapper) {
        const resizeObserver = new ResizeObserver(() => {
          this.headerResize();
        });
        resizeObserver.observe(swiperWrapper);
      }
    }, 1400);
  }

  headerResize() {
    const swiperWrapper = this.elRef.nativeElement.querySelector('.menu-display-panel');
    if (swiperWrapper.offsetHeight > 550) {
      this.gridRows = 3;
    } else if (swiperWrapper.offsetHeight > 415) {
      this.gridRows = 2;
    } else {
      this.gridRows = 1;
    }
  }

  toggleMobileMenu(trigger: string) {
    this.mobileMenuOpen = !this.mobileMenuOpen;
    this.amplitudeService.trackEvent('Mobile Menu Toggle', {
      trigger: trigger,
      status: this.mobileMenuOpen ? 'Opened' : 'Closed',
    });
  }

  getMenuHeight() {
    const height = this.menuContainer ? this.menuContainer.nativeElement.offsetHeight : 0;
    this.menuService.setMenuHeight(height);
    return height;
  }

  setScreenWidth() {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth;
    }
  }

  setActive(i: number) {
    this.activeItemIndex = i;

    const menuItem = this.menuParts[i];
    this.trackMainMenuInteraction('Sub-Menu Hover', menuItem.title, menuItem.title, 'link');
  }

  noticeClosed() {
    sessionStorage.setItem('noticeAcknowledged', 'true');
    this.noticeAcknowledged = true;
    if (this.currentLocation?.indexOf('tours') !== -1) {
      //This is my most evil genius creation, tour pages have their own blue submenu
      //if you don't "scroll" then that menu cant recalculate its position and leaves a weird gap
      scrollTo(0, 1);
    }
  }

  subSectionSelection(type: string, parentIndex: number, itemIndex: number, mobileMenu = false) {
    const menuItem = this.menuParts[parentIndex];
    this.activeSubSectionIndex = itemIndex;
    this.activeSubInnerIndex = itemIndex;

    let link = '';
    switch (type) {
      case MenuSectionType.BrowseTours:
        if (menuItem.content?.length) {
          link = menuItem.content[itemIndex].title;
        }
        break;

      case MenuSectionType.AboutUs:
        if (menuItem.aboutUsBlocks?.length) {
          link = menuItem.aboutUsBlocks[itemIndex].title;
        }
        break;

      case MenuSectionType.TravelInspiration:
        if (menuItem?.travelInspirationCards?.length) {
          link = menuItem.travelInspirationCards[itemIndex].title;
        }
        break;
    }
  }

  setSubLevel(i: number) {
    this.activeSubInnerIndex = -1;
    this.activeSubMobileIndex = i === this.activeSubMobileIndex ? -1 : i;
    this.mobileSubOpen = !!i;
    const menuItem = this.menuParts[i];
    this.trackMainMenuInteraction('Mobile Sub-Menu Click', menuItem.title, menuItem.title, 'link');
  }

  closeAndResetMobileMenu() {
    this.mobileMenuOpen = false;
    this.setSubLevel(0);
  }

  handleWhatsappClick() {
    this.trackMainMenuInteraction('WhatsApp Us Click', 'WhatsApp Us', 'https://wa.me/447731943978', 'link');
    dataLayer.push({ event: 'whatsapp_us_click' });
  }

  trackMainMenuInteraction(event: string, linkText: string, linkUrl: string, type: string) {
    const eventData = {
      event: event,
      linkText: linkText,
      linkUrl: linkUrl,
      type: type,
      page: this.amplitudeService.setPageUrl(this.router.url),
    };
    this.amplitudeService.trackEvent('Main Menu Interaction', eventData);
  }

  trackSubMenuInteractionComAmplitude(event: string, parent: string, type: string, link: string) {
    const eventData = {
      event: event,
      parent: parent,
      type: type,
      link: link,
      page: this.amplitudeService.setPageUrl(this.router.url),
    };
    this.amplitudeService.trackEvent('Sub-Menu Interaction', eventData);
  }

  trackSocialLinkAmplitude(event: string, platform: string, section: string) {
    const socialData = {
      event: event,
      platform: platform,
      element: 'Header Navigation',
      section: section,
      type: 'icon',
      page: this.amplitudeService.setPageUrl(this.router.url),
    };
    this.amplitudeService.trackEvent('Social Link Interaction', socialData);
  }

  protected readonly isExternalLink = isExternalLink;
  protected readonly MenuSectionType = MenuSectionType;
}
