import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CheckoutStateModel } from '@app/feature/checkout/dto/types';
import { SubSink } from 'subsink';
import { Store } from '@ngxs/store';
import { CheckoutState } from '@app/feature/checkout/store/checkout.state';
import { take } from 'rxjs/operators';
import { GeoModel } from '@models/geo.model';
import { NgIf, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-need-assistance',
  standalone: true,
  imports: [NgOptimizedImage, NgIf],
  templateUrl: './need-assistance.component.html',
  styleUrl: './need-assistance.component.scss',
})
export class NeedAssistanceComponent implements OnInit, OnDestroy {
  state$: Observable<CheckoutStateModel>;
  subs: SubSink = new SubSink();
  geo: GeoModel;
  icon = 'https://images.ctfassets.net/zggpk8714k6l/4PbBqIZoDAdOP2mch3b7Nb/03359ecc47aec386e824037710931933/Phone.svg';

  constructor(private readonly store: Store) {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.state$ = this.store.select(CheckoutState.getState);
    this.subs.sink = this.state$.pipe(take(1)).subscribe(state => {
      this.geo = state.geo;
    });
  }
}
