/**
 * Returns true if the URL or path passed in is the same domain as the current domain.
 * This is useful to identify if a link is internal or external. Returns true for relative paths.
 * N.B. ONLY WORKS IN THE BROWSER SO CHECK FOR BROWSER ENVIRONMENT BEFORE USING.
 *
 * @param {string} urlOrPath - The URL or path to check.
 * @returns {boolean} - True if the URL or path is the same domain as the current domain.
 */
export const isCurrentDomain = (urlOrPath: string): boolean => {
  const url = new URL(urlOrPath, location.origin);
  return location.hostname === new URL(url).hostname;
};

/**
 * Returns true if the URL passed in is the same domain as the specified domain.
 * This is useful to identify if a link is part of a given domain.
 *
 * @param {string} url - The URL to check.
 * @param {string} domain - The domain to compare against.
 * @returns {boolean} - True if the URL is part of the specified domain.
 */
export const isSameDomain = (url: string, domain: string): boolean => {
  return new URL(url).hostname === domain;
};

/**
 * Returns true if the URL or path passed in is an internal link.
 * Shortcut to check if the link is either the same domain or part of the expatexplore.com domain.
 * This is needed to identify external links locally and on dev (since in dev links point to production content from contentful).
 * Returns true for relative paths.
 *
 * @param {string} urlOrPath - The URL or path to check.
 * @returns {boolean} - True if the URL or path is an internal link.
 */
export const isInternalLink = (urlOrPath: string): boolean => {
  return (
    isCurrentDomain(urlOrPath) || isSameDomain(urlOrPath, 'expatexplore.com') || isSubdomainOfExpatExplore(urlOrPath)
  );
};

/**
 * Checks if a given URL is a subdomain of expatexplore.com.
 *
 * @param {string} url - The URL to check.
 * @returns {boolean} - True if the URL is a subdomain of expatexplore.com, false otherwise.
 */
export const isSubdomainOfExpatExplore = (url: string): boolean => {
  try {
    const hostname = new URL(url).hostname;
    // Check if the hostname ends with expatexplore.com and is not exactly expatexplore.com
    return hostname.endsWith('expatexplore.com') && hostname !== 'expatexplore.com';
  } catch (error) {
    console.error('Invalid URL:', error);
    return false;
  }
};

/**
 * Returns true if the URL or path passed in is an external link.
 * This is the opposite of isInternalLink.
 *
 * @param {string} urlOrPath - The URL or path to check.
 * @returns {boolean} - True if the URL or path is an external link.
 */
export const isExternalLink = (urlOrPath: string): boolean => {
  return !isInternalLink(urlOrPath);
};

export const extractUrlSearchParams = (url: string): URLSearchParams => {
  try {
    const urlObj = new URL(url);
    return urlObj.searchParams;
  } catch (error) {
    return new URLSearchParams(url);
  }
};

/**
 * Returns true if the URL passed in has query parameters.
 *
 * @param {string} url - The URL to check.
 * @returns {boolean} - True if the URL has query parameters.
 */
export const hasQueryParams = (url: string): boolean => {
  return /\?.+=.+/.test(url) || /%3F.+%3D.+/.test(url);
};

/**
 * Formats a price to two decimal places.
 *
 * This function uses `toFixed(2)` to format the price to exactly two decimal places.
 *
 * @param {number} price - The price to be formatted.
 * @returns {string} - The formatted price with two decimal places.
 */
export function roundPriceForDisplay(price: number): string {
  return price.toFixed(2); // Return price formatted with two decimal places
}

/**
 * Checks if a property on an object is writable.
 * This is useful to check if a property can be modified which might be different in different scenarios
 * e.g. when a property is part of the Ngxs state, it is immutable
 *
 * @template T - The type of the object.
 * @param {T} obj - The object to check.
 * @param {keyof T} key - The property key to check.
 * @returns {boolean} - True if the property is writable, false otherwise.
 */
export const isWritableProperty = <T extends object>(obj: T, key: keyof T): boolean => {
  const desc = Object.getOwnPropertyDescriptor(obj, key) || {};
  return Boolean(desc.writable);
};

/**
 * Creates a sha256 hash of the message passed in and returns it as a hex encoded value
 */
export const sha256 = async (message: string) => {
  // Encode the message as a Uint8Array
  const encoder = new TextEncoder();
  const data = encoder.encode(message);

  // Compute the hash
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);

  // Convert ArrayBuffer to hex string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

  return hashHex;
};
