<div class="main-menu" #menuContainer [class.fixed]="navFixed" (window:resize)="getMenuHeight(); setScreenWidth()">
  <div class="nav-white-bar">
    <div class="container align-items-center">
      <div class="d-flex justify-content-between">
        <div class="justify-content-start align-middle d-flex align-items-center">
          <a
            routerLink=""
            title="Home"
            (click)="trackMainMenuInteraction('Home Logo Click', 'Expat Logo', 'home', 'Image')">
            <app-expat-logo />
          </a>
          <span class="logo-slogan">20 Years of<br />Memories</span>
        </div>
        <div class="d-flex align-items-center justify-content-end white-right-side">
          <div class="social-slogan d-none d-lg-flex">
            <a
              href="https://www.facebook.com/ExpatExploreTravel/"
              target="_blank"
              class="social-link hover-label ball-button"
              rel="noopener noreferrer"
              title="Facebook"
              (click)="trackSocialLinkAmplitude('Facebook Click', 'Facebook', 'Desktop Menu')">
              <span>Thank you for 240 000 likes!</span>
              <img
                class="ball-log"
                src="https://images.ctfassets.net/zggpk8714k6l/65qHdkYDWg3rdhUk7AKY23/5c87eb63e416962c9c895aa4bd5a0e35/navFacebook.svg?w=30&h=30"
                alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/expatexplore/"
              target="_blank"
              class="social-link hover-label ball-button"
              rel="noopener noreferrer"
              title="Instagram"
              (click)="trackSocialLinkAmplitude('Instagram Click', 'Instagram', 'Desktop Menu')">
              <img
                src="https://images.ctfassets.net/zggpk8714k6l/2H3QcmqvBwBYnloIVLtRF2/0c4807a1299b0f8ed7d2fc5cc7a2af37/navInstagram.svg?w=30&h=30"
                alt="Instagram" />
            </a>
            <a
              href="https://www.youtube.com/user/ExpatExploreOfficial/featured"
              target="_blank"
              class="social-link hover-label ball-button"
              rel="noopener noreferrer"
              title="YouTube"
              (click)="trackSocialLinkAmplitude('YouTube Click', 'YouTube', 'Desktop Menu')">
              <img
                src="https://images.ctfassets.net/zggpk8714k6l/6RaVxNEMyDhIgQKqNYjMIB/089e714d3ac26f049523a8a020c89afe/navYoutube.svg?w=30&h=30"
                alt="YouTube" />
            </a>
          </div>
          <div class="contact-help d-flex align-items-center justify-content-end">
            <a
              class="phone divider-item d-xl-block d-none"
              [href]="'tel:' + geo.phone"
              title="Expat Explore phone number"
              *ngIf="geo && geo.phone"
              (click)="trackMainMenuInteraction('Phone Number Click', geo.phone, 'tel:' + geo.phone, 'link')">
              {{ geo.phone }}
            </a>
            <div class="dropdown-box divider-item box-opener d-none d-xl-block">
              <app-whatsapp (whatsappClick)="handleWhatsappClick()"></app-whatsapp>
            </div>
            <a
              [routerLink]="'/contact-us'"
              title="Contact Us"
              class="dropdown-box divider-item box-opener d-xl-block d-none"
              (click)="trackMainMenuInteraction('Contact Us Click', 'Contact Us', '/contact-us', 'link')">
              <span class="box-opener">Contact Us</span>
            </a>
            <div class="dropdown-box divider-item account-divider" *ngIf="isAuth0Loading$">
              <app-login-register></app-login-register>
            </div>
            <a
              href="javascript:void(0)"
              class="divider-item mobile-nav-trigger d-lg-none d-block"
              (keyup)="toggleMobileMenu('Menu Icon Click')"
              (click)="toggleMobileMenu('Menu Icon Click')">
              <img
                class="mobile-icon"
                src="https://images.ctfassets.net/zggpk8714k6l/1aBWw6RIiTuoJ5oKncaYhI/bb4a591da2f7a2bf1a2e14469e2498eb/Group_305_3x.png?h=50"
                alt="Menu" />
            </a>
            <ng-container *ngIf="mobileMenuOpen">
              <!--Mobile Menu Overlay-->
              <div class="header-mobile-menu-container-overlay" (click)="toggleMobileMenu('Overlay Click')"></div>
              <!--Mobile Menu Structure-->
              <div class="header-mobile-menu-container-outer" @mobileMenuShadow @mobileMenuSlide>
                <div class="header-mobile-menu-container-inner" [ngClass]="{ inSub: mobileSubOpen }">
                  <img
                    class="mobile-menu mobile-menu-back-icon img-fluid"
                    (keyup)="setSubLevel(0)"
                    (click)="setSubLevel(0)"
                    src="https://images.ctfassets.net/zggpk8714k6l/4jJKBhe9v1X4rwDupUEa31/52e1afdd2d3a210798d6ce3d503caad7/Back__2_.svg?h=50&w=50"
                    alt="close back"
                    *ngIf="mobileSubOpen" />
                  <h3 class="red-fade">Menu</h3>
                  <img
                    class="mobile-menu mobile-menu-close-icon img-fluid"
                    (keyup)="toggleMobileMenu('Close Button Click')"
                    (click)="toggleMobileMenu('Close Button Click')"
                    src="https://images.ctfassets.net/zggpk8714k6l/4ClDSqKXPDk4Sf6JYawUAi/f763dafb3f7840629d2accb7a4a893c0/close.svg?h=50&w=50"
                    alt="close menu" />
                  <ul class="mobile-menu-main-list">
                    <ng-container *ngFor="let menuItem of menuParts; let i = index">
                      <!-- Direct links nav items without sub items-->
                      <li
                        *ngIf="
                          menuItem.mainMenuItem && !menuItem.hasSubMenu && menuItem.optionalUrl && menuItem.mobile
                        ">
                        <app-content-link
                          [url]="menuItem.optionalUrl"
                          [title]="menuItem.mobileTitle"
                          (keyup)="closeAndResetMobileMenu()"
                          (click)="closeAndResetMobileMenu()"
                          (linkAction)="
                            trackMainMenuInteraction(
                              'Mobile Menu Link Click',
                              menuItem.title,
                              menuItem.optionalUrl,
                              'link'
                            )
                          ">
                          <img
                            [alt]="menuItem.mobileTitle"
                            class="img-fluid"
                            [src]="
                              mobileSubOpen && i !== activeSubMobileIndex
                                ? menuItem.mobileIcons[0].nonActiveIcon.url
                                : mobileSubOpen
                                  ? menuItem.mobileIcons[0].activeIcon.url
                                  : menuItem.mobileIcons[0].standardIcon.url
                            " />
                          <span [ngClass]="{ 'new-menu-item': menuItem.newItem === true }">
                            {{ menuItem.mobileTitle }}
                          </span>
                        </app-content-link>
                      </li>
                      <!-- Nav items with sub items-->
                      <li *ngIf="menuItem.mainMenuItem && menuItem.hasSubMenu && menuItem.mobile">
                        <a
                          (click)="setSubLevel(i)"
                          class="mobile-menu-main-sub"
                          [ngClass]="{ active: i === activeSubMobileIndex }">
                          <img
                            [alt]="menuItem.mobileTitle"
                            class="img-fluid"
                            [src]="
                              mobileSubOpen && i !== activeSubMobileIndex
                                ? menuItem.mobileIcons[0].nonActiveIcon.url
                                : mobileSubOpen
                                  ? menuItem.mobileIcons[0].activeIcon.url
                                  : menuItem.mobileIcons[0].standardIcon.url
                            " />
                          <span [ngClass]="{ 'new-menu-item': menuItem.newItem === true }">
                            {{ menuItem.mobileTitle }}
                          </span>
                        </a>
                      </li>
                    </ng-container>
                    <li *ngIf="!mobileSubOpen">
                      <div class="contact-us-container">
                        <h1>Contact Us</h1>
                        <a
                          [href]="'tel:' + geo.phone"
                          class="side-icon-link"
                          (click)="
                            trackMainMenuInteraction('Mobile Phone Number Click', geo.phone, 'tel:' + geo.phone, 'link')
                          ">
                          <img
                            alt="Phone"
                            src="https://images.ctfassets.net/zggpk8714k6l/2jxY3RIID31w9KatRGbkD2/9d799919a97511b69dfd9ee33240df0c/phone.svg?w=20" />
                          {{ geo.phone }}
                        </a>
                        <a
                          href="mailto:info@expatexplore.com"
                          class="side-icon-link"
                          (click)="
                            trackMainMenuInteraction(
                              'Mobile Mail Info Click',
                              'info@expatexplore.com',
                              'mailto:info@expatexplore.com',
                              'link'
                            )
                          ">
                          <img
                            alt="Email"
                            src="https://images.ctfassets.net/zggpk8714k6l/1P3y47qu5aA9HVKAg2an0h/9d53c3549813385789d251482ee06a34/email_icon.svg?w=20" />
                          info&#64;expatexplore.com
                        </a>
                        <a
                          href="https://wa.me/447731943978"
                          target="_blank"
                          class="side-icon-link"
                          rel="noopener"
                          (click)="
                            trackMainMenuInteraction(
                              'Mobile Whatsapp Click',
                              'Send a Whatsapp',
                              'https://wa.me/447731943978',
                              'link'
                            )
                          ">
                          <img
                            alt="WhatsApp"
                            src="https://images.ctfassets.net/zggpk8714k6l/1Jnz8Ti32U4BwSqgLRSbLh/561957deb8ba26f9126cb9def266f37f/whatsapp_icon.png?h=20" />
                          Send a WhatsApp
                        </a>
                        <a
                          href="https://m.me/expatexploretravel"
                          target="_blank"
                          class="side-icon-link"
                          rel="noopener"
                          (click)="
                            trackMainMenuInteraction(
                              'Mobile Facebook Message Click',
                              'Send a Facebook Message',
                              'https://m.me/expatexploretravel',
                              'link'
                            )
                          ">
                          <img
                            alt="Facebook Message"
                            src="https://images.ctfassets.net/zggpk8714k6l/2YLfeLGNLGfNpD8CFu62rB/d38eb5caede9a7ef37f39a0e8c89f87e/FB_Messenger_icon.svg?h=20" />
                          Send a Facebook Message
                        </a>
                        <div class="gradientBorder"></div>
                        <div class="socialIcons">
                          <div class="social-bar">
                            <a
                              href="https://www.facebook.com/ExpatExploreTravel/"
                              target="_blank"
                              rel="noopener noreferrer"
                              (click)="trackSocialLinkAmplitude('Facebook Click', 'Facebook', 'Mobile Menu')">
                              <img
                                src="https://images.ctfassets.net/zggpk8714k6l/6uFoJmc3A77V06yKq33i63/40e25a13e1f5b0cb323fc137313981b2/Facebook.png?h=20"
                                alt="Facebook" />
                            </a>
                            <a
                              href="https://www.instagram.com/expatexplore/"
                              target="_blank"
                              rel="noopener noreferrer"
                              (click)="trackSocialLinkAmplitude('Instagram Click', 'Instagram', 'Mobile Menu')">
                              <img
                                src="https://images.ctfassets.net/zggpk8714k6l/fpnBXXBFKcbpmHYXibymX/7a59ce5a82c91748ef00fa4139f97fee/instagram_Icon.svg?h=20"
                                alt="Instagram" />
                            </a>
                            <a
                              href="https://www.youtube.com/user/ExpatExploreOfficial/featured"
                              target="_blank"
                              rel="noopener noreferrer"
                              (click)="trackSocialLinkAmplitude('YouTube Click', 'YouTube', 'Mobile Menu')">
                              <img
                                src="https://images.ctfassets.net/zggpk8714k6l/1XClOfCc8mIPe9Dhb0ddM2/0a4b8e4f5c55b074b378105496e60091/Youtube_Icon.svg?h=20"
                                alt="YouTube" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ng-container *ngFor="let menuItem of menuParts; let i = index">
                    <ng-container
                      *ngIf="
                        i === activeSubMobileIndex && menuItem.mainMenuItem && menuItem.hasSubMenu && menuItem.mobile
                      ">
                      <ul class="mobile-menu-inner-list">
                        <!-- Tour Content Links -->
                        <li *ngFor="let inner of menuItem.content; let iInner = index">
                          <a
                            (click)="subSectionSelection(MenuSectionType.BrowseTours, i, iInner, true)"
                            class="mobile-sub-nav"
                            [ngClass]="{ active: iInner === activeSubInnerIndex }">
                            {{ inner.title }}
                          </a>
                          <div class="mobile-sub-items" *ngIf="activeSubInnerIndex === iInner">
                            <app-content-link
                              [url]="tour.url"
                              [title]="tour.title"
                              *ngFor="let tour of inner.linkedTours"
                              (keyup)="closeAndResetMobileMenu()"
                              (click)="closeAndResetMobileMenu()"
                              (linkAction)="
                                trackSubMenuInteractionComAmplitude(
                                  'Mobile Sub-Menu Click',
                                  menuItem.title,
                                  MenuSectionType.BrowseTours,
                                  tour.title
                                )
                              ">
                              <span>{{ tour.title }}</span>
                              <small *ngIf="tour.newTour"><i>New</i></small>
                            </app-content-link>

                            <!--Builder IO scheduled item-->
                            <app-content-link
                              *ngIf="
                                subNavItemTakeOverConfig.subNavTab !== '' &&
                                menuItem.content[activeSubInnerIndex]?.title === subNavItemTakeOverConfig.subNavTab
                              "
                              [url]="subNavItemTakeOverConfig.subItemUrl"
                              [title]="subNavItemTakeOverConfig.subItemTitle"
                              (linkAction)="
                                trackSubMenuInteractionComAmplitude(
                                  'Mobile Sub-Menu Click',
                                  menuItem.title,
                                  MenuSectionType.BrowseTours,
                                  subNavItemTakeOverConfig.subNavTab
                                )
                              ">
                              <span>{{ subNavItemTakeOverConfig.subItemTitle }}</span>
                            </app-content-link>
                          </div>
                        </li>
                        <!-- About Us Content -->
                        <li *ngFor="let inner of menuItem.aboutUsBlocks; let iInner = index">
                          <a
                            (click)="subSectionSelection(MenuSectionType.AboutUs, i, iInner, true)"
                            class="mobile-sub-nav"
                            [ngClass]="{ active: iInner === activeSubInnerIndex }">
                            {{ inner.title }}
                          </a>
                          <div class="mobile-sub-items about" *ngIf="activeSubInnerIndex === iInner">
                            <div [innerHTML]="inner.content"></div>
                            <app-content-link
                              [url]="inner.linkUrl"
                              [title]="inner.title"
                              (keyup)="closeAndResetMobileMenu()"
                              (click)="closeAndResetMobileMenu()"
                              (linkAction)="
                                trackSubMenuInteractionComAmplitude(
                                  'Mobile Sub-Menu Click',
                                  menuItem.title,
                                  MenuSectionType.AboutUs,
                                  inner.linkText
                                )
                              ">
                              <span>{{ inner.linkText }}</span>
                            </app-content-link>
                          </div>
                        </li>
                        <!-- Travel Inspo Links -->
                        <li *ngFor="let inner of menuItem.travelInspirationCards; let iInner = index">
                          <app-content-link
                            [url]="inner.url"
                            [title]="inner.title"
                            class="mobile-sub-nav no-caret"
                            (keyup)="closeAndResetMobileMenu()"
                            (click)="closeAndResetMobileMenu()"
                            (linkAction)="
                              trackSubMenuInteractionComAmplitude(
                                'Mobile Sub-Menu Click',
                                menuItem.title,
                                MenuSectionType.TravelInspiration,
                                inner.title
                              )
                            ">
                            {{ inner.title }}
                            <small *ngIf="inner.newTour"><i>New</i></small>
                          </app-content-link>
                        </li>
                      </ul>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="nav-red-bar">
    <div class="container">
      <div class="row">
        <div class="col-9 d-flex align-items-center justify-content-start">
          <a
            routerLink=""
            class="navbar-brand d-lg-block d-none"
            (click)="trackMainMenuInteraction('Home Icon Click', 'Home Icon', 'home', 'Icon')">
            <img
              src="https://images.ctfassets.net/zggpk8714k6l/42z2j3aUjbto36P1dNLGEj/b2a1db2023ed83295b4221472fa3346f/Home.svg?w=25"
              alt="Home page" />
          </a>
          <ul class="red-bar-list-container d-flex align-items-center justify-content-start d-lg-block d-none">
            <li
              *ngFor="let menuItem of menuParts; let i = index"
              class="row"
              [ngStyle]="{ 'background-color': menuItem.colourConfigs ? menuItem.colourConfigs.backgroundColour : '' }">
              <ng-template
                [ngIf]="menuItem.mainMenuItem && !menuItem.hasSubMenu && menuItem.optionalUrl && menuItem.desktop">
                <!--Direct links nav items without sub items-->
                <app-content-link
                  [url]="menuItem.optionalUrl"
                  (linkAction)="
                    trackMainMenuInteraction('Menu Link Click', menuItem.title, menuItem.optionalUrl, 'link')
                  ">
                  <span [ngStyle]="{ color: menuItem.colourConfigs ? menuItem.colourConfigs.textColour : '' }">
                    {{ menuItem.title }}
                  </span>
                  <ng-container *ngIf="menuItem.newItem === true">
                    <span class="new-menu-item" style="position: relative; top: -0.7rem; right: 1.5rem">New</span>
                  </ng-container>
                </app-content-link>
              </ng-template>
              <!--Sub items with drop outs-->
              <ng-template [ngIf]="menuItem.mainMenuItem && menuItem.hasSubMenu">
                <div
                  class="has-sub-menu"
                  (mouseenter)="setActive(i)"
                  appMenuHeightCalculations
                  [ngClass]="{ 'new-menu-item': menuItem.newItem === true }">
                  {{ menuItem.title }}
                  <div class="container dropout">
                    <div class="menu-display-panel">
                      <div class="inner-panel">
                        <div class="inner-panel-height-container">
                          <div class="nav-sub-suction-heading">
                            <h3 class="red-fade">
                              <img [src]="menuItem.mobileIcons[0].activeIcon.url" [alt]="menuItem.title" />
                              {{ menuItem.title }}
                            </h3>
                          </div>
                          <!--If it has content[] then it is browse our tours or destinations template-->
                          <ng-template [ngIf]="menuItem.content && menuItem.content.length > 0">
                            <div class="row m-0 nav-sub-section-row">
                              <div class="col-lg-3 p-0 nav-sub-section-left">
                                <div
                                  *ngFor="let subSection of menuItem.content; let j = index"
                                  (mouseenter)="subSectionSelection(MenuSectionType.BrowseTours, i, j)"
                                  [ngClass]="{ activeSubsection: j === activeSubSectionIndex }"
                                  [ngStyle]="{
                                    backgroundColor:
                                      subNavItemTakeOverConfig.backgroundColour !== '' &&
                                      subNavItemTakeOverConfig.subNavTab === subSection.title
                                        ? subNavItemTakeOverConfig.backgroundColour
                                        : '',
                                    color:
                                      subNavItemTakeOverConfig.textColour !== '' &&
                                      subNavItemTakeOverConfig.subNavTab === subSection.title
                                        ? subNavItemTakeOverConfig.textColour
                                        : '',
                                  }">
                                  {{ subSection.title }}
                                </div>
                              </div>
                              <div
                                class="col-lg-9 nav-sub-section-right"
                                [ngStyle]="{
                                  backgroundColor:
                                    subNavItemTakeOverConfig.subNavTab !== '' &&
                                    subNavItemTakeOverConfig.backgroundColour !== '' &&
                                    menuItem.content[activeSubSectionIndex]?.title ===
                                      subNavItemTakeOverConfig.subNavTab
                                      ? subNavItemTakeOverConfig.backgroundColour
                                      : '',
                                }">
                                <ng-template
                                  [ngIf]="
                                    menuItem.content[activeSubSectionIndex] &&
                                    menuItem.content &&
                                    menuItem.content[activeSubSectionIndex].linkedTours
                                  ">
                                  <div class="nav-sub-section-right-inner">
                                    <ng-template
                                      [ngIf]="menuItem.content[activeSubSectionIndex].linkedTours.length > 9">
                                      <!-- More than 9 items init Swiper -->
                                      <div class="swiper-header-container">
                                        <!-- One Row Carousel -->
                                        <swiper-container
                                          *ngIf="gridRows === 1"
                                          navigation="true"
                                          slides-per-view="3"
                                          slides-per-group="3"
                                          grid-rows="1"
                                          grid-fill="row"
                                          space-between="15"
                                          navigation-next-el=".swiper-header-next"
                                          navigation-prev-el=".swiper-header-prev"
                                          pagination-clickable="true"
                                          pagination-el=".swiper-header-pagination">
                                          <swiper-slide
                                            *ngFor="
                                              let linkedTour of menuItem.content[activeSubSectionIndex].linkedTours
                                            "
                                            class="nav-sub-section-tour-card">
                                            <app-content-link
                                              [url]="linkedTour.url"
                                              [title]="linkedTour.title"
                                              (linkAction)="
                                                trackSubMenuInteractionComAmplitude(
                                                  'Sub-Menu Click',
                                                  menuItem.title,
                                                  MenuSectionType.BrowseTours,
                                                  linkedTour.title
                                                )
                                              ">
                                              <img
                                                [src]="linkedTour.image.url + '?w=325&h=100&fit=fill&fm=webp'"
                                                class="img-fluid"
                                                [alt]="linkedTour.image.title" />
                                              <div class="title">{{ linkedTour.title }}</div>
                                            </app-content-link>
                                          </swiper-slide>
                                        </swiper-container>
                                        <!-- Two Row Carousel -->
                                        <swiper-container
                                          *ngIf="gridRows === 2"
                                          navigation="true"
                                          slides-per-view="3"
                                          slides-per-group="3"
                                          grid-rows="2"
                                          grid-fill="row"
                                          space-between="15"
                                          navigation-next-el=".swiper-header-next"
                                          navigation-prev-el=".swiper-header-prev"
                                          pagination-clickable="true"
                                          pagination-el=".swiper-header-pagination">
                                          <swiper-slide
                                            *ngFor="
                                              let linkedTour of menuItem.content[activeSubSectionIndex].linkedTours
                                            "
                                            class="nav-sub-section-tour-card">
                                            <app-content-link
                                              [url]="linkedTour.url"
                                              [title]="linkedTour.title"
                                              (linkAction)="
                                                trackSubMenuInteractionComAmplitude(
                                                  'Sub-Menu Click',
                                                  menuItem.title,
                                                  MenuSectionType.BrowseTours,
                                                  linkedTour.title
                                                )
                                              ">
                                              <img
                                                [src]="linkedTour.image.url + '?w=325&h=100&fit=fill&fm=webp'"
                                                class="img-fluid"
                                                [alt]="linkedTour.image.title" />
                                              <div class="title">{{ linkedTour.title }}</div>
                                            </app-content-link>
                                          </swiper-slide>
                                        </swiper-container>
                                        <!-- Full/Three Row Carousel -->
                                        <swiper-container
                                          *ngIf="gridRows === 3"
                                          navigation="true"
                                          slides-per-view="3"
                                          slides-per-group="3"
                                          grid-rows="3"
                                          grid-fill="row"
                                          space-between="15"
                                          navigation-next-el=".swiper-header-next"
                                          navigation-prev-el=".swiper-header-prev"
                                          pagination-clickable="true"
                                          pagination-el=".swiper-header-pagination">
                                          <swiper-slide
                                            *ngFor="
                                              let linkedTour of menuItem.content[activeSubSectionIndex].linkedTours
                                            "
                                            class="nav-sub-section-tour-card">
                                            <app-content-link
                                              [url]="linkedTour.url"
                                              [title]="linkedTour.title"
                                              (linkAction)="
                                                trackSubMenuInteractionComAmplitude(
                                                  'Sub-Menu Click',
                                                  menuItem.title,
                                                  MenuSectionType.BrowseTours,
                                                  linkedTour.title
                                                )
                                              ">
                                              <img
                                                [src]="linkedTour.image.url + '?w=325&h=100&fit=fill&fm=webp'"
                                                class="img-fluid"
                                                [alt]="linkedTour.image.title" />
                                              <div class="title">{{ linkedTour.title }}</div>
                                            </app-content-link>
                                          </swiper-slide>
                                        </swiper-container>
                                        <!-- Swiper Controls -->
                                        <div class="controls-next-prev">
                                          <div class="controls-inner">
                                            <div class="swiper-btn swiper-header-prev prev">
                                              <span>Prev</span>
                                            </div>
                                            <div class="swiper-header-pagination"></div>
                                            <div class="swiper-btn swiper-header-next next">
                                              <span>Next</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </ng-template>
                                    <!-- Less than 9 items init Columns -->
                                    <ng-template
                                      [ngIf]="menuItem.content[activeSubSectionIndex].linkedTours.length < 9">
                                      <div class="row">
                                        <div
                                          *ngFor="let linkedTour of menuItem.content[activeSubSectionIndex].linkedTours"
                                          class="nav-sub-section-tour-card col-lg-4 mb-3 d-flex flex-column align-items-stretch justify-content-between">
                                          <app-content-link
                                            class="d-flex flex-column align-items-stretch flex-grow-1"
                                            [url]="linkedTour.url"
                                            [title]="linkedTour.title"
                                            (linkAction)="
                                              trackSubMenuInteractionComAmplitude(
                                                'Sub-Menu Click',
                                                menuItem.title,
                                                MenuSectionType.BrowseTours,
                                                linkedTour.title
                                              )
                                            ">
                                            <img
                                              [src]="linkedTour.image.url + '?w=325&h=102&fit=fill'"
                                              width="325"
                                              class="img-fluid"
                                              [alt]="linkedTour.image.title" />
                                            <div class="title">{{ linkedTour.title }}</div>
                                          </app-content-link>
                                        </div>
                                        <!-- Dynamic builderIO entry-->
                                        <div
                                          class="nav-sub-section-tour-card col-lg-4 mb-3 d-flex flex-column align-items-stretch justify-content-between"
                                          *ngIf="
                                            subNavItemTakeOverConfig.subNavTab !== '' &&
                                            menuItem.content[activeSubSectionIndex]?.title ===
                                              subNavItemTakeOverConfig.subNavTab
                                          ">
                                          <app-content-link
                                            class="d-flex flex-column align-items-stretch flex-grow-1"
                                            [url]="subNavItemTakeOverConfig.subItemUrl"
                                            [title]="subNavItemTakeOverConfig.subItemTitle"
                                            (linkAction)="
                                              trackSubMenuInteractionComAmplitude(
                                                'Sub-Menu Click',
                                                menuItem.title,
                                                MenuSectionType.BrowseTours,
                                                subNavItemTakeOverConfig.subNavTab
                                              )
                                            ">
                                            <img
                                              [src]="subNavItemTakeOverConfig.imageUrl + '?w=325&h=102&fit=fill'"
                                              width="325"
                                              class="img-fluid"
                                              [alt]="subNavItemTakeOverConfig.subItemTitle" />
                                            <div class="title">{{ subNavItemTakeOverConfig.subItemTitle }}</div>
                                          </app-content-link>
                                        </div>
                                      </div>
                                    </ng-template>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </ng-template>
                          <!--If it has aboutUsBlocks[] then it is About Us template style-->
                          <ng-template [ngIf]="menuItem.aboutUsBlocks && menuItem.aboutUsBlocks.length > 0">
                            <div class="inner-panel-content-wrapper">
                              <div class="row">
                                <div class="col-lg-6" *ngFor="let block of menuItem.aboutUsBlocks; let j = index">
                                  <div
                                    class="nav-subsection-about-us"
                                    (mouseenter)="subSectionSelection(MenuSectionType.AboutUs, i, j)">
                                    <div class="nav-subsection-about-us-heading">
                                      <img [src]="block.icon.url" [alt]="block.icon.title" />
                                      <h3>{{ block.title }}</h3>
                                    </div>
                                    <div
                                      [innerHTML]="block.content"
                                      *ngIf="block.content"
                                      class="about-us-content-block"></div>
                                    <app-content-link
                                      *ngIf="block.linkUrl && block.linkText"
                                      [url]="block.linkUrl"
                                      [title]="block.linkText"
                                      (linkAction)="
                                        trackSubMenuInteractionComAmplitude(
                                          'Sub-Menu Click',
                                          menuItem.title,
                                          MenuSectionType.AboutUs,
                                          block.linkText
                                        )
                                      ">
                                      {{ block.linkText }}
                                    </app-content-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                          <!--If it has travelInspirationCards[] then it is Travel & inspiration template style-->
                          <ng-template
                            [ngIf]="menuItem.travelInspirationCards && menuItem.travelInspirationCards.length > 0">
                            <div class="inner-panel-content-wrapper">
                              <div class="row">
                                <div
                                  class="col-lg-3"
                                  *ngFor="let block of menuItem.travelInspirationCards; let j = index">
                                  <div
                                    class="nav-inspiration-block"
                                    (mouseenter)="subSectionSelection(MenuSectionType.TravelInspiration, i, j)">
                                    <app-content-link
                                      [url]="block.url"
                                      [title]="block.title"
                                      (linkAction)="
                                        trackSubMenuInteractionComAmplitude(
                                          'Sub-Menu Click',
                                          menuItem.title,
                                          MenuSectionType.TravelInspiration,
                                          block.title
                                        )
                                      ">
                                      <img
                                        [src]="block.image.url + '?w=310&h=120&fit=fill&fm=webp'"
                                        [alt]="block.image.title"
                                        class="img-fluid" />
                                      <span>{{ block.title }}</span>
                                    </app-content-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
        </div>
        <!--        search input form in red bar       -->
        <div class="col-12 col-lg-3 py-2 py-md-0 nav-search">
          <app-search-input></app-search-input>
        </div>
      </div>
    </div>
  </div>
  <ng-template [ngIf]="!noticeAcknowledged">
    <div class="nav-notice" #navNotice [ngClass]="{ 'tour-page': isTourPage }">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-lg-9 d-flex notice-wrapper">
            <div class="notice-inner">
              Hello i am a notice section please notice me
              <a href="#" title="important notice">I am a link</a>
            </div>
            <a
              href="javascript:void(0)"
              class="close-notice d-flex justify-content-end"
              (keyup)="noticeClosed()"
              (click)="noticeClosed()">
              <span>Close</span>
              <span>X</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
