import { GeoModel } from '@models/geo.model';
import { PromotionData } from '@app/feature/promotion/dto/types';

export class SetPromotion {
  static readonly type = '[Promotion] Set';

  constructor(public promotion: string) {}
}

// SetPromoCode
export class SetPromoCode {
  static readonly type = '[Promotion] SetPromoCode';

  constructor(public promoCode: string) {}
}

// LoadPromoCodeStyles
export class LoadPromoCodeStyles {
  static readonly type = '[Promotion] LoadPromoCodeStyles';
}

// LoadPromoCodeData
export class LoadPromoCodeData {
  static readonly type = '[Promotion] LoadPromoCodeData';

  constructor(public promoCode: string) {}
}

// ResetPromoCodeStore
export class ResetPromoCodeStore {
  static readonly type = '[Promotion] ResetPromoCodeStore';
}

export class SetPromoData {
  static readonly type = '[Promotion] Set Promo Data';

  constructor(public promoData: PromotionData) {}
}

//LoadGeoData
export class LoadGeoData {
  static readonly type = '[Promotion] Load Geo Data';
}

//SetGeoData
export class SetGeoData {
  static readonly type = '[Promotion] SetGeoData';

  constructor(public geoData: GeoModel) {}
}

export class ClearPromoItems {
  static readonly type = '[Promotion] Clear Promotion Items';
}
